/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import {
  AssociationGetter,
  AssociationMemberProps,
  AssociationRequest,
} from '~/types/association-types'

type RequestResponse = {
  message: string
}

type AssociationState = {
  associations: AssociationRequest[]
  association: AssociationGetter
  isLoading: boolean
  skeletonLoading: boolean
  isLoadingModal: boolean
  statesBrazil: []
  messageError: any
  controllerModalState: boolean
  controllerModalStateEdit: boolean
  errors: boolean
  cities: []
  associationMembers: AssociationMemberProps[]
}

type AssociationAction = {
  readonly requestAssociations: () => Promise<void>
  readonly requestAssociation: (id: number) => Promise<void>
  readonly requestCreateAssociations: (params: AssociationRequest) => Promise<void>
  readonly requestUpdateAssociations: (id: number, params: any) => Promise<void>
  readonly requestGetUsersByAssociationId: (
    associationId: number,
    requestFilter: string,
  ) => Promise<AssociationMemberProps[]>
  readonly requestDeleteUserAssociationLink: (
    associationId: number,
    userId: number,
  ) => Promise<void>
  readonly requestCreateUserAssociationLink: (
    associationId: number,
    userId: number,
  ) => Promise<void>
  readonly clearFormsCreateUser: () => void
  readonly modalControllerChangeCreate: (value: boolean) => void
  readonly listStatesBrazil: () => Promise<void>
  readonly modalControllerChangeEdit: (value: boolean) => void
}

type State = AssociationState & AssociationAction

const initialState: AssociationState = {
  associations: [],
  association: {} as AssociationGetter,
  isLoading: false,
  skeletonLoading: false,
  isLoadingModal: false,
  messageError: '',
  controllerModalState: false,
  controllerModalStateEdit: false,
  errors: false,
  statesBrazil: [],
  cities: [],
  associationMembers: [],
}

export const useAssociationStore = create<State>((set, get) => ({
  ...initialState,
  requestAssociations: async () => {
    try {
      set({ isLoading: true })
      const { data: associations } = await api.get('association/')
      set({
        isLoading: false,
        associations: associations.association,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  requestAssociation: async (id: number) => {
    const { data } = await api.get(`association/${id}`)
    set((state) => {
      state.association = data
    })
  },
  modalControllerChangeEdit: (value) => {
    set({
      controllerModalStateEdit: value,
    })
  },
  requestCreateAssociations: async (params: AssociationRequest) => {
    const { clearFormsCreateUser, requestAssociations, modalControllerChangeCreate } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .post('association/', params)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          errors: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Cadastrado com sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalControllerChangeCreate(false)
        })
        requestAssociations()
        clearFormsCreateUser()
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errors: true,
        })
      })
  },
  requestUpdateAssociations: async (id: number, params: AssociationRequest) => {
    const { requestAssociations, modalControllerChangeEdit, requestAssociation } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .put(`/association/${id}`, params)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          skeletonLoading: false,
        })
        requestAssociation(id)
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          confirmButtonText: 'OK',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalControllerChangeEdit(false)
        })
        requestAssociations()
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errors: true,
        })
      })
  },
  clearFormsCreateUser: () => {
    set({ messageError: '', errors: false, isLoading: false })
  },
  modalControllerChangeCreate: (value) => {
    set({
      controllerModalState: value,
    })
  },
  listStatesBrazil: async () => {
    try {
      const { data } = await api.get('/limit/estados')
      set({
        statesBrazil: data,
      })
    } catch (error: any) {
      set({
        messageError: error?.response?.data?.message,
      })
    }
  },
  requestGetUsersByAssociationId: async (associationId: number) => {
    set({ isLoading: true })
    try {
      const { data } = await api.get<AssociationMemberProps[]>(
        `/users/association/${associationId}`,
      )
      set({ associationMembers: data })
      return data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
  requestDeleteUserAssociationLink: async (associationId: number, userId: number) => {
    set({ isLoading: true, messageError: '' })
    try {
      const { data } = await api.delete<RequestResponse>(
        `/association/link/user?associacao_id=${associationId}&usuario_id=${userId}`,
      )
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  requestCreateUserAssociationLink: async (associationId: number, userId: number) => {
    set({ isLoading: true, messageError: '' })
    try {
      const { data } = await api.post<RequestResponse>(
        `/association/link/user?associacao_id=${associationId}&usuario_id=${userId}`,
      )
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      set({ messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
}))
