import { ReactElement } from 'react'

import { Box } from '@material-ui/core'
import clsx from 'clsx'

import {
  plantupIntelligenceImg as upImg,
  plantupIntelligenceImg as upLightImg,
  plantupIntelligenceImg,
} from '~/assets'
import { useStyles } from '~/layouts/app/components/drawer-menu/styles'

interface Props {
  readonly isOpen: boolean
}

export function HeaderMenu({ isOpen }: Props): ReactElement {
  const classes = useStyles()

  return (
    <>
      <Box
        className={clsx(classes.toolbarIcon, classes.divLogo, {
          [classes.bgGrey]: isOpen,
        })}
      >
        {!isOpen ? (
          <img src={upImg} alt="Logo" className={classes.upLight} />
        ) : (
          <img src={upLightImg} alt="Logo" className={classes.upLight} />
        )}
      </Box>
      <Box className={classes.centerLogo}>
        <img alt="Logo" src={plantupIntelligenceImg} className={classes.plantupIntelligence} />
      </Box>
    </>
  )
}
