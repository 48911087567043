export const CpfCnpjMask = (value: string) => {
  if (!value) return '-'

  if (value.length <= 11) {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
}

export const FormatPhone = (value: string) => {
  if (!value) return '-'

  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1)$2')
    .replace(/(\d)(\d{4})(\d)/g, ' $1$2-$3')
}

export const FormatPhoneWithCountry = (pais_celular: string, celular: string) => {
  pais_celular = pais_celular ?? '55'
  celular = celular ?? '-'

  if (pais_celular.length === 2)
    return `+${pais_celular} ${celular
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1)$2')
      .replace(/(\d)(\d{4})(\d)/g, ' $1$2-$3')}`

  return `+${pais_celular} ${celular.replace(/\D/g, '').replace(/^(\d{3})(\d)/g, '$1 $2')}`
}

export const FormatCep = (value: String) => {
  if (!value) return '-'

  return value.replace(/\D/g, '').replace(/^(\d{5})(\d)/g, '$1-$2')
}

export const removeCountryOfPhone = (value: string, pais_celular?: string) => {
  if (!value) return '-'

  pais_celular = pais_celular ?? '55'
  let phone = value.replace(`+${pais_celular}`, '')
  phone = phone.replace(/\D/g, '')
  return phone
}
