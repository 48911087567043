import { ReactElement, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid'
import { Delete } from '@material-ui/icons'
import { createFilterOptions } from '@material-ui/lab'
import Autocomplete from '@mui/material/Autocomplete'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useAssociationStore } from '~/store/association/association-store'
import { useUserStore } from '~/store/user/user-store'
import { AssociationMemberProps } from '~/types/association-types'
import { RequestDataUser } from '~/types/user-types/user-types'
import { FormatPhoneWithCountry } from '~/utils'

import strings from './strings'
import { useStyles } from './styles'

type RouteParamsProps = {
  associationId: string
}

type Props = {
  userRequestFilter: string
}

interface UserListI {
  nome: string
  email: string
  id: number
}

const schema = yup.object().shape({
  usuario_id: yup.string().required('Campo obrigatório'),
})

function AssociationMemberList(props: Props): ReactElement {
  const OPTIONS_LIMIT = 80
  const classes = useStyles()
  const { userRequestFilter } = props
  const defaultFilterOptions = createFilterOptions()
  const filterOptions = (options: UserListI[], state: any) =>
    defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)

  const [usuario, setUsuario] = useState<string | null>(null)
  const { associationId: associationIdRouteParam } = useParams<RouteParamsProps>()
  const associationId = Number(associationIdRouteParam)
  const { getUserAssociatedMembers } = useUserStore()
  const [associationMembers, setAssociationMembers] = useState<AssociationMemberProps[]>([])
  const [listAllUsers, setListAllUsers] = useState<RequestDataUser[]>([])
  const [pageSize, setPageSize] = useState<number>(5)

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    context: '',
  })

  const {
    messageError,
    requestGetUsersByAssociationId,
    requestCreateUserAssociationLink,
    requestDeleteUserAssociationLink,
  } = useAssociationStore()

  const loadAllUsers = async () => setListAllUsers(await getUserAssociatedMembers())

  const loadAssociationMembers = async () =>
    setAssociationMembers(await requestGetUsersByAssociationId(associationId, userRequestFilter))

  useEffect(() => {
    const loadInitialData = async () => {
      await loadAssociationMembers()
      await loadAllUsers()
    }

    loadInitialData()
  }, [])

  const onSubmit: SubmitHandler<any> = async () => {
    await requestCreateUserAssociationLink(associationId, getValues('usuario_id'))
    await loadAssociationMembers()
    setUsuario(null)
  }

  const deleteRelacionamento = async (userId: number) => {
    const alertResult = await Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja remover o vínculo do Usuário com a Associação?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    })

    if (alertResult.isConfirmed) {
      await requestDeleteUserAssociationLink(associationId, userId)
      await loadAssociationMembers()
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      flex: 0.3,
    },
    {
      field: 'role',
      headerName: 'Cargo',
      width: 150,
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.role?.nome
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      flex: 0.3,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.celular
          ? FormatPhoneWithCountry(params.row?.pais_celular, params.row?.celular)
          : '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return <Typography variant="body1">{params.value}</Typography>
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value === '1' ? 'Ativo' : 'Inativo'
      },
    },
    {
      field: 'id',
      headerName: ' ',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            id="button-delete-equipe"
            variant="contained"
            color="secondary"
            className={classes.buttonDelete}
            onClick={() => deleteRelacionamento(params.value)}
          >
            <Delete />
          </Button>
        )
      },
    },
  ]

  return (
    <Paper elevation={8} className={classes.paper}>
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} sm={12} container>
          <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">{strings.title}</Typography>
            <Grid item>
              <Grid container item>
                <Controller
                  name="usuario_id"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Autocomplete<any>
                      {...field}
                      id={(getValues('usuario_id') || '').toString()}
                      options={listAllUsers}
                      fullWidth
                      filterOptions={filterOptions}
                      value={usuario}
                      sx={{ width: 425 }}
                      getOptionLabel={(option: UserListI) => (option.nome ? option.nome : '')}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderOption={(props, option: UserListI) => (
                        <ListItem {...props} key={String(option.id)}>
                          <ListItemText
                            primary={option.nome}
                            secondary={option.email}
                            classes={{
                              primary: classes.primary,
                              secondary: classes.secundary,
                            }}
                          />
                        </ListItem>
                      )}
                      onChange={(_event, value) => {
                        setValue('usuario_id', value?.id)
                        setUsuario(value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          InputLabelProps={{
                            classes: {
                              root: classes.root,
                            },
                          }}
                          key={String(params.id)}
                          variant="outlined"
                          label="Selecione..."
                          placeholder="Selecione..."
                          className={classes.textInput}
                          helperText={errors.usuario_id?.message || messageError?.usuario_id}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                        />
                      )}
                    />
                  )}
                />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  id="button-add-equipe"
                >
                  {strings.addUser}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} className={classes.resumeTable}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25]}
            rows={associationMembers}
            columns={columns}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
            autoHeight
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AssociationMemberList
