import React, { ReactElement, useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { GridCellParams, GridColDef, DataGrid, GridValueGetterParams } from '@material-ui/data-grid'
import { VerifiedUser, DoneAll } from '@material-ui/icons'
import { AxiosError } from 'axios'

import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { CultureCodesEnums, TypeSafraEmuns } from '~/enums'
import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'
import { useStoreMissions, PropsMissions } from '~/store/missions-store'

import strings from './strings'
import { useStyles } from './styles'

type PropsRows = {
  id?: number
  safra: string
  safra_id: number
  tipo_safra: number
  cultura_id: string
  cultura: string
  situacao: number
  area_cadastrada_total: string
  cliente_id: number
  missao_id: number
}

type ParamsRoute = {
  cliente_id: string
}

type Props = {
  filtros: any
}

const columnsAudit = (missions: PropsMissions[]): GridColDef[] => [
  { field: 'id', hide: true },
  {
    field: 'safra',
    headerName: 'Missão',
    flex: 0.2,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Typography component="span" variant="subtitle2">
        {missions &&
          missions.map((value) => {
            const { row } = params
            const { mission_detail } = value
            if (
              row?.safra_id === mission_detail[0]?.safra_id &&
              row?.cultura_id === mission_detail[0]?.cultura_id
            )
              return value.titulo
            return ''
          })}
      </Typography>
    ),
    valueGetter: (params: GridValueGetterParams) => {
      return (
        missions &&
        missions.map((value) => {
          const { row } = params
          const { mission_detail } = value
          if (
            row?.safra_id === mission_detail[0]?.safra_id &&
            row?.cultura_id === mission_detail[0]?.cultura_id
          )
            return value.titulo
          return ''
        })
      )
    },
  },
  {
    field: 'cultura',
    headerName: 'Cultura',
    flex: 0.2,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridCellParams) => CultureCodesEnums[params.row.cultura_id],
  },
  {
    field: 'tipo_safra',
    headerName: 'Tipo Safra',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
    type: 'string',
    renderCell: (params: GridCellParams) => TypeSafraEmuns[params.row.tipo_safra],
  },
  {
    field: 'area_cadastrada_total',
    headerName: 'Área',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'situacao_auditoria',
    headerName: 'Resultado',
    type: 'string',
    flex: 0.3,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridCellParams) => (
      <Typography
        component="span"
        variant="subtitle2"
        style={{
          color: params.row.situacao === '2' ? 'green' : 'rgb(183 138 0)',
        }}
      >
        {params.row.situacao_auditoria}
      </Typography>
    ),
  },
  {
    field: 'situacaoValue',
    headerName: 'Ações',
    description: 'Ações',
    flex: 0.1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridCellParams) => (
      <Link
        to={{
          pathname: `${AppRoutePath.AUDITFARMER.replace(':cliente_id', params.row.cliente_id)}`,
          state: {
            safra_id: params.row.safra_id,
            cultura_id: params.row.cultura_id,
            tipo_safra: params.row.tipo_safra,
            missao_id: params.row.missao_id,
          },
        }}
      >
        <Button
          id="button-aditoria-abrir"
          variant="contained"
          color="primary"
          size="small"
          endIcon={params.row.situacao === '1' ? <VerifiedUser /> : <DoneAll />}
        >
          {strings.audit.open}
        </Button>
      </Link>
    ),
  },
]

function GridAuditSummary(props: Props): ReactElement {
  const classes = useStyles()
  const { cliente_id } = useParams<ParamsRoute>()
  const { filtros } = props

  const { missions } = useStoreMissions()
  const alert = useAlert()

  const [rows, setRows] = useState<PropsRows[]>([])
  useEffect(() => {
    api
      .get(`/audit/mission/${cliente_id}`)
      .then((res: any) => {
        res.data.map((r: any, i: any) => {
          r.id = i
          return r
        })
        setRows(res.data)
      })
      .catch((error: AxiosError) =>
        alert.show({ message: error.response?.data.message, type: 'error' }),
      )
  }, [])

  return (
    <Grid xs={12} sm={12} item>
      <Paper variant="outlined" className={`${classes.paper} ${classes.paperSummaryAudit}`}>
        <Typography variant="h5" className={classes.textTitleCard}>
          Sumário de Auditoria
        </Typography>
        <DataGrid
          rows={rows}
          columns={columnsAudit(missions)}
          pageSize={25}
          disableSelectionOnClick
          localeText={GRID_DEFAULT_LOCALE_TEXT}
          autoHeight
          rowHeight={40}
          {...filtros}
        />
      </Paper>
    </Grid>
  )
}

export default GridAuditSummary
