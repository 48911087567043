import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { Grid, TextField, MenuItem, Fade, FormControl, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import 'react-phone-input-2/lib/material.css'

import Modal from '~/components/modal'
import { Status } from '~/constants/data-field'
import { useAlert } from '~/hooks/message'
import { consultancyEditMapper } from '~/mappers/consultancy-mapper'
import { useCepStore } from '~/store/cep-store'
import { useCityStore } from '~/store/city-store'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { FormsHandleEdit } from '~/types/consultancy-types'
import { FormatPhoneWithCountry } from '~/utils'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'

const EditUser: React.ForwardRefRenderFunction<FormsHandleEdit> = (props, ref) => {
  const classes = useStyles()

  const {
    isLoading,
    messageError,
    modalControllerChangeEdit,
    controllerModalStateEdit,
    statesBrazil,
    Consultacy,
    requestUpdateConsultancy,
    clearFormsCreateUser,
    listStatesBrazil,
  } = useConsultacyStore()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<any>({})
  const alert = useAlert()
  const [cities, setCities] = useState<any[]>([])
  const { listCitiesByStateId } = useCityStore()
  const onSubmit: SubmitHandler<any> = async (usersData) => {
    requestUpdateConsultancy(Consultacy?.id, consultancyEditMapper(usersData))
  }
  const { find: findCepStore, isLoading: isLoadingCep } = useCepStore()
  const findCep = async (cep: string) => {
    const viacep = await findCepStore(cep.replace('-', ''))

    clearFormsCreateUser()
    if (viacep.erro) {
      alert.show({ message: 'CEP não encontrado!', type: 'error' })
      return
    }
    setValue('bairro', viacep.bairro, { shouldDirty: true })
    setValue('rua', viacep.logradouro, { shouldDirty: true })
    setValue('complemento', viacep.complemento, { shouldDirty: true })
    const state: any = statesBrazil.find(({ sigla }) => sigla === viacep.uf)

    if (state) {
      setValue('estado', state?.id ?? '', { shouldDirty: true })

      listCitiesByStateId(state?.id).then((data) => {
        setCities(data.cidades)
        setValue(
          'cidade_id',
          data?.cidades?.find(
            (value: any) =>
              value.descricao === viacep.localidade.normalize('NFD').replace(/\p{Mn}/gu, ''),
          ) ?? '',
          { shouldDirty: true },
        )
      })
    }
  }

  const closeModal = () => {
    modalControllerChangeEdit(false)
    clearFormsCreateUser()
  }
  useEffect(() => {
    setCities([])
    listStatesBrazil()
    setValue('cidade_id', Consultacy?.city)
    if (Consultacy?.city?.state?.sigla)
      listCitiesByStateId(Number(Consultacy?.city?.state?.id)).then((data) =>
        setCities(data.cidades),
      )
  }, [controllerModalStateEdit])

  useImperativeHandle(ref, () => {
    return {
      async openModalEditForms() {
        modalControllerChangeEdit(true)
        reset()
      },
    }
  })

  return (
    <Modal
      open={controllerModalStateEdit}
      onClose={closeModal}
      title={strings.textModal.editModalTitle}
      onSave={handleSubmit(onSubmit)}
      save
      isLoad={isLoading}
      disableCloseClickOut
      scroll
    >
      <Fade in={controllerModalStateEdit}>
        <FormControl
          onSubmit={handleSubmit(onSubmit)}
          variant="outlined"
          className={classes.formStyle}
        >
          <Grid container spacing={2} className={classes.modalStyle}>
            <Grid item sm={6}>
              <Controller
                name="nome"
                control={control}
                defaultValue={Consultacy.nome ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="nome"
                    variant="outlined"
                    label="Nome completo"
                    placeholder="Nome completo"
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={(!!errors?.nome || !_.isEmpty(messageError?.nome)) && true}
                    helperText={errors.nome?.message ?? messageError?.nome}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="fantasia"
                control={control}
                defaultValue={Consultacy.fantasia ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="fantasia"
                    variant="outlined"
                    label="Nome Fantasia"
                    placeholder="Nome Fantasia"
                    className={classes.textInput}
                    error={!!errors?.fantasia}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.fantasia?.message}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="documento"
                control={control}
                defaultValue={Consultacy?.documento ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="documento"
                    variant="outlined"
                    label="CPF/CNPJ"
                    placeholder="CPF/CNPJ"
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={(!!errors?.documento || !_.isEmpty(messageError?.documento)) && true}
                    helperText={errors.documento?.message ?? messageError?.documento}
                    value={
                      field.value
                        ? maskInterceptor(
                            field?.value?.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj',
                          )(field.value)
                        : ''
                    }
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="email"
                control={control}
                defaultValue={Consultacy?.email ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="email"
                    variant="outlined"
                    label="E-mail"
                    placeholder="E-mail"
                    className={classes.textInput}
                    error={(!!errors?.email || !_.isEmpty(messageError?.email)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.email?.message ?? messageError?.email}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="telefone"
                control={control}
                defaultValue={FormatPhoneWithCountry('55', Consultacy?.celular)}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    required
                    id="celular"
                    label="Celular"
                    defaultCountry="br"
                    variant="outlined"
                    onlyCountries={['br']}
                    error={(!!errors?.telefone || !_.isEmpty(messageError?.telefone)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.telefone?.message ?? messageError?.telefone}
                    InputProps={{
                      style: {
                        borderRadius: 30,
                        width: '100%',
                        color: '#00446B',
                        borderColor: 'red',
                      },
                    }}
                    className={classes.textInput}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="cep"
                defaultValue={Consultacy?.cep ?? ''}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <TextField
                    {...field}
                    required
                    id="cep"
                    variant="outlined"
                    label="CEP"
                    placeholder="CEP"
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={(!!errors?.cep || !_.isEmpty(messageError?.cep)) && true}
                    helperText={errors.cep?.message ?? messageError?.cep}
                    value={maskInterceptor('zipCode')(field.value)}
                    InputProps={{
                      endAdornment: <>{isLoadingCep ? <CircularProgress size={15} /> : ''}</>,
                    }}
                    onChange={(value) => {
                      if (value.target.value.length === 9) findCep(value.target.value)
                      return onChange(value)
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="estado"
                control={control}
                defaultValue={Consultacy?.city?.state?.id ?? []}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="estado"
                    variant="outlined"
                    label="Estado"
                    placeholder="Estado"
                    onChange={async (event: any) => {
                      setValue('estado', event.target.value)
                      const citiesData = await listCitiesByStateId(Number(event.target.value))
                      setCities(citiesData.cidades)
                    }}
                    className={classes.textInput}
                    error={!!errors?.estado_id}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.estado_id?.message}
                  >
                    {statesBrazil.map((Option: any) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="cidade_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    key={Math.random()}
                    id="textfield-editar-cliente"
                    getOptionLabel={(option) => option?.descricao || ''}
                    options={cities}
                    getOptionSelected={(option, value) => {
                      return option.descricao === value.descricao
                    }}
                    onChange={(e, value) => {
                      field.onChange(value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        variant="outlined"
                        label="Selecione a Cidade"
                        placeholder="Selecione a Cidade"
                        className={classes.textInput}
                        FormHelperTextProps={{
                          className: classes.helperText,
                        }}
                        helperText={errors.cidade_id?.message || messageError?.cidade_id}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="rua"
                defaultValue={Consultacy?.rua ?? ''}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="rua"
                    variant="outlined"
                    label="Rua"
                    className={classes.textInput}
                    error={(!!errors?.rua || !_.isEmpty(messageError?.rua)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.rua?.message ?? messageError?.rua}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="bairro"
                defaultValue={Consultacy?.bairro ?? ''}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="bairro"
                    variant="outlined"
                    label="Bairro"
                    className={classes.textInput}
                    error={(!!errors?.bairro || !_.isEmpty(messageError?.bairro)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.bairro?.message ?? messageError?.bairro}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="complemento"
                control={control}
                defaultValue={Consultacy?.complemento ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="complemento"
                    variant="outlined"
                    label="Complemento"
                    className={classes.textInput}
                    error={(!!errors?.complemento || !_.isEmpty(messageError?.complemento)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.complemento?.message ?? messageError?.complemento}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="numero"
                control={control}
                defaultValue={Consultacy?.numero ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="numero"
                    variant="outlined"
                    label="Número"
                    className={classes.textInput}
                    error={(!!errors?.numero || !_.isEmpty(messageError?.numero)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.numero?.message ?? messageError?.numero}
                  />
                )}
              />
            </Grid>

            <Grid item sm={4}>
              <Controller
                name="situacao"
                control={control}
                defaultValue={Number(Consultacy?.situacao) ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="situacao"
                    variant="outlined"
                    label="Situação"
                    placeholder="Situacao"
                    className={classes.textInput}
                    error={!!errors?.situacao}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.situacao?.message}
                  >
                    {Status.map((Option) => (
                      <MenuItem key={Option.statusValue} value={Option.statusValue}>
                        {Option.statusName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Fade>
    </Modal>
  )
}

export default forwardRef(EditUser)
