import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    '@media (max-width: 1366px)': {
      minHeight: 670,
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '2px',
        height: '4.5px',
      },
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },
  gridTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleFarmer: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  textLeft: {
    textAlign: 'left',
    padding: theme.spacing(1),
  },
  textRight: {
    textAlign: 'end',
    padding: theme.spacing(1),
  },
  gridContent: {
    justifyContent: 'center',
  },
  divider: {
    height: 1.5,
  },
  selectInput: {
    width: '100%',
  },
  gridButtonSubmit: {
    textAlign: 'right',
  },
  listPage: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    placeContent: 'center',
  },
  gridCenter: {
    justifyContent: 'center',
  },
  textInput: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  gridMargin: {
    margin: 0,
  },
  paperFullHeight: {
    height: '100%',
    padding: theme.spacing(2, 0),
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '5px',
    },
    '*::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '*::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  },
  gridList: {
    maxHeight: '100%',
  },
  gridMid: {
    maxHeight: 'calc(86.3vh - 333px)',
  },
  iconBack: {
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: 90,
  },

  bodyInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '24px 0px',
    padding: '8px 0px',
  },

  paperInfoFarmer: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
}))
