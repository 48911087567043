const strings = {
  welcome: 'Seja bem vindo(a)',
  presentation: {
    start: 'Essa é a nova cara do ',
    middle: 'PlantUp',
    end: ' Administrativo, mais rápido, moderno e simplificado.',
  },
  fastAccess: 'Acesso Rápido.',
  fastAccessConsultancy: 'Acesse, gerencie e altere a consultoria e seus dados agora mesmo!',
  audit: {
    title: 'Auditoria',
    subtitle: 'Auditar',
  },
}

export default strings
