import { ThemeOptions } from '@material-ui/core/styles'

import commonTheme from './common-theme'

const darkTheme = {
  ...commonTheme,
  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#red',
    },
    text: {
      primary: '#FFF',
      secondary: '#525252',
      light: '#ffffff',
    },
  },
} as ThemeOptions

export default darkTheme
