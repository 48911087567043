import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  bodyInformation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    flexGrow: 1,
  },
  gridTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  titleFarmer: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  textLeft: {
    textAlign: 'left',
    padding: theme.spacing(1),
  },
  textRight: {
    textAlign: 'end',
    padding: theme.spacing(1),
  },
  gridContent: {
    justifyContent: 'center',
  },
  divider: {
    height: 1.5,
  },
  selectInput: {
    width: '100%',
  },
  gridButtonSubmit: {
    textAlign: 'right',
  },
  gridCenter: {
    justifyContent: 'center',
  },
  textInput: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  gridMargin: {
    margin: 0,
  },
  paperFullHeight: {
    height: '100%',
    padding: theme.spacing(2, 0),
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '5px',
    },
    '*::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '*::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  },
  gridList: {
    maxHeight: '100%',
  },
  gridMid: {
    maxHeight: 'calc(86.3vh - 333px)',
  },
}))
