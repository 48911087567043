import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(2, auto)',
    padding: '0px 16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    height: '56px',
    borderRadius: '24px',
    backgroundColor: '#fff',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  cardColumnTitle: {
    width: '100%',
    margin: '8px 4px 0px 8px',
    display: 'flex',
    flexDirection: 'row',
  },
  cardTitle: {
    color: '#173A58',
    height: '20px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    marginBottom: '8px',
    textTransform: 'uppercase',

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: '100%',
      '&:after': {
        borderBottom: `1px solid ${theme.palette.divider}`,
        content: '',
        right: '-100%',
      },
    },
  },
  cardText: {
    margin: '0px',
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '8px',
    color: '#173A58',
    height: '40px',
  },
}))
