import React from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonGroup } from '@material-ui/core'
import { GridColDef, DataGrid, GridColumnHeaderParams } from '@material-ui/data-grid'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Swal from 'sweetalert2'

import SearchDataGrid from '~/components/search-data-grid'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { useFilterConsultancyState } from '~/store/consultancy/filter-consultancy'
import { maskInterceptor } from '~/utils/mask-interceptor'

const WaitingGrid: React.FC = () => {
  const { items } = useFilterConsultancyState()
  const setFilter = useFilterConsultancyState((state) => state.setFilter)
  const DISAPPROVED_STATUS = 2
  const APPROVED_STATUS = 1

  const checkActionConsultancy = (
    consultancyID: number,
    status: number,
    message: string,
    colorButtonConfirm: string,
    colorButtonCancel: string,
  ) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: message,
      confirmButtonColor: colorButtonConfirm,
      showCancelButton: true,
      cancelButtonColor: colorButtonCancel,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        updateAndDeprecate(consultancyID, status)
      } else {
        Swal.close()
      }
    })
  }

  const { listWaiting, updateAndDeprecate } = useConsultacyStore()

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 0.2,
      width: 150,
      minWidth: 120,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'fantasia',
      headerName: 'Nome Fantasia',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'documento',
      headerName: 'CPF/CNPJ',
      width: 120,
      flex: 0.2,
      renderCell: (params) =>
        params?.row?.documento.length === 11
          ? maskInterceptor('cpf')(params.row?.documento)
          : maskInterceptor('cnpj')(params.row?.documento),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 120,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => maskInterceptor('phone')(params.row?.celular),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      flex: 0.2,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      renderCell: (params) => params?.row?.city.descricao,
      valueGetter: (params) => params?.row?.city.descricao,
    },
    {
      field: 'reprovar',
      headerName: 'Aprovar/Reprovar',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <ButtonGroup disableElevation variant="contained">
            <Button
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={() => {
                checkActionConsultancy(
                  params?.row?.id,
                  APPROVED_STATUS,
                  'Deseja realmente aprovar este cadastro?',
                  '#4cd7c1',
                  '#FF5C6C',
                )
              }}
            >
              <CheckIcon />
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#ff5c6c',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={() => {
                checkActionConsultancy(
                  params?.row?.id,
                  DISAPPROVED_STATUS,
                  'Deseja realmente reprovar este cadastro?',
                  '#FF5C6C',
                  '#2b78c0',
                )
              }}
            >
              <CloseIcon />
            </Button>
          </ButtonGroup>
        )
      },
    },
    {
      field: 'acoes',
      headerName: 'Exibir',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Link to={`${AppRoutePath.CONSULTANCYDETAIL.replace(':consultancyId', params?.row?.id)}`}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
            >
              <VisibilityIcon />
            </Button>
          </Link>
        )
      },
    },
  ]

  return (
    <DataGrid
      rows={listWaiting}
      style={{
        maxHeight: '66vh',
        minHeight: '66vh',
        height: '100%',
      }}
      columns={columns}
      disableSelectionOnClick
      filterModel={{ items }}
      onFilterModelChange={(model) => setFilter(model)}
      localeText={GRID_DEFAULT_LOCALE_TEXT}
    />
  )
}

export default WaitingGrid
