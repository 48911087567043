const strings = {
  usersPage: {
    title: 'Associações',
  },
  buttonTitle: {
    title: 'Adicionar Associação',
  },

  buttonAssociationTitle: 'Detalhes',
}

export default strings
