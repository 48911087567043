import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderStartStartRadius: '0px',
    borderStartEndRadius: '0px',
  },
  headerProperty: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
  },
  resumeTable: {
    flex: 1,
    display: 'flex',
    width: 'auto',
  },
}))
