import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import _ from 'lodash'

import { Status } from '~/constants/data-field'
import { useGrowCropsStore } from '~/store/variety/growcrops'
import { VorietyTransterInputProps } from '~/types/grow-crops-types'

import { ModalPostTechnologyGenetic } from '../modal-post'
import { useStyles } from './styles'

interface Props {
  nameField: string
  label: string
}

const CULTURA_SOJA = '1'

const GrowCropsForms: React.FC = () => {
  const [title, setTitle] = useState<Props>({} as Props)
  const classes = useStyles()
  const [cultureIdValue, setCultureIdValue] = useState('')

  const {
    cultureList,
    geneticList,
    tecnologyList,
    setCultureValue,
    modalPostController,
    messageError,
  } = useGrowCropsStore()

  const {
    control,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext<VorietyTransterInputProps>()

  useEffect(() => {
    const cultureId = getValues('cultura_id')
    if (cultureId !== cultureIdValue) {
      setCultureIdValue(cultureId)
    }
  }, [watch('cultura_id'), cultureIdValue, getValues])

  const handleDisableMaturationField = (variety: string | number): boolean => {
    return Number(variety) === Number(CULTURA_SOJA)
  }

  const handlePopperForms = (label: string, nameField: string) => {
    modalPostController(true)
    setTitle({
      label,
      nameField,
    })
  }

  return (
    <>
      <Grid container spacing={3} className={classes.modalStyle}>
        <Grid item sm={6}>
          <Controller
            name="cultura_id"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" className={classes.selectInput}>
                <InputLabel id="cultura_label">Cultura*</InputLabel>
                <Select
                  {...field}
                  labelId="cultura_label"
                  id="textfield-cultura"
                  label="Cultura"
                  autoWidth
                  required
                  defaultValue=""
                  error={(!!errors?.cultura_id || !_.isEmpty(messageError?.cultura_id)) && true}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setCultureValue(getValues('cultura_id'))
                  }}
                >
                  {cultureList.map((Option) => (
                    <MenuItem key={Option.id} value={Option.id}>
                      {Option.nome}
                    </MenuItem>
                  ))}
                </Select>
                {errors.cultura_id?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.cultura_id?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                id="textfield-nome"
                variant="outlined"
                label="Nome"
                defaultValue=""
                placeholder="Nome"
                className={classes.selectInput}
                error={!!errors?.nome}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.nome?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.root}>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={() => handlePopperForms('Genética', 'genetica')}
            >
              <AddIcon />
            </IconButton>
            <Controller
              name="genetica_id"
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel id="geneticaLabel">Genética*</InputLabel>
                  <Select
                    labelId="geneticaLabel"
                    id="textfield-genetica"
                    label="Genetica"
                    autoWidth
                    required
                    defaultValue=""
                    error={(!!errors?.genetica_id || !_.isEmpty(messageError?.genetica_id)) && true}
                    {...field}
                  >
                    {geneticList.map((Option) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.nome}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.genetica_id?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.genetica_id?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Paper>
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.root}>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={() => handlePopperForms('Tecnologia', 'tecnologia')}
            >
              <AddIcon />
            </IconButton>
            <Controller
              name="tecnologia"
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel id="tecnologiaLabel">Tecnologia*</InputLabel>
                  <Select
                    labelId="tecnologiaLabel"
                    id="textfield-tecnologia"
                    label="Tecnologia"
                    autoWidth
                    required
                    defaultValue=""
                    error={(!!errors?.tecnologia || !_.isEmpty(messageError?.tecnologia)) && true}
                    {...field}
                  >
                    {tecnologyList.map((Option) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.nome}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.tecnologia?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.tecnologia?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Paper>
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="grupo_maturacao"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="textfield-maturacao"
                variant="outlined"
                label="Grupo de Maturação"
                disabled={!handleDisableMaturationField(cultureIdValue)}
                required={handleDisableMaturationField(cultureIdValue)}
                placeholder="Grupo de Maturação"
                className={classes.selectInput}
                error={
                  (!!errors?.grupo_maturacao ||
                    !_.isEmpty(messageError?.variedade?.grupo_maturacao)) &&
                  true
                }
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={
                  errors.grupo_maturacao?.message ?? messageError?.variedade?.grupo_maturacao
                }
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" className={classes.selectInput}>
                <InputLabel id="statusLabel">Status*</InputLabel>
                <Select
                  labelId="statusLabel"
                  id="textfield-status"
                  label="Status"
                  defaultValue=""
                  autoWidth
                  required
                  error={(!!errors?.status || !_.isEmpty(messageError?.status)) && true}
                  {...field}
                >
                  {Status.map((Option) => (
                    <MenuItem key={Option.statusValue} value={Option.statusValue}>
                      {Option.statusName}
                    </MenuItem>
                  ))}
                </Select>
                {errors.status?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.status?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <ModalPostTechnologyGenetic label={title.label} nameField={title.nameField} />
    </>
  )
}
export default GrowCropsForms
