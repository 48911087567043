const strings = {
  document: 'CPF/CNPJ',
  mail: 'E-mail',
  phone: 'Celular',
  premium: 'Cliente PlantUP IA',
  status: 'Situação',
  address: 'Endereço',
  source: 'Origem',
  registerIn: 'Cadastrado em',
}

export default strings
