import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    minHeight: 690,
    '@media (max-width: 1366px)': {
      minHeight: 670,
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '2px',
        height: '4.5px',
      },
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },
  iconBack: {
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: 90,
  },
  bodyInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '24px 0px',
    paddingBottom: '8px',
  },
  tabs: {
    color: '#A8A8A8',
    minHeight: 'fit-content',
    minWidth: 112,
    padding: theme.spacing(1.5, 2, 1, 2),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'relative',
    overflow: 'visible',
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        right: -2 * theme.shape.borderRadius,
        height: theme.shape.borderRadius,
        width: 2 * theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        zIndex: -1,
        boxShadow: `${-theme.shape.borderRadius}px 0 0 0 ${theme.palette.background.paper}`,
      },
    },
    '&:not(:first-of-type)': {
      '&.Mui-selected::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: -2 * theme.shape.borderRadius,
        height: theme.shape.borderRadius,
        width: 2 * theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        zIndex: -1,
        boxShadow: `${theme.shape.borderRadius}px 0 0 0 ${theme.palette.background.paper}`,
      },
    },
  },
}))
