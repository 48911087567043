import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import { ContactProps } from '~/types/contacts-types'

type ContactState = {
  isLoading: boolean
  messageError: any
  listContacts: ContactProps[]
}

type ResponseSuccess = {
  message: string
}

type ContactActions = {
  getContactsByClientId: (clientId: number | string) => Promise<ContactProps[]>
  deleteContact: (contactId: number) => Promise<void>
  addContact: (clientId: number | string, dados: ContactProps) => Promise<void>
  editContact: (contactId: number, dados: ContactProps) => Promise<void>
}

type State = ContactState & ContactActions

const initialState: ContactState = {
  isLoading: false,
  messageError: [],
  listContacts: [],
}

export const useContactStore = create<State>((set) => ({
  ...initialState,
  getContactsByClientId: async (clientId) => {
    set({ isLoading: true })
    try {
      const { data: listContacts } = await api.get<ContactProps[]>(`/contact/client/${clientId}`)
      set({ listContacts })
      return listContacts
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
  addContact: async (clientId, dados) => {
    set({ isLoading: true, messageError: '' })
    try {
      const resp = await api.post<ResponseSuccess>('/contact', { ...dados, cliente_id: clientId })
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: resp.data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  deleteContact: async (contactId) => {
    set({ isLoading: true })
    try {
      const resp = await api.delete<ResponseSuccess>(`/contact/${contactId}`)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: resp.data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  editContact: async (contactId, dados) => {
    set({ isLoading: true })
    try {
      const resp = await api.put<ResponseSuccess>(`/contact/${contactId}`, { ...dados })
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: resp.data.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
}))
