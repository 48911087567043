import React from 'react'

import {
  Card,
  CardContent,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  LinearProgress,
} from '@material-ui/core'
import { Forward } from '@material-ui/icons'

import Modal from '~/components/modal'
import { useValidationStore } from '~/store/validation/validation-store'
import { IValidationHistory } from '~/types/validation-types'

import { CardHeaderHistory } from '../card-header-history'
import strings from './strings'
import { useStyles } from './styles'

interface IChangeHistory {
  action: () => void
  state: boolean
  history: IValidationHistory[]
}

export const ChangeHistory: React.FC<IChangeHistory> = ({ action, state, history }) => {
  const { isLoadingButton, isLoadingHistory } = useValidationStore()

  const classes = useStyles()

  return (
    <Modal
      title={strings.title}
      open={state}
      disableCloseClickOut
      isLoad={isLoadingButton}
      onClose={action}
    >
      {isLoadingHistory ? (
        <LinearProgress />
      ) : (
        <Grid className={classes.modalStyle}>
          {history.map((item) => (
            <React.Fragment key={item.fazenda}>
              <Grid container sm={12} className={classes.cardHeaderInfo}>
                <CardHeaderHistory itemHistory={item} />
              </Grid>
              <Grid container justifyContent="space-between" alignContent="space-between" sm={12}>
                <Grid item sm={5}>
                  <Card>
                    <CardContent>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.headerTable}>{strings.field}</TableCell>
                              <TableCell className={classes.headerTable}>{strings.value}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.situacao_new && item.situacao_old ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  {strings.oldSituation}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    id="chips-status"
                                    label={item.situacao_old === '2' ? 'Aprovado' : 'Pendente'}
                                    style={
                                      item.situacao_old === '2'
                                        ? {
                                            backgroundColor: '#24d2b5',
                                            color: '#fff',
                                            fontSize: 16,
                                            fontWeight: 600,
                                          }
                                        : {
                                            backgroundColor: '#ff5c6c',
                                            color: '#fff',
                                            fontSize: 16,
                                            fontWeight: 600,
                                          }
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.produtividade_old && item.produtividade_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  Produtividade Antiga
                                </TableCell>
                                <TableCell>{item.produtividade_old}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.produtividade_pluma_old && item.produtividade_pluma_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  Produtividade de pluma Antiga
                                </TableCell>
                                <TableCell>{item.produtividade_pluma_old}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.area_plantada_old && item.area_plantada_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  Area Plantada Antiga
                                </TableCell>
                                <TableCell>{item.area_plantada_old}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.talhao_old && item.talhao_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>Talhão Antigo</TableCell>
                                <TableCell>{item.produtividade_pluma_old}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}
                            {item.cidade_new && item.cidade_old ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>Cidade Antiga</TableCell>
                                <TableCell>{item.cidade_descricao_old}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item sm={2} className={classes.arrowIcon}>
                  <Forward className={classes.iconSize} />
                </Grid>

                <Grid item sm={5}>
                  <Card>
                    <CardContent>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className={classes.headerTable}>{strings.field}</TableCell>
                              <TableCell className={classes.headerTable}>{strings.value}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.situacao_new && item.situacao_old ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  {strings.newSituation}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    id="chips-status"
                                    label={item.situacao_new === '1' ? 'Pendente' : 'Aprovado'}
                                    style={
                                      item.situacao_new === '1'
                                        ? {
                                            backgroundColor: '#ff5c6c',
                                            color: '#fff',
                                            fontSize: 16,
                                            fontWeight: 600,
                                          }
                                        : {
                                            backgroundColor: '#24d2b5',
                                            color: '#fff',
                                            fontSize: 16,
                                            fontWeight: 600,
                                          }
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.produtividade_old && item.produtividade_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  Produtividade Nova
                                </TableCell>
                                <TableCell>{item.produtividade_new}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.produtividade_pluma_old && item.produtividade_pluma_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  Produtividade de pluma Nova
                                </TableCell>
                                <TableCell>{item.produtividade_pluma_new}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.area_plantada_old && item.area_plantada_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>
                                  Area Plantada Nova
                                </TableCell>
                                <TableCell>{item.area_plantada_new}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}

                            {item.talhao_old && item.talhao_new ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>Talhão Novo</TableCell>
                                <TableCell>{item.talhao_new}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}
                            {item.cidade_new && item.cidade_old ? (
                              <TableRow>
                                <TableCell className={classes.cellTable}>Cidade Nova</TableCell>
                                <TableCell>{item.cidade_descricao_new}</TableCell>
                              </TableRow>
                            ) : (
                              <TableRow style={{ borderBottom: '1px solid #ddd' }}>
                                <TableCell colSpan={0} style={{ padding: '0px' }} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Modal>
  )
}
