const strings = {
  growCropsPage: {
    title: 'Microrregiões',
  },
  buttonTitle: {
    title: 'Adicionar Microrregião',
  },
  textModal: {
    addModalTitle: 'Cadastro Microrregião',
    editModalTitle: 'Editar Microrregião',
  },
}

export default strings
