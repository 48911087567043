import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    width: '100%',
    maxWidth: '700px',
    minWidth: '700px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  logoItelligence: {
    width: '60%',
    marginBottom: '15px',
  },

  forgotPassword: {
    textAlign: 'left',
    alignSelf: 'center',
    gap: '8px',
  },

  iconForgotPassword: {
    verticalAlign: 'top',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '70%',
    borderRadius: '17px',
    height: '45px',
  },

  submitLogin: {
    backgroundColor: 'rgb(23,58,88)',
    border: '1px solid rgb(23,58,88)',
  },

  alert: {
    width: '50%',
  },

  selectInteration: {
    float: 'left',
    minWidth: '100%',
  },

  gridObservation: {
    marginTop: theme.spacing(2),
    width: '100%',
  },

  helperText: {
    color: theme.palette.error.main,
  },

  selectInput: {
    width: '100%',
  },
}))
