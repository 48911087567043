import { ReactElement, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid'
import { Delete } from '@material-ui/icons'
import { createFilterOptions } from '@material-ui/lab'
import Autocomplete from '@mui/material/Autocomplete'
import { debounce } from 'lodash'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useTeamStore } from '~/store/team-store'
import { TeamProps } from '~/types/team-types'
import { FormatPhoneWithCountry } from '~/utils'

import strings from './strings'
import { useStyles } from './styles'

type Props = {
  cliente_id: string
  title: string
  type: number
}

interface UserListI {
  nome: string
  email: string
  id: number
}

const schema = yup.object().shape({
  usuario_id: yup.string().required('Campo obrigatório'),
})

function EquipeClient(props: Props): ReactElement {
  const OPTIONS_LIMIT = 80

  const classes = useStyles()
  const { cliente_id, title, type } = props
  const defaultFilterOptions = createFilterOptions()
  const filterOptions = (options: UserListI[], state: any) =>
    defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)

  const { getEquipeByClientId, deleteUserCliente, addToTeam, getListUsersToTeam } = useTeamStore(
    (state) => state,
  )
  const [usuario, setUsuario] = useState<string | null>(null)
  const { messageError, typeRequest } = useTeamStore()
  const [listEquipe, setListEquipe] = useState<TeamProps[]>([])
  const [listAllUsers, setListAllUsers] = useState([])
  const [pageSize, setPageSize] = useState(5)
  const [usuarioAtualizado, setUsuarioAtualizado] = useState(false)
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    context: '',
  })

  const fetchUsersImmediate = (value: string) => {
    if (value === '') {
      setIsFetchingUsers(false)
      return
    }
    setIsFetchingUsers(true)
    getListUsersToTeam(value).then((data) => {
      setListAllUsers(data)
      setIsFetchingUsers(false)
    })
  }

  const fetchUsers = debounce(fetchUsersImmediate, 300)

  useEffect(() => {
    getEquipeByClientId(cliente_id).then((data) => {
      setListEquipe(data)
    })
  }, [])

  const onSubmit: SubmitHandler<any> = async () => {
    const usuarioId = getValues('usuario_id')

    addToTeam(cliente_id, usuarioId, type).then(() => {
      fetchUsersImmediate('')
      getEquipeByClientId(cliente_id).then((data) => {
        setListEquipe(data)
      })
    })

    setUsuario(null)
    setTimeout(() => {
      setUsuarioAtualizado(true)
    }, 0)
  }

  useEffect(() => {
    if (usuarioAtualizado) {
      if (
        usuario !== null &&
        !listAllUsers.some((user: { id: number }) => user.id === Number(usuario))
      ) {
        const usuarioEncontrado = listAllUsers?.find(
          (user: { id: number }) => user.id === Number(usuario),
        )
        setUsuario(usuarioEncontrado || null)
      }
      setUsuarioAtualizado(false)
    }
  }, [listAllUsers, usuario, usuarioAtualizado])

  const deleteRelacionamento = (userId: number) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja remover o vínculo do Usuário com o Agricultor?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteUserCliente(cliente_id, userId).then(() => {
          fetchUsersImmediate('')
          getEquipeByClientId(cliente_id).then((data) => {
            setListEquipe(data)
          })
        })
      }
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      flex: 0.3,
    },
    {
      field: 'role',
      headerName: 'Cargo',
      width: 150,
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.role?.nome
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      flex: 0.3,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.celular
          ? FormatPhoneWithCountry(params.row?.pais_celular, params.row?.celular)
          : '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return <Typography variant="body1">{params.value}</Typography>
      },
      valueGetter: (params: GridValueGetterParams) => {
        return params.value === '1' ? 'Ativo' : 'Inativo'
      },
    },
    {
      field: 'id',
      headerName: ' ',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            id="button-delete-equipe"
            variant="contained"
            color="secondary"
            className={classes.buttonDelete}
            onClick={() => deleteRelacionamento(params.value)}
          >
            <Delete />
          </Button>
        )
      },
    },
  ]

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} sm={12} container>
          <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" className={classes.typography}>
              {title}
            </Typography>
            <Grid item>
              <Grid container item>
                <>
                  <Controller
                    name="usuario_id"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Box position="relative">
                        <Autocomplete<any>
                          {...field}
                          id={(getValues('usuario_id') || '').toString()}
                          options={listAllUsers}
                          value={usuario}
                          fullWidth
                          filterOptions={filterOptions}
                          sx={{ width: 425 }}
                          onInputChange={(_, newInputValue) => {
                            setInputValue(newInputValue)
                          }}
                          getOptionLabel={(option: UserListI) => {
                            if (inputValue.includes('@')) {
                              return option.email
                            }
                            return option.nome
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_event, newValue) => {
                            setUsuario(newValue)
                            setValue('usuario_id', newValue ? newValue.id.toString() : '')
                          }}
                          renderOption={(props, option: UserListI) => (
                            <ListItem {...props} key={String(option.id)}>
                              <ListItemText
                                primary={option.nome}
                                secondary={option.email}
                                classes={{
                                  primary: classes.primary,
                                  secondary: classes.secundary,
                                }}
                              />
                            </ListItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              InputLabelProps={{
                                classes: {
                                  root: classes.root,
                                },
                              }}
                              key={String(params.id)}
                              variant="outlined"
                              label="Nome / Email..."
                              placeholder="Insira um nome ou email com @..."
                              className={classes.textInput}
                              helperText={
                                errors.usuario_id?.message ||
                                (type === typeRequest && messageError?.usuario_id)
                              }
                              FormHelperTextProps={{
                                className: classes.helperText,
                              }}
                              onChange={(event) => {
                                const { value } = event.target
                                fetchUsers(value)
                              }}
                            />
                          )}
                        />
                        {isFetchingUsers && (
                          <CircularProgress
                            size={20}
                            style={{ position: 'absolute', top: '30%', right: 40 }}
                          />
                        )}
                      </Box>
                    )}
                  />
                </>

                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  id="button-add-equipe"
                >
                  {strings.addUser}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} className={classes.resumeTable}>
          <DataGrid
            pageSize={pageSize}
            onPageSizeChange={(newPageSize: any) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25]}
            rows={listEquipe}
            columns={columns}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
            autoHeight
            rowHeight={38}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default EquipeClient
