import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
  },
  isFocused: {
    color: '#F8BB01!important',
  },
  textLink: {
    color: theme.palette.type === 'light' ? '#C7D2DA' : '#606164',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    },
  },
  iconLink: {
    minWidth: theme.spacing(6.5),
    color: theme.palette.type === 'light' ? '#C7D2DA' : '#606164',
  },
  activeLink: {
    color: theme.palette.primary.main,
  },
  iconExpand: {
    color: theme.palette.type === 'light' ? '#C7D2DA' : '#6F6F6F',
    minWidth: theme.spacing(1),
  },
  descriptionList: {
    '& > span': {
      fontFamily: 'DinTextProRegular',
      fontWeight: 400,
    },
  },
}))
