import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Grid, Typography, Paper, Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { AxiosError } from 'axios'

import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'

import FormAudit from './components/form-audit'
import FormInteracion from './components/form-interaction'
import GridUserRegister from './components/grid-user-register'
import strings from './strings'
import { useStyles } from './styles'

type PropsFarmer = {
  id: number
  nome: string
  documento: string
  celular: string
  email: string
  status: number
}

type Params = {
  cliente_id: string
}

const AuditFarmer = () => {
  const classes = useStyles()
  const history = useHistory()
  const [farmer, setFarmer] = useState<PropsFarmer>()
  const { cliente_id } = useParams<Params>()
  const alert = useAlert()

  useEffect(() => {
    api
      .get(`/farmer/${cliente_id}`)
      .then((res: any) => setFarmer(res.data))
      .catch((error: AxiosError) => {
        alert.show({ message: error.response?.data.message, type: 'error' })
      })
  }, [])

  return (
    <Grid item xs className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12}>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.iconBack}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </Button>
          </Grid>
          <Grid item xs className={classes.gridTitle}>
            <Typography variant="h6">{strings.description}&nbsp;</Typography>

            <Typography variant="h5" className={classes.titleFarmer}>
              {farmer?.nome.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <Paper variant="outlined" className={classes.paperInfoFarmer}>
            <Grid container item xs={12} className={classes.gridContent}>
              <Grid xs={12} item className={classes.textLeft}>
                <Typography variant="h6" className={classes.titleFarmer}>
                  {strings.userRegister.title}
                </Typography>
                <Typography variant="body2">{strings.userRegister.description}</Typography>
              </Grid>
              <GridUserRegister cliente_id={cliente_id} />
            </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <Paper variant="outlined" className={classes.paperInfoFarmer}>
            <FormAudit />
          </Paper>
        </Grid>
        <Grid container item xs={12} className={classes.bodyInformation}>
          <Paper variant="outlined" className={classes.paperInfoFarmer}>
            <FormInteracion />
          </Paper>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default AuditFarmer
