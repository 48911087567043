import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    minHeight: 690,
  },
  resumeAuditTable: {
    flex: 1,
    display: 'flex',
    height: '80vh',
  },
  containerFormsModal: {
    width: '100%',
  },
  textInput: {
    width: '100%',
  },
  positionCircularProgress: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'flex-start',
    marginTop: '120px',
  },
}))
