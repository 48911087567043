import { ReactElement } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import TitlePage from '../components/title-page'
import { Title } from '../constants/pages'
import { AuthRoutePath } from '../constants/paths'
import Login from '../pages/login'

export default function AuthRoutes(): ReactElement {
  return (
    <Switch>
      <Route exact path="/auth" render={() => <Redirect to={AuthRoutePath.LOGIN} />} />
      <Route
        exact
        path={AuthRoutePath.LOGIN}
        render={(props) => (
          <TitlePage {...props} title={Title.LOGIN}>
            <Login />
          </TitlePage>
        )}
      />
    </Switch>
  )
}
