import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import { CityProps } from '~/types/city-types.ts'
import { microRegionDataById, SourceCreateMicroRegion } from '~/types/microregion-types'

type MicroregionStates = {
  controllerModalState: boolean
  errorsUsers: boolean
  messageError: any
  regionList: []
  statesBrazil: []
  stateWithBrazilDescription: []
  LimitMicroregionData: any
  isLoading: boolean
  rules: SourceCreateMicroRegion | null
  dataMicroRegionById: microRegionDataById | null
}

type MicroregionActions = {
  modalController: (value: boolean) => void
  getMicroregionById: (rulesId: number) => Promise<void>
  getMicroregion: () => Promise<void>
  listStatesBrazil: () => Promise<void>
  createMicroregion: (rules: SourceCreateMicroRegion | null) => Promise<void>
  setRulesExtension: (rules: any) => Promise<void>
  updateMicroregion: (id: number, rules: any) => Promise<void>
  clearForms: () => void
  listCitiesByStateId: (stateId?: number) => Promise<CityProps[] | any>
}

type State = MicroregionStates & MicroregionActions

const initialState: MicroregionStates = {
  controllerModalState: false,
  isLoading: false,
  messageError: '',
  regionList: [],
  statesBrazil: [],
  stateWithBrazilDescription: [],
  LimitMicroregionData: null,
  errorsUsers: false,
  rules: null,
  dataMicroRegionById: null,
}

export const useMicroregionStore = create<State>((set, get) => ({
  ...initialState,
  modalController: (valueState) => {
    set({
      controllerModalState: valueState,
    })
  },
  clearForms: () => {
    set({ messageError: '', errorsUsers: false, rules: null })
  },
  getMicroregionById: async (rulesID) => {
    const { data } = await api.get(`/regions/${rulesID}`)
    set({ dataMicroRegionById: data })
  },
  getMicroregion: async () => {
    set({ isLoading: true, messageError: '' })
    try {
      const { data } = await api.get('/regions')
      set({
        regionList: data,
      })
      set({ isLoading: false, messageError: '' })
    } catch (error: any) {
      set({ isLoading: false, messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  createMicroregion: async (rules) => {
    set({ isLoading: true, messageError: '' })
    const { modalController, getMicroregion } = get()
    try {
      const { data } = await api.post('/regions', rules)
      if (data) {
        set({ isLoading: false, messageError: '' })

        Swal.fire({
          icon: 'success',
          title: 'Cadastrado com sucesso!',
          text: data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
        modalController(false)
        getMicroregion()
      }
    } catch (error: any) {
      set({ isLoading: false, messageError: error?.response?.data?.errors })
      Swal.fire({
        icon: 'error',
        title: 'Erro Ao Cadastrar Microregião!',
        heightAuto: false,
        text: error?.response?.data?.errors,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  updateMicroregion: async (id: number, params) => {
    set({ isLoading: true, messageError: '' })
    const { modalController, getMicroregion } = get()
    try {
      const { data } = await api.put(`/regions/${id}`, params)
      if (data) {
        set({ isLoading: false, messageError: '' })

        Swal.fire({
          icon: 'success',
          title: 'Atualizada com sucesso!',
          text: data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
        modalController(false)
        getMicroregion()
      }
    } catch (error: any) {
      set({ isLoading: false, messageError: error?.response?.data?.errors })

      Swal.fire({
        icon: 'error',
        title: 'Erro Ao Atualizar Regra!',
        text: error.response?.data?.errors,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  setRulesExtension: async (rules) => {
    set({
      LimitMicroregionData: rules,
    })
  },
  listStatesBrazil: async () => {
    try {
      const { data } = await api.get('/limit/estados')
      set({
        statesBrazil: data,
      })
    } catch (error: any) {
      set({
        messageError: error?.response?.data?.message,
      })
    }
  },
  listCitiesByStateId: async (stateId) => {
    set({ isLoading: true })
    try {
      const cities = await api.get(`/country/state/${stateId}/cities`)
      return cities.data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }

    return []
  },
}))
