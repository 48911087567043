import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 700,
    width: '100%',
    color: '#2C3242',
  },
  resumeTable: {
    flex: 1,
    display: 'flex',
    width: 'auto',
  },
  buttonDelete: {
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      filter: `brightness('0.9')`,
    },
  },
  textInput: {
    minWidth: '300px',
    fontSize: '12px',
    color: '#00446B',
    borderRadius: '30px',
    paddingRight: '5px',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  primary: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  secundary: {
    fontSize: '16px',
  },
  createAt: {
    fontSize: '25px',
  },
  bodyInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '24px 0px',
    padding: '8px 0px',
  },
  headerContainer: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
  },
}))
