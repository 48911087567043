import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { Grid, Paper, Typography, Button, Box } from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useFilterValidationStateRefs } from '~/store/validation/filter-validation-refs'
import { useValidationStore } from '~/store/validation/validation-store'
import { IModalRefs } from '~/types/validation-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import ModalRefs from './components/modal-refs'
import string from './strings'
import { useStyles } from './styles'

export const Refs: React.FC = () => {
  const { items } = useFilterValidationStateRefs()
  const setFilter = useFilterValidationStateRefs((state) => state.setFilter)
  const modalRef = useRef<IModalRefs>(null)

  const classes = useStyles()
  const { refs, isLoading } = useValidationStore()

  const handleModalRefs = () => {
    modalRef.current?.handleActions()
  }

  const columns: GridColDef[] = [
    {
      field: 'quem',
      headerName: 'Nome',
      width: 350,
      minWidth: 170,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },

    {
      field: 'telefone',
      headerName: 'Telefone',
      width: 250,
      renderCell: (params) => maskInterceptor('phone')(params.row?.telefone),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'referencia',
      headerName: 'Função',
      width: 250,
      valueGetter: (params) => params.row.referencia,
      renderCell: (params) => params.row.referencia,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'empresa',
      headerName: 'Empresa',
      width: 300,
      headerAlign: 'center',
      valueGetter: (params: any) =>
        params.row?.empresa ? params.row.empresa : 'Empresa não informado',
      renderCell: (params: any) =>
        params.row?.empresa ? params.row.empresa : 'Empresa não informado',
      minWidth: 140,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
  ]
  return (
    <Paper variant="outlined" className={`${classes.paper}`}>
      {isLoading ? (
        <Skeleton height={300} borderRadius={10} />
      ) : (
        <>
          <Grid item container sm={12} xs={12} className={classes.headerContainer}>
            <Typography variant="h5" className={classes.typography}>
              {string.title}
            </Typography>
            <Box>
              <Button variant="contained" className={classes.buttonAdd} onClick={handleModalRefs}>
                <AddIcon />
              </Button>
            </Box>
          </Grid>
          <DataGrid
            rows={refs}
            columns={columns}
            disableSelectionOnClick
            autoHeight
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </>
      )}

      <ModalRefs ref={modalRef} />
    </Paper>
  )
}
