import * as React from 'react'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Button, FormControl, Grid, MenuItem, Paper, TextField } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import MuiPhoneNumber from 'material-ui-phone-number-2'

import { ChangedByValidationFilter, resultados } from '~/constants/data-field'
import { useValidationStore } from '~/store/validation/validation-store'
import { IValidationFilters } from '~/types/validation-types'
import { maskInterceptor, maskNumberQtyTotal } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

interface QuickSearchToolbar {
  onChangeFilter: ((filters: IValidationFilters) => void) | undefined
}

const defaultValues = {
  cliente_nome: '',
  cpf_cnpj_agricultor: '',
  celular_agricultor: '',
  email: '',
  descricao_alterado_por: '',
  descricao_situacao: '',
  qtde_total: '',
  ultima_data_envio_validacao: '',
}

const ValidationCardFilter: React.FC<QuickSearchToolbar> = ({ onChangeFilter }) => {
  const classes = useStyles()
  const { filters, setFilters } = useValidationStore()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IValidationFilters>({
    defaultValues,
  })

  useEffect(() => {
    if (filters) {
      Object.keys(filters).forEach((key) => {
        setValue(key as keyof IValidationFilters, filters[key as keyof IValidationFilters])
      })
    }
  }, [filters, setValue])

  const onSubmit: SubmitHandler<IValidationFilters> = async (formValidationFilter) => {
    setFilters(formValidationFilter)

    if (onChangeFilter) {
      onChangeFilter(formValidationFilter)
    }
  }

  const resetForm = () => {
    reset(defaultValues)
  }

  return (
    <Paper elevation={0} className={`${classes.paper} ${classes.cardPaper}`}>
      <FormControl variant="outlined" className={classes.formStyle}>
        <Grid item sm={12} className={classes.gridFields} container spacing={2}>
          <Grid item sm={3}>
            <Controller
              name="cliente_nome"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="nome"
                  variant="outlined"
                  label="Agricultor"
                  placeholder="Nome do agricultor"
                  className={classes.customInput}
                  error={!!errors?.cliente_nome}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.cliente_nome?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="cpf_cnpj_agricultor"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="cpf_cnpj_agricultor"
                  variant="outlined"
                  label="CPF/CNPJ"
                  className={classes.customInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  value={
                    field.value
                      ? maskInterceptor(
                          field?.value?.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj',
                        )(field.value)
                      : ''
                  }
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="celular_agricultor"
              control={control}
              render={({ field }) => (
                <MuiPhoneNumber
                  {...field}
                  size="small"
                  id="celular_agricultor"
                  defaultCountry="br"
                  label="Celular"
                  variant="outlined"
                  onlyCountries={['br', 'py']}
                  masks={{ py: '+... ... ......' }}
                  error={!!errors?.email}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.email?.message}
                  className={classes.customInput}
                  value={field.value || ''}
                  InputProps={{
                    style: {
                      borderRadius: 16,
                      width: '100%',
                      color: '#00446B',
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="email"
                  variant="outlined"
                  label="E-mail"
                  placeholder="E-mail"
                  error={!!errors?.email}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.email?.message}
                  className={classes.customInput}
                  value={field.value || ''}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.gridFields} container spacing={2}>
          <Grid item sm={3}>
            <Controller
              name="ultima_data_envio_validacao"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="ultima_data_envio_validacao"
                  variant="outlined"
                  label="Data último envio"
                  type="date"
                  className={classes.customInput}
                  error={!!errors?.ultima_data_envio_validacao}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.ultima_data_envio_validacao?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="descricao_alterado_por"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="descricao_alterado_por"
                  variant="outlined"
                  label="Alterado por"
                  defaultValue=""
                  placeholder="Alterado por"
                  error={!!errors?.descricao_alterado_por}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.descricao_alterado_por?.message}
                  className={classes.customInput}
                  value={field.value || ''}
                >
                  {ChangedByValidationFilter.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="qtde_total"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="qtde_total"
                  variant="outlined"
                  label="Área(ha)"
                  className={classes.customInput}
                  error={!!errors?.qtde_total}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={
                    errors.qtde_total?.message ||
                    'Use uma virgula para a primeira decimal. Ex: 25.852,30'
                  }
                  onChange={(event) => {
                    let { value } = event.target
                    value = maskNumberQtyTotal(value)
                    field.onChange(value)
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="descricao_situacao"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  variant="outlined"
                  id="descricao_situacao"
                  label="Status"
                  placeholder="Status"
                  defaultValue=""
                  error={!!errors?.descricao_situacao}
                  helperText={errors.descricao_situacao?.message}
                  className={classes.customInput}
                >
                  {resultados.map((Option) => (
                    <MenuItem key={Option.label} value={Option.label}>
                      {Option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>

        <Grid item sm={12} className={classes.buttonActions}>
          <Button
            id="button-limpar-form"
            variant="contained"
            color="secondary"
            className={classes.buttonClear}
            onClick={resetForm}
            endIcon={<ClearIcon />}
          >
            {strings.buttons.clear}
          </Button>
          <Button
            id="button-filtrar-form"
            type="submit"
            variant="contained"
            color="primary"
            className={classes.buttonFilter}
            endIcon={<SearchIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {strings.buttons.send}
          </Button>
        </Grid>
      </FormControl>
    </Paper>
  )
}

export default ValidationCardFilter
