import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#ECEFF1',
    flexGrow: 1,
    overflowY: 'auto',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 300,
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}))
