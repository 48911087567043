import { ReactElement, useEffect } from 'react'

import { Grid, Paper, Typography, Snackbar, CircularProgress } from '@material-ui/core'
import { Beenhere, PeopleAlt } from '@material-ui/icons'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { ContentPasteSearch } from '@mui/icons-material'

import CardFastAccess from '~/components/card-home'
import Copyright from '~/components/copyright'
import PlantationWorkerIcon from '~/components/navSection/components/plantation-worder-icon'
import { AppRoutePath } from '~/constants/paths'
import { useTokenStore } from '~/store/auth'
import { useStoreMissions } from '~/store/missions-store'

import strings from './strings'
import { useStyles } from './styles'

function Home(): ReactElement {
  const classes = useStyles()
  const { loggedUser } = useTokenStore()
  const { expiresIn, isLoading } = useStoreMissions()

  const listMissions = useStoreMissions((state) => state.listMissions)

  useEffect(() => {
    if (new Date(expiresIn).getTime() <= new Date().getTime()) listMissions()
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.paper}>
          <Grid item xs>
            <Typography variant="h5">{`${strings.welcome}`}</Typography>
            <Typography variant="h4" className={classes.username}>
              {loggedUser?.nome}
            </Typography>
          </Grid>
          <Grid item xs>
            <Grid className={classes.gridSubtitle}>
              <Typography variant="h5" className={classes.subtitle} noWrap>
                {strings.presentation.start}
              </Typography>
              <Typography variant="h5" className={`${classes.subtitle} ${classes.textPlantup}`}>
                &nbsp;{strings.presentation.middle}&nbsp;
              </Typography>
              <Typography variant="h5" className={classes.subtitle} noWrap>
                {strings.presentation.end}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs className={classes.gridFastAccess}>
            <Typography variant="body1">{strings.fastAccess}</Typography>
          </Grid>
          <Grid item xs className={classes.gridCardPresentation}>
            <CardFastAccess
              description={strings.fastAccessValidation}
              route={AppRoutePath.VALIDATION}
              title="Validação"
            >
              <Beenhere className={classes.iconsFastAccess} />
            </CardFastAccess>
            <CardFastAccess
              description={strings.fastAccessAudit}
              route={AppRoutePath.AUDIT}
              title="Auditoria"
            >
              <ContentPasteSearch className={classes.iconsFastAccess} />
            </CardFastAccess>
            <CardFastAccess
              description={strings.fastAccessClient}
              route={AppRoutePath.CLIENTS}
              title="Agricultores"
            >
              <PlantationWorkerIcon modifyIconHome />
            </CardFastAccess>
            <CardFastAccess
              description={strings.fastAccessUser}
              route={AppRoutePath.USERS}
              title="Usuários"
            >
              <PeopleAlt className={classes.iconsFastAccess} />
            </CardFastAccess>
          </Grid>
          {isLoading && (
            <Grid container justifyContent="center">
              <Snackbar open autoHideDuration={6000}>
                <Alert severity="info" icon={<CircularProgress color="inherit" size={25} />}>
                  Carregando Missões, aguarde...
                </Alert>
              </Snackbar>
            </Grid>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.gridCopyright}>
        <Copyright />
      </Grid>
    </Grid>
  )
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default Home
