import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { Grid, Paper, Typography, Button, Box, Chip } from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import Swal from 'sweetalert2'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useFilterValidationStateFieldController } from '~/store/validation/filter-validation-field-controller'
import { useValidationStore } from '~/store/validation/validation-store'
import { IModalField, IFieldController } from '~/types/validation-types'

import ModalField from './components/modal-field'
import string from './strings'
import { useStyles } from './styles'

export const ControlerFields: React.FC = () => {
  const { items } = useFilterValidationStateFieldController()
  const modalFieldControllerRef = useRef<IModalField>(null)

  const setFilter = useFilterValidationStateFieldController((state) => state.setFilter)
  const classes: any = useStyles()

  const { fieldControllers, SafraField, isLoading } = useValidationStore()
  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  const handleModalFieldController = () => {
    if (SafraField.some((item) => item.cultura_id === '0')) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Selecione uma cultura, safra e tipo safra!',
        confirmButtonColor: '#2b78c0',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: true,
        customClass: {
          container: 'alert-container',
        },
      })
    } else {
      modalFieldControllerRef.current?.actionsController()
    }
  }

  const fieldControllersCreateUniqueKey = fieldControllers.map((item: IFieldController) => {
    return {
      ...item,
      id: Math.random(),
    }
  })
  const columns: GridColDef[] = [
    {
      field: 'desc_cultura',
      headerName: 'Cultura',
      minWidth: 200,
      valueGetter: (params) => params.row.desc_cultura,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },

    {
      field: 'desc_safra',
      headerName: 'Safra',
      minWidth: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'desc_tipo_safra',
      headerName: 'Tipo Safra',
      minWidth: 120,
      flex: 0.2,
      valueGetter: (params) => params.row.desc_tipo_safra,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'desc_agricultor',
      headerName: 'Agricultor',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      valueGetter: (params) => params.row.desc_agricultor,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'area_consultada',
      headerName: 'Área Consultada',
      width: 120,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(Number(params.row.area_consultada)),
      valueGetter: (params) => params.row.area_consultada,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'fonte_consultada',
      headerName: 'Fonte Consultada',
      flex: 0.2,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.fonte_consultada,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'area_plantada',
      headerName: 'Válido',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.area_plantada,
      renderCell: (params) => {
        const percentual =
          (Number(params.row?.area_plantada) * 100) / Number(params.row?.area_consultada)

        if (percentual >= 90 && percentual <= 110) {
          return (
            <Chip
              id="chips-status"
              label="Válido"
              style={{ backgroundColor: '#24d2b5', color: '#fff' }}
            />
          )
        }
        return (
          <Chip
            id="chips-status"
            label="Inválido"
            style={{ backgroundColor: '#ff5c6c', color: '#fff' }}
          />
        )
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
  ]
  return (
    <Paper variant="outlined" className={`${classes.paper}`}>
      {isLoading ? (
        <Skeleton height={300} style={{ borderRadius: '10px' }} />
      ) : (
        <>
          <Grid item container sm={12} xs={12} className={classes.headerContainer}>
            <Typography variant="h5" className={classes.typography}>
              {string.title}
            </Typography>
            <Box>
              <Button
                variant="contained"
                className={classes.buttonAdd}
                onClick={handleModalFieldController}
              >
                <AddIcon />
              </Button>
            </Box>
          </Grid>
          <DataGrid
            rows={fieldControllersCreateUniqueKey}
            columns={columns}
            disableSelectionOnClick
            autoHeight
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </>
      )}

      <ModalField ref={modalFieldControllerRef} />
    </Paper>
  )
}
