import { ReactElement } from 'react'

import { Grid } from '@material-ui/core'

import AuthRoutes from '../../routes/auth.routes'
import { useStyles } from './styles'

function AuthLayout(): ReactElement {
  const classes = useStyles()

  return (
    <Grid container component="main" className={classes.root}>
      <AuthRoutes />
    </Grid>
  )
}

export default AuthLayout
