import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid',
    maxHeight: '100vh',
    boxShadow: theme.shadows[5],
    '&::-webkit-scrollbar': {
      width: '0.01em',
    },
    overflow: 'scroll',
  },
  gridMain: {
    flexDirection: 'column',
  },
  gridHeader: {
    margin: theme.spacing(2),
  },
  gridFooter: {
    flexDirection: 'row',
    padding: theme.spacing(1),
    width: '100%',
    minWidth: 600,
    flexGrow: 1,
    margin: '0px 8px 8px 0px',
  },
  saveButton: {
    textAlign: 'right',
  },
}))
