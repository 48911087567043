import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { Grid, Paper, Typography, Button, Box, Tooltip } from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import Swal from 'sweetalert2'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { interactionsEmuns, motivesEmuns, resultEmuns } from '~/enums'
import { useFilterValidationStateRefsInteraction } from '~/store/validation/filter-validation-refs-interaction'
import { useValidationStore } from '~/store/validation/validation-store'
import { IRefsInteractions } from '~/types/validation-types'

import ModalRefsInteractions from './components/modal-refs-interactions'
import string from './strings'
import { useStyles } from './styles'

export const RefsInteractions: React.FC = () => {
  const { items } = useFilterValidationStateRefsInteraction()
  const setFilter = useFilterValidationStateRefsInteraction((state) => state.setFilter)
  const classes = useStyles()
  const modalInteracationFarmeRef = useRef<IRefsInteractions>(null)

  const { refsInteraction, SafraField, isLoading, refs } = useValidationStore()

  const handleModalRefsInteraction = () => {
    if (refs.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Cadastre uma referência antes de criar uma interação!',
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
      return
    }

    if (SafraField.some((item) => item.cultura_id === '0')) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Selecione uma cultura, safra e tipo safra!',
        confirmButtonColor: '#2b78c0',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: true,
        customClass: {
          container: 'alert-container',
        },
      })
    } else {
      modalInteracationFarmeRef.current?.handleAction()
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'usuario_criacao',
      headerName: 'Usuario',
      width: 200,
      minWidth: 120,
      valueGetter: (params: any) =>
        params.row?.usuario_criacao ? params.row.usuario_criacao : 'Usuario não informado',
      renderCell: (params: any) =>
        params.row?.usuario_criacao ? params.row.usuario_criacao : 'Usuario não informado',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },

    {
      field: 'cultura_safra_tipo',
      headerName: 'Cultura | Safra | tipo',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'quem',
      headerName: 'Referencia',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'meio_interacao',
      headerName: 'Interação',
      width: 200,
      headerAlign: 'center',
      minWidth: 140,
      valueGetter: (params: any) => interactionsEmuns[params.row.meio_interacao],
      renderCell: (params) => interactionsEmuns[params.row.meio_interacao],
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Data',
      width: 200,
      headerAlign: 'center',
      valueGetter: (params) => {
        const date = new Date(params.row?.created_at)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
      renderCell: (params) => {
        const date = new Date(params.row?.created_at)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'motivo',
      headerName: 'Motivo',
      minWidth: 200,
      headerAlign: 'center',
      valueGetter: (params) => motivesEmuns[params.row.motivo],
      renderCell: (params) => motivesEmuns[params.row.motivo],
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'area_plantio',
      headerName: 'Área Plantio',
      minWidth: 200,
      headerAlign: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'local_plantio',
      headerName: 'Local Plantio',
      minWidth: 200,
      headerAlign: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'resultado',
      headerName: 'Resultado',
      minWidth: 200,
      headerAlign: 'center',
      valueGetter: (params) => resultEmuns[params.row.resultado],
      renderCell: (params) => resultEmuns[params.row.resultado],
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'observacao',
      headerName: 'Observação',
      minWidth: 500,
      flex: 1,
      valueGetter: (params: any) => (params.row?.observacao ? params.row.observacao : ' - '),
      renderCell: (params: any) => {
        const texto = params.row?.observacao ? params.row.observacao : ' - '

        return (
          <Tooltip title={texto}>
            <span className={`MuiDataGrid-cell ${classes.dataGrid} cellTolltip`}>{texto}</span>
          </Tooltip>
        )
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
  ]
  return (
    <Paper variant="outlined" className={`${classes.paper}`}>
      {isLoading ? (
        <Skeleton height={300} borderRadius={10} />
      ) : (
        <>
          <Grid item container sm={12} xs={12} className={classes.headerContainer}>
            <Typography variant="h5" className={classes.typography}>
              {string.title}
            </Typography>
            <Box>
              <Button
                variant="contained"
                className={classes.buttonAdd}
                onClick={handleModalRefsInteraction}
              >
                <AddIcon />
              </Button>
            </Box>
          </Grid>
          <DataGrid
            className={classes.dataGrid}
            rows={refsInteraction}
            columns={columns}
            disableSelectionOnClick
            autoHeight
            filterModel={{ items }}
            getRowId={(row) => row.id}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </>
      )}

      <ModalRefsInteractions ref={modalInteracationFarmeRef} />
    </Paper>
  )
}
