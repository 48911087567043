import { ReactElement, useEffect, useState } from 'react'

import { Grid, Paper, Typography, Button } from '@material-ui/core'

import { useContactStore } from '~/store/contact-store'

import { ContactModal } from './components/add-contact'
import { ContactsList } from './components/list-contacts'
import strings from './strings'
import { useStyles } from './styles'

type Props = {
  cliente_id: string
}

function ContactsMade(props: Props): ReactElement {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { cliente_id } = props

  const { getContactsByClientId, listContacts } = useContactStore()

  useEffect(() => {
    getContactsByClientId(cliente_id)
  }, [])

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid container item xs={12} justifyContent="space-between" alignItems="center">
        <Typography variant="h5" className={classes.typography}>
          {strings.title}
        </Typography>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          {strings.addContact}
        </Button>
      </Grid>
      <Grid container item sm={12} xs={12} spacing={2} style={{ marginTop: '10px' }}>
        {listContacts &&
          listContacts.map((value) => {
            return <ContactsList {...value} key={value.id} />
          })}
      </Grid>

      <ContactModal
        open={openModal}
        title="Novo Contato"
        onClose={() => setOpenModal(false)}
        cliente_id={cliente_id}
      />
    </Paper>
  )
}

export default ContactsMade
