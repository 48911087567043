import React from 'react'

import { Grid, Paper, Typography, Divider } from '@material-ui/core'

import { useStyles } from './styles'

interface IValidationDetails {
  title: string
  value: string
  classCard: string
}

export const CardInfo: React.FC<IValidationDetails> = ({ title, value, classCard }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={12} container>
      <Paper variant="outlined" className={`${classes.paper}`}>
        <Grid item className={classes.cardTypographyPosition}>
          <Typography variant="h6" className={classCard}>
            {title}
          </Typography>
        </Grid>
        <Divider />
        <Grid item>
          <Typography variant="h6" className={classCard}>
            {value}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  )
}
