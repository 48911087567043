import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, Typography, Divider } from '@material-ui/core'
import { AxiosError } from 'axios'

import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'

import HistoryAuditAgpro from '../history-agpro-audit'
import strings from './strings'
import { useStyles } from './styles'

type PropsUserRegister = {
  nome: string
  email?: string
  celular?: string
  safra_id: number
  tipo_safra: number
  cultura_id: number
  area_cadastrada_total: number
}

type PropsState = {
  cultura_id: number
  safra_id: number
  tipo_safra: string
}

type Props = {
  cliente_id: string
}

const GridUserRegister = (props: Props) => {
  const classes = useStyles()
  const { cultura_id, safra_id, tipo_safra } = useHistory<PropsState>().location.state
  const [userRegister, setUserResgister] = useState<PropsUserRegister[]>([])
  const { cliente_id } = props
  const alert = useAlert()

  useEffect(() => {
    api
      .get(`/audit/results/users/${cliente_id}`, {
        params: { cultura_id, safra_id, tipo_safra },
      })
      .then((res: any) => setUserResgister(res.data))
      .catch((error: AxiosError) => {
        alert.show({ message: error.response?.data.message, type: 'error' })
      })
  }, [])

  return (
    <>
      {userRegister &&
        userRegister.map((user) => (
          <>
            <Grid container item xs={12} className={classes.bodyInformation}>
              <Grid xs={5} item className={classes.textLeft}>
                <Typography variant="body1" className={classes.titleFarmer}>
                  {strings.name}
                </Typography>
                <Typography variant="body2">{user.nome}</Typography>
              </Grid>
              {user.email && (
                <>
                  <Grid xs={4} item className={classes.textLeft}>
                    <Typography variant="body1" className={classes.titleFarmer}>
                      {strings.email}
                    </Typography>
                    <Typography variant="body2">{user.email}</Typography>
                  </Grid>
                </>
              )}
              {user.celular && (
                <Grid xs={3} item className={classes.textLeft}>
                  <Typography variant="body1" className={classes.titleFarmer}>
                    {strings.cellPhone}
                  </Typography>
                  <Typography variant="body2">{user.celular}</Typography>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} className={classes.bodyInformation}>
              <Grid xs={5} item className={classes.textLeft}>
                <Typography variant="body1" className={classes.titleFarmer}>
                  {strings.area}
                </Typography>
                <Typography variant="body2">{user.area_cadastrada_total}</Typography>
              </Grid>
              {user.email && (
                <>
                  <Grid xs={7} item className={classes.textLeft}>
                    <HistoryAuditAgpro email={user.email} />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid xs={12} item container spacing={2}>
              <Divider className={classes.divider} />
            </Grid>
          </>
        ))}
      {userRegister.length < 1 && (
        <Typography variant="body1" className={classes.titleFarmer}>
          {strings.notFound}
        </Typography>
      )}
    </>
  )
}

export default GridUserRegister
