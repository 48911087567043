import React, { useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'

import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { GridColDef, DataGrid, GridColumnHeaderParams } from '@material-ui/data-grid'
import { VerifiedUser } from '@material-ui/icons'

import Copyright from '~/components/copyright'
import SearchDataGrid from '~/components/search-data-grid'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useFilterAuditState } from '~/store/audit/filter-audit'
import { useAuditListStore } from '~/store/audit/list-audits'

import strings from './strings'
import { useStyles } from './styles'

const Audit: React.FC = () => {
  const classes = useStyles()

  const { items } = useFilterAuditState()
  const setFilter = useFilterAuditState((state) => state.setFilter)

  const auditList = useAuditListStore((state) => state.auditList)
  const load = useAuditListStore((state) => state.load)

  useLayoutEffect(() => {
    load()
  }, [load])

  const columns: GridColDef[] = [
    {
      field: 'agpro',
      headerName: 'Agpro',
      width: 150,
      flex: 0.3,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'agricultor',
      headerName: 'Agricultor',
      width: 150,
      flex: 0.3,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'desc_status_auditoria',
      headerName: 'Status',
      width: 150,
      flex: 0.2,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'safra',
      headerName: 'Safra',
      width: 150,
      flex: 0.2,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'ultima_data_validacao',
      headerName: 'Data última validação',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'cliente_id',
      headerName: 'Ações',
      description: 'Abre os detalhes da auditoria',
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Link to={`${AppRoutePath.AUDITDETAILS.replace(':cliente_id', params.value)}`}>
          <Button variant="contained" color="primary" size="small" endIcon={<VerifiedUser />}>
            {strings.audit.subtitle}
          </Button>
        </Link>
      ),
    },
  ]

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12} className={classes.titleStyle}>
          <Typography variant="h4" color="primary">
            {strings.audit.title}
          </Typography>
        </Grid>
        <Grid container item sm={12} xs={12} className={classes.resumeAuditTable}>
          <DataGrid
            rows={auditList}
            columns={columns}
            disableSelectionOnClick
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Paper>
      <Copyright />
    </Grid>
  )
}

export default Audit
