import moment from 'moment'

import { ContactProps } from '~/types/contacts-types'

export function contactMapper(dados: ContactProps) {
  return {
    ...dados,
    data_contato: moment(dados.data_contato).format('YYYY-MM-DD'),
  }
}
