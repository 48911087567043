import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { Grid, Paper, Typography, Button, ButtonGroup, CircularProgress } from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Swal from 'sweetalert2'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { FonteEmuns, TypeSafraEmuns } from '~/enums'
import { submitApprovedResult } from '~/mappers/validation-mapper'
import { useFilterValidationStateSummary } from '~/store/validation/filter-validation-summary'
import { useValidationStore } from '~/store/validation/validation-store'
import {
  IValidationSumary,
  IResultsModalActionsIncomplete,
  IResultsModalActionsDisapproved,
} from '~/types/validation-types'

import ModalResultsActionDisapproved from './components/result-actions-modal/Disapproved'
import ModalResultsActionIncomplete from './components/result-actions-modal/Incomplete'
import string from './strings'
import { useStyles } from './styles'

export const ValidationSumary: React.FC = () => {
  const { items } = useFilterValidationStateSummary()
  const setFilter = useFilterValidationStateSummary((state) => state.setFilter)
  const classes = useStyles()
  const { validationSumary, aprovedResults, isLoading, isLoadingActions } = useValidationStore()
  const modalActionsIncomplete = useRef<IResultsModalActionsIncomplete>(null)
  const modalActionsDisapproved = useRef<IResultsModalActionsDisapproved>(null)

  const handleIncompleteResult = (validationDataParams: any) => {
    modalActionsIncomplete.current?.handleAction(validationDataParams)
  }

  const handleDisapprovedResult = (validationDataParams: any) => {
    modalActionsDisapproved.current?.handleAction(validationDataParams)
  }
  const validationSumaryCreateUniqueKey = validationSumary.map((item: IValidationSumary) => {
    return {
      ...item,
      id: Math.random(),
    }
  })

  const approvedResult = async (params: any) => {
    await aprovedResults(submitApprovedResult(params))
  }
  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  const columns: GridColDef[] = [
    {
      field: 'cultura',
      headerName: 'Cultura',
      width: 150,
      flex: 0.3,
      minWidth: 120,
      valueGetter: (params) => params.row.cultura,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },

    {
      field: 'safra_desc',
      headerName: 'Safra',
      width: 200,
      valueGetter: (params) => params.row.safra_desc,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'tipo_safra',
      headerName: 'Tipo Safra',
      width: 120,
      flex: 0.2,
      valueGetter: (params) => TypeSafraEmuns[params.row?.tipo_safra],
      renderCell: (params: any) => {
        return TypeSafraEmuns[params.row?.tipo_safra] ?? ''
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'area_plantada',
      headerName: 'Área',
      width: 160,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      valueGetter: (params) => params.row.area_plantada,
      renderCell: (params) => formatNumber(Number(params.row.area_plantada)),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'area_protege',
      headerName: 'Protege',
      width: 120,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.area_protege,
      renderCell: (params) => formatNumber(Number(params.row.area_protege)),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'area_portal',
      headerName: 'Portal',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.area_portal,
      renderCell: (params) => formatNumber(Number(params.row.area_portal)),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'fonte_consulta_validacao',
      headerName: 'Fonte Aprovado',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => FonteEmuns[params.row?.fonte_consulta_validacao],
      renderCell: (params: any) => {
        return FonteEmuns[params.row?.fonte_consulta_validacao] ?? 'Fonte não informada'
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'situacao_agricultor_safra',
      headerName: 'Situação',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.row.situacao_agricultor_safra,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'action',
      headerName: 'Ações',
      flex: 0.4,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.row?.situacao_agricultor_safra === 'Aprovado') {
          return (
            <>
              {isLoadingActions ? (
                <CircularProgress />
              ) : (
                <ButtonGroup disableElevation variant="contained">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#ffaf05',
                      color: '#fff',
                      fontSize: '10px',
                    }}
                    onClick={() => {
                      handleIncompleteResult(params.row)
                    }}
                  >
                    <ErrorOutlineIcon />
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#ff5c6c',
                      color: '#fff',
                      fontSize: '10px',
                    }}
                    onClick={() => {
                      handleDisapprovedResult(params.row)
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </ButtonGroup>
              )}
            </>
          )
        }
        if (params.row?.situacao_agricultor_safra === 'Incompleto') {
          return (
            <>
              {isLoadingActions ? (
                <CircularProgress />
              ) : (
                <ButtonGroup disableElevation variant="contained">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#2FA4D3',
                      color: '#fff',
                      fontSize: '10px',
                    }}
                    onClick={() => {
                      Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Tem certeza que deseja aprovar esse resultado?',
                        confirmButtonColor: '#2b78c0',
                        showCancelButton: true,
                        cancelButtonColor: '#ff7d89',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não, cancele',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showCloseButton: true,
                        customClass: {
                          container: 'alert-container',
                        },
                      }).then(async (result: any) => {
                        if (result.isConfirmed) {
                          approvedResult(params.row)
                        }
                      })
                    }}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#ff5c6c',
                      color: '#fff',
                      fontSize: '10px',
                    }}
                    onClick={() => {
                      handleDisapprovedResult(params.row)
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </ButtonGroup>
              )}
            </>
          )
        }

        if (params.row?.situacao_agricultor_safra === 'Reprovado') {
          return (
            <>
              {isLoadingActions ? (
                <CircularProgress />
              ) : (
                <ButtonGroup disableElevation variant="contained">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#2FA4D3',
                      color: '#fff',
                      fontSize: '10px',
                    }}
                    onClick={() => {
                      Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Tem certeza que deseja aprovar esse resultado?',
                        confirmButtonColor: '#2b78c0',
                        showCancelButton: true,
                        cancelButtonColor: '#ff7d89',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não, cancele',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showCloseButton: true,
                        customClass: {
                          container: 'alert-container',
                        },
                      }).then(async (result: any) => {
                        if (result.isConfirmed) {
                          approvedResult(params.row)
                        }
                      })
                    }}
                  >
                    <CheckIcon />
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#ffaf05',
                      color: '#fff',
                      fontSize: '10px',
                    }}
                    onClick={() => {
                      handleIncompleteResult(params.row)
                    }}
                  >
                    <ErrorOutlineIcon />
                  </Button>
                </ButtonGroup>
              )}
            </>
          )
        }
        return (
          <>
            {isLoadingActions ? (
              <CircularProgress />
            ) : (
              <ButtonGroup disableElevation variant="contained">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#2FA4D3',
                    color: '#fff',
                    fontSize: '10px',
                  }}
                  onClick={() => {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Atenção',
                      text: 'Tem certeza que deseja aprovar esse resultado?',
                      confirmButtonColor: '#2b78c0',
                      showCancelButton: true,
                      cancelButtonColor: '#ff7d89',
                      confirmButtonText: 'Sim',
                      cancelButtonText: 'Não, cancele',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      showCloseButton: true,
                      customClass: {
                        container: 'alert-container',
                      },
                    }).then(async (result: any) => {
                      if (result.isConfirmed) {
                        approvedResult(params.row)
                      }
                    })
                  }}
                >
                  <CheckIcon />
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#ffaf05',
                    color: '#fff',
                    fontSize: '10px',
                  }}
                  onClick={() => {
                    handleIncompleteResult(params.row)
                  }}
                >
                  <ErrorOutlineIcon />
                </Button>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: '#ff5c6c',
                    color: '#fff',
                    fontSize: '10px',
                  }}
                  onClick={() => {
                    handleDisapprovedResult(params.row)
                  }}
                >
                  <CloseIcon />
                </Button>
              </ButtonGroup>
            )}
          </>
        )
      },
    },
  ]
  return (
    <>
      <Paper variant="outlined" className={`${classes.paper}`}>
        {isLoading ? (
          <Skeleton height={300} style={{ borderRadius: '10px' }} />
        ) : (
          <>
            <Grid item container sm={12} xs={12} className={classes.headerContainer}>
              <Typography variant="h5" className={classes.typography}>
                {string.title}
              </Typography>
            </Grid>
            <DataGrid
              rows={validationSumaryCreateUniqueKey}
              columns={columns}
              disableSelectionOnClick
              autoHeight
              filterModel={{ items }}
              onFilterModelChange={(model) => setFilter(model)}
              localeText={GRID_DEFAULT_LOCALE_TEXT}
            />
          </>
        )}
      </Paper>

      <ModalResultsActionDisapproved ref={modalActionsDisapproved} />
      <ModalResultsActionIncomplete ref={modalActionsIncomplete} />
    </>
  )
}
