const strings = {
  growCropsPage: {
    title: 'Consultoria',
  },
  buttonCreateConsultant: {
    title: 'Adicionar Consultoria',
  },
  addConsultantModal: {
    title: 'Adicionar Consultoria',
  },
}

export default strings
