const strings = {
  consultancy: 'Dados da consultoria',
  equipe: 'Equipe',
  tabsLabel: {
    tabA: 'Usuários',
    tabB: 'Agricultores',
  },
}

export default strings
