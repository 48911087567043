import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { Grid, TextField, MenuItem, Fade, FormControl, CircularProgress } from '@material-ui/core'
import lodash from 'lodash'
import MuiPhoneNumber from 'material-ui-phone-number-2'

import 'react-phone-input-2/lib/material.css'

import Modal from '~/components/modal'
import { Status, Visualizacao } from '~/constants/data-field'
import { useAlert } from '~/hooks/message'
import { associationCreateMapper } from '~/mappers/association-mapper'
import { useAssociationStore } from '~/store/association/association-store'
import { useCepStore } from '~/store/cep-store'
import { useCityStore } from '~/store/city-store'
import { FormsHandleCreate } from '~/types/association-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'

const CreateAssociation: React.ForwardRefRenderFunction<FormsHandleCreate> = (props, ref) => {
  const classes = useStyles()

  const {
    isLoading,
    messageError,
    clearFormsCreateUser,
    controllerModalState,
    modalControllerChangeCreate,
    statesBrazil,
  } = useAssociationStore()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<any>()
  const [cities, setCities] = useState<any[]>([])
  const createAssociation = useAssociationStore((state) => state.requestCreateAssociations)
  const alert = useAlert()
  const onSubmit: SubmitHandler<any> = async (usersData) => {
    createAssociation(associationCreateMapper(usersData))
  }
  const { find: findCepStore, isLoading: isLoadingCep } = useCepStore()
  const { listCitiesByStateId } = useCityStore()

  const closeModal = () => {
    modalControllerChangeCreate(false)
    clearFormsCreateUser()
  }

  const findCep = async (cep: string) => {
    const viacep = await findCepStore(cep.replace('-', ''))

    clearFormsCreateUser()
    if (viacep.erro) {
      alert.show({ message: 'CEP não encontrado!', type: 'error' })
      return
    }
    setValue('bairro', viacep.bairro, { shouldDirty: true })
    setValue('rua', viacep.logradouro, { shouldDirty: true })
    setValue('complemento', viacep.complemento, { shouldDirty: true })
    const state: any = statesBrazil.find(({ sigla }) => sigla === viacep.uf)

    if (state) {
      setValue('estado', state?.id ?? '', { shouldDirty: true })

      listCitiesByStateId(state?.id).then((data) => {
        setCities(data.cidades)
        setValue(
          'cidade_id',
          data?.cidades?.find(
            (value: any) =>
              value.descricao === viacep.localidade.normalize('NFD').replace(/\p{Mn}/gu, ''),
          )?.id ?? '',
          { shouldDirty: true },
        )
      })
    }
  }
  useImperativeHandle(ref, () => {
    return {
      async openModalCreateForms() {
        modalControllerChangeCreate(true)
        reset()
      },
    }
  })
  return (
    <Modal
      open={controllerModalState}
      onClose={closeModal}
      title={strings.textModal.addModalTitle}
      onSave={handleSubmit(onSubmit)}
      save
      isLoad={isLoading}
      disableCloseClickOut
      scroll
    >
      <Fade in={controllerModalState}>
        <FormControl
          onSubmit={handleSubmit(onSubmit)}
          variant="outlined"
          className={classes.formStyle}
        >
          <Grid container spacing={2} className={classes.modalStyle}>
            <Grid item sm={6}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="nome"
                    variant="outlined"
                    label="Nome completo"
                    placeholder="Nome do usuário"
                    className={classes.textInput}
                    error={(!!errors?.nome || !lodash.isEmpty(messageError?.nome)) && true}
                    helperText={errors.nome?.message ?? messageError?.nome}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="fantasia"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="fantasia"
                    variant="outlined"
                    label="Nome Fantasia"
                    placeholder="Nome Fantasia"
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={(!!errors?.fantasia || !lodash.isEmpty(messageError?.fantasia)) && true}
                    helperText={errors.fantasia?.message ?? messageError?.fantasia}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="documento"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="documento"
                    variant="outlined"
                    label="CPF/CNPJ"
                    placeholder="CPF/CNPJ"
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={
                      (!!errors?.documento || !lodash.isEmpty(messageError?.documento)) && true
                    }
                    helperText={errors.documento?.message ?? messageError?.documento}
                    value={
                      field.value
                        ? maskInterceptor(
                            field?.value?.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj',
                          )(field.value)
                        : ''
                    }
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="email"
                    variant="outlined"
                    label="E-mail"
                    placeholder="E-mail"
                    className={classes.textInput}
                    error={(!!errors?.email || !lodash.isEmpty(messageError?.email)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.email?.message ?? messageError?.email}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="telefone"
                control={control}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    required
                    id="celular"
                    label="Celular"
                    defaultCountry="br"
                    variant="outlined"
                    onlyCountries={['br']}
                    error={(!!errors?.telefone || !lodash.isEmpty(messageError?.telefone)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.telefone?.message ?? messageError?.telefone}
                    InputProps={{
                      style: {
                        borderRadius: 30,
                        width: '100%',
                        color: '#00446B',
                        borderColor: 'red',
                      },
                    }}
                    className={classes.textInput}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="cep"
                control={control}
                defaultValue=""
                render={({ field: { onChange, ...field } }) => (
                  <TextField
                    {...field}
                    required
                    id="cep"
                    variant="outlined"
                    label="CEP"
                    placeholder="CEP"
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={(!!errors?.cep || !lodash.isEmpty(messageError?.cep)) && true}
                    helperText={errors.cep?.message ?? messageError?.cep}
                    value={maskInterceptor('zipCode')(field.value)}
                    InputProps={{
                      endAdornment: <>{isLoadingCep ? <CircularProgress size={15} /> : ''}</>,
                    }}
                    onChange={(value) => {
                      if (value.target.value.length === 9) findCep(value.target.value)
                      return onChange(value)
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="estado"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="estado"
                    variant="outlined"
                    label="Estado"
                    placeholder="Estado"
                    onChange={async (event) => {
                      setValue('estado', event.target.value)
                      const citiesData = await listCitiesByStateId(Number(event.target.value))
                      setCities(citiesData.cidades)
                    }}
                    className={classes.textInput}
                    error={
                      (!!errors?.estado_id || !lodash.isEmpty(messageError?.estado_id)) && true
                    }
                    helperText={errors.estado_id?.message ?? messageError?.estado_id}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  >
                    {statesBrazil.map((Option: any) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="cidade_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="cidade_id"
                    variant="outlined"
                    label="Cidade"
                    placeholder="Cidade"
                    error={
                      (!!errors?.cidade_id || !lodash.isEmpty(messageError?.cidade_id)) && true
                    }
                    helperText={errors.cidade_id?.message ?? messageError?.cidade_id}
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  >
                    {cities.map((Option: any) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="rua"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="rua"
                    variant="outlined"
                    label="Rua"
                    className={classes.textInput}
                    error={(!!errors?.rua || !lodash.isEmpty(messageError?.rua)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.rua?.message ?? messageError?.rua}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="bairro"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="bairro"
                    variant="outlined"
                    label="Bairro"
                    className={classes.textInput}
                    error={(!!errors?.bairro || !lodash.isEmpty(messageError?.bairro)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.bairro?.message ?? messageError?.bairro}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="complemento"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="complemento"
                    variant="outlined"
                    label="Complemento"
                    className={classes.textInput}
                    error={
                      (!!errors?.complemento || !lodash.isEmpty(messageError?.complemento)) && true
                    }
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.complemento?.message ?? messageError?.complemento}
                  />
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="numero"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="numero"
                    variant="outlined"
                    label="Número"
                    className={classes.textInput}
                    error={(!!errors?.numero || !lodash.isEmpty(messageError?.numero)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.numero?.message ?? messageError?.numero}
                  />
                )}
              />
            </Grid>

            <Grid item sm={4}>
              <Controller
                name="estado_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="estado_id"
                    variant="outlined"
                    label="UF da Associação"
                    placeholder="UF da Associação"
                    className={classes.textInput}
                    error={
                      (!!errors?.estado_id || !lodash.isEmpty(messageError?.estado_id)) && true
                    }
                    helperText={errors.estado_id?.message ?? messageError?.estado_id}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  >
                    {statesBrazil.map((Option: any) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.descricao}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={4}>
              <Controller
                name="visualizacao"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="visualizacao"
                    variant="outlined"
                    label="Visualização"
                    placeholder="Visualizção"
                    className={classes.textInput}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    error={(!!errors?.status || !lodash.isEmpty(messageError?.status)) && true}
                    helperText={errors.status?.message ?? messageError?.status}
                  >
                    {Visualizacao.map((Option) => (
                      <MenuItem key={Option.statusName} value={Option.statusValue}>
                        {Option.statusName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={4}>
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="status"
                    variant="outlined"
                    label="Status"
                    placeholder="Status"
                    className={classes.textInput}
                    error={(!!errors?.status || !lodash.isEmpty(messageError?.status)) && true}
                    helperText={errors.status?.message ?? messageError?.status}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  >
                    {Status.map((Option) => (
                      <MenuItem key={Option.statusValue} value={Option.statusValue}>
                        {Option.statusName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Fade>
    </Modal>
  )
}

export default forwardRef(CreateAssociation)
