import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    padding: '20px 20px 20px 0',
    maxHeight: 1080,
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    overflowY: 'auto',
    '@global': {
      '*::-webkit-scrollbar': {
        width: '5px',
      },
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },

  primary: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  secundary: {
    fontSize: '16px',
  },

  iconBack: {
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: 90,
  },
}))
