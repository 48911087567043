import { Color } from '@material-ui/lab/Alert'
import create from 'zustand'

export type MessageState = {
  open?: boolean
  timeDuration?: number
  message: string
  type: Color | undefined
}

type MessageActions = {
  show: (props: MessageState) => void
  close: () => void
}

type State = MessageState & MessageActions

const initialState: MessageState = {
  open: false,
  timeDuration: 6000,
  message: '',
  type: undefined,
}

export const useMessageStore = create<State>((set) => ({
  ...initialState,
  show: (props: MessageState) => {
    set({ ...props, open: true })
  },
  close: () => set({ open: false, message: '' }),
}))
