import { useEffect, useState } from 'react'

import { Button, ButtonGroup, Chip, Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { Copyright } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { microregionMapper } from '~/mappers/microregion-mapper'
import { useFilterAuditState } from '~/store/audit/filter-audit'
import { useMicroregionStore } from '~/store/microregion/microregion-store'
import { MicroregionDataMapper } from '~/types/microregion-types'
import { useDataStateAndCities } from '~/utils/functions/functions'

import { MicroregionModal } from './components/microregion-modal'
import strings from './strings'
import { useStyles } from './styles'

export const Microregion: React.FC = () => {
  const classes = useStyles()
  const { items } = useFilterAuditState()
  const setFilter = useFilterAuditState((state) => state.setFilter)
  const [microregionCitiesId, setMicroregionCitiesId] = useState<any>(undefined)
  const { modalController, getMicroregion, regionList, getMicroregionById } = useMicroregionStore()
  const [dataProcessListMicroregion, setDataProcessListMicroregion] = useState<
    MicroregionDataMapper[]
  >([])

  useEffect(() => {
    getMicroregion()
  }, [getMicroregion])

  const dataList = useDataStateAndCities(regionList)

  useEffect(() => {
    if (dataList.length > 0) {
      const mappedData = microregionMapper(dataList)
      setDataProcessListMicroregion(mappedData)
    }
  }, [dataList])

  const openModal = (data: any) => {
    modalController(true)
    if (!data) {
      setMicroregionCitiesId(undefined)
    } else {
      setMicroregionCitiesId(data)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Microrregião',
      flex: 0.1,
      width: 110,
      minWidth: 100,
      renderCell: (params) => params.row?.nome,
    },
    {
      field: 'id',
      headerName: 'Cidade(s)',
      width: 200,
      renderCell: (params) => (
        <ul>
          <li>{params.row?.cidade}</li>
        </ul>
      ),
      type: 'string',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      minWidth: 110,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Chip
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <ButtonGroup disableElevation variant="contained">
            <Button
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={async () => {
                openModal(params?.row?.id)
                await getMicroregionById(Number(params?.row?.id))
              }}
            >
              <EditIcon />
            </Button>
          </ButtonGroup>
        )
      },
    },
  ]

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12} className={classes.headerContainer}>
          <Typography variant="h4" color="primary">
            {strings.growCropsPage.title}
          </Typography>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '14px',
            }}
            onClick={() => {
              openModal(null)
            }}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>
        <Grid container item xs={12} className={classes.resumeAuditTable}>
          <DataGrid
            rows={dataProcessListMicroregion}
            columns={columns}
            disableSelectionOnClick
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Paper>
      <Copyright />
      <MicroregionModal microregionCitiesId={microregionCitiesId} />
    </Grid>
  )
}
