import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'

import {
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import AssociationEdit from '~/pages/association/components/Modals/edit'
import { useAssociationStore } from '~/store/association/association-store'
import { FormsHandleEdit } from '~/types/association-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'
import 'react-loading-skeleton/dist/skeleton.css'

type RouteParamsProps = {
  associationId: string
}

const AssociationDataInfo: React.FC = () => {
  const classes = useStyles()
  const { associationId } = useParams<RouteParamsProps>()

  const { association, requestAssociation } = useAssociationStore()

  const editAssociationRef = useRef<FormsHandleEdit>(null)

  const openModalAssociation = (association: any) => {
    editAssociationRef.current?.openModalEditForms(association)
  }

  const getAssociationById = async (associationId: number) => {
    const data = requestAssociation(Number(associationId))
    openModalAssociation(data)
  }

  return (
    <Paper elevation={8} className={classes.paper}>
      <Grid
        item
        xs={12}
        sm={12}
        container
        justifyContent="space-between"
        style={{ padding: '0px 10px' }}
      >
        <Typography variant="h4">{strings.userTitle}</Typography>
        <IconButton
          component="span"
          color="primary"
          onClick={async () => getAssociationById(Number(associationId))}
          id="button-editar-user"
        >
          <Edit />
        </IconButton>
      </Grid>
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
          <Grid item sm={2}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.fantasia}
                  secondary={association?.association?.fantasia}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={2}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.document}
                  secondary={maskInterceptor('document')(association?.association?.documento)}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={3}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.email}
                  secondary={association?.association?.email}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={2}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.phone}
                  secondary={maskInterceptor('phone')(association?.association?.telefone)}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={2}>
            <List>
              <ListItem>
                <Grid container direction="column" style={{ maxWidth: '120px' }}>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.situation}
                  />
                  <Chip
                    label={association.association?.status === '1' ? 'Ativo' : 'Inativo'}
                    style={
                      association.association?.status === '1'
                        ? { backgroundColor: '#24d2b5', color: '#fff' }
                        : { backgroundColor: '#ff5c6c', color: '#fff' }
                    }
                  />
                </Grid>
              </ListItem>
            </List>
          </Grid>
          <Divider variant="fullWidth" component="article" style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
          <Grid item sm={3}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.endereco}
                  secondary={`${association?.association?.rua}, ${
                    association?.association?.numero ?? ''
                  } ${association?.association?.bairro}, ${
                    association?.association?.cidade.descricao
                  } - ${association?.association?.cidade?.state?.sigla} - ${maskInterceptor(
                    'zipCode',
                  )(association?.association?.cep)}`}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={2}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.cidade}
                  secondary={association?.association?.cidade?.descricao}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={2}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.estado}
                  secondary={association?.association?.cidade?.state?.descricao}
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={2}>
            <List>
              <ListItem>
                <Grid container direction="column" style={{ maxWidth: '120px' }}>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={strings.visualizacao}
                  />
                  <Chip
                    id="chips-status"
                    label={
                      association.association?.visualizacao === '1'
                        ? 'Dados detalhados'
                        : 'Dados consolidados'
                    }
                    style={
                      association.association?.visualizacao === '1'
                        ? {
                            backgroundColor: '#f9c320',
                            color: '#fff',
                            fontWeight: '500',
                          }
                        : {
                            backgroundColor: '#2ad7f9',
                            color: '#fff',
                            fontWeight: '500',
                          }
                    }
                  />
                </Grid>
              </ListItem>
            </List>
          </Grid>

          <Grid item sm={2}>
            <List>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secundary,
                  }}
                  primary={strings.uf}
                  secondary={association?.association?.estado?.sigla}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
      <AssociationEdit ref={editAssociationRef} />
    </Paper>
  )
}

export default AssociationDataInfo
