import { ReactElement } from 'react'
import { Redirect } from 'react-router-dom'

import { useClearFilterReset } from '~/hooks/clearFilter'

import { useTokenStore } from '../../store/auth'

function Logout(): ReactElement {
  const { logout } = useTokenStore()
  logout()
  useClearFilterReset()
  return <Redirect key={1} from="/logout" to="/login" />
}

export default Logout
