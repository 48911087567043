import { ReactElement, useState } from 'react'

import { Grid, Paper, Typography, Divider, IconButton } from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'
import Swal from 'sweetalert2'

import { useContactStore } from '~/store/contact-store'
import { ContactProps } from '~/types/contacts-types'

import { useStyles } from '../../styles'
import { ContactModal } from '../add-contact'

export function ContactsList(props: ContactProps): ReactElement {
  const classes = useStyles()
  const { ...contact } = props
  const [openModal, setOpenModal] = useState<boolean>(false)

  const { deleteContact, getContactsByClientId } = useContactStore()

  const confirmDeteleContact = (contact: ContactProps) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja remover o contato realizado?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result) => {
      if (result.isConfirmed)
        deleteContact(contact.id).then(() => getContactsByClientId(contact.cliente_id))
    })
  }

  return (
    <Grid xs={6} item>
      <Paper elevation={5} className={classes.paper}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container xs justifyContent="flex-start">
            <Typography variant="h6">{contact.assunto}</Typography>
          </Grid>
          <Typography variant="h6">
            {new Date(contact.data_contato).toLocaleDateString()}
          </Typography>
        </Grid>
        <Typography variant="body2" align="left" style={{ width: '100%' }}>
          {contact.observacao}
        </Typography>
        <Divider className={classes.divider} />
        <Grid xs={12} container>
          <IconButton color="primary" onClick={() => setOpenModal(true)}>
            <Edit />
          </IconButton>
          <IconButton
            className={classes.buttonDelete}
            onClick={() => confirmDeteleContact(contact)}
          >
            <Delete />
          </IconButton>
        </Grid>
        <ContactModal
          editContactProps={contact}
          title="Editar contato"
          open={openModal}
          onClose={() => setOpenModal(false)}
          cliente_id={contact.cliente_id}
        />
      </Paper>
    </Grid>
  )
}
