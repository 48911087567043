/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem, TextField } from '@material-ui/core'
import lodash from 'lodash'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import TransitionsModal from '~/components/modal'
import { DefaultInput, Status } from '~/constants/data-field'
import { useHarvestStore } from '~/store/harvest-registration/harvest-store'
import { FormStateHarvest } from '~/types/harvest-registration-types'

import strings from './strings'
import { useStyles } from './styles'

const schema = yup.object({
  description: yup.string().required('Campo é obrigatório'),
  status: yup.string().required('Campo é obrigatório'),
})

interface Props {
  harvestId: string
}

export const HarvestModal: React.FC<Props> = ({ harvestId }) => {
  const classes = useStyles()

  const {
    controllerModalState,
    modalController,
    createHarvestRegistration,
    updateHarvestRegistration,
    getHarvestRegistrationById,
    isLoading,
    dataHarvestById,
  } = useHarvestStore()

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<FormStateHarvest>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (harvestId && dataHarvestById) {
      const updateFormData = async () => {
        try {
          reset()
          setValue('description', dataHarvestById.description)
          setValue('status', dataHarvestById.status)
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: error.message,
            confirmButtonColor: '#2b78c0',
            customClass: {
              container: 'alert-container',
            },
          })
        }
      }
      updateFormData()
    } else {
      reset()
    }
  }, [controllerModalState, dataHarvestById, harvestId, getHarvestRegistrationById])

  const closeModal = () => {
    modalController(false)
  }

  const handleSave = () => {
    handleSubmit(
      async (data) => {
        if (!harvestId) {
          createHarvestRegistration(data)
        } else {
          updateHarvestRegistration(Number(harvestId), data)
        }
      },
      (error: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      },
    )()
  }

  return (
    <div className={classes.root}>
      <TransitionsModal
        open={controllerModalState}
        onClose={closeModal}
        onSave={handleSave}
        title={!harvestId ? strings.textModal.ModalTitle : strings.textModal.ModalTitleEdit}
        save
        isLoad={isLoading}
        disableCloseClickOut
      >
        <Grid container spacing={2} className={classes.modalStyle}>
          <Grid item xs={7}>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="description"
                  fullWidth
                  variant="outlined"
                  label={`${strings.formFields.Safra}`}
                  placeholder="Safra/Ano"
                  className={classes.textInput}
                  error={!!errors?.description}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              name="status"
              control={control}
              defaultValue={DefaultInput.statusDefault}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  required
                  id="status"
                  variant="outlined"
                  label="Status"
                  placeholder="Status"
                  className={classes.selectInput}
                  defaultValue={DefaultInput.statusDefault}
                  error={(!!errors?.status || !lodash.isEmpty(errors?.status)) && true}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors?.status?.message ?? errors?.status}
                >
                  {Status.map((Option) => (
                    <MenuItem key={Option.statusValue} value={Option.statusValue}>
                      {Option.statusName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </TransitionsModal>
    </div>
  )
}
