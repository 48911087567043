import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
html {
  box-sizing: border-box;
}

html, body, #root {
  height: 100vh;
}
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ECEFF1;
  }
  .page {
    position: absolute;
    left: 15px;
    right: 15px;
  }
  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .page-exit {
    opacity: 1;
    transform: scale(1);
  }
  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  .alert-container{
    z-index: 30000;
  }
  `
