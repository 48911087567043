const strings = {
  usersPage: {
    title: 'Usuários',
  },
  buttonTitle: {
    title: 'Adicionar Usuário',
  },
  textModal: {
    addModalTitle: 'Adicionar Usuário',
    editModalTitle: 'Editar Usuário',
  },

  buttonUserTitle: 'Detalhes',
}

export default strings
