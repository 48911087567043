const strings = {
  header: {
    title: 'Adicionar Consultoria',
  },
  fields: {
    required: 'Campo obrigatório',
  },
}

export default strings
