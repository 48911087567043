/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  TextField,
  FormControl,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as yup from 'yup'

import Copyright from '~/components/copyright'
import Modal from '~/components/modal'
import { ItensRow } from '~/components/row-itens'
import SearchTable from '~/components/search-table'
import { userAcessCtv } from '~/store/acesso-ctv-store'
import { ListRequestCtv, CtvTransferProps } from '~/types/state-types'

import strings from './strings'
import { useStyles } from './style'

const schema = yup.object({
  ctvOrigem: yup.string().required('Campo é obrigatório'),
  ctvDestino: yup.string().required('Campo é obrigatório'),
})

type FiltersProps = {
  columnField: string
  value: string
}

const AcessCtv: React.FC = () => {
  const {
    listState,
    getCityById,
    getCtv,
    copyCtv,
    controllerModal,
    modalController,
    isLoading,
    statesList,
    setStatesList,
  } = userAcessCtv()
  const classes = useStyles()

  const [fixedStatesList, setFixedStatesList] = useState(statesList)
  const [addfiltros, setAddFiltros] = useState<FiltersProps[]>([])

  useEffect(() => {
    listState().then((value) => setFixedStatesList(value))
  }, [])

  const [listCtv, setListCtv] = useState<ListRequestCtv[]>([])
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CtvTransferProps>({
    resolver: yupResolver(schema),
  })
  const onSubmit: SubmitHandler<CtvTransferProps> = async (usersData) => {
    const { ctvDestino, ctvOrigem } = usersData
    copyCtv(ctvOrigem, ctvDestino)
  }

  const ModalOpen = async () => {
    modalController(true)
    reset()
    const userCtv = await getCtv()
    setListCtv(userCtv)
  }

  const ModalClose = () => {
    modalController(false)
  }

  async function listCityForState(stateId: number) {
    await getCityById(stateId)
  }

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12} className={classes.gridTitle}>
          <Typography variant="h4" color="primary" className={classes.typography}>
            {strings.usersPage.title}
          </Typography>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '14px',
            }}
            onClick={ModalOpen}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.resumeAuditTable}>
          <TableContainer component={Paper}>
            <Table id="state-table">
              <TableHead id="state-table-head">
                <TableRow>
                  <TableCell align="center" width={10} style={{ minWidth: 300 }}>
                    <SearchTable
                      params={{
                        headerName: strings.tableHeader.state,
                        field: 'descricao',
                      }}
                      items={fixedStatesList}
                      setFilter={setStatesList}
                      addFiltros={setAddFiltros}
                      filtros={addfiltros}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <SearchTable
                      params={{
                        headerName: strings.tableHeader.initials,
                        field: 'sigla',
                      }}
                      items={fixedStatesList}
                      setFilter={setStatesList}
                      addFiltros={setAddFiltros}
                      filtros={addfiltros}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <SearchTable
                      params={{
                        headerName: strings.tableHeader.country,
                        field: 'descricao_pais',
                      }}
                      items={fixedStatesList}
                      setFilter={setStatesList}
                      addFiltros={setAddFiltros}
                      filtros={addfiltros}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <SearchTable
                      params={{
                        headerName: strings.tableHeader.initialsCountry,
                        field: 'sigla_pais',
                      }}
                      items={fixedStatesList}
                      setFilter={setStatesList}
                      addFiltros={setAddFiltros}
                      filtros={addfiltros}
                    />
                  </TableCell>
                  <TableCell align="center" width={10}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statesList?.map((item) => (
                  <ItensRow key={item.id} item={item} listCityForState={listCityForState} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>

      <Modal
        open={controllerModal}
        onClose={ModalClose}
        onSave={handleSubmit(onSubmit)}
        save
        title={strings.textModal.addModalTitle}
        disableCloseClickOut
        isLoad={isLoading}
      >
        <FormControl
          onSubmit={handleSubmit(onSubmit)}
          variant="outlined"
          className={classes.formStyle}
        >
          <Grid container className={classes.modalStyle} justifyContent="space-between">
            <Grid item sm={5}>
              <Typography
                style={{
                  margin: '10px',
                }}
              >
                {strings.modalAcessCtvCopy.originCtv}
              </Typography>
              <Controller
                name="ctvOrigem"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="ctvOrigem"
                    variant="outlined"
                    label="CTV Origem"
                    defaultValue=""
                    placeholder="CTV Origem"
                    error={!!errors?.ctvOrigem}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.ctvOrigem?.message}
                    className={classes.textInput}
                  >
                    {listCtv.map((Option) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Typography className={classes.arrowPosition}>≫</Typography>
            <Grid item sm={5}>
              <Typography
                style={{
                  margin: '10px',
                }}
              >
                {strings.modalAcessCtvCopy.destinyCtv}
              </Typography>
              <Controller
                name="ctvDestino"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="ctvDestino"
                    defaultValue=""
                    variant="outlined"
                    label="CTV Destino"
                    placeholder="CTV destino"
                    error={!!errors?.ctvDestino}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.ctvDestino?.message}
                    className={classes.textInput}
                  >
                    {listCtv.map((Option) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.nome}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Modal>
      <Copyright />
    </Grid>
  )
}

export default AcessCtv
