import { incompleteEmuns } from '~/enums'
import {
  IHectareByResult,
  IResultSubmit,
  IValidationPayload,
  ISubmitFieldController,
  ISubmitFieldControllerTransformer,
  IValidationSumary,
  ISubmitImcompleteResult,
  ISubmitDisapprovedResult,
  ISubmitDisapprovedResultMapper,
  IResultAproved,
  IResultIncomplete,
  ICreateInteractionCodeSubmit,
  IFarmerInteraction,
  IFarmerInteractionSubmitMapper,
  IFarmerInteractionSubmit,
  IRefSubmitMapper,
  IRefSubmit,
  IAssistantInteractionSubmitMapper,
  IAssistantInteractionSubmit,
  IRefsInteractionSubmitMapper,
  IRefsInteractionSubmit,
  IMapperSubmitCulture,
} from '~/types/validation-types'

export function submitValidation(requestValidation: IValidationPayload) {
  return {
    cultura: requestValidation.cultura?.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
    situacao: requestValidation?.situacao,
  } as IResultSubmit
}

export function submitFieldController(
  requestValidation: ISubmitFieldController,
  client_id?: string,
) {
  return {
    cliente_id: client_id,
    agricultor: requestValidation.agricultor,
    area_consultada: requestValidation.area_consultada,
    fonte_consultada: requestValidation.fonte,
    filtros_safra: requestValidation.cultura.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
  } as ISubmitFieldControllerTransformer
}

export function transformHectareByResult(hectareByResult: any) {
  return {
    inserido: hectareByResult[0] === 0 ? '0.00' : hectareByResult[0],
    em_validacao: hectareByResult[1] === 0 ? '0.00' : hectareByResult[1],
    aprovado: hectareByResult[2] === 0 ? '0.00' : hectareByResult[2],
    reprovado: hectareByResult[3] === 0 ? '0.00' : hectareByResult[3],
  } as IHectareByResult
}

export const submitDisapprovedResult = (
  formDataDisapproved: ISubmitDisapprovedResult,
  dataSubmit: IValidationSumary,
  client_id?: string,
) => {
  return {
    cliente_id: client_id,
    cultura_id: dataSubmit.cultura_id,
    safra_id: dataSubmit.safra_id,
    tipoSafra: dataSubmit.tipo_safra,
    descricao_reprovacao:
      formDataDisapproved.reprova === '1' ? 'Não é agricultor' : 'Não Conseguiu Contato',
    motivo_reprovacao: formDataDisapproved.reprova,
  } as ISubmitDisapprovedResultMapper
}

export const submitImcompleteResult = (
  formDataImcomplete: ISubmitImcompleteResult,
  dataSubmit: IValidationSumary,
  client_id?: string,
) => {
  return {
    cliente_id: client_id,
    safra_id: dataSubmit.safra_id,
    cultura_id: dataSubmit.cultura_id,
    descricao_incompleto: incompleteEmuns[formDataImcomplete.incompleto],
    tipoSafra: dataSubmit.tipo_safra,
  } as IResultIncomplete
}

export const submitApprovedResult = (params: IValidationSumary) => {
  return {
    cliente_id: params.cliente_id,
    cultura_id: params.cultura_id,
    safra_id: params.safra_id,
    tipoSafra: params.tipo_safra,
  } as IResultAproved
}

export const submitIntercationMapper = (params: IFarmerInteraction) => {
  return {
    data_interacao: params.data_interacao,
    meio_interacao: params.meio_interacao,
    tipo_fonte: params.fonte,
  } as ICreateInteractionCodeSubmit
}
export const submitCreateInteracationFarmer = (
  params: IFarmerInteractionSubmit,
  interacao_id: number,
  cliente_id?: string,
) => {
  return {
    cliente_id,
    interacao_id,
    filtros_safra: params.cultura.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
    area_plantio: params.area_plantio,
    autorizou: Number(params.autorizou),
    conhece_plantup: params.conhece,
    motivo: params.motivo,
    observacao: params.observacao,
    resultado: params.resultado,
  } as IFarmerInteractionSubmitMapper
}

export const submitRefMapper = (params: IRefSubmit, cliente_id?: string) => {
  return {
    cliente_id,
    referencia: params.funcao,
    empresa: params.empresa,
    quem: params.nome,
    telefone: params.telefone
      .replace(/\s/g, '')
      .replace('(', '')
      .replace(')', '')
      .replace('+', '')
      .replace('55', ''),
  } as IRefSubmitMapper
}

export const submitAssistanceInteraction = (
  params: IAssistantInteractionSubmit,
  cliente_id?: string,
) => {
  return {
    filtros_safra: params.cultura.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
    interacao_id: params.interacao,
    cliente_id,
    observacao: params.observacao,
    nome: params.nome,
  } as IAssistantInteractionSubmitMapper
}

export const submitInteractionRefs = (params: IRefsInteractionSubmit, cliente_id?: string) => {
  return {
    filtros_safra: params.cultura.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
    interacao_id: params.interacao,
    cliente_id,
    observacao: params.observacao,
    area_plantio: params.area_plantio,
    local_plantio: params.local,
    motivo: params.motivo,
    referencia_id: params.referencia,
    referencia_status: params.ativo ? '1' : '0',
    resultado: params.resultado,
  } as IRefsInteractionSubmitMapper
}

export const safraFieldMapper = (safras: any) => {
  return safras.filter((item: any) => item.cultura_id !== '0')
}

export const validationDataMapper = (paylaod: IValidationPayload) => {
  return {
    situacao: paylaod.situacao,
    cultura: paylaod.cultura.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
  } as IResultSubmit
}

export const fieldControllerDataMapper = (paylaod: IValidationPayload) => {
  return {
    cultura: paylaod.cultura.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
  } as IMapperSubmitCulture
}

export const validationSummaryMapper = (paylaod: IValidationPayload) => {
  return {
    situacao: paylaod.situacao,
    cultura: paylaod.cultura.map(
      (item) => `${item.cultura_id};${item.safra_id};${item.tipo_safra}`,
    ),
  } as IResultSubmit
}
