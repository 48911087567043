import * as React from 'react'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Button, FormControl, Grid, MenuItem, Paper, TextField } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

import {
  InterationStageClientFilter,
  OriginClientsFilter,
  PremiumClientsFilter,
  RegisteredTeamClientsFilter,
  Status,
} from '~/constants/data-field'
import { useClientStore } from '~/store/client/client-store'
import { ClientFilters } from '~/types/client-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

interface QuickSearchToolbar {
  onChangeFilter: ((filters: ClientFilters) => void) | undefined
}

const defaultValues = {
  nome: '',
  documento: '',
  origem: '',
  descricao_etapa: '',
  cadastrou_equipe: '',
  premium: '',
}

const ClientCardFilter: React.FC<QuickSearchToolbar> = ({ onChangeFilter }) => {
  const classes = useStyles()
  const { filters, setFilters } = useClientStore()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<ClientFilters>({
    defaultValues,
  })

  useEffect(() => {
    if (filters) {
      Object.keys(filters).forEach((key) => {
        setValue(key as keyof ClientFilters, filters[key as keyof ClientFilters])
      })
    }
  }, [filters, setValue])

  const onSubmit: SubmitHandler<ClientFilters> = async (formClientFilter) => {
    setFilters(formClientFilter)

    if (onChangeFilter) {
      onChangeFilter(formClientFilter)
    }
  }

  const resetForm = () => {
    reset(defaultValues)
  }

  return (
    <Paper elevation={0} className={`${classes.paper} ${classes.cardPaper}`}>
      <FormControl variant="outlined" className={classes.formStyle}>
        <Grid item sm={12} className={classes.gridFields} container spacing={2}>
          <Grid item sm={3}>
            <Controller
              name="nome"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="nome"
                  variant="outlined"
                  label="Agricultor"
                  placeholder="Nome do agricultor"
                  className={classes.textInput}
                  error={!!errors?.nome}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.nome?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="documento"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="documento"
                  variant="outlined"
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  value={
                    field.value
                      ? maskInterceptor(
                          field?.value?.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj',
                        )(field.value)
                      : ''
                  }
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="origem"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="origem"
                  variant="outlined"
                  label="Origem"
                  defaultValue=""
                  placeholder="Origem"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {OriginClientsFilter.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="descricao_etapa"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="descricao_etapa"
                  variant="outlined"
                  label="Etapa"
                  defaultValue=""
                  placeholder="Etapa"
                  error={!!errors?.descricao_etapa}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.descricao_etapa?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {InterationStageClientFilter.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="cadastrou_equipe"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="cadastrou_equipe"
                  variant="outlined"
                  label="Equipe"
                  defaultValue=""
                  placeholder="Equipe"
                  error={!!errors?.cadastrou_equipe}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.cadastrou_equipe?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {RegisteredTeamClientsFilter.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="premium"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="premium"
                  variant="outlined"
                  label="Cliente Premium?"
                  defaultValue=""
                  placeholder="Cliente Premium?"
                  error={!!errors?.premium}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.premium?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {PremiumClientsFilter.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="status"
                  variant="outlined"
                  label="Status"
                  defaultValue=""
                  placeholder="Status"
                  error={!!errors?.status}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.status?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {Status.map((Option) => (
                    <MenuItem key={Option.statusValue} value={Option.statusValue}>
                      {Option.statusName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.buttonActions}>
          <Button
            id="button-limpar-form"
            variant="contained"
            color="secondary"
            className={classes.buttonClear}
            onClick={resetForm}
            endIcon={<ClearIcon />}
          >
            {strings.buttons.clear}
          </Button>
          <Button
            id="button-filtrar-form"
            type="submit"
            variant="contained"
            color="primary"
            className={classes.buttonFilter}
            endIcon={<SearchIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {strings.buttons.send}
          </Button>
        </Grid>
      </FormControl>
    </Paper>
  )
}

export default ClientCardFilter
