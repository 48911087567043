import React from 'react'

import { Button, Chip, Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import DeleteIcon from '@material-ui/icons/Delete'
import Swal from 'sweetalert2'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { TypeCargos } from '~/enums/enum'
import { useFilterSubGridUser } from '~/store/user/filter-subgrid-user'
import { useUserStore } from '~/store/user/user-store'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'

const UserFarmerInfo: React.FC = () => {
  const classes = useStyles()
  const { userWithClient, removeRelationShipClient, userRelationshipDelete, userDataById } =
    useUserStore()
  const { items } = useFilterSubGridUser()
  const setFilter = useFilterSubGridUser((state) => state.setFilter)

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      flex: 0.3,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.nome,
    },
    {
      field: 'cargo',
      headerName: 'Cargo',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      renderCell: (params) => TypeCargos[params?.row?.cargo],
    },
    {
      field: 'email',
      headerName: 'email',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.email,
    },
    {
      field: 'celular',
      headerName: 'celular',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.celular,
      renderCell: (params) => maskInterceptor('phone')(params?.row?.celular),
    },
    {
      field: 'status_agricultor',
      headerName: 'Status Agricultor',
      flex: 0.2,
      minWidth: 110,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => (params?.row?.status_agricultor === '1' ? 'Ativo' : 'Inativo'),
      renderCell: (params) => {
        return (
          <Chip
            label={params?.row?.status_agricultor === '1' ? 'Ativo' : 'Inativo'}
            style={
              params?.row?.status_agricultor === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'status_vinculo',
      headerName: 'Status Vinculo',
      flex: 0.2,
      minWidth: 110,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => (params?.row?.status_vinculo === '1' ? 'Ativo' : 'Inativo'),
      renderCell: (params) => {
        return (
          <Chip
            label={params?.row?.status_vinculo === '1' ? 'Ativo' : 'Inativo'}
            style={
              params?.row?.status_vinculo === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Remover todos vinculos',
      flex: 0.4,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ backgroundColor: '#ed4b4b', margin: '3px 0px' }}
            endIcon={<DeleteIcon />}
            onClick={() => {
              Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Deseja remover todos os vinculos?',
                confirmButtonColor: '#ff7d89',
                showCancelButton: true,
                cancelButtonColor: '#2b78c0',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCloseButton: true,
                customClass: {
                  container: 'alert-container',
                },
              }).then(async (result: any) => {
                if (result.isConfirmed) {
                  userRelationshipDelete(userDataById.id, true)
                } else {
                  Swal.close()
                }
              })
            }}
          >
            {strings.deleteAllButton}
          </Button>
        )
      },
      renderCell: (params) => {
        return (
          <Button
            id="button-deletar-usuario"
            variant="contained"
            style={{
              backgroundColor: '#ff5c6c',
              color: '#fff',
              fontSize: '10px',
            }}
            onClick={() => {
              Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Deseja remover esse vinculo?',
                confirmButtonColor: '#ff7d89',
                showCancelButton: true,
                cancelButtonColor: '#2b78c0',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCloseButton: true,
                customClass: {
                  container: 'alert-container',
                },
              }).then(async (result: any) => {
                if (result.isConfirmed) {
                  removeRelationShipClient(userDataById.id, params?.row?.cliente_id)
                } else {
                  Swal.close()
                }
              })
            }}
          >
            <DeleteIcon />
          </Button>
        )
      },
    },
  ]

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} sm={12} container>
          <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h4" align="left">
              {strings.farmerInfoTitle}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} className={classes.resumeTable}>
          <DataGrid
            rows={userWithClient}
            columns={columns}
            rowsPerPageOptions={[25, 50, 100]}
            autoPageSize
            autoHeight
            style={{ maxHeight: 400, minHeight: 100, overflow: 'auto' }}
            filterModel={{ items }}
            getRowId={(row) => row.cliente_id}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default UserFarmerInfo
