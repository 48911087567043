import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import EditUser from '~/components/edit-user'
import { PerfilAdmin, TypeAgpro, TypeCargos, TypeValidacaoAutomaticaFilter } from '~/enums/enum'
import { StatusEnum } from '~/pages/client/client-details/components/info-client/components/detail-client/enum'
import { useUserStore } from '~/store/user/user-store'
import { FormsHandleEdit } from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import 'react-loading-skeleton/dist/skeleton.css'
import strings from './strings'
import { useStyles } from './style'

type RouteParamsProps = {
  userId: string
}

const UserDataInfo: React.FC = () => {
  const classes = useStyles()
  const { userId } = useParams<RouteParamsProps>()

  const { editDataUserId, userDataById, getUser, listRelationShipClient } = useUserStore()

  const editUserRef = useRef<FormsHandleEdit>(null)

  const OpenModalEditUser = (user: any) => {
    editUserRef.current?.openModalEditForms(user)
  }

  const getListUserById = async (userId: number) => {
    const data = await editDataUserId(userId)
    OpenModalEditUser(data)
  }

  useEffect(() => {
    getUser(userId)
    listRelationShipClient(userId)
  }, [getUser, userId])

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" className={classes.paper}>
        <Grid container item xs={12} justifyContent="space-between" style={{ padding: '0px 10px' }}>
          <Typography variant="h5"> {strings.userInfoTitle} </Typography>
          <IconButton
            component="span"
            color="primary"
            onClick={() => getListUserById(Number(userId))}
            id="button-editar-user"
          >
            <Edit />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.headerContainer}>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {strings.document}
              </Typography>
              <Typography variant="body2"> {maskInterceptor('cpf')(userDataById?.cpf)} </Typography>
            </Grid>

            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.email}{' '}
              </Typography>
              <Typography variant="body2"> {userDataById?.email} </Typography>
            </Grid>

            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.phone}{' '}
              </Typography>
              <Typography variant="body2">
                {' '}
                {maskInterceptor('phone')(userDataById?.celular)}{' '}
              </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.situation}{' '}
              </Typography>
              <Typography variant="body2"> {StatusEnum[userDataById.status]} </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.agpro}{' '}
              </Typography>
              <Typography variant="body2"> {TypeAgpro[userDataById?.plantupper]} </Typography>
            </Grid>
          </Grid>
          <Divider
            variant="fullWidth"
            component="article"
            style={{ width: '100%', margin: '10px 0' }}
          />
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'start',
              marginTop: '8px',
              marginRight: '12px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'flex-start',
            }}
          >
            <Grid
              xs={2}
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.cargo}{' '}
              </Typography>
              <Typography variant="body2"> {TypeCargos[userDataById?.cargo_id]} </Typography>
            </Grid>

            <Grid
              xs={2}
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ marginLeft: '42px' }}
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.adminInfo}{' '}
              </Typography>
              <Typography variant="body2"> {PerfilAdmin[userDataById?.perfil_admin]} </Typography>
            </Grid>

            <Grid
              xs={4}
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ marginLeft: '42px' }}
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.validacaoAuditoriaAtuomatica}{' '}
              </Typography>
              <Typography variant="body2">
                {' '}
                {TypeValidacaoAutomaticaFilter[userDataById?.validacao_auditoria_automatica]}{' '}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <EditUser ref={editUserRef} />
    </Grid>
  )
}

export default UserDataInfo
