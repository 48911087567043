import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  gridCenter: {
    justifyContent: 'flex-start',
    alignContent: 'center',
    textAlign: 'start',
  },
  lastAudit: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}))
