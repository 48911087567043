const strings = {
  welcome: 'Seja bem vindo(a)',
  presentation: {
    start: 'Essa é a nova cara do ',
    middle: 'PlantUP',
    end: ' Administrativo, mais rápido, moderno e simplificado.',
  },
  fastAccess: 'Acesso Rápido.',
  fastAccessConsultancy: 'Acesse, gerencie e altere a consultoria e seus dados agora mesmo!',
  fastAccessClient: 'Verifique os dados de seus agricultores, realize alterações e novas adições.',
  fastAccessUser: 'Faça alterações nas informações relacionadas aos usuários cadastrados.',
  fastAccessAudit: 'Audite seus dados cadastrados. Análise completa de suas informações.',
  fastAccessCtvs: 'Acesse, gerencie e altere os acessos de CTVs e seus dados agora mesmo!',
  fastAccessCultivar: 'Acesse, gerencie e altere as cultivares e seus dados agora mesmo!',
  fastAccessRegiao: 'Acesse, gerencie e altere as Regiões e seus dados agora mesmo!',
  fastAccessValidation: 'Realize a verificação dos seus dados. Acesse, gerencie e altere!',
}

export default strings
