import { useForm, Controller } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  FormHelperText,
} from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'
import { useAuditListStore } from '~/store/audit/list-audits'

import { useStyles } from './styles'

type FormState = {
  area_plantio: string
  autorizou: string
  conhece_plantup: string
  observacao: string
  meio_interacao: string
  tipo_fonte: string
  tipo_safra: string
  motivo: string
}

type PropsState = {
  cultura_id: number
  safra_id: number
  tipo_safra: string
}

type ParamsRoute = {
  cliente_id: string
}

const motivos = [
  'AGUARDANDO CORREÇÃO',
  'AGUARDANDO REFERÊNCIAS',
  'ÁREA INCOMPLETA',
  'CHECANDO REFERÊNCIAS',
  'ENTRAR EM CONTATO',
  'LANÇAMENTO NÃO AUTORIZAÇÃO',
  'NÃO É AGRICULTOR',
  'NÃO TEM INTERESSE',
  'TENTATIVA DE CONTATO',
  'AUDITORIA OK',
]

const schema = yup.object().shape({
  area_plantio: yup
    .number()
    .required('Campo obrigatório')
    .typeError('Área de Plantio é do tipo numérico'),
  autorizou: yup.number().required('Campo obrigatório'),
  conhece_plantup: yup.number().required('Campo obrigatório'),
  observacao: yup.string().required('Campo obrigatório'),
  meio_interacao: yup.string().required('Campo obrigatório'),
  tipo_fonte: yup.string().required('Campo obrigatório'),
  tipo_safra: yup.number().required('Campo obrigatório'),
  motivo: yup.string().required('Campo obrigatório'),
})

const FormInteracion = () => {
  const { handleSubmit, control, formState, reset } = useForm<FormState>({
    resolver: yupResolver(schema),
  })
  const { cultura_id, safra_id, tipo_safra } = useHistory<PropsState>().location.state
  const { cliente_id } = useParams<ParamsRoute>()

  const { errors } = formState
  const classes = useStyles()
  const { load } = useAuditListStore()

  const alert = useAlert()

  const onSubmit = (data: FormState) => {
    api
      .post('/interaction/farmer', {
        ...data,
        data_interacao: new Date(),
        cultura_id,
        safra_id,
        cliente_id,
      })
      .then(() => {
        load()
        alert.show({ message: 'Inserido com sucesso', type: 'success' })
        reset({
          area_plantio: '',
          autorizou: '',
          conhece_plantup: '',
          observacao: '',
          meio_interacao: '',
          tipo_fonte: '',
          tipo_safra: '',
          motivo: '',
        })
      })
      .catch((error: AxiosError) =>
        alert.show({ message: error.response?.data.message, type: 'error' }),
      )
  }

  return (
    <>
      <Grid container xs={12} item className={classes.textLeft}>
        <Typography variant="h6" className={classes.titleFarmer}>
          Formulário de Interação
        </Typography>
      </Grid>
      <Grid container xs={12} item spacing={3} className={classes.gridMargin}>
        <Grid item md={4} lg={2} className={classes.textLeft}>
          <Controller
            name="conhece_plantup"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="conhece_plantup" className={classes.textInput}>
                  Conhece o Plantup?
                </InputLabel>
                <Select
                  labelId="conhece_plantup"
                  id="conhece_plantup"
                  className={classes.selectInput}
                  required
                  {...field}
                >
                  <MenuItem value={1}>Sim</MenuItem>
                  <MenuItem value={2}>Não</MenuItem>
                </Select>
                {errors.conhece_plantup?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.conhece_plantup?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item md={4} lg={2} className={classes.textLeft}>
          <Controller
            name="autorizou"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="autorizou" className={classes.textInput}>
                  Autorizou cadastro?
                </InputLabel>
                <Select
                  {...field}
                  labelId="autorizou"
                  id="autorizou"
                  className={classes.selectInput}
                  required
                >
                  <MenuItem value={1}>Sim</MenuItem>
                  <MenuItem value={2}>Não</MenuItem>
                </Select>
                {errors.autorizou?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.autorizou?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs className={classes.textLeft}>
          <Controller
            name="area_plantio"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="area_plantio" className={classes.textInput}>
                  Área Plantio
                </InputLabel>
                <TextField
                  {...field}
                  id="area_plantio"
                  type="number"
                  className={classes.selectInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={errors.area_plantio?.message}
                  FormHelperTextProps={{ className: classes.helperText }}
                />
              </Grid>
            )}
          />
        </Grid>
        <Grid item md={4} lg={2} className={classes.textLeft}>
          <Controller
            name="meio_interacao"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="meio_interacao" className={classes.textInput}>
                  Meio de Interação
                </InputLabel>
                <Select
                  labelId="meio_interacao"
                  id="meio_interacao"
                  className={classes.selectInput}
                  required
                  {...field}
                >
                  <MenuItem value={1}>WhatsApp</MenuItem>
                  <MenuItem value={2}>Telefone</MenuItem>
                  <MenuItem value={3}>Email</MenuItem>
                </Select>
                {errors.meio_interacao?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.meio_interacao?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs className={classes.textLeft}>
          <Controller
            name="tipo_fonte"
            control={control}
            defaultValue="7"
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="tipo_fonte" className={classes.textInput}>
                  Tipo de Fonte
                </InputLabel>
                <Select
                  labelId="tipo_fonte"
                  id="tipo_fonte"
                  className={classes.selectInput}
                  required
                  {...field}
                >
                  <MenuItem value={1}>AgPro</MenuItem>
                  <MenuItem value={7}>Agricultor</MenuItem>
                  <MenuItem value={2}>Consultor</MenuItem>
                  <MenuItem value={3}>CTV</MenuItem>
                  <MenuItem value={4}>Diretor</MenuItem>
                  <MenuItem value={5}>Gerente</MenuItem>
                  <MenuItem value={6}>RCA</MenuItem>
                </Select>
                {errors.tipo_fonte?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.tipo_fonte?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs className={classes.textLeft}>
          <Controller
            name="motivo"
            control={control}
            defaultValue="10"
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="motivo" className={classes.textInput}>
                  Motivo
                </InputLabel>
                <Select
                  labelId="motivo"
                  id="motivo"
                  className={classes.selectInput}
                  required
                  {...field}
                >
                  {motivos.map((value, id) => (
                    <MenuItem value={id + 1} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                {errors.motivo?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.motivo?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs className={classes.textLeft}>
          <Controller
            name="tipo_safra"
            control={control}
            defaultValue={tipo_safra}
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="tipo_safra" className={classes.textInput}>
                  Tipo de Safra
                </InputLabel>
                <Select
                  labelId="tipo_safra"
                  id="tipo_safra"
                  className={classes.selectInput}
                  required
                  {...field}
                >
                  <MenuItem value={1}>1º Safra</MenuItem>
                  <MenuItem value={2}>2º Safra</MenuItem>
                </Select>
                {errors.tipo_safra?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.tipo_safra?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>

        <Grid item xs={12} className={classes.gridCenter}>
          <Controller
            name="observacao"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                id="observacao"
                label="Observação"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                helperText={errors.observacao?.message}
                FormHelperTextProps={{ className: classes.helperText }}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} className={classes.gridButtonSubmit}>
          <Button
            id="button-salvar-interecao"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Done />}
            onClick={handleSubmit(onSubmit)}
          >
            Salvar Interação
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default FormInteracion
