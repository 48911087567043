import { ICreateAssociation, inputAssociation } from '~/types/association-types'

export function associationCreateMapper(requestType: ICreateAssociation) {
  return {
    ...requestType,
    telefone: requestType?.telefone
      ?.replace(/\s/g, '')
      ?.replace('(', '')
      ?.replace(')', '')
      ?.replace('+', '')
      ?.replace('55', ''),
  } as any
}

export function associationEditMapper(requestType: inputAssociation) {
  return {
    ...requestType,
    cidade_id: requestType.cidade_id.id ?? '',
    telefone: requestType?.telefone
      ?.replace(/\s/g, '')
      ?.replace('(', '')
      ?.replace(')', '')
      ?.replace('+', '')
      ?.replace('-', '')
      ?.replace('55', ''),
  } as any
}
