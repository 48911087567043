const strings = {
  harvestRegistrationPage: {
    title: 'Safras',
  },
  buttonTitle: {
    title: 'Cadastrar Safra',
  },
  textModal: {
    addModalTitle: 'Cadastro Safra',
    editModalTitle: 'Editar Safra',
  },
}

export default strings
