import React, { useMemo, ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core'
import { ArrowForwardIosRounded } from '@material-ui/icons'
import clsx from 'clsx'

import { AppRoutePath } from '~/constants/paths'

import { useStyles } from './styles'

interface Props {
  readonly title: string
  readonly route: AppRoutePath
  readonly icon: React.ReactNode
  readonly focused: boolean
  readonly onClick: () => void
}

export function ItemMenu({ title, route, icon, focused, onClick }: Props): ReactElement {
  const classes = useStyles()
  const location = useLocation()

  const currentRoute = useMemo(() => location.pathname, [location])

  return (
    <ListItem button className={classes.listItem}>
      <Tooltip title={title}>
        <Link
          to={route}
          id="button-menu-list"
          onClick={onClick}
          className={clsx(classes.textLink, {
            [classes.isFocused]: focused,
            [classes.activeLink]: currentRoute.match(route),
          })}
        >
          <ListItemIcon
            className={clsx(classes.iconLink, {
              [classes.isFocused]: focused,
              [classes.activeLink]: currentRoute.match(route),
            })}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={title} className={classes.descriptionList} />
          <ListItemIcon
            className={clsx(classes.iconExpand, {
              [classes.isFocused]: focused,
              [classes.activeLink]: currentRoute.match(route),
            })}
          >
            <ArrowForwardIosRounded fontSize="small" />
          </ListItemIcon>
        </Link>
      </Tooltip>
    </ListItem>
  )
}
