import React, { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import 'react-loading-skeleton/dist/skeleton.css'

import { allResults, filterSetMount, tipo } from '~/constants/data-field'
import {
  fieldControllerDataMapper,
  safraFieldMapper,
  submitValidation,
  transformHectareByResult,
  validationDataMapper,
  validationSummaryMapper,
} from '~/mappers/validation-mapper'
import HeadersDetails from '~/shared/headers-details/headers-details'
import { useValidationStore } from '~/store/validation/validation-store'

import { CardInfo } from './components/card-info'
import { ClientDetails } from './components/client-details'
import { ControlerFields } from './components/controler-fields'
import { InteractionAssistant } from './components/interaction-assistant'
import { InteractionsFarmer } from './components/interaction-farmer'
import { Refs } from './components/refs'
import { RefsInteractions } from './components/refs-interactions'
import { ValidationData } from './components/validation-data'
import { ValidationSumary } from './components/validation-summary'
import strings from './string'
import { useStyles } from './style'

const ValidationDetails: React.FC = () => {
  const classes = useStyles()
  const {
    aba,
    setNavigation,
    client,
    getResultsBySafra,
    resultSafras,
    getHectareByResult,
    setHectareInfo,
    hectareInfo,
    getValidationData,
    getFieldController,
    getValidationSumary,
    getAssistantInteraction,
    getFarmerInteraction,
    getRefsInteraction,
    getRefs,
    setSafraField,
    isLoading,
    setGettersData,
  } = useValidationStore()

  const history = useHistory()

  const onBack = () => {
    history.goBack()
    setNavigation(aba)
    setSafraField([])
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    setGettersData(data)
    const results = await getHectareByResult(submitValidation(data), client?.cliente_id)
    setHectareInfo(transformHectareByResult(results))
    await Promise.all([
      getValidationData(validationDataMapper(data), client?.cliente_id),
      getFieldController(fieldControllerDataMapper(data), client?.cliente_id),
      getValidationSumary(validationSummaryMapper(data), client?.cliente_id),
      getAssistantInteraction(fieldControllerDataMapper(data), client?.cliente_id),
      getAssistantInteraction(fieldControllerDataMapper(data), client?.cliente_id),
      getFarmerInteraction(fieldControllerDataMapper(data), client?.cliente_id),
      getRefsInteraction(fieldControllerDataMapper(data), client?.cliente_id),
      getRefs(client?.cliente_id),
    ])
  }

  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<any>()

  useEffect(() => {
    getResultsBySafra(client?.cliente_id)
    setValue('cultura', allResults)
    async function getRequets() {
      const results = await getHectareByResult(submitValidation(filterSetMount), client?.cliente_id)

      setGettersData(filterSetMount)
      setHectareInfo(transformHectareByResult(results))

      await Promise.all([
        getValidationData(validationDataMapper(filterSetMount), client?.cliente_id),
        getFieldController(fieldControllerDataMapper(filterSetMount), client?.cliente_id),
        getValidationSumary(validationSummaryMapper(filterSetMount), client?.cliente_id),
        getAssistantInteraction(fieldControllerDataMapper(filterSetMount), client?.cliente_id),
        getFarmerInteraction(fieldControllerDataMapper(filterSetMount), client?.cliente_id),
        getRefsInteraction(fieldControllerDataMapper(filterSetMount), client?.cliente_id),
        getRefs(client?.cliente_id),
      ])
    }
    getRequets()
  }, [
    client?.cliente_id,
    getAssistantInteraction,
    getFarmerInteraction,
    getFieldController,
    getHectareByResult,
    getRefs,
    getRefsInteraction,
    getResultsBySafra,
    getValidationData,
    getValidationSumary,
    setGettersData,
    setHectareInfo,
    setValue,
  ])

  const handleFieldAllController = () => {
    if (getValues('cultura').length === 0) {
      setValue('cultura', allResults)
    }
  }
  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <HeadersDetails nameTitle={strings.detalhes} onClickBack={onBack} />

        <Grid container item xs={12} className={classes.headerContainer}>
          <Grid item sm={5}>
            <Typography variant="h4" className={classes.titleName}>
              {client?.cliente_nome}
            </Typography>
          </Grid>
          <Grid item sm={2} className={classes.inputFilter}>
            <Controller
              name="situacao"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel id="situacao_id">Situação</InputLabel>
                  <Select
                    {...field}
                    labelId="situacao_label"
                    id="situacao_id"
                    label="situacao_id"
                    autoWidth
                    required
                    error={!!errors?.tipo}
                  >
                    {tipo.map((Option) => (
                      <MenuItem key={Option.situacao} value={Option.situacao}>
                        {Option.situacaoValue}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.tipo?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.tipo?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="cultura"
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <Autocomplete
                    {...field}
                    multiple
                    key={Math.random()}
                    id="cultura_id"
                    options={allResults.concat(resultSafras)}
                    getOptionLabel={(option) => option.descricao}
                    onChange={(e, values) => {
                      setSafraField(safraFieldMapper(values))
                      setValue('cultura', values)
                      handleFieldAllController()
                    }}
                    isOptionEqualToValue={(option, value) => option.descricao === value.descricao}
                    renderInput={(params) => (
                      <TextField {...params} label="Cultura" variant="outlined" />
                    )}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={2}>
            <Button
              variant="contained"
              className={classes.buttonFilter}
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {strings.buttonTitle}
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.bodyInformation}>
          <Grid item sm={3} className={classes.validationContent}>
            {isLoading ? (
              <Skeleton height={150} borderRadius={10} />
            ) : (
              <CardInfo
                title="INSERIDOS(ha)"
                value={formatNumber(Number(hectareInfo.inserido))}
                classCard={classes.insert}
              />
            )}
          </Grid>
          <Grid item sm={3} className={classes.validationContent}>
            {isLoading ? (
              <Skeleton height={150} borderRadius={10} />
            ) : (
              <CardInfo
                title="EM VALIDAÇÃO(ha)"
                value={formatNumber(Number(hectareInfo.em_validacao))}
                classCard={classes.validation}
              />
            )}
          </Grid>
          <Grid item sm={3} className={classes.validationContent}>
            {isLoading ? (
              <Skeleton height={150} borderRadius={10} />
            ) : (
              <CardInfo
                title="APROVADOS(ha)"
                value={formatNumber(Number(hectareInfo.aprovado))}
                classCard={classes.aproved}
              />
            )}
          </Grid>
          <Grid item sm={3} className={classes.validationContent}>
            {isLoading ? (
              <Skeleton height={150} borderRadius={10} />
            ) : (
              <CardInfo
                title="REPROVADOS(ha)"
                value={formatNumber(Number(hectareInfo.reprovado))}
                classCard={classes.disapproved}
              />
            )}
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.bodyInformation}>
          <ClientDetails />
        </Grid>

        <Grid container item xs={12} className={classes.bodyInformation}>
          <ValidationData />
        </Grid>

        <Grid item xs={12} container className={classes.bodyInformation}>
          <ControlerFields />
        </Grid>

        <Grid item xs={12} container className={classes.bodyInformation}>
          <ValidationSumary />
        </Grid>

        <Grid item xs={12} container className={classes.bodyInformation}>
          <InteractionsFarmer />
        </Grid>

        <Grid item xs={12} container className={classes.bodyInformation}>
          <InteractionAssistant />
        </Grid>
        <Grid item xs={12} container className={classes.bodyInformation}>
          <Refs />
        </Grid>
        <Grid item xs={12} container className={classes.bodyInformation}>
          <RefsInteractions />
        </Grid>
      </Paper>
    </Grid>
  )
}

export default ValidationDetails
