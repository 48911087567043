const strings = {
  growCropsPage: {
    title: 'Regiões',
  },
  buttonTitle: {
    title: 'Adicionar Nova Regra',
  },
  textModal: {
    addModalTitle: 'Cadastro Nova Regra',
    editModalTitle: 'Editar Nova Regra',
  },
  dataGridHeader: {
    culture: 'Cultura',
    origensAgriculture: 'Grupo de Maturação',
    limits: 'Sigla País',
    status: 'Status',
    actions: 'Ações',
  },
}

export default strings
