import React, { ReactElement, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  Paper,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  Snackbar,
} from '@material-ui/core'
import { VisibilityOff, Visibility } from '@material-ui/icons'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import * as yup from 'yup'

import { AppRoutePath } from '~/constants/paths'

import { plantupIntelligenceImg } from '../../assets'
import { useTokenStore } from '../../store/auth'
import strings from './strings'
import { useStyles } from './styles'

type FormState = {
  email: string
  password: string
}
interface State {
  showPassword: boolean
}

const schema = yup.object().shape({
  email: yup.string().email('Informe um email válido').required('Campo obrigatório'),
  password: yup.string().required('Campo obrigatório'),
})

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function Login(): ReactElement {
  const history = useHistory()
  const [values, setValues] = React.useState<State>({
    showPassword: false,
  })
  const login = useTokenStore((state) => state.login)
  const clearError = useTokenStore((state) => state.clearError)

  const { token, isLoading, messageError } = useTokenStore()

  useEffect(() => {
    if (token) {
      history.push(AppRoutePath.HOME)
    }
  }, [token])

  const handleClose = () => {
    clearError()
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const classes = useStyles()

  const { handleSubmit, control, formState } = useForm<FormState>({
    resolver: yupResolver(schema),
  })

  const { errors } = formState
  // eslint-disable-next-line no-console
  const onSubmit = (data: FormState) => {
    const { email, password } = data
    login(email, password)
  }

  return (
    <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
      <div className={classes.paper}>
        <img src={plantupIntelligenceImg} alt="Logo" className={classes.logoItelligence} />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={strings.email.label}
                autoComplete="email"
                autoFocus
                helperText={errors.email?.message}
                FormHelperTextProps={{ error: true }}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  {strings.password.label}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Senha"
                  {...field}
                />
              </FormControl>
            )}
          />
          <Grid container>
            <Grid item xs>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={strings.remember.label}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            {!isLoading ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={`${classes.submit} ${classes.submitLogin}`}
              >
                {strings.buttons.submit}
              </Button>
            ) : (
              <CircularProgress className={classes.submit} />
            )}
          </Grid>
        </form>
      </div>
      {messageError && (
        <Grid container justifyContent="center">
          <Snackbar open autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {messageError}
            </Alert>
          </Snackbar>
        </Grid>
      )}
    </Grid>
  )
}

export default Login
