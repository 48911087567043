'use client'

import { Button, Grid, LinearProgress, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { useStyles } from './style'

export interface HeadersDetailsProps {
  nameTitle?: string
  onClickBack(): void
}

export default function HeadersDetails({ nameTitle, onClickBack }: HeadersDetailsProps) {
  const classes = useStyles()

  return (
    <Grid container item xs={12}>
      <Grid item xs={1}>
        <Button
          variant="contained"
          color="primary"
          className={classes.iconBack}
          onClick={onClickBack}
        >
          <ArrowBack />
        </Button>
      </Grid>
      <Grid item xs>
        {!nameTitle ? (
          <LinearProgress
            style={{
              marginTop: '16px',
            }}
          />
        ) : (
          <Typography variant="h4" color="primary">
            {nameTitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
