import React from 'react'

import { Tooltip } from '@material-ui/core'

import { useStyles } from './styles'

interface ICardHistory {
  itemHistory: any
}

export const CardHeaderHistory: React.FC<ICardHistory> = ({ itemHistory }) => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <div className={classes.column}>
        <h2 className={classes.cardTitle}>Data</h2>
        <p className={classes.cardText}>{itemHistory.created_at}</p>
      </div>
      <div className={classes.column}>
        <h2 className={classes.cardTitle}>Usuário</h2>
        <Tooltip title={itemHistory.usuario_alteracao}>
          <p className={classes.cardText}>{itemHistory.usuario_alteracao}</p>
        </Tooltip>
      </div>
      <div className={classes.column}>
        <h2 className={classes.cardTitle}>Cargo</h2>
        <p className={classes.cardText}>{itemHistory.tipo_usuario}</p>
      </div>
    </div>
  )
}
