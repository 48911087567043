import { ReactElement, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  ButtonGroup,
  Chip,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import { Delete, Email } from '@material-ui/icons'
import { createFilterOptions } from '@material-ui/lab'
import Autocomplete from '@mui/material/Autocomplete'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { useFilterConsultancyFarmerState } from '~/store/consultancy/filter-farmer-consultancy'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

const schema = yup.object().shape({
  usuario_id: yup.string().required('Campo obrigatório'),
})

interface UserListI {
  nome: string
  email: string
  id: number
}

type Params = {
  consultancyId: string
}

function ClientsDetailsConsultancy(): ReactElement {
  const OPTIONS_LIMIT = 80
  const classes = useStyles()
  const { Clients, Farmers = [] } = useConsultacyStore()
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    context: '',
  })
  const defaultFilterOptions = createFilterOptions()
  const filterOptions = (options: UserListI[], state: any) =>
    defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT)
  const { consultancyId } = useParams<Params>()
  const { items } = useFilterConsultancyFarmerState()
  const setFilter = useFilterConsultancyFarmerState((state) => state.setFilter)

  const [usuario, setUsuario] = useState<UserListI | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true)
  const [usuarioAtualizado, setUsuarioAtualizado] = useState(false)

  const {
    messageError,
    requestGetFarmerConsultantConsultancy,
    requestCreateFarmerConsultancy,
    requestDeleteFarmerConsultancy,
    requestResendEmail,
  } = useConsultacyStore()

  const onSubmit: SubmitHandler<any> = async () => {
    await requestCreateFarmerConsultancy(getValues('usuario_id'), consultancyId)
    await requestGetFarmerConsultantConsultancy()

    const usuarioSelecionado = Farmers.find(
      (user: { id: number }) => user.id === Number(getValues('usuario_id')),
    )
    setUsuario(usuarioSelecionado || null)
    setTimeout(() => {
      setUsuarioAtualizado(true)
    }, 0)
  }

  useEffect(() => {
    async function loadData() {
      await requestGetFarmerConsultantConsultancy()
      setIsLoading(false)
    }
    loadData()
  }, [])

  useEffect(() => {
    if (usuarioAtualizado) {
      if (
        usuario !== null &&
        !Farmers.some((user: { id: number }) => user.id === Number(usuario))
      ) {
        const usuarioEncontrado = Farmers?.find(
          (user: { id: number }) => user.id === Number(usuario),
        )
        setUsuario(usuarioEncontrado || null)
      }
      setUsuarioAtualizado(false)
    }
  }, [Farmers, usuario, usuarioAtualizado])

  const deleteRelacionamento = async (userId: string) => {
    const alertResult = await Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja remover o vínculo do Agricultor com a Consultoria?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    })

    if (alertResult.isConfirmed) {
      await requestDeleteFarmerConsultancy(userId, consultancyId)
      await requestGetFarmerConsultantConsultancy()
    }
  }

  const resendEmail = async (userId: string) => {
    const alertResult = await Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja reenviar o email de aceite para o agricultor?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    })

    if (alertResult.isConfirmed) {
      await requestResendEmail(userId, consultancyId)
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      flex: 0.3,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.nome,
    },
    {
      field: 'documento',
      headerName: 'Documento',
      width: 150,
      flex: 0.3,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.documento,
      renderCell: (params) => maskInterceptor('document')(params?.row?.documento),
    },
    {
      field: 'email',
      headerName: 'email',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.email,
    },
    {
      field: 'celular',
      headerName: 'celular',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params?.row?.celular,
      renderCell: (params) => maskInterceptor('phone')(params?.row?.celular),
    },
    {
      field: 'pivot.status',
      headerName: 'Acesso',
      flex: 0.2,
      minWidth: 110,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => (params.row?.pivot.status === '1' ? 'Confirmado' : 'Aguardando'),
      renderCell: (params) => {
        return (
          <Chip
            label={params.row?.pivot.status === '1' ? 'Confirmado' : 'Aguardando'}
            style={
              params.row?.pivot.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ffcc00', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {params.row?.pivot.status === '0' ? (
              <Button
                id="button-enviar-email"
                variant="contained"
                style={{
                  backgroundColor: '#48bbe6',
                  color: '#fff',
                  fontSize: '10px',
                }}
                onClick={() => resendEmail(params.row.id)}
              >
                <Email />
              </Button>
            ) : null}
            <Button
              id="button-delete-agricultor"
              variant="contained"
              color="secondary"
              className={classes.buttonDelete}
              onClick={() => deleteRelacionamento(params.row.id)}
            >
              <Delete />
            </Button>
          </ButtonGroup>
        )
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
  ]

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} sm={12} container>
          <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" className={classes.typography}>
              {' '}
              {strings.infoFarmer}{' '}
            </Typography>
            <Grid item>
              <Grid item container>
                {Farmers && ( // Renderiza o Autocomplete somente se Farmers não for null
                  <>
                    <Controller
                      name="usuario_id"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <Autocomplete<any>
                          {...field}
                          id={(getValues('usuario_id') || '').toString()}
                          options={Farmers}
                          value={usuario}
                          fullWidth
                          filterOptions={filterOptions}
                          sx={{ width: 425 }}
                          getOptionLabel={(option: UserListI) => (option.nome ? option.nome : '')}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_event, newValue) => {
                            setUsuario(newValue)
                            setValue('usuario_id', newValue ? newValue.id.toString() : '')
                          }}
                          renderOption={(props, option: UserListI) => (
                            <ListItem {...props} key={String(option.id)}>
                              <ListItemText
                                primary={option.nome}
                                secondary={option.email}
                                classes={{
                                  primary: classes.primary,
                                  secondary: classes.secundary,
                                }}
                              />
                            </ListItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              InputLabelProps={{
                                classes: {
                                  root: classes.root,
                                },
                              }}
                              key={String(params.id)}
                              variant="outlined"
                              label="Selecione..."
                              placeholder="Selecione..."
                              className={classes.textInput}
                              helperText={errors.usuario_id?.message || messageError?.usuario_id}
                              FormHelperTextProps={{
                                className: classes.helperText,
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  id="button-add-equipe"
                >
                  {strings.addUser}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} className={classes.resumeTable}>
          <DataGrid
            rows={Clients}
            columns={columns}
            rowsPerPageOptions={[25, 50, 100]}
            autoPageSize
            autoHeight
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
            rowHeight={50}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ClientsDetailsConsultancy
