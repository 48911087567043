const strings = {
  textModal: {
    ModalTitle: 'Cadastrar Microrregião',
    ModalTitleEdit: 'Editar Microrregião',
  },
  formFields: {
    Microrregiao: 'Nome Microrregião',
    Status: 'Status',
    UF: 'UF',
    Cidade: 'Cidade(s)',
  },
}

export default strings
