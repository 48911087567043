import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Box, Grid, Paper, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'

import HeadersDetails from '~/shared/headers-details/headers-details'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'

import ConsultancyClient from './components/consultancy-client'
import ConsultancyData from './components/consultancy-data'
import UserConsultancy from './components/user-consultancy'
import strings from './string'
import { useStyles } from './style'

type Params = {
  consultancyId: string
}

const ConsultancyDetails: React.FC = () => {
  const classes = useStyles()
  const { consultancyId } = useParams<Params>()
  const getConsultacyById = useConsultacyStore((state) => state.consultancyById)
  const getConsultacyWithClient = useConsultacyStore((state) => state.clientConsultancy)
  const getConsultacyWithUser = useConsultacyStore((state) => state.userConsultancy)
  const [valueTabs, setValueTabs] = useState<string>('1')

  const { Consultacy } = useConsultacyStore()
  const history = useHistory()

  const onBack = () => {
    history.goBack()
  }

  useEffect(() => {
    getConsultacyById(consultancyId)
    getConsultacyWithClient(consultancyId)
    getConsultacyWithUser(consultancyId)
  }, [])

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: string) => {
    setValueTabs(newValue)
  }

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <HeadersDetails nameTitle={Consultacy?.nome} onClickBack={onBack} />

        <Grid container item xs={12} spacing={0} className={classes.bodyInformation}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ConsultancyData />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ width: '100%', fontFamily: 'body1' }}>
          <TabContext value={valueTabs}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                style={{
                  marginLeft: '16px',
                  marginRight: '16px',
                  border: 'solid 1px rgba(0, 0, 0, 0.12)',
                  borderEndStartRadius: '0px',
                  borderEndEndRadius: '0px',
                  borderBottom: 'none',
                }}
              >
                <Tab className={classes.tabs} label={strings.tabsLabel.tabA} value="1" />
                <Tab className={classes.tabs} label={strings.tabsLabel.tabB} value="2" />
              </TabList>
            </Box>
            <TabPanel style={{ padding: '0px 16px' }} value="1">
              <Grid item xs={12}>
                <UserConsultancy />
              </Grid>
            </TabPanel>
            <TabPanel style={{ padding: '0px 16px' }} value="2">
              <Grid item xs={12}>
                <ConsultancyClient />
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </Grid>
  )
}

export default ConsultancyDetails
