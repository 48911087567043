import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'

type EnlargeCropsStates = {
  controllerModalState: boolean
  errorsUsers: boolean
  messageError: any
  extesionAreaList: []
  statesBrazil: []
  rules: any
  stateWithBrazilDescription: []
  rulesExtensionData: any
  isLoading: boolean
}

type EnlargeCropsActions = {
  modalController: (value: boolean) => void
  getEnlargeRegionById: (rulesId: number) => Promise<void>
  rulesExtension: () => Promise<void>
  deleteRulesExtension: (rulesId: number) => Promise<void>
  listStatesBrazil: () => Promise<void>
  listStatesWithBrazil: () => Promise<void>
  createRulesExtension: (rule: any) => Promise<void>
  setRulesExtension: (rules: any) => Promise<void>
  updateRulesExtension: (rules: any) => Promise<void>
  clearForms: () => void
}

type State = EnlargeCropsStates & EnlargeCropsActions

const initialState: EnlargeCropsStates = {
  controllerModalState: false,
  isLoading: false,
  messageError: '',
  extesionAreaList: [],
  statesBrazil: [],
  stateWithBrazilDescription: [],
  rulesExtensionData: null,
  errorsUsers: false,
  rules: null,
}

export const useEnlargeRegion = create<State>((set, get) => ({
  ...initialState,
  modalController: (valueState) => {
    set({
      controllerModalState: valueState,
    })
  },
  clearForms: () => {
    set({ messageError: '', errorsUsers: false, rules: null })
  },
  getEnlargeRegionById: async (rulesID) => {
    const { data } = await api.get(`/limit/${rulesID}`)
    set({ rules: data })
  },
  rulesExtension: async () => {
    try {
      const { data } = await api.get('/limit')
      set({
        extesionAreaList: data,
      })
    } catch (error: any) {
      set({
        messageError: error?.response?.data?.message,
      })
    }
  },
  createRulesExtension: async (rule) => {
    const { modalController, rulesExtension } = get()
    await api
      .post('/limit', rule)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Cadastrado com sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalController(false)
        })
        rulesExtension()
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errorsUsers: true,
        })
        Swal.fire({
          icon: 'error',
          title: 'Erro Ao Cadastrar Regra!',
          heightAuto: false,
          text: err.response?.data?.errors.origem_id,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      })
  },
  updateRulesExtension: async (rule) => {
    const { modalController, rulesExtension } = get()
    await api
      .put('/limit', rule)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Atualizada com sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalController(false)
        })
        rulesExtension()
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errorsUsers: true,
        })
        Swal.fire({
          icon: 'error',
          title: 'Erro Ao Atualizar Regra!',
          text: err.response?.data?.errors.origem_id,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      })
  },
  deleteRulesExtension: async (rulesId) => {
    const { rulesExtension } = get()
    try {
      api
        .delete(`/limit/${rulesId}`)
        .then((resp) => {
          Swal.fire({
            icon: 'success',
            title: 'Removido com sucesso!',
            text: resp.data.message,
            confirmButtonColor: '#2b78c0',
            customClass: {
              container: 'alert-container',
            },
          })
          rulesExtension()
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Erro Ao Remover Regra!',
            text: err.message,
            confirmButtonColor: '#2b78c0',
            customClass: {
              container: 'alert-container',
            },
          })
        })
    } catch (error: any) {
      set({
        messageError: error?.response?.data?.message,
      })
    }
  },
  listStatesWithBrazil: async () => {
    try {
      const { data } = await api.get('/limit/estados/brasil')
      set({
        stateWithBrazilDescription: data,
      })
    } catch (error: any) {
      set({
        messageError: error?.response?.data?.message,
      })
    }
  },
  setRulesExtension: async (rules) => {
    set({
      rulesExtensionData: rules,
    })
  },
  listStatesBrazil: async () => {
    try {
      const { data } = await api.get('/limit/estados')
      set({
        statesBrazil: data,
      })
    } catch (error: any) {
      set({
        messageError: error?.response?.data?.message,
      })
    }
  },
}))
