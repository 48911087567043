const strings = {
  textModal: {
    ModalTitle: 'Cadastrar Safra',
    ModalTitleEdit: 'Editar Safra',
  },
  formFields: {
    Safra: 'Safra',
    Status: 'Status',
  },
}
export default strings
