import React, { useEffect, useState, useMemo } from 'react'

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import _ from 'lodash'

import { userAcessCtv } from '~/store/acesso-ctv-store'
import { ListRequestCtv, CityProps } from '~/types/state-types'

import strings from './strings'
import { useStyles } from './styles'

interface CitiesProps {
  dataCities?: CityProps[]
}

export const TableCtv: React.FC<CitiesProps> = ({ dataCities }) => {
  const classes = useStyles()

  const cities = useMemo(() => {
    return dataCities?.length ? dataCities : []
  }, [dataCities])

  const [listCtv, setListCtv] = useState<ListRequestCtv[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingCtv, setIsLoadingCtv] = useState(true)
  const [selectedCtvs, setSelectedCtvs] = useState<{ [cityId: string]: any }>({})

  const { getCtv, updateCtvByCity } = userAcessCtv()

  useEffect(() => {
    const listCtv = async () => {
      setIsLoadingCtv(true)
      const ctvs = await getCtv()
      setListCtv(ctvs)
      setIsLoadingCtv(false)
    }
    listCtv()
  }, [])

  useEffect(() => {
    const newSelectedCtvs = { ...selectedCtvs }

    cities.forEach((item) => {
      const initialCtv = listCtv.find((option) => option.nome === item?.ctv?.nome) || null
      newSelectedCtvs[item.id] = initialCtv
    })

    setSelectedCtvs(newSelectedCtvs)
  }, [listCtv, cities])

  return (
    <TableContainer component={Paper}>
      <Table id="table-ctv">
        <TableHead id="table-ctv-head">
          <TableRow>
            <TableCell align="left" width={100}>
              {strings.tableHeader.counties}
            </TableCell>
            <TableCell align="left" width={100}>
              {strings.tableHeader.initials}
            </TableCell>
            <TableCell align="left">{strings.tableHeader.responsibleCTV}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cities.map((item) => (
            <React.Fragment key={item.id}>
              <TableRow id={`cities-tr-${item.descricao}`}>
                <TableCell align="left" id={`cities-tr-describe-${item.descricao}`}>
                  {item.descricao}
                </TableCell>
                <TableCell align="left" id={`cities-tr-initials-${item.descricao}`}>
                  {item.sigla}
                </TableCell>
                <TableCell align="left" width={80} id={`cities-tr-ctvs-${item.id}`}>
                  <Autocomplete<any>
                    id="ctv_field"
                    getOptionLabel={(option) => option?.nome}
                    options={listCtv}
                    value={selectedCtvs[item.id] || null}
                    onChange={(e, newValue) => {
                      setSelectedCtvs({ ...selectedCtvs, [item.id]: newValue })

                      if (item?.ctv?.nome !== newValue?.nome && !_.isEmpty(newValue?.nome)) {
                        updateCtvByCity(item?.id, newValue?.id)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Selecione um CTV"
                        placeholder="Selecione um CTV"
                        className={classes.textInput}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
