import { useEffect, useState } from 'react'

import { useMicroregionStore } from '~/store/microregion/microregion-store'
import { ClientList } from '~/types/client-types'
import { VorietyListProps } from '~/types/grow-crops-types'
import { Page, SourcePage } from '~/types/pagination-types'
import { UserList } from '~/types/user-types/user-types'
import { IValidation } from '~/types/validation-types'

export function convertToPage(data: SourcePage<ClientList>): Page<ClientList> {
  return {
    totalRowCount: data.total,
    pageSize: data.per_page,
    currentPage: data.current_page,
    data: data.data,
    next_page_url: data.next_page_url,
    prev_page_url: data.prev_page_url,
  }
}

export function convertToPageUsers(data: SourcePage<UserList>): Page<UserList> {
  return {
    totalRowCount: data.total,
    pageSize: data.per_page,
    currentPage: data.current_page,
    data: data.data,
    next_page_url: data.next_page_url,
    prev_page_url: data.prev_page_url,
  }
}

export function convertToPageValidation(data: SourcePage<IValidation>): Page<IValidation> {
  return {
    totalRowCount: data.total,
    pageSize: data.per_page,
    currentPage: data.current_page,
    data: data.data,
    next_page_url: data.next_page_url,
    prev_page_url: data.prev_page_url,
  }
}

export function convertToPageGrowcrops(data: SourcePage<VorietyListProps>): Page<VorietyListProps> {
  return {
    totalRowCount: data.total,
    pageSize: data.per_page,
    currentPage: data.current_page,
    data: data.data,
    next_page_url: data.next_page_url,
    prev_page_url: data.prev_page_url,
  }
}

export function useDataStateAndCities(regionList: any) {
  const { statesBrazil, listCitiesByStateId, listStatesBrazil } = useMicroregionStore()
  const [dataList, setDataList] = useState<any[]>([])

  useEffect(() => {
    listStatesBrazil()
  }, [listStatesBrazil])

  useEffect(() => {
    const getData = async () => {
      const result = await Promise.all(
        regionList.map(async (item: any) => {
          const state: any = statesBrazil.find(({ sigla }) => sigla === item.uf)

          const citiesArray = state ? await listCitiesByStateId(state?.id) : []
          const cityList = citiesArray?.cidades || []
          const city: any = cityList.find(
            (cidade: { id: any }) => String(cidade.id) === String(item.cidade_id),
          )

          return {
            ...item,
            state,
            city,
          }
        }),
      )

      setDataList(result)
    }

    if (statesBrazil.length > 0) {
      getData()
    }
  }, [regionList, statesBrazil, listCitiesByStateId])

  return dataList
}

export function handleMaturationValue(value: string): string {
  value = value.replace(/[^0-9.]/g, '')

  if (value === '') return ''

  const parts = value.split('.')
  if (parts[0].length > 1) parts[0] = parts[0].substring(0, 1)
  if (parts[1] && parts[1].length > 1) parts[1] = parts[1].substring(0, 1)
  value = parts.join('.')

  const numValue = Number(value)
  if (numValue < 1) return '1'
  if (numValue > 10) return '10'

  return value
}

export function formatNumberQtyTotal(value: string): string {
  value = value.replace(/\./g, '')

  const lastCommaIndex = value.lastIndexOf(',')
  if (lastCommaIndex !== -1) {
    value = `${value.substring(0, lastCommaIndex)}.${value.substring(lastCommaIndex + 1)}`
  }

  value = parseFloat(value).toString()

  return value
}
