const strings = {
  title: 'Campos de Controle',
  document: 'CPF/CNPJ',
  email: 'Email',
  celular: 'Celular',
  situacao: 'Situacao',
  titleModal: 'Novo contato',
}

export default strings
