const strings = {
  document: 'CPF/CNPJ',
  email: 'E-mail',
  phone: 'Celular',
  situation: 'Situação',
  cargo: 'Cargo',
  adminInfo: 'Perfil Administrativo',
  agpro: 'agPRO',
  farmerInfoTitle: 'Dados do agricultor',
  deleteAllButton: 'Remover todos vinculos',
}

export default strings
