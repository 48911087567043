const strings = {
  document: 'documento',
  endereco: 'Endereço',
  rua: 'Rua',
  numero: 'Número',
  bairro: 'Bairro',
  complemento: 'Complemento',
  telefone: 'Telefone',
  fantasia: 'Nome fantasia',
  cidade: 'Cidade',
  uf: 'UF da Associação',
  email: 'E-mail',
  phone: 'Celular',
  situation: 'Situação',
  visualizacao: 'Visualização',
  userTitle: 'Dados Associação',
  estado: 'Estado',
}

export default strings
