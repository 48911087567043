import _ from 'lodash'

import { PropsFormCreateUser, RequestDataUser } from '~/types/user-types/user-types'

export function userCreateMapper(requestType: PropsFormCreateUser) {
  return {
    ...requestType,
    pais_celular:
      requestType.celular.replace('595', '').replace('+', '').replace(/\s/g, '').length === 9
        ? '595'
        : '55',
    cpf: !_.isEmpty(requestType?.cpf) ? requestType?.cpf.replace(/[^0-9]+/g, '') : null,
    celular:
      requestType.celular.length === 11
        ? requestType.celular
            .replace(/\s/g, '')
            .replace('(', '')
            .replace(')', '')
            .replace('+', '')
            .replace('55', '')
        : requestType.celular
            .replace(/\s/g, '')
            .replace('(', '')
            .replace(')', '')
            .replace('+', '')
            .replace('55', '')
            .replace('595', ''),
  } as RequestDataUser
}
