/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'

import { Grid, Paper, Typography, Button, Chip, ButtonGroup } from '@material-ui/core'
import { GridColDef, DataGrid } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Swal from 'sweetalert2'

import Copyright from '~/components/copyright'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { CultureCodes } from '~/enums/enum'
import { useFilterAuditState } from '~/store/audit/filter-audit'
import { useEnlargeRegion } from '~/store/enlarge-region-store'

import { EnlargeRegionModal } from './components/enlarge-modal'
import strings from './strings'
import { useStyles } from './styles'

export const EnlargeRegion: React.FC = () => {
  const { items } = useFilterAuditState()
  const setFilter = useFilterAuditState((state) => state.setFilter)
  const [destinoId, setDestinoId] = useState<any>(undefined)

  const states = useEnlargeRegion((state) => state.listStatesBrazil)
  const statesWithBrasil = useEnlargeRegion((state) => state.listStatesWithBrazil)

  const {
    modalController,
    extesionAreaList,
    rulesExtension,
    deleteRulesExtension,
    getEnlargeRegionById,
  } = useEnlargeRegion()

  const deleteRule = async (ruleId: number) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja remover o vínculo do limite da região?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      cancelButtonColor: '#ff7d89',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteRulesExtension(ruleId)
      } else {
        Swal.close()
      }
    })
  }

  const classes = useStyles()
  const columns: GridColDef[] = [
    {
      field: 'cultura_id',
      headerName: 'Cultura',
      flex: 0.1,
      width: 110,
      minWidth: 100,
      renderCell: (params) => CultureCodes[params.row?.cultura_id],
    },
    {
      field: 'home_state',
      headerName: 'Origem Agricultor',
      width: 200,
      renderCell: (params) => (
        <ul>
          <li>{params.row?.home_state.descricao}</li>
        </ul>
      ),
      type: 'string',
    },
    {
      field: 'destination_state',
      headerName: 'Limite Entorno',
      width: 100,
      flex: 0.1,
      renderCell: (params) =>
        params.row?.tipo_vinculo === '1' ? (
          <span>Brasil</span>
        ) : (
          <ul>
            <li>{params.row?.destination_state.sigla}</li>
          </ul>
        ),
      type: 'string',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      minWidth: 110,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Chip
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <ButtonGroup disableElevation variant="contained">
            <Button
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={async () => {
                openModal()
                setDestinoId(Number(params?.row?.destino_id))
                await getEnlargeRegionById(params?.row.id)
              }}
            >
              <EditIcon />
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#ff5c6c',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={() => deleteRule(params.row?.id)}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        )
      },
    },
  ]

  useEffect(() => {
    rulesExtension()
    async function listStates() {
      await states()
      await statesWithBrasil()
    }
    listStates()
  }, [])

  const openModal = () => {
    modalController(true)
    setDestinoId(undefined)
  }

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12} className={classes.headerContainer}>
          <Typography variant="h4" color="primary">
            {strings.growCropsPage.title}
          </Typography>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '14px',
            }}
            onClick={openModal}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>
        <Grid container item xs={12} className={classes.resumeAuditTable}>
          <DataGrid
            rows={extesionAreaList}
            columns={columns}
            disableSelectionOnClick
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Paper>
      <Copyright />
      <EnlargeRegionModal destinoId={destinoId} />
    </Grid>
  )
}
