import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { Close, Save } from '@material-ui/icons'
import clsx from 'clsx'
import _ from 'lodash'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import * as yup from 'yup'

import { DefaultInput, SituationConsultant, Status } from '~/constants/data-field'
import { useAlert } from '~/hooks/message'
import { consultancyDataCreateMapper } from '~/mappers/consultance-mapper'
import { useCepStore } from '~/store/cep-store'
import { useCityStore } from '~/store/city-store'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { IConsultancy } from '~/types/consultancy-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import { validationSchema } from './schemaValidation'
import strings from './strings'
import { useStyles } from './style'

import 'react-phone-input-2/lib/material.css'

interface Props {
  open: boolean
  onClose: () => void
  save?: boolean
  title?: string
  disableCloseClickOut?: boolean
  isLoad?: boolean
  scroll?: boolean
}

const defaultValues = {
  nome: '',
  documento: '',
  email: '',
  celular: '',
  cep: '',
  rua: '',
  numero: '',
  complemento: '',
  bairro: '',
  status: '',
  created_at: '',
  updated_at: '',
  cidade_id: '',
  estado_id: '',
  pais_id: '',
  fantasia: '',
  situacao: '',
  city: '',
}

export const AddConsultantModal: React.FC<Props> = ({
  open,
  onClose,
  save,
  isLoad = false,
  scroll,
}: Props) => {
  const { clearFormsCreateUserConsultancy, statesBrazil, modalControllerChangeCreate } =
    useConsultacyStore()

  const createConsultancy = useConsultacyStore((state) => state.createConsultancy)

  const classes = useStyles()
  const alert = useAlert()

  const { find: findCepStore, isLoading: isLoadingCep } = useCepStore()
  const { listCitiesByStateId } = useCityStore()
  const [cities, setCities] = useState<any[]>([])

  const findCep = async (cep: string) => {
    const viacep = await findCepStore(cep.replace('-', ''))

    clearFormsCreateUserConsultancy()
    if (viacep.erro) {
      alert.show({ message: 'CEP não encontrado!', type: 'error' })
      return
    }
    setValue('bairro', viacep.bairro, { shouldDirty: true })
    setValue('rua', viacep.logradouro, { shouldDirty: true })
    setValue('complemento', viacep.complemento, { shouldDirty: true })

    const state: any = statesBrazil.find(({ sigla }) => sigla === viacep.uf)

    if (state) {
      setValue('estado_id', state?.id ?? '', { shouldDirty: true })
      listCitiesByStateId(state?.id).then((data) => {
        setCities(data.cidades)
        setValue(
          'cidade_id',
          data?.cidades?.find(
            (value: any) =>
              value.descricao === viacep.localidade.normalize('NFD').replace(/\p{Mn}/gu, ''),
          )?.id ?? '',
          { shouldDirty: true },
        )
      })
    }
  }

  const schema = yup.object(validationSchema)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IConsultancy>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit: SubmitHandler<IConsultancy> = async (consultancyData) => {
    const consultancyMapper = consultancyDataCreateMapper(consultancyData)
    await createConsultancy(consultancyMapper)
    modalControllerChangeCreate(false)
    reset()
    onClose()
  }

  return (
    <Modal
      aria-labelledby="TITULO"
      aria-describedby="descricao"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper
          elevation={8}
          className={`${classes.paper}`}
          style={scroll ? { overflow: 'scroll' } : { overflow: 'clip' }}
        >
          <Grid container spacing={2} className={classes.gridMain}>
            <Grid item sm={12} className={classes.gridHeader}>
              <Typography variant="h5" component="h5">
                {strings.header.title}
              </Typography>
            </Grid>
            <Divider />
            <Grid item sm={12} className={classes.containerFormsModal}>
              <form noValidate className={classes.form}>
                <Grid container spacing={2} className={classes.gridContainerForms}>
                  <Grid item sm={6}>
                    <Controller
                      name="nome"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="nome"
                          variant="outlined"
                          label="Nome Consultoria"
                          placeholder="Nome da Consultoria"
                          className={clsx(classes.textInput)}
                          error={!!errors?.nome}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors.nome?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="fantasia"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="fantasia"
                          variant="outlined"
                          label="Nome fantasia"
                          placeholder="Nome fantasia"
                          className={classes.textInput}
                          error={!!errors?.fantasia}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors.fantasia?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="documento"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="documento"
                          variant="outlined"
                          label="CPF/CNPJ"
                          placeholder="CPF/CNPJ"
                          className={classes.textInput}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          error={(!!errors?.documento || !_.isEmpty(errors?.documento)) && true}
                          helperText={errors?.documento?.message ?? errors?.documento}
                          value={maskInterceptor('document')(field.value)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="email"
                          variant="outlined"
                          label="E-mail"
                          placeholder="E-mail"
                          className={classes.textInput}
                          error={(!!errors?.email || !_.isEmpty(errors?.email)) && true}
                          helperText={errors?.email?.message ?? errors?.email}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="celular"
                      control={control}
                      render={({ field }) => (
                        <MuiPhoneNumber
                          {...field}
                          required
                          id="celular"
                          label="celular"
                          defaultCountry="br"
                          masks={{ py: '+... ... ......' }}
                          variant="outlined"
                          onlyCountries={['br', 'py']}
                          error={(!!errors?.celular || !_.isEmpty(errors?.celular)) && true}
                          helperText={errors?.celular?.message ?? errors?.celular}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          InputProps={{
                            style: {
                              borderRadius: 30,
                              width: '100%',
                              color: '#00446B',
                            },
                          }}
                          className={clsx(classes.textInput)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="cep"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, ...field } }) => (
                        <TextField
                          {...field}
                          required
                          id="cep"
                          variant="outlined"
                          label="CEP"
                          placeholder="CEP"
                          className={classes.textInput}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          error={(!!errors?.cep || !_.isEmpty(errors?.cep)) && true}
                          helperText={errors?.cep?.message ?? errors?.cep}
                          value={maskInterceptor('zipCode')(field.value)}
                          InputProps={{
                            endAdornment: <>{isLoadingCep ? <CircularProgress size={15} /> : ''}</>,
                          }}
                          onChange={(value) => {
                            if (value.target.value.length === 9) findCep(value.target.value)
                            return onChange(value)
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="estado_id"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          select
                          id="estado"
                          variant="outlined"
                          label="Estado"
                          placeholder="Estado"
                          onChange={async (event) => {
                            setValue('estado_id', event.target.value)
                            const citiesData = await listCitiesByStateId(Number(event.target.value))
                            setCities(citiesData.cidades)
                          }}
                          className={classes.textInput}
                          error={(!!errors?.estado_id || !_.isEmpty(errors?.estado_id)) && true}
                          helperText={errors?.estado_id?.message ?? errors?.estado_id}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                        >
                          {statesBrazil.map((Option: any) => (
                            <MenuItem key={Option.id} value={Option.id}>
                              {Option.descricao}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="cidade_id"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          select
                          id="cidade_id"
                          variant="outlined"
                          label="Cidade"
                          placeholder="Cidade"
                          error={(!!errors?.cidade_id || !_.isEmpty(errors?.cidade_id)) && true}
                          helperText={errors?.cidade_id?.message ?? errors?.cidade_id}
                          className={classes.textInput}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                        >
                          {cities.map((Option: any) => (
                            <MenuItem key={Option.id} value={Option.id}>
                              {Option.descricao}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="rua"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="rua"
                          variant="outlined"
                          label="Rua"
                          className={classes.textInput}
                          error={(!!errors?.rua || !_.isEmpty(errors?.rua)) && true}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors?.rua?.message ?? errors?.rua}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="bairro"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          id="bairro"
                          variant="outlined"
                          label="Bairro"
                          className={classes.textInput}
                          error={(!!errors?.bairro || !_.isEmpty(errors?.bairro)) && true}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors?.bairro?.message ?? errors?.bairro}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="complemento"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="complemento"
                          variant="outlined"
                          label="Complemento"
                          className={classes.textInput}
                          error={(!!errors?.complemento || !_.isEmpty(errors?.complemento)) && true}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors.complemento?.message ?? errors?.complemento}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="numero"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="numero"
                          required
                          variant="outlined"
                          label="Número"
                          className={classes.textInput}
                          error={(!!errors?.numero || !_.isEmpty(errors?.numero)) && true}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors.numero?.message ?? errors?.numero}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={DefaultInput.statusDefault}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          required
                          id="status"
                          variant="outlined"
                          label="Status"
                          placeholder="Status"
                          className={classes.textInput}
                          error={(!!errors?.status || !_.isEmpty(errors?.status)) && true}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors?.status?.message ?? errors?.status}
                        >
                          {Status.map((Option) => (
                            <MenuItem key={Option.statusValue} value={Option.statusValue}>
                              {Option.statusName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="situacao"
                      control={control}
                      defaultValue={DefaultInput.SituationConsultantDefault}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          required
                          id="situacao"
                          variant="outlined"
                          label="Situacao"
                          placeholder="Situacao"
                          className={classes.textInput}
                          error={(!!errors?.situacao || !_.isEmpty(errors?.situacao)) && true}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                          helperText={errors?.situacao?.message ?? errors?.situacao}
                        >
                          {SituationConsultant.map((Option) => (
                            <MenuItem key={Option.value} value={Option.value}>
                              {Option.description}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid container item sm={12} spacing={2} className={classes.gridFooter}>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  id="button-fechar-modal"
                  color="secondary"
                  startIcon={<Close />}
                  onClick={onClose}
                >
                  FECHAR
                </Button>
              </Grid>
              {save && (
                <Grid item sm={6} className={classes.saveButton}>
                  {isLoad ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      id="button-save-modal"
                      variant="contained"
                      color="primary"
                      endIcon={<Save />}
                      onClick={handleSubmit(onSubmit)}
                      type="submit"
                    >
                      Salvar
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default AddConsultantModal
