import { parseInt } from 'lodash'

import { IConsultancy } from '~/types/consultancy-types'

export function consultancyDataCreateMapper(requestType: IConsultancy) {
  return {
    name: requestType.nome,
    phone: requestType.celular
      .replace(/\s/g, '')
      .replace('(', '')
      .replace(')', '')
      .replace('+', '')
      .replace('55', ''),
    email: requestType.email,
    trade_name: requestType.fantasia,
    document: requestType.documento,
    neighborhood: requestType.bairro,
    zip_code: requestType.cep.replace('-', ''),
    city_id: parseInt(requestType.cidade_id),
    street: requestType.rua,
    country_id: requestType.celular.length > 9 ? 1 : 168,
    situation: parseInt(requestType.situacao),
    status: parseInt(requestType.status),
    complement: requestType.complemento,
    number: requestType.numero,
  }
}
