import create from 'zustand'
import { persist } from 'zustand/middleware'

import { api } from '~/services/api'

type LoggedUser = {
  id: number
  nome: string
  cpf: string
  username?: string
  email: string
  celular: string
  status: string
  created_at: Date
  updated_at: Date
  cargo_id: string
  perfil_admin: string
  plantupper: string
  consultor: string
  perfil_id: string
  pais_celular?: number
  fcm_token?: string
}

type AuthState = {
  token: string
  isLoading: boolean
  messageError: string
  expiresIn: Date
  loggedUser?: LoggedUser
}

type AuthActions = {
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  clearError: () => void
  clearUserAuth: () => void
}

type State = AuthState & AuthActions

const initialState: AuthState = {
  token: '',
  isLoading: false,
  messageError: '',
  expiresIn: new Date(Date.now() - 86400 * 1000),
  loggedUser: {} as LoggedUser,
}

export const useTokenStore = create<State>(
  persist(
    (set, get) => ({
      ...initialState,
      login: async (email: string, password: string) => {
        set({ isLoading: true, messageError: '', token: '' })
        try {
          const { data } = await api.post('/auth/login', { email, password })

          api.defaults.headers.Authorization = `Bearer ${data.access_token}`

          const { data: userLogged } = await api.get('/auth/user')

          set({
            token: data.access_token,
            isLoading: false,
            expiresIn: new Date(Date.now() + data.expires_in * 1000),
            loggedUser: userLogged,
          })
        } catch (error: any) {
          set({
            isLoading: false,
            messageError: error?.response?.data?.message,
          })
        }
      },
      logout: () => {
        const { clearUserAuth } = get()
        api.defaults.headers.Authorization = ''
        api.post('/auth/logout')
        set({
          token: '',
          expiresIn: new Date(Date.now() - 86400 * 1000),
          isLoading: false,
        })
        clearUserAuth()
      },
      clearError: () => {
        set({ messageError: '' })
      },
      clearUserAuth: () => {
        set({
          expiresIn: new Date(Date.now() - 86400 * 1000),
          isLoading: false,
          loggedUser: undefined,
          messageError: '',
          token: '',
        })
      },
    }),
    {
      name: 'auth',
    },
  ),
)
