import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    minHeight: 690,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: '16px',
  },
  resumeAuditTable: {
    height: '76vh',
  },
  containerFormsModal: {
    width: '100%',
  },

  textInput: {
    width: '100%',
    color: '#00446B',
    borderRadius: '30px',
  },
  tableCellStye: {
    borderBottom: 'none',
  },
  modalStyle: {
    maxWidth: 800,
    padding: 30,
  },
  helperText: {
    color: theme.palette.error.main,
  },
  arrowPosition: {
    marginTop: 50,
    fontSize: 25,
  },
  titlePosition: {
    marginBottom: 10,
  },
  formStyle: {
    width: '100%',
  },
}))
