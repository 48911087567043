import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import _ from 'lodash'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import Modal from '~/components/modal'
import { funcoes } from '~/constants/data-field'
import { submitRefMapper } from '~/mappers/validation-mapper'
import { useValidationStore } from '~/store/validation/validation-store'
import { IModalRefs, IRefSubmit } from '~/types/validation-types'

import { ValidationSchema } from './schemaValidation'
import { useStyles } from './styles'

import 'react-phone-input-2/lib/material.css'

const REFERENCIA_EXTERNA = 'Referência Externa'

const schema = yup.object(ValidationSchema)

const ModalRefs: React.ForwardRefRenderFunction<IModalRefs> = (props, ref) => {
  const { messageError, isLoading, client, createRefs } = useValidationStore()
  const classes = useStyles()
  const [modalRefState, setModalRefState] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IRefSubmit>({
    resolver: yupResolver(schema),
  })

  const handleCreateInteraction: SubmitHandler<IRefSubmit> = async (data) => {
    createRefs(submitRefMapper(data, client?.cliente_id))
  }

  useImperativeHandle(ref, () => {
    return {
      handleActions() {
        reset()
        setModalRefState(true)
      },
    }
  })

  const closeModal = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja cancelar a inserção?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      cancelButtonColor: '#ff7d89',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setModalRefState(false)
      }
    })
  }

  return (
    <Modal
      onClose={closeModal}
      open={modalRefState}
      isLoad={isLoading}
      title="Nova Referência"
      onSave={handleSubmit(handleCreateInteraction)}
      save
    >
      <Grid container spacing={3} className={classes.modalStyle}>
        <Grid item sm={6}>
          <Controller
            name="funcao"
            control={control}
            defaultValue={REFERENCIA_EXTERNA}
            render={({ field }) => (
              <FormControl variant="outlined" className={classes.selectInput}>
                <InputLabel id="funcao">Função</InputLabel>
                <Select
                  labelId="funcao"
                  id="funcao"
                  label="funcao"
                  disabled
                  autoWidth
                  error={(!!errors?.funcao || !_.isEmpty(messageError?.funcao)) && true}
                  {...field}
                >
                  {funcoes.map((Option) => (
                    <MenuItem key={Option.value} value={Option.label}>
                      {Option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.funcao?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.funcao?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                id="nome"
                variant="outlined"
                label="Nome Completo"
                defaultValue=""
                placeholder="Nome Completo"
                className={classes.selectInput}
                error={!!errors?.nome}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.nome?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="empresa"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                id="empresa"
                variant="outlined"
                label="Empresa"
                defaultValue=""
                placeholder="Empresa"
                className={classes.selectInput}
                error={!!errors?.empresa}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.empresa?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="telefone"
            control={control}
            render={({ field }) => (
              <MuiPhoneNumber
                {...field}
                required
                id="telefone"
                label="Telefone"
                defaultCountry="br"
                masks={{ py: '+... ... ......' }}
                variant="outlined"
                onlyCountries={['br']}
                error={(!!errors?.telefone || !_.isEmpty(messageError?.telefone)) && true}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.telefone?.message ?? messageError?.telefone}
                InputProps={{
                  style: {
                    borderRadius: 30,
                    width: '100%',
                    color: '#00446B',
                  },
                }}
                className={classes.textInput}
              />
            )}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

export default forwardRef(ModalRefs)
