import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  resumeAuditTable: {
    flex: 1,
    display: 'flex',
    height: '80vh',
  },
  containerFormsModal: {
    width: '100%',
  },
  textInput: {
    color: '#00446B',
    borderRadius: '30px',
  },
  selectInput: {
    width: '100%',
  },
  modalStyle: {
    padding: '1rem',
    maxWidth: 800,
  },
  formStyle: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  iconButton: {
    padding: 10,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))
