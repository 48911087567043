const strings = {
  growCropsPage: {
    title: 'Cultivares',
  },
  buttonTitle: {
    title: 'Adicionar Cultivar',
  },
  textModal: {
    addModalTitle: 'Cadastro de cultivar',
    editModalTitle: 'Editar de cultivar',
  },
  modaltabs: {
    addModalTitle: 'Cadastro de cultivar',
    editModalTitle: 'Editar de cultivar',
  },
  dataGridHeader: {
    name: 'Nome',
    culture: 'Cultura',
    maturationGroup: 'Grupo de Maturação',
    initialsCountry: 'Sigla País',
    genetics: 'Genetica',
    tecnology: 'Tecnologia',
    status: 'Status',
    actions: 'Ações',
  },
}

export default strings
