import React, { useLayoutEffect, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Grid, Paper, Typography, Button, Chip } from '@material-ui/core'
import { GridColDef, DataGrid, GridColumnHeaderParams } from '@material-ui/data-grid'
import { VerifiedUser } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'

import Copyright from '~/components/copyright'
import SearchDataGrid from '~/components/search-data-grid'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useAssociationStore } from '~/store/association/association-store'
import { useFilterAssociationState } from '~/store/association/filter-association'
import { FormsHandleEdit, FormsHandleCreate } from '~/types/association-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import CreateAssociation from './components/Modals/create'
import EditAssociation from './components/Modals/edit'
import strings from './strings'
import { useStyles } from './style'

const Association: React.FC = () => {
  const { associations, requestAssociation } = useAssociationStore()

  const associationsList = useAssociationStore((state) => state.requestAssociations)

  const AssociationCreateRef = useRef<FormsHandleCreate>(null)

  const OpenModalCreateUser = () => {
    AssociationCreateRef.current?.openModalCreateForms()
  }

  const states = useAssociationStore((state) => state.listStatesBrazil)

  useEffect(() => {
    async function listStates() {
      await states()
    }
    listStates()
  }, [])
  useLayoutEffect(() => {
    associationsList()
  }, [associationsList])

  const classes = useStyles()
  const { items } = useFilterAssociationState()
  const setFilter = useFilterAssociationState((state) => state.setFilter)

  const editAssociationRef = useRef<FormsHandleEdit>(null)

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      align: 'left',
      headerAlign: 'center',
      flex: 0.5,
      width: 150,
      minWidth: 120,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'documento',
      headerName: 'Documento',
      width: 160,
      flex: 0.5,
      align: 'left',
      headerAlign: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      renderCell: (params) => maskInterceptor('document')(params.row?.documento),
    },
    {
      field: 'telefone',
      headerName: 'Celular',
      width: 120,
      align: 'left',
      headerAlign: 'center',
      flex: 0.5,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => params.row?.role?.telefone,
      renderCell: (params) => maskInterceptor('phone')(params.row?.telefone),
    },
    {
      field: 'email',
      headerName: 'E-mail',
      align: 'left',
      headerAlign: 'center',
      flex: 0.7,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'estado',
      headerName: 'UF',
      align: 'left',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params) => params.row?.estado.sigla,
      valueGetter: (params) => params.row?.estado?.sigla,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'left',
      width: 140,
      flex: 0.5,
      headerAlign: 'center',
      minWidth: 140,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) => (params.row?.status === '1' ? 'Ativo' : 'Inativo'),
      renderCell: (params) => {
        return (
          <Chip
            id="chips-status"
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? {
                    backgroundColor: '#24d2b5',
                    color: '#fff',
                    fontWeight: '500',
                  }
                : {
                    backgroundColor: '#ff5c6c',
                    color: '#fff',
                    fontWeight: '500',
                  }
            }
          />
        )
      },
    },
    {
      field: 'visualizacao',
      headerName: 'Visualização',
      width: 120,
      align: 'left',
      flex: 0.5,
      headerAlign: 'center',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
      valueGetter: (params) =>
        params.row?.status === '1' ? 'Dados detalhados' : 'Dados consolidados',
      renderCell: (params) => {
        return (
          <Chip
            id="chips-status"
            label={params.row?.visualizacao === '1' ? 'Dados detalhados' : 'Dados consolidados'}
            style={
              params.row?.visualizacao === '1'
                ? {
                    backgroundColor: '#f9c320',
                    color: '#fff',
                    fontWeight: '500',
                  }
                : {
                    backgroundColor: '#2ad7f9',
                    color: '#fff',
                    fontWeight: '500',
                  }
            }
          />
        )
      },
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        return (
          <Link to={`${AppRoutePath.ASSOCIATIONDETAIL.replace(':associationId', params?.row?.id)}`}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={<VerifiedUser />}
              onClick={async () => {
                requestAssociation(Number(params?.row?.id))
              }}
            >
              {strings.buttonAssociationTitle}
            </Button>
          </Link>
        )
      },
    },
  ]
  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          style={{ marginBottom: '1.3rem' }}
        >
          <Typography variant="h4" color="primary">
            {strings.usersPage.title}
          </Typography>
          <Button
            id="button-adicionar-usuario"
            variant="contained"
            endIcon={<AddIcon />}
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '14px',
            }}
            onClick={() => {
              OpenModalCreateUser()
            }}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>
        <Grid container item xs={12} className={classes.resumeAuditTable}>
          <DataGrid
            rows={associations}
            columns={columns}
            disableSelectionOnClick
            filterModel={{ items }}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Paper>
      <Copyright />
      <Grid container item xs={12}>
        <EditAssociation ref={editAssociationRef} />
      </Grid>
      <Grid container item xs={12}>
        <CreateAssociation ref={AssociationCreateRef} />
      </Grid>
    </Grid>
  )
}

export default Association
