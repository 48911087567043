import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '24px 24px 24px 24px',
    flexGrow: 1,
    minHeight: 696,
    height: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    '@media (max-width: 1366px)': {
      minHeight: 670,
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '2px',
        height: '4.5px',
      },
      '*::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '10px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.dark,
      },
    },
  },
  headerContainer: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
  },
  bodyInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '24px 0px',
    padding: '8px 0px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 320,
  },
  gridSelectMission: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  gridInfoFarmer: {
    marginRight: 'auto',
  },
  paperInfoFarmer: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  resumeAuditTable: {
    flex: 1,
    display: 'flex',
    height: '80vh',
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardDescription: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  textTitleCard: {
    fontWeight: 'bold',
  },
  textContentCardFarmer: {
    width: '50%',
  },
  paperSummaryAudit: {
    flexDirection: 'column',
  },
  iconBack: {
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: 90,
  },
  typography: {
    fontSize: 28,
    fontWeight: 400,
    color: '#455a64',
  },
  primary: {
    color: '#455a64',
    fontSize: 20,
    fontWeight: 400,
  },
  secundary: {
    color: '#455a64',
    fontSize: 15,
  },
  titleName: {
    color: '#21a4d3',
    marginLeft: 32,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
}))
