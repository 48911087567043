import { ThemeOptions } from '@material-ui/core/styles'

export default {
  typography: {
    fontFamily: [
      'DinCondensedBold',
      'Inter',
      'Roboto',
      'Segoe UI',
      'Helvetica',
      'sans-serif',
      'Arial',
    ].join(','),
  },
  shape: {
    borderRadius: 16,
  },
  danger: '#ff0000',
} as ThemeOptions
