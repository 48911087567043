import React, { useEffect, useState } from 'react'

import { Button, Chip, Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid'
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons'

import Copyright from '~/components/copyright'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { CultureCodes } from '~/enums/enum'
import { useGrowCropsStore } from '~/store/variety/growcrops'
import { useGrowcropsQuery } from '~/store/variety/growcrops-service'
import { GrowCropsFilter, VarietyProps } from '~/types/grow-crops-types'

import GrowcropsCardFilter from './components/growcrops-card-filter'
import { TabsGrowCrops } from './components/tabs-growcrops'
import strings from './strings'
import { useStyles } from './style'

const listPageSize = 100

const GrowCrops: React.FC = () => {
  const classes = useStyles()
  const [variety, setVariety] = useState<VarietyProps>({} as VarietyProps)
  const [filters, setFilters] = React.useState<GrowCropsFilter | null>(null)
  const [page, setPage] = React.useState(1)

  const {
    getCultureList,
    getTechnologyList,
    getGeneticList,
    getFeaturesList,
    modalTabsController,
    getVarietyById,
  } = useGrowCropsStore()

  useEffect(() => {
    getTechnologyList()
    getCultureList()
    getGeneticList()
    getFeaturesList()
  }, [getCultureList, getFeaturesList, getGeneticList, getTechnologyList])

  const createEmptyVariety = (): VarietyProps => {
    return {
      id: 0,
      cultura_id: '',
      genetica_id: '',
      nome: '',
      grupo_maturacao: '',
      tecnologia: '',
      status: '',
      pms: '',
      acamamento: '',
      habito_crescimento: '',
      resistencia_nematoide: '',
      resistencia_outras: '',
      faixa_grupo_maturacao: '',
      product: {
        id: 0,
        variedade_id: '',
        origem: '',
        status: '',
        characteristics: [],
      },
      genetic: {
        id: 0,
        nome: '',
        status: '',
      },
      culture: {
        id: 0,
        nome: '',
        status: '',
      },
      technology: {
        id: 0,
        nome: '',
        status: '',
      },
    }
  }

  const handleOpenModal = async (varietyId?: number) => {
    if (varietyId) {
      const dataTeste = await getVarietyById(varietyId)
      setVariety(dataTeste)
    } else {
      setVariety(createEmptyVariety())
    }
    modalTabsController(true)
  }

  const { data, isFetching } = useGrowcropsQuery({
    size: listPageSize,
    page,
    filters,
  })

  const columns: GridColDef[] = [
    {
      field: 'cultura',
      headerName: 'Cultura',
      flex: 0.2,
      width: 150,
      minWidth: 120,
      filterable: false,
      renderCell: (params) => CultureCodes[params.row?.cultura_id],
      valueGetter: (params: GridValueGetterParams) => CultureCodes[params.row?.cultura_id],
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 200,
      filterable: false,
    },
    {
      field: 'grupo_maturacao',
      headerName: 'Grupo Maturação',
      width: 120,
      flex: 0.2,
      filterable: false,
      renderCell: (params) => params.row?.grupo_maturacao,
    },
    {
      field: 'genetic.nome',
      headerName: 'Genética',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      filterable: false,
      renderCell: (params) => params.row?.genetic.nome,
      valueGetter: (params: GridValueGetterParams) => params.row?.genetic.nome,
    },
    {
      field: 'tecnologia',
      headerName: 'Tecnologia',
      width: 120,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => params.row?.technology.nome,
      valueGetter: (params: GridValueGetterParams) => params.row?.technology.nome,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => {
        return (
          <Chip
            id="status-chips"
            label={params.row?.status === '1' ? 'Ativo' : 'Inativo'}
            style={
              params.row?.status === '1'
                ? { backgroundColor: '#24d2b5', color: '#fff' }
                : { backgroundColor: '#ff5c6c', color: '#fff' }
            }
          />
        )
      },
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.status === '1' ? 'Ativo' : 'Inativo',
    },
    {
      field: 'Ações',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            id="button-edit-cultivar"
            variant="contained"
            style={{
              backgroundColor: '#48bbe6',
              color: '#fff',
              fontSize: '10px',
            }}
            onClick={() => handleOpenModal(params.row?.id)}
          >
            <EditIcon />
          </Button>
        )
      },
    },
  ]

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          className={classes.marginContainer}
        >
          <Typography variant="h4" color="primary">
            {strings.growCropsPage.title}
          </Typography>
          <Button
            id="button-add-cultivar"
            variant="contained"
            endIcon={<AddIcon />}
            className={classes.buttonStyle}
            onClick={() => handleOpenModal()}
          >
            {strings.buttonTitle.title}
          </Button>
        </Grid>

        <Grid container item sm={12} xs={12}>
          <GrowcropsCardFilter onChangeFilter={setFilters} />
        </Grid>

        <Grid container item sm={12} xs={12} className={classes.resumeAuditTable}>
          <DataGrid
            page={page - 1}
            rows={data?.data || []}
            rowCount={data?.totalRowCount || 0}
            columns={columns}
            loading={isFetching}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
            onPageChange={(newPage) => setPage(newPage + 1)}
            pageSize={listPageSize}
            rowsPerPageOptions={[listPageSize]}
            paginationMode="server"
          />
        </Grid>
      </Paper>
      <Copyright />
      <TabsGrowCrops variety={variety} />
    </Grid>
  )
}

export default GrowCrops
