import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },

  text: {
    color: theme.palette.text.secondary,
  },

  modalStyle: {
    padding: '20px',
    width: '100%',
  },

  FieldShowSpace: {
    padding: '0 20px 20px 20px',
  },
  buttonAdd: {
    backgroundColor: '#48bbe6',
    color: '#fff',
    fontSize: '10px',
  },
  styleBlock: {
    color: theme.palette.text.hint,
  },
}))
