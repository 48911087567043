import React from 'react'

import {
  Home,
  EmojiPeople,
  Security,
  Spa,
  Beenhere,
  BusinessCenter,
  PeopleAlt,
} from '@material-ui/icons'
import FilterTiltShiftIcon from '@material-ui/icons/FilterTiltShift'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import { ContentPasteSearch } from '@mui/icons-material'
import DateRangeIcon from '@mui/icons-material/DateRange'

import { AppRoutePath } from '~/constants/paths'

import PlantationWorkerIcon from './components/plantation-worder-icon'

type Item = {
  readonly title: string
  readonly icon: React.ReactNode
  readonly route: AppRoutePath
  readonly focused: boolean
}

export default [
  {
    title: 'Início',
    icon: <Home />,
    route: AppRoutePath.HOME,
    focused: false,
  },
  {
    title: 'Validação',
    icon: <Beenhere />,
    route: AppRoutePath.VALIDATION,
    focused: false,
  },
  {
    title: 'Auditoria',
    icon: <ContentPasteSearch />,
    route: AppRoutePath.AUDIT,
    focused: false,
  },
  {
    title: 'Agricultores',
    icon: <PlantationWorkerIcon modifyIconHome={false} />,
    route: AppRoutePath.CLIENTS,
    focused: false,
  },
  {
    title: 'Usuários',
    icon: <PeopleAlt />,
    route: AppRoutePath.USERS,
    focused: false,
  },
  {
    title: 'Cultivares',
    icon: <Spa />,
    route: AppRoutePath.GROWCROPS,
    focused: false,
  },
  {
    title: 'Acesso CTVs',
    icon: <Security />,
    route: AppRoutePath.CTV,
    focused: false,
  },
  {
    title: 'Consultoria',
    icon: <EmojiPeople />,
    route: AppRoutePath.CONSULTANCY,
    focused: false,
  },
  {
    title: 'Regiões',
    icon: <GpsFixedIcon />,
    route: AppRoutePath.ENLARGEREGION,
    focused: false,
  },
  {
    title: 'Microrregião',
    icon: <FilterTiltShiftIcon />,
    route: AppRoutePath.MICROREGION,
    focused: false,
  },
  {
    title: 'Safras',
    icon: <DateRangeIcon />,
    route: AppRoutePath.HARVESTREGISTRATION,
    focused: false,
  },
  {
    title: 'Associação',
    icon: <BusinessCenter />,
    route: AppRoutePath.ASSOCIATION,
    focused: false,
  },
] as Item[]
