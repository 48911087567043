import React from 'react'

import {
  Fade,
  Paper,
  Modal,
  Backdrop,
  Button,
  Grid,
  Typography,
  Divider,
  CircularProgress,
} from '@material-ui/core'
import { Close, Save } from '@material-ui/icons'

import { useStyles } from './styles'

interface Props {
  open: boolean
  onClose: () => void
  onSave?: () => void
  title: string
  save?: boolean
  disableCloseClickOut?: boolean
  isLoad?: boolean
  scroll?: boolean
}

const TransitionsModal: React.FC<Props> = ({
  children,
  open,
  onClose,
  title,
  save,
  onSave,
  disableCloseClickOut = false,
  isLoad = false,
  scroll,
}) => {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="TITULO"
      aria-describedby="descricao"
      className={classes.modal}
      open={open}
      onClose={(event, reason) => {
        if (!disableCloseClickOut || reason !== 'backdropClick') {
          onClose()
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper
          elevation={8}
          className={`${classes.paper}`}
          style={scroll ? { overflow: 'scroll' } : { overflow: 'clip' }}
        >
          <Grid container spacing={2} className={classes.gridMain}>
            <Grid item sm={12} className={classes.gridHeader}>
              <Typography variant="h5" component="h5">
                {title}
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
            <Grid item sm={12}>
              {children}
            </Grid>
            <Grid item sm={12}>
              <Divider />
            </Grid>
            <Grid container item sm={12} spacing={2} className={classes.gridFooter}>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  id="button-fechar-modal"
                  color="secondary"
                  startIcon={<Close />}
                  onClick={onClose}
                >
                  FECHAR
                </Button>
              </Grid>
              {save && (
                <Grid item sm={6} className={classes.saveButton}>
                  {isLoad ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      id="button-save-modal"
                      variant="contained"
                      color="primary"
                      endIcon={<Save />}
                      onClick={onSave}
                      type="submit"
                    >
                      Salvar
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default TransitionsModal
