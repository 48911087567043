import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import {
  harvestDataById,
  HarvestsSeason,
  SourceCreateHarvest,
} from '~/types/harvest-registration-types'

type HarvestRegistrationStates = {
  controllerModalState: boolean
  errorsUsers: boolean
  messageError: any
  HarvestData: any
  harvest: []
  isLoading: boolean
  rules: SourceCreateHarvest | null
  dataHarvestById: harvestDataById | null
}

type HarvestRegistrationActions = {
  modalController: (value: boolean) => void
  getHarvestRegistrationById: (rulesId: number) => Promise<void>
  createHarvestRegistration: (rules: SourceCreateHarvest | null) => Promise<void>
  setRulesExtension: (rules: any) => Promise<void>
  updateHarvestRegistration: (id: number, rules: any) => Promise<void>
  clearForms: () => void
  getAllHarvestRegistration: () => Promise<HarvestsSeason[] | any>
  deleteHarvestRegistration: (id: number) => Promise<void>
}

type State = HarvestRegistrationStates & HarvestRegistrationActions

const initialState: HarvestRegistrationStates = {
  controllerModalState: false,
  isLoading: false,
  messageError: '',
  HarvestData: null,
  harvest: [],
  errorsUsers: false,
  rules: null,
  dataHarvestById: null,
}

export const useHarvestStore = create<State>((set, get) => ({
  ...initialState,
  modalController: (valueState) => {
    set({
      controllerModalState: valueState,
    })
  },
  clearForms: () => {
    set({ messageError: '', errorsUsers: false, rules: null })
  },
  getHarvestRegistrationById: async (rulesID) => {
    const { data } = await api.get(`/harvest/${rulesID}`)
    set({ dataHarvestById: data })
  },
  getAllHarvestRegistration: async () => {
    set({ isLoading: true, messageError: '' })
    try {
      const { data } = await api.get('/harvest')
      set({
        harvest: data,
      })
      set({ isLoading: false, messageError: '' })
    } catch (error: any) {
      set({ isLoading: false, messageError: error?.response?.data?.errors })
    } finally {
      set({ isLoading: false })
    }
  },
  createHarvestRegistration: async (rules) => {
    set({ isLoading: true, messageError: '' })
    const { modalController, getAllHarvestRegistration } = get()
    try {
      const { data } = await api.post('/harvest', rules)
      if (data) {
        set({ isLoading: false, messageError: '' })

        Swal.fire({
          icon: 'success',
          title: 'Cadastrado com sucesso!',
          text: data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
        modalController(false)
        getAllHarvestRegistration()
      }
    } catch (error: any) {
      set({ isLoading: false, messageError: error?.response?.data?.errors })
      Swal.fire({
        icon: 'error',
        title: 'Erro Ao Cadastrar Safra!',
        heightAuto: false,
        text: error?.response?.data?.errors,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  updateHarvestRegistration: async (id: number, params) => {
    set({ isLoading: true, messageError: '' })
    const { modalController, getAllHarvestRegistration } = get()
    try {
      const { data } = await api.put(`/harvest/${id}`, params)
      if (data) {
        set({ isLoading: false, messageError: '' })

        Swal.fire({
          icon: 'success',
          title: 'Atualizada com sucesso!',
          text: data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
        modalController(false)
        getAllHarvestRegistration()
      }
    } catch (error: any) {
      set({ isLoading: false, messageError: error?.response?.data?.errors })

      Swal.fire({
        icon: 'error',
        title: 'Erro Ao Atualizar Safra!',
        text: error.response?.data?.errors,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  setRulesExtension: async (rules) => {
    set({
      HarvestData: rules,
    })
  },

  listAllHarvestSeason: async () => {
    set({ isLoading: true })
    const { modalController } = get()
    try {
      const harvests = await api.get(`/harvest`)
      modalController(false)
      return harvests.data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }

    return []
  },
  deleteHarvestRegistration: async (harvestId, alertSuccess = true) => {
    set({ isLoading: true })
    try {
      await api.delete(`/harvest/${harvestId}`).then((res) => {
        if (alertSuccess) {
          Swal.fire({
            icon: 'success',
            title: 'Removido com sucesso!',
            text: res.data.message,
            confirmButtonColor: '#2b78c0',
            customClass: {
              container: 'alert-container',
            },
          })
        }
        set({ isLoading: false })
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
}))
