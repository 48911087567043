import { GridFilterModel, GridLinkOperator } from '@material-ui/data-grid'
import create from 'zustand'
import { persist } from 'zustand/middleware'

type MessageActions = {
  setFilter: (props: GridFilterModel) => void
  clearFilterFieldController: () => void
}

type State = GridFilterModel & MessageActions

const initialState: GridFilterModel = { items: [] }

export const useFilterValidationStateFieldController = create<State>(
  persist(
    (set) => ({
      ...initialState,
      setFilter: (props: GridFilterModel) => {
        set({ ...props, linkOperator: GridLinkOperator.And })
      },
      clearFilterFieldController: () => {
        set({
          items: [],
        })
      },
    }),
    {
      name: 'validationFilterController',
    },
  ),
)
