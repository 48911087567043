import React, { useRef } from 'react'

import { Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import { StatusEnum } from '~/pages/client/client-details/components/info-client/components/detail-client/enum'
import ConsultancyEdit from '~/pages/consultancy/consultancy-details/components/Modals/Edit'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { FormsHandleEdit } from '~/types/consultancy-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

const ConsultancyData: React.FC = () => {
  const classes = useStyles()
  const { Consultacy, consultancyById } = useConsultacyStore()
  const date = new Date(Consultacy?.created_at)
  const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
  const editConsultancyRef = useRef<FormsHandleEdit>(null)

  const openModalConsultancy = (consultacy: any) => {
    editConsultancyRef.current?.openModalEditForms(consultacy)
  }

  const getConsultancyById = async (consultancyId: number) => {
    const data = consultancyById(Number(consultancyId))
    openModalConsultancy(data)
  }

  return (
    <Grid xs={12} sm={12} item>
      <Paper variant="outlined" className={classes.paper}>
        <Grid xs={12} container justifyContent="space-between">
          <Typography variant="h5">{strings.title}</Typography>
          <IconButton
            component="span"
            color="primary"
            onClick={async () => getConsultancyById(Number(Consultacy?.id))}
            id="button-editar-consultancy"
          >
            <Edit />
          </IconButton>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={12} className={classes.headerContainer}>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.nomeFantasia}{' '}
              </Typography>
              <Typography variant="body2"> {Consultacy?.fantasia} </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.document}{' '}
              </Typography>
              <Typography variant="body2">
                {' '}
                {Consultacy?.documento.length === 11
                  ? maskInterceptor('cpf')(Consultacy?.documento)
                  : maskInterceptor('cnpj')(Consultacy?.documento)}{' '}
              </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.email}{' '}
              </Typography>
              <Typography variant="body2"> {Consultacy?.email} </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.celular}{' '}
              </Typography>
              <Typography variant="body2">
                {' '}
                {maskInterceptor('phone')(Consultacy?.celular)}{' '}
              </Typography>
            </Grid>
            <Grid
              container
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.situacao}{' '}
              </Typography>
              <Typography variant="body2"> {StatusEnum[Number(Consultacy.status)]} </Typography>
            </Grid>
          </Grid>
          <Divider
            variant="fullWidth"
            component="article"
            style={{ width: '100%', margin: '10px 0' }}
          />
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="flex-start"
            style={{ textAlign: 'start' }}
          >
            <Grid
              xs={7}
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {strings.address}
              </Typography>

              <Typography variant="body1">{`Logradouro: ${Consultacy?.rua} , Nº ${
                Consultacy?.numero
              }, Bairro ${Consultacy?.bairro}, CEP: ${maskInterceptor('zipCode')(
                Consultacy?.cep,
              )}`}</Typography>
              <Typography variant="body1">{`Cidade: ${Consultacy?.city?.descricao} - ${Consultacy?.city?.state?.sigla} / ${Consultacy?.city?.state?.pais?.nome_pt}`}</Typography>
            </Grid>
            <Grid
              xs={2}
              item
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ marginLeft: '16px' }}
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {' '}
                {strings.cadastro}{' '}
              </Typography>
              <Typography variant="body2"> {`${parseDate}`} </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <ConsultancyEdit ref={editConsultancyRef} />
    </Grid>
  )
}

export default ConsultancyData
