import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { Box, List, Avatar, ListItem, ListItemText, IconButton, Tooltip } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from '~/layouts/app/components/drawer-menu/styles'
import { useTokenStore } from '~/store/auth'

import strings from './strings'

interface Props {
  readonly handleOpenDrawer: () => void
}

export function FooterMenu({ handleOpenDrawer }: Props): ReactElement {
  const classes = useStyles()
  const { loggedUser } = useTokenStore()

  return (
    <List className={classes.listRoot}>
      <Tooltip title={strings.profile}>
        <ListItem button onClick={handleOpenDrawer} className={classes.listImage}>
          <Box className={clsx(classes.toolbarIcon, classes.avatar)}>
            <Avatar
              src="https://64.media.tumblr.com/f6247feea79daf91ce7dde5796d858a9/tumblr_pd9co9KAuE1vsss7oo1_250.png"
              alt="Logo"
              className={classes.imgProfile}
            />
          </Box>
          <ListItemText
            primary={loggedUser?.nome}
            className={clsx(classes.descriptionList, classes.userFullname)}
          />
        </ListItem>
      </Tooltip>
      <Link to="/logout">
        <Tooltip title={strings.logout}>
          <ListItem className={classes.themeMode}>
            <IconButton>
              <ExitToApp />
            </IconButton>
          </ListItem>
        </Tooltip>
      </Link>
    </List>
  )
}
