import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Button, FormControl, Grid, MenuItem, Paper, TextField } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'

import { Status } from '~/constants/data-field'
import { useGrowCropsStore } from '~/store/variety/growcrops'
import { GrowCropsFilter } from '~/types/grow-crops-types'
import { handleMaturationValue } from '~/utils/functions/functions'

import strings from './strings'
import { useStyles } from './styles'

interface QuickSearchToolbar {
  onChangeFilter: ((filters: GrowCropsFilter) => void) | undefined
}

const defaultValues = {
  name_variety: '',
  culture: '',
  genetic: '',
  technology: '',
  maturation: '',
  status: '',
}

const GrowcropsCardFilter: React.FC<QuickSearchToolbar> = ({ onChangeFilter }) => {
  const classes = useStyles()

  const { cultureList, geneticList, tecnologyList } = useGrowCropsStore()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<GrowCropsFilter>({
    defaultValues,
  })

  const onSubmit: SubmitHandler<GrowCropsFilter> = async (formClientFilter) => {
    if (onChangeFilter) {
      onChangeFilter(formClientFilter)
    }
  }

  const resetForm = () => {
    reset(defaultValues)
  }

  return (
    <Paper elevation={0} className={`${classes.paper} ${classes.cardPaper}`}>
      <FormControl variant="outlined" className={classes.formStyle}>
        <Grid item sm={12} className={classes.gridFields} container spacing={2}>
          <Grid item sm={3}>
            <Controller
              name="name_variety"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="name_variety"
                  variant="outlined"
                  label="Nome da Variedade"
                  placeholder="Nome da Variedade"
                  className={classes.textInput}
                  error={!!errors?.name_variety}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.name_variety?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="genetic"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="genetic"
                  variant="outlined"
                  label="Genética"
                  defaultValue=""
                  placeholder="Genética"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {geneticList.map((Option) => (
                    <MenuItem key={Option.id} value={Option.id}>
                      {Option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="technology"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="technology"
                  variant="outlined"
                  label="Tecnologia"
                  defaultValue=""
                  placeholder="Tecnologia"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {tecnologyList.map((Option) => (
                    <MenuItem key={Option.id} value={Option.id}>
                      {Option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="culture"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="culture"
                  variant="outlined"
                  label="Cultura"
                  defaultValue=""
                  placeholder="Cultura"
                  error={!!errors?.culture}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.culture?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {cultureList.map((Option) => (
                    <MenuItem key={Option.id} value={Option.id}>
                      {Option.nome}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.gridFields} container spacing={2}>
          <Grid item sm={3}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="status"
                  variant="outlined"
                  label="Status"
                  defaultValue=""
                  placeholder="Status"
                  error={!!errors?.status}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.status?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {Status.map((Option) => (
                    <MenuItem key={Option.statusValue} value={Option.statusValue}>
                      {Option.statusName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item sm={3}>
            <Controller
              name="maturation"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="maturation"
                  variant="outlined"
                  label="Grupo de Maturação"
                  defaultValue=""
                  placeholder="Grupo de Maturação"
                  error={!!errors?.maturation}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={
                    errors.maturation?.message ||
                    'Digite um número de 1 a 10. Use um ponto para decimais.'
                  }
                  className={classes.textInput}
                  value={field.value || ''}
                  onChange={(event) => {
                    let { value } = event.target
                    value = handleMaturationValue(value)
                    field.onChange(value)
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.buttonActions}>
          <Button
            id="button-limpar-form"
            variant="contained"
            color="secondary"
            className={classes.buttonClear}
            onClick={resetForm}
            endIcon={<ClearIcon />}
          >
            {strings.buttons.clear}
          </Button>
          <Button
            id="button-filtrar-form"
            type="submit"
            variant="contained"
            color="primary"
            className={classes.buttonFilter}
            endIcon={<SearchIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {strings.buttons.send}
          </Button>
        </Grid>
      </FormControl>
    </Paper>
  )
}

export default GrowcropsCardFilter
