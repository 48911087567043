import {
  SourceCreateMicroRegion,
  MicroregionDataMapper,
  SourceUpdateMicroRegion,
} from '~/types/microregion-types'

export function microregionMapper(payload: any[]): MicroregionDataMapper[] {
  return payload.map((item) => ({
    id: item.id,
    nome: item.nome,
    status: item.status,
    estado: item?.state?.descricao,
    estadoId: item?.state?.id,
    cidade: item?.city?.descricao,
    cidadeId: item?.city?.id,
  }))
}

export function createMicroregionMapper(params: any): SourceCreateMicroRegion {
  return {
    name: params.name,
    status: params.status,
    uf: params.uf,
    cities: params.cities?.map((city: { id: string }) => city.id),
  }
}

export function updateMicroregionMapper(params: any): SourceUpdateMicroRegion {
  return {
    name: params.name,
    status: params.status,
    uf: params.uf,
    cities: params.cities?.find((city: any) => city.id)?.id,
  }
}
