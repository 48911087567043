/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import lodash from 'lodash'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import TransitionsModal from '~/components/modal'
import { DefaultInput, Status } from '~/constants/data-field'
import { createMicroregionMapper, updateMicroregionMapper } from '~/mappers/microregion-mapper'
import { useMicroregionStore } from '~/store/microregion/microregion-store'
import {
  FormStateMicroregion,
  OptionTypeCities,
  OptionTypeStateMicroregion,
} from '~/types/microregion-types'

import strings from './string'
import { useStyles } from './styles'

const schema = yup.object({
  name: yup.string().required('Campo é obrigatório'),
  status: yup.string().required('Campo é obrigatório'),
  uf: yup.string().required('Campo é obrigatório'),
  cities: yup.array().min(1, 'Campo é obrigatório').required('Campo é obrigatório'),
})

interface Props {
  microregionCitiesId: string
}

export const MicroregionModal: React.FC<Props> = ({ microregionCitiesId }) => {
  const classes = useStyles()

  const {
    controllerModalState,
    modalController,
    statesBrazil,
    listCitiesByStateId,
    createMicroregion,
    updateMicroregion,
    getMicroregionById,
    isLoading,
    messageError,
    dataMicroRegionById,
  } = useMicroregionStore()

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<FormStateMicroregion>({
    resolver: yupResolver(schema),
  })
  const [cities, setCities] = useState([])
  const [uf, setUf] = useState<number | string>('')

  useEffect(() => {
    if (microregionCitiesId && dataMicroRegionById) {
      const stateSiglaToId: { [key: string]: number } = statesBrazil.reduce(
        (statesReduce: { [key: string]: number }, state: OptionTypeStateMicroregion) => {
          statesReduce[state.sigla] = state.id
          return statesReduce
        },
        {},
      )
      const fetchCities = async () => {
        if (uf) {
          const stateId = stateSiglaToId[uf]
          const citiesData = await listCitiesByStateId(stateId)
          setCities(citiesData.cidades)
        }
      }
      fetchCities()
    }
  }, [uf, listCitiesByStateId, statesBrazil])

  useEffect(() => {
    if (microregionCitiesId && dataMicroRegionById) {
      const updateFormData = async () => {
        try {
          const state: any = statesBrazil.find(({ sigla }) => sigla === dataMicroRegionById.uf)

          let foundCity = null
          if (state) {
            const citiesArray = state ? await listCitiesByStateId(state?.id) : []
            const cityList = citiesArray?.cidades || []
            foundCity = cityList.find(
              (cidade: { id: any }) => String(cidade.id) === String(dataMicroRegionById.cidade_id),
            )
          }

          reset()
          setValue('name', dataMicroRegionById?.nome)
          setValue('status', dataMicroRegionById?.status)
          setValue('uf', dataMicroRegionById?.uf)
          setValue('cities', foundCity ? [foundCity] : [])
          setUf(dataMicroRegionById?.uf || '')
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: error.message,
            confirmButtonColor: '#2b78c0',
            customClass: {
              container: 'alert-container',
            },
          })
        }
      }
      updateFormData()
    } else {
      reset()
      setUf('')
    }
  }, [
    controllerModalState,
    dataMicroRegionById,
    microregionCitiesId,
    statesBrazil,
    getMicroregionById,
  ])

  const stateSiglaToId: { [key: string]: number } = statesBrazil.reduce(
    (statesReduce: { [key: string]: number }, state: OptionTypeStateMicroregion) => {
      statesReduce[state.sigla] = state.id
      return statesReduce
    },
    {},
  )

  const closeModal = () => {
    modalController(false)
  }

  const handleSave = () => {
    handleSubmit(
      async (data) => {
        if (!microregionCitiesId) {
          const dataCreateMapper = createMicroregionMapper(data)
          createMicroregion(dataCreateMapper)
        } else {
          const dataUpdateMapper = updateMicroregionMapper(data)
          updateMicroregion(Number(microregionCitiesId), dataUpdateMapper)
        }
      },
      (error: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: error.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      },
    )()
  }

  return (
    <div className={classes.root}>
      <TransitionsModal
        open={controllerModalState}
        onClose={closeModal}
        onSave={handleSave}
        title={
          !microregionCitiesId ? strings.textModal.ModalTitle : strings.textModal.ModalTitleEdit
        }
        save
        isLoad={isLoading}
        disableCloseClickOut
      >
        <Grid container spacing={2} className={classes.modalStyle}>
          <Grid item xs={7}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="nome"
                  fullWidth
                  variant="outlined"
                  label={`${strings.formFields.Microrregiao}`}
                  placeholder="Nome da microrregião"
                  className={classes.textInput}
                  error={!!errors?.name}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              name="status"
              control={control}
              defaultValue={DefaultInput.statusDefault}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  required
                  id="status"
                  variant="outlined"
                  label="Status"
                  placeholder="Status"
                  className={classes.selectInput}
                  defaultValue={DefaultInput.statusDefault}
                  error={(!!errors?.status || !lodash.isEmpty(errors?.status)) && true}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors?.status?.message ?? errors?.status}
                >
                  {Status.map((Option) => (
                    <MenuItem key={Option.statusValue} value={Option.statusValue}>
                      {Option.statusName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={5}>
            <Controller
              name="uf"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel
                    id="uf"
                    style={errors?.uf ? { color: '#f43539' } : { color: '#2c3242' }}
                  >
                    {`${strings.formFields.UF}*`}
                  </InputLabel>

                  <Select
                    {...field}
                    required
                    labelId="state"
                    id="uf"
                    key={Math.random()}
                    label="UF"
                    autoWidth
                    placeholder="UF"
                    error={!!errors?.uf}
                    value={field.value || uf}
                    onChange={async (event: React.ChangeEvent<{ value: unknown }>) => {
                      const selectedValue = event.target.value as string
                      setValue('uf', selectedValue)
                      const stateId = stateSiglaToId[selectedValue]
                      const citiesData = await listCitiesByStateId(stateId)
                      setCities(citiesData.cidades)
                    }}
                  >
                    {statesBrazil.map((Option: any) => (
                      <MenuItem key={Option.id} value={Option.sigla}>
                        {Option.sigla}
                      </MenuItem>
                    ))}
                  </Select>

                  {errors.uf?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.uf?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <Controller
              name="cities"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="cities"
                  multiple
                  key={Math.random()}
                  onChange={(e, values) => {
                    const mappedValues = values.map((value) => ({
                      descricao: value.descricao,
                      id: value.id,
                    }))
                    setValue('cities', mappedValues)
                  }}
                  options={cities}
                  disableCloseOnSelect
                  getOptionLabel={(option: OptionTypeCities) => option.descricao}
                  isOptionEqualToValue={(option: OptionTypeCities, value: OptionTypeCities) =>
                    option.id === value.id
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      label="Selecione a Cidade"
                      placeholder="Selecione a Cidade"
                      className={classes.textInput}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      helperText={errors.cities?.message || messageError?.cities}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </TransitionsModal>
    </div>
  )
}
