import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: '100%',
  },
  modalStyle: {
    padding: '1rem',
    maxWidth: 800,
  },
  formStyle: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  textInput: {
    width: '100%',
  },
  iconButton: {
    padding: 10,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))
