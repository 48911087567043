import * as yup from 'yup'

export const validationSchema = yup.object({
  nome: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),

  observacao: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),
})
