const strings = {
  usersPage: {
    title: 'CTV por Município',
  },
  buttonTitle: {
    title: 'Copiar Acessos',
  },
  textModal: {
    addModalTitle: 'Copiando Acesso CTV',
  },
  tableHeader: {
    state: 'Estado',
    initials: 'Sigla',
    country: 'País',
    initialsCountry: 'Sigla País',
    counties: 'Munícipios',
  },

  modalAcessCtvCopy: {
    originCtv: 'CTV Origem',
    destinyCtv: 'CTV Destino',
  },
}

export default strings
