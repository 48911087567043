import { useFormContext, Controller } from 'react-hook-form'

import 'react-phone-input-2/lib/material.css'
import { Grid, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core'
import Swal from 'sweetalert2'

import { fetaures } from '~/constants/data-field'
import { useGrowCropsStore } from '~/store/variety/growcrops'

import { useStyles } from './style'

interface Props {
  askQuestion: boolean
  setAskQuestion: (value: boolean) => void
}

export const LesionNematodeForms = (props: Props) => {
  const classes = useStyles()
  const { askQuestion, setAskQuestion } = props

  const { control, getValues, setValue } = useFormContext<any>()
  const { featuresList, setAlterChange } = useGrowCropsStore()

  const arrayFeatures = featuresList.filter(
    (item) => item?.caracteristica !== 'CISTO' && item?.tipo !== 'DOENCAS',
  )

  const getFieldsNoValue = () => {
    const valuesFields = getValues()

    return Object.entries(valuesFields).filter((element) => {
      const key = element[0]
      return (
        key !== 'cultura_id' &&
        key !== 'genetica_id' &&
        key !== 'tecnologia' &&
        key !== 'status' &&
        key !== 'nome' &&
        key !== 'grupo_maturacao' &&
        !element[1]
      )
    })
  }

  const handleChangeFieldValue = (valueFieldParams: string | unknown) => {
    const valuesFields = getFieldsNoValue()

    if (valueFieldParams === 'Sem Informação' && !askQuestion && valuesFields.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Deseja preencher como "Sem Informação" as características que não estão preenchidas?',
        confirmButtonColor: '#2b78c0',
        showCancelButton: true,
        cancelButtonColor: '#ff7d89',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: true,
        customClass: {
          container: 'alert-container',
        },
      }).then(async (result) => {
        if (result.isConfirmed) valuesFields.map((item) => setValue(item[0], 'Sem Informação'))

        setAskQuestion(true)
      })
    }
  }

  return (
    <Grid container spacing={3} className={classes.modalStyle}>
      {arrayFeatures?.map((item) => (
        <Grid item sm={12} key={Math.random()}>
          <Controller
            name={item.nome_cientifico.replace(/\s/g, '').toLowerCase()}
            key={item.nome_cientifico}
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" className={classes.selectInput}>
                <InputLabel id={`${item.nome_cientifico.replace(/\s/g, '')}_id`}>
                  {item.nome_cientifico}
                </InputLabel>
                <Select
                  {...field}
                  labelId={`${item.nome_cientifico.replace(/\s/g, '')}_id`}
                  id={item.nome_cientifico.replace(/\s/g, '').toLowerCase()}
                  label={item.nome_cientifico}
                  autoWidth
                  required
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    handleChangeFieldValue(e.target.value)
                    setAlterChange(true)
                  }}
                  defaultValue=""
                >
                  {fetaures.map((Option) => (
                    <MenuItem key={Option.fetauresValue} value={Option.fetauresValue}>
                      {Option.fetauresName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      ))}
    </Grid>
  )
}
