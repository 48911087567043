const strings = {
  team: 'Equipe',
  ctv: 'CTV',
  agpro: 'AgPro',
  tabsLabel: {
    tabA: 'Propriedades',
    tabB: 'Usuários',
    tabC: 'Contatos Realizados',
  },
}

export default strings
