import { ReactElement, useEffect } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import _ from 'lodash'
import * as yup from 'yup'

import TransitionsModal from '~/components/modal'
import { useAlert } from '~/hooks/message'
import { contactMapper } from '~/mappers/contact-mapper'
import { useContactStore } from '~/store/contact-store'
import { ContactProps } from '~/types/contacts-types'

import { useStyles } from '../../styles'
import strings from './strings'

const schema = yup.object().shape({
  assunto: yup.string().required('Campo obrigatório'),
  data_contato: yup.date().required('Campo obrigatório'),
  observacao: yup.string().required('Campo obrigatório'),
})

type Props = {
  open: boolean
  onClose: () => void
  cliente_id: string
  editContactProps?: ContactProps
  title: string
}

export function ContactModal(props: Props): ReactElement {
  const classes = useStyles()
  const { open, onClose, cliente_id, editContactProps, title } = props

  const alert = useAlert()

  const { messageError, addContact, getContactsByClientId, isLoading, editContact } =
    useContactStore((state) => state)

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isDirty },
    reset,
  } = useForm<ContactProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...editContactProps,
      data_contato: editContactProps?.data_contato
        ? new Date(editContactProps.data_contato)
        : new Date(),
    },
  })

  const onSubmit: SubmitHandler<ContactProps> = async (clientForm) => {
    if (isDirty) {
      const editFieldClient = contactMapper(
        _.pick(clientForm, Object.keys({ ...dirtyFields })) as ContactProps,
      )
      if (editContactProps) {
        await editContact(editContactProps.id, editFieldClient)
        await getContactsByClientId(cliente_id)
      } else {
        await addContact(cliente_id, editFieldClient)
        await getContactsByClientId(cliente_id)
      }
    } else {
      alert.show({ message: 'Nenhum campo alterado', type: 'warning' })
    }
  }

  useEffect(() => {
    reset(
      editContactProps ?? {
        data_contato: new Date(),
      },
    )
  }, [open, editContactProps, reset])

  return (
    <TransitionsModal
      open={open}
      onClose={onClose}
      title={title}
      onSave={handleSubmit(onSubmit)}
      save
      isLoad={isLoading}
      disableCloseClickOut
    >
      <Grid
        container
        spacing={2}
        style={{ padding: '10px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid xs={6} item>
          <Controller
            name="assunto"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                variant="outlined"
                label={strings.subject}
                placeholder={strings.subject}
                className={classes.textInput}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.assunto?.message || messageError?.email}
              />
            )}
          />
        </Grid>
        <Grid xs={6} item style={{ alignSelf: 'center', textAlign: 'end' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              name="data_contato"
              control={control}
              render={({ field: { onChange, value } }) => (
                <KeyboardDatePicker
                  autoOk
                  required
                  disableFuture
                  clearable
                  placeholder="01/12/1999"
                  format="dd/MM/yyyy"
                  clearLabel="Limpar"
                  cancelLabel="Cancelar"
                  okLabel="Confirmar"
                  value={value || null}
                  onChange={(date) => {
                    onChange(date)
                  }}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid xs={12} item>
          <Controller
            name="observacao"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="observacao"
                label="Observação do Contato"
                multiline
                required
                rows={4}
                defaultValue=""
                variant="outlined"
                fullWidth
                helperText={errors.observacao?.message}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </TransitionsModal>
  )
}
