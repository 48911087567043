const strings = {
  title: 'Histórico de Alteração',
  field: 'Campo',
  value: 'Valor',
  newSituation: 'Nova Situação',
  newCity: 'Nova Cidade',
  oldSituation: 'Antiga Situação',
  oldCity: 'Antiga Cidade',
  cardHeaderDate: 'Data',
  cardHeaderUser: 'Usuário',
  cardHeaderOffice: 'Cargo',
}

export default strings
