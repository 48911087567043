import { useFilterAuditState } from '~/store/audit/filter-audit'
import { useTokenStore } from '~/store/auth'
import { useFilterClientState } from '~/store/client/filter-client'
import { useFilterConsultancyState } from '~/store/consultancy/filter-consultancy'
import { useFilterConsultancyUserState } from '~/store/consultancy/filter-user-consultancy'
import { useFilterSubGridUser } from '~/store/user/filter-subgrid-user'
import { useFilterValidationStateAssistant } from '~/store/validation/filter-validation-assistant'
import { useFilterValidationStateFarmer } from '~/store/validation/filter-validation-farmer'
import { useFilterValidationStateFieldController } from '~/store/validation/filter-validation-field-controller'
import { useFilterValidationStateRefs } from '~/store/validation/filter-validation-refs'
import { useFilterValidationStateRefsInteraction } from '~/store/validation/filter-validation-refs-interaction'
import { useFilterValidationStateSummary } from '~/store/validation/filter-validation-summary'
import { useVarietyFilterState } from '~/store/variety/filter-variety'

export const useClearFilterReset = () => {
  const { clearFilterAudit } = useFilterAuditState()
  const { clearFilterClient } = useFilterClientState()
  const { clearFilterVariety } = useVarietyFilterState()
  const { clearFilterConsultancy } = useFilterConsultancyState()
  const { clearFilterAssistant } = useFilterValidationStateAssistant()
  const { clearFilterFarmer } = useFilterValidationStateFarmer()
  const { clearFilterFieldController } = useFilterValidationStateFieldController()
  const { clearFilterRefsInteraction } = useFilterValidationStateRefsInteraction()
  const { clearFilterRefs } = useFilterValidationStateRefs()
  const { clearFilterSummary } = useFilterValidationStateSummary()
  const { clearFilterConsultancyUser } = useFilterConsultancyUserState()
  const { clearFilterUserSub } = useFilterSubGridUser()
  const { clearUserAuth } = useTokenStore()

  clearFilterAudit()
  clearFilterClient()
  clearFilterVariety()
  clearFilterConsultancy()
  clearFilterConsultancyUser()
  clearFilterAssistant()
  clearFilterFarmer()
  clearFilterAssistant()
  clearFilterFieldController()
  clearFilterRefs()
  clearFilterRefsInteraction()
  clearFilterSummary()
  clearFilterUserSub()
  clearUserAuth()
  localStorage.clear()
}
