import React from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, Paper } from '@material-ui/core'

import HeadersDetails from '~/shared/headers-details/headers-details'
import { useUserStore } from '~/store/user/user-store'

import UserDataInfo from './components/user-data-info'
import UserFarmerInfo from './components/user-farmer-info'
import { useStyles } from './style'

const InformationUser: React.FC = () => {
  const classes = useStyles()
  const { userDataById } = useUserStore()

  const history = useHistory()

  const onBack = () => {
    history.goBack()
  }

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <HeadersDetails nameTitle={userDataById.nome} onClickBack={onBack} />

        <Grid container item xs={12} style={{ paddingTop: '24px' }} spacing={0}>
          <Grid container item spacing={5}>
            <Grid item xs={12}>
              <UserDataInfo />
            </Grid>
            <Grid item xs={12}>
              <UserFarmerInfo />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default InformationUser
