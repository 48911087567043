/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react'

import { Grid, Paper, Typography, AppBar, Tabs, Tab, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Copyright from '~/components/copyright'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'

import { AddConsultantModal } from './components/add-consultant'
import ApprovedGrid from './components/aproved-grid'
import DisapprovedGrid from './components/disapproved-grid'
import { TablePanel } from './components/tab-panel'
import WaitingGrid from './components/waiting-grid'
import strings from './strings'
import { useStyles } from './style'

const Consultancy: React.FC = () => {
  const classes = useStyles()
  const {
    listAprovedConsultancy,
    listDisapprovedConsultancy,
    listWaitingConsultancy,
    listStatesBrazil,
    setNavigation,
    aba,
    clearFormsCreateUserConsultancy,
  } = useConsultacyStore()
  const tabsHandleControl = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleTabsControl = (event: React.ChangeEvent<{}>, newValue: number) => {
    setNavigation(newValue)
  }

  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    listAprovedConsultancy()
    listDisapprovedConsultancy()
    listWaitingConsultancy()
    listStatesBrazil()
    clearFormsCreateUserConsultancy()
  }, [])

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          justifyContent="space-between"
          style={{ marginBottom: '1.3rem' }}
        >
          <Typography variant="h4" color="primary">
            {strings.growCropsPage.title}
          </Typography>
          <Button className={classes.buttonHeaderNewConsultant} onClick={() => setOpenModal(true)}>
            {strings.buttonCreateConsultant.title}
            <AddIcon />
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.resumeAuditTable}>
          <AppBar position="static">
            <Tabs value={aba} onChange={handleTabsControl}>
              <Tab label="Aprovadas" {...tabsHandleControl(0)} />
              <Tab label="Aguardando Aprovação" {...tabsHandleControl(1)} />
              <Tab label="Reprovadas" {...tabsHandleControl(2)} />
            </Tabs>
          </AppBar>
          <TablePanel value={aba} index={0}>
            <ApprovedGrid />
          </TablePanel>
          <TablePanel value={aba} index={1}>
            <WaitingGrid />
          </TablePanel>
          <TablePanel value={aba} index={2}>
            <DisapprovedGrid />
          </TablePanel>
        </Grid>

        <AddConsultantModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title={strings.addConsultantModal.title}
          disableCloseClickOut
          isLoad={false}
          save
          scroll
        />
      </Paper>
      <Copyright />
    </Grid>
  )
}

export default Consultancy
