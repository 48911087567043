/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import { FarmProps } from '~/types/farm-types'

type FarmState = {
  isLoading: boolean
  messageError: any
  listFarm: FarmProps[]
}

type FarmActions = {
  getFarmsByClientId: (clientId: number | string) => Promise<FarmProps[]>
}

type State = FarmState & FarmActions

const initialState: FarmState = {
  isLoading: false,
  messageError: [],
  listFarm: [],
}

export const useFarmStore = create<State>((set) => ({
  ...initialState,
  getFarmsByClientId: async (clientId) => {
    set({ isLoading: true })
    try {
      const { data: listFarm } = await api.get<FarmProps[]>(`/property/${clientId}/list`)
      set({ listFarm })
      return listFarm
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
}))
