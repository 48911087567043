import React, { useEffect, useRef, useState } from 'react'

import plantationWorkerClicked from '../../../assets/img/plantation_worker_clicked.svg'
import plantationWorkerWhite from '../../../assets/img/plantation_worker_white.svg'
import plantationWorker from '../../../assets/img/plantation_worker.svg'

interface Props {
  modifyIconHome: boolean
}

const styleIconMenu = {
  width: 24,
  height: 24,
}

const styleIconHome = {
  fontSize: '100px !important',
  backgroundColor: '#303f9f',
  borderRadius: 90,
  padding: 20,
  color: 'white',
  width: 100,
  height: 100,
}

const PlantationWorkerIcon: React.FC<Props> = ({ modifyIconHome }) => {
  const [isClicked, setIsClicked] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClick = () => {
    setIsClicked(!isClicked)
  }
  useEffect(() => {
    if (isClicked) {
      const handleDocumentClick = (event: MouseEvent) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
          setIsClicked(false)
        }
      }
      document.addEventListener('click', handleDocumentClick)
      return () => {
        document.removeEventListener('click', handleDocumentClick)
      }
    }

    return undefined
  }, [isClicked])

  return (
    <button
      ref={buttonRef}
      type="button"
      onClick={handleClick}
      style={{ border: 'none', background: 'none' }}
    >
      {!modifyIconHome ? (
        <img
          src={isClicked ? plantationWorkerClicked : plantationWorker}
          alt="Plantation Worker"
          style={styleIconMenu}
        />
      ) : (
        <img src={plantationWorkerWhite} alt="Plantation Worker" style={styleIconHome} />
      )}
    </button>
  )
}

export default PlantationWorkerIcon
