import { ReactElement } from 'react'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import 'moment/locale/pt-br'
import { ThemeProvider } from '~/themes'

import { GlobalStyle } from './global-styles'
import Routes from './routes'
import QueryClientProvider from './shared/data-display/components/query-client-provider'

dayjs.locale('pt-br')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/Cuiaba')

export default function App(): ReactElement {
  return (
    <QueryClientProvider>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
        <ThemeProvider>
          <Routes />
          <GlobalStyle />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </QueryClientProvider>
  )
}
