import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Fade, FormControl, Grid, MenuItem, TextField } from '@material-ui/core'
import _ from 'lodash'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import 'react-phone-input-2/lib/material.css'

import Modal from '~/components/modal'
import {
  Cargo,
  DefaultInput,
  PerfilAdmin,
  Status,
  ValidacaoAutomaticaFilter,
  agpro,
  associationAdminField,
  associationMemberField,
  associationMemberYesOption,
} from '~/constants/data-field'
import { userCreateMapper } from '~/mappers/user-mapper'
import { useUserStore } from '~/store/user/user-store'
import {
  FormsHandleEdit,
  PropsFormCreateUser,
  RequestDataUser,
} from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'

const schema = yup.object({
  email: yup.string().email('Formato de email Inválido.').required('Formato de email Inválido.'),
  cpf: yup.string().nullable(),
  celular: yup.string().required('Celular é obrigatório'),
  nome: yup.string().required('Nome é obrigatório'),
  status: yup.string().required('Campo é obrigatório'),
  plantupper: yup.string().required('Campo é obrigatório'),
  cargo_id: yup.string().required('Campo é obrigatório'),
  associado: yup.string().required('Campo é obrigatório'),
  perfil_id: yup.string().required('Campo é obrigatório'),
  validacao_auditoria_automatica: yup.string().required('Campo é obrigatório'),
})

const EditUser: React.ForwardRefRenderFunction<FormsHandleEdit> = (props, ref) => {
  const OPEN_ALERT_DELETERELATIONSHIP_OPTIONS = false

  const classes = useStyles()
  const [users, setUser] = useState<RequestDataUser>({} as RequestDataUser)
  const [ctv, setCtv] = useState<string | undefined>(undefined)
  const [isAssociationAdminDisplayed, setIsAssociationAdminDisplayed] = useState<boolean>(false)
  const updateUser = useUserStore((state) => state.update)
  const userRelationshipDelete = useUserStore((state) => state.userRelationshipDelete)
  const updateAndRemoveRelasionships = (userId: number) => {
    userRelationshipDelete(userId, OPEN_ALERT_DELETERELATIONSHIP_OPTIONS)
    removeCtvForCity(userId)
  }
  const {
    isLoading,
    messageError,
    clearFormsCreateUser,
    modalControllerChangeEdit,
    controllerModalStateEdit,
    removeCtvForCity,
  } = useUserStore()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    resetField,
  } = useForm<PropsFormCreateUser>({
    resolver: yupResolver(schema),
  })
  const onSubmit: SubmitHandler<PropsFormCreateUser> = async (usersData) => {
    const userMapper = userCreateMapper(usersData)
    const userData = _.omitBy(userMapper, _.isNil) as RequestDataUser

    if (users.perfil_admin === '4' && ctv !== '4') {
      ctvBondVerification(userData, usersData.id)
    } else {
      updateUser(userData, usersData.id)
    }
  }

  const ctvBondVerification = (userData: RequestDataUser, userId: number) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Os dados serão atualizados. Deseja remover os vínculos do Usuário com o(s) Agricultor(es)?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      cancelButtonColor: '#ff7d89',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        updateUser(userData, userId, updateAndRemoveRelasionships)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        updateUser(userData, userId)
      } else {
        Swal.close()
        resetField('perfil_admin')
        setCtv('4')
      }
    })
  }

  const closeModal = () => {
    modalControllerChangeEdit(false)
    clearFormsCreateUser()
    setIsAssociationAdminDisplayed(false)
  }

  useEffect(() => {
    reset({
      ...users,
      celular: users.celular?.length >= 10 ? `55${users.celular}` : `595${users.celular}`,
      perfil_id: users.perfil_id ?? DefaultInput.associationAdminDefault,
    })
  }, [reset, users])

  useImperativeHandle(ref, () => {
    return {
      openModalEditForms(user: RequestDataUser) {
        setUser(user)
        setCtv(user.perfil_admin)
        modalControllerChangeEdit(true)
        setIsAssociationAdminDisplayed(user.associado === associationMemberYesOption.value)
        reset()
      },
    }
  })

  return (
    <Modal
      open={controllerModalStateEdit}
      onClose={closeModal}
      title={strings.textModal.editModalTitle}
      onSave={handleSubmit(onSubmit)}
      save
      isLoad={isLoading}
      disableCloseClickOut
      scroll
    >
      <Fade in={controllerModalStateEdit}>
        <FormControl
          onSubmit={handleSubmit(onSubmit)}
          variant="outlined"
          className={classes.formStyle}
        >
          <Grid container spacing={3} className={classes.modalStyle}>
            <Grid item sm={!_.isEmpty(users.cpf) ? 7 : 12}>
              <Controller
                name="nome"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="nome"
                    variant="outlined"
                    label="Nome completo"
                    placeholder="Nome do usuário"
                    className={classes.textInput}
                    error={!!errors?.nome}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.nome?.message}
                  />
                )}
              />
            </Grid>

            {!_.isEmpty(users.cpf) ? (
              <Grid item sm={5}>
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      id="cpf"
                      variant="outlined"
                      label="CPF"
                      placeholder="CPF"
                      className={classes.textInput}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      error={(!!errors?.cpf || !_.isEmpty(messageError?.cpf)) && true}
                      helperText={errors.cpf?.message ?? messageError?.cpf}
                      value={maskInterceptor('cpf')(field.value)}
                    />
                  )}
                />
              </Grid>
            ) : null}

            <Grid item sm={7}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="email"
                    variant="outlined"
                    label="E-mail"
                    placeholder="E-mail"
                    className={classes.textInput}
                    error={(!!errors?.email || !_.isEmpty(messageError?.email)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.email?.message ?? messageError?.email}
                  />
                )}
              />
            </Grid>

            <Grid item sm={5}>
              <Controller
                name="celular"
                control={control}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    required
                    id="celular"
                    defaultCountry="br"
                    label="Celular"
                    variant="outlined"
                    onlyCountries={['br', 'py']}
                    masks={{ py: '+... ... ......' }}
                    error={(!!errors?.celular || !_.isEmpty(messageError?.celular)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.celular?.message ?? messageError?.celular}
                    className={classes.textInput}
                    InputProps={{
                      style: {
                        borderRadius: 16,
                        width: '100%',
                        color: '#00446B',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={3}>
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="status"
                    variant="outlined"
                    label="Status"
                    placeholder="Status"
                    className={classes.textInput}
                    key={users.status}
                    defaultValue={users.status}
                    error={!!errors?.status}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.status?.message}
                  >
                    {Status.map((Option) => (
                      <MenuItem key={Option.statusValue} value={Option.statusValue}>
                        {Option.statusName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item sm={3}>
              <Controller
                name="plantupper"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="plantupper"
                    variant="outlined"
                    label="AgPro"
                    placeholder="Não"
                    className={classes.textInput}
                    error={(!!errors?.plantupper || !_.isEmpty(messageError?.plantupper)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.plantupper?.message ?? messageError?.plantupper}
                  >
                    {agpro.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="validacao_auditoria_automatica"
                control={control}
                defaultValue={DefaultInput.validacaoAutomaticaDefault}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="validacao_auditoria_automatica"
                    variant="outlined"
                    label="Valida Resultados Automaticamente?"
                    placeholder="validacao_auditoria_automatica"
                    className={classes.textInput}
                    error={!!errors?.validacao_auditoria_automatica}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.validacao_auditoria_automatica?.message}
                  >
                    {ValidacaoAutomaticaFilter.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.description}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="cargo_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="cargo"
                    variant="outlined"
                    label="Cargo"
                    placeholder="Cargo"
                    className={classes.textInput}
                    key={users.cargo_id}
                    defaultValue={users.cargo_id}
                    error={!!errors?.cargo_id}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.cargo_id?.message}
                  >
                    {Cargo.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="perfil_admin"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    id="outlined-required"
                    variant="outlined"
                    label="Perfil Administrativo"
                    placeholder="Perfil Administrativo"
                    className={classes.textInput}
                    key={users.perfil_admin}
                    defaultValue={users.perfil_admin}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setCtv(e.target.value)
                    }}
                  >
                    {PerfilAdmin.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={isAssociationAdminDisplayed ? 6 : 12}>
              <Controller
                name="associado"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    required
                    id="asociado"
                    variant="outlined"
                    label="Membro de Associação"
                    placeholder="Não"
                    className={classes.textInput}
                    error={!!errors?.associado || !_.isEmpty(messageError?.associado)}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.associado?.message ?? messageError?.associado}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const selectedValue: string = event.target.value
                      const isAssociationMember: boolean =
                        selectedValue === associationMemberYesOption.value

                      field.onChange(selectedValue)
                      setIsAssociationAdminDisplayed(isAssociationMember)

                      if (!isAssociationMember) {
                        resetField('perfil_id')
                      }
                    }}
                  >
                    {associationMemberField.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {isAssociationAdminDisplayed && (
              <Grid item sm={6}>
                <Controller
                  name="perfil_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      required
                      id="perfil_id"
                      variant="outlined"
                      label="Admin. da Associação"
                      placeholder="Não"
                      defaultValue={DefaultInput.associationAdminDefault}
                      className={classes.textInput}
                      error={!!errors?.perfil_id || messageError?.perfil_id}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      helperText={errors.perfil_id?.message ?? messageError?.perfil_id}
                    >
                      {associationAdminField.map((Option) => (
                        <MenuItem key={Option.value} value={Option.value}>
                          {Option.nameValue}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </FormControl>
      </Fade>
    </Modal>
  )
}

export default forwardRef(EditUser)
