import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    padding: '20px 20px 20px 0',
    maxHeight: 1080,
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  selectInput: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  resumeAuditTable: {
    flex: 1,
    display: 'flex',
    height: '80vh',
  },
  containerFormsModal: {
    width: '100%',
  },
  textInput: {
    width: '100%',
    color: '#00446B',
    borderRadius: '30px',
  },
  modalStyle: {
    padding: '1rem',
    maxWidth: 800,
  },
  formStyle: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  teste: {
    borderRadius: 35,
  },
}))
