import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  selectInput: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },

  modalStyle: {
    padding: '1rem',
    maxWidth: 800,
  },
}))
