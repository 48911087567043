import { ReactElement } from 'react'

import { Container } from '@material-ui/core'

import AppRoutes from '../../routes/app.routes'
import { DrawerMenu } from './components/drawer-menu'
import { useStyles } from './styles'

function AppLayout(): ReactElement {
  const classes = useStyles()

  return (
    <Container maxWidth={false} disableGutters component="main" className={classes.root}>
      <DrawerMenu />
      <AppRoutes />
    </Container>
  )
}

export default AppLayout
