import React, { useImperativeHandle, useState, forwardRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  LinearProgress,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Autocomplete } from '@mui/material'
import Swal from 'sweetalert2'

import Modal from '~/components/modal'
import { fonte, interacoes } from '~/constants/data-field'
import {
  safraFieldMapper,
  submitAssistanceInteraction,
  submitIntercationMapper,
} from '~/mappers/validation-mapper'
import { useValidationStore } from '~/store/validation/validation-store'
import { IAssistantInteractionSubmit, IAssistantIteraction } from '~/types/validation-types'

import { validationSchema } from './schemaValidation'
import strings from './strings'
import { useStyles } from './styles'

const ModalAssistant: React.ForwardRefRenderFunction<IAssistantIteraction> = (props, ref) => {
  const classes = useStyles()
  const [modalController, setModalController] = useState<boolean>(false)
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm<IAssistantInteractionSubmit>({
    resolver: yupResolver(validationSchema),
  })

  const {
    resultSafras,
    isLoadingInteraction,
    createInteractionCode,
    setShowField,
    showField,
    client,
    SafraField,
    createInteractionAssistence,
  } = useValidationStore()

  const dateFormatter = (date: Date) => {
    const parseDate = new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date)
    return parseDate
  }
  const handleCreateInteraction: SubmitHandler<any> = async (data) => {
    const submitInteraction = submitIntercationMapper(data)
    const response = await createInteractionCode(submitInteraction)
    setValue('interacao', response.data?.id)
  }

  const closeModal = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja cancelar a inserção?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      cancelButtonColor: '#ff7d89',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setModalController(false)
      }
    })
  }

  const handleSubmitInteractionFarmer: SubmitHandler<IAssistantInteractionSubmit> = async (
    data,
  ) => {
    createInteractionAssistence(submitAssistanceInteraction(data, client?.cliente_id))
  }
  useImperativeHandle(ref, () => {
    return {
      handleAction() {
        setModalController(true)
        setShowField(false)
        reset()
        setValue('data_interacao', dateFormatter(new Date()))
        setValue('cultura', safraFieldMapper(SafraField))
      },
    }
  })
  return (
    <Modal
      open={modalController}
      onClose={closeModal}
      save={!!showField}
      title={strings.title}
      disableCloseClickOut
      onSave={handleSubmit(handleSubmitInteractionFarmer)}
    >
      {isLoadingInteraction ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : (
        <Grid container spacing={2} className={classes.modalStyle}>
          <Grid item sm={2}>
            <Controller
              name="interacao"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="interacao"
                  variant="outlined"
                  label="Código Interação"
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="..."
                  className={classes.selectInput}
                  error={!!errors?.interacao}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.interacao?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="meio_interacao"
              control={control}
              defaultValue={1}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel
                    id="meio-meio_interacao"
                    style={showField ? { color: '#c5c5c5' } : { color: '#2C3242' }}
                  >
                    Meio de Interação
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="meio_interacao"
                    id="meio_interacao"
                    label="meio_interacao"
                    autoWidth
                    disabled={!!showField}
                    required
                    error={!!errors?.interacao}
                  >
                    {interacoes.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.interacao?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.interacao?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="data_interacao"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="data_interacao"
                  variant="outlined"
                  label="Data da interação"
                  type="date"
                  disabled={!!showField}
                  placeholder="Data da interação"
                  className={classes.selectInput}
                  error={!!errors?.data_interacao}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.data_interacao?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="fonte"
              control={control}
              defaultValue={1}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel
                    id="fonte"
                    style={showField ? { color: '#c5c5c5' } : { color: '#2C3242' }}
                  >
                    Tipo Fonte
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="fonte"
                    id="fonte"
                    label="fonte"
                    autoWidth
                    disabled={!!showField}
                    required
                    error={!!errors?.fonte}
                  >
                    {fonte.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.fonte?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.fonte?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={1}>
            <Button
              variant="contained"
              disabled={!!showField}
              className={classes.buttonAdd}
              onClick={handleSubmit(handleCreateInteraction)}
            >
              <AddIcon />
            </Button>
          </Grid>
          <Divider />
        </Grid>
      )}
      {showField ? (
        <Grid container spacing={2} className={classes.FieldShowSpace}>
          <Grid item sm={6}>
            <Controller
              name="nome"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="nome"
                  variant="outlined"
                  label="Nome"
                  defaultValue=""
                  placeholder="Nome"
                  className={classes.selectInput}
                  error={!!errors?.nome}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.nome?.message}
                />
              )}
            />
          </Grid>

          <Grid item sm={6}>
            <Controller
              name="cultura"
              control={control}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <Autocomplete
                    {...field}
                    multiple
                    id="cultura"
                    options={resultSafras}
                    getOptionLabel={(option) => option.descricao}
                    onChange={(e, values) => setValue('cultura', values)}
                    isOptionEqualToValue={(option, value) => option.descricao === value.descricao}
                    renderInput={(params) => (
                      <TextField {...params} label="Cultura - Safra - Tipo " variant="outlined" />
                    )}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name="observacao"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="observacao"
                  required
                  variant="outlined"
                  label="Observação"
                  defaultValue=""
                  multiline
                  minRows={5}
                  placeholder="Área Consultada"
                  className={classes.selectInput}
                  error={!!errors?.observacao}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.observacao?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        <Divider />
      )}
    </Modal>
  )
}

export default forwardRef(ModalAssistant)
