import { ReactElement } from 'react'
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'

import { api } from '~/services/api'

import TitlePage from '../components/title-page'
import { Title } from '../constants/pages'
import AppLayout from '../layouts/app'
import AuthLayout from '../layouts/auth'
import Logout from '../pages/logout'
import { useTokenStore } from '../store/auth'

export default function Routes(): ReactElement {
  const token = useTokenStore((state) => state.token)
  const { expiresIn, logout } = useTokenStore()

  if (new Date(expiresIn).getTime() <= new Date().getTime() && token) logout()

  const getAppLayout = () => {
    return [
      <Route key={0} path="/app" component={AppLayout} />,
      <Redirect key={1} from="/" to="/app" />,
      <Redirect key={2} from="/auth" to="/app" />,
    ]
  }

  const getAuthLayout = () => {
    return [
      <Route key={0} path="/auth" component={AuthLayout} />,
      <Redirect key={1} from="/" to="/auth" />,
      <Redirect key={2} from="/app" to="/auth" />,
    ]
  }

  api.interceptors.request.use(
    (config) => {
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/logout" component={Logout} />
        {token ? getAppLayout() : getAuthLayout()}
        <Route
          path="*"
          render={(props) => (
            <TitlePage {...props} title={Title.NOT_FOUND}>
              <div>Página não encontrada!</div>
            </TitlePage>
          )}
        />
      </Switch>
    </BrowserRouter>
  )
}
