/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import * as yup from 'yup'

import Modal from '~/components/modal'
import { DefaultInputRegion, cutureRegion } from '~/constants/data-field'
import { extensioAreaMapper } from '~/mappers/extension-area-mapper'
import { useEnlargeRegion } from '~/store/enlarge-region-store'

import strings from './strings'
import { useStyles } from './styles'

interface EnlargeRegion {
  id: number
  cultura_id: number
  origem_id?: number
  destino_id?: any
  destination_state?: any[]
  tipo_vinculo: number
  home_state: any
  destination_country?: any[]
}

const schema = yup.object({
  cultura_id: yup.number().required('Campo é obrigatório'),
  origem_id: yup.number().required('Campo é obrigatório'),
  destino_id: yup.array().min(1, 'Campo é obrigatório').required('Campo é obrigatório'),
})

interface Props {
  destinoId: number
}

const TIPO_VINCULO_PAIS = '1'
const DESTINO_ID_BRASIL = 1

export const EnlargeRegionModal: React.FC<Props> = ({ destinoId }) => {
  const {
    controllerModalState,
    modalController,
    statesBrazil,
    createRulesExtension,
    updateRulesExtension,
    stateWithBrazilDescription,
    isLoading,
    messageError,
    rules,
  } = useEnlargeRegion()

  const classes = useStyles()
  const closeModal = () => {
    modalController(false)
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    const dataMapper = extensioAreaMapper(data)
    if (!destinoId) {
      createRulesExtension(dataMapper)
    } else {
      updateRulesExtension(dataMapper)
    }
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm<EnlargeRegion>({
    resolver: yupResolver(schema),
  })

  const [origemId, setOrigemId] = useState<number | string>('')

  useEffect(() => {
    if (destinoId && rules && stateWithBrazilDescription.length) {
      let destinos: any[] = []

      if (rules.tipo_vinculo === TIPO_VINCULO_PAIS) {
        destinos = stateWithBrazilDescription.filter(
          (destino: any) => destino.id === DESTINO_ID_BRASIL,
        )
      } else {
        destinos = stateWithBrazilDescription.filter((destino: any) =>
          rules.destinos.includes(destino.id),
        )
      }
      reset()
      setValue('destino_id', destinos)
      setValue('origem_id', rules?.origem_id)
      setOrigemId(rules?.origem_id || '')
    } else {
      reset()
      setOrigemId('')
    }
  }, [controllerModalState, rules, destinoId, statesBrazil])

  return (
    <Modal
      open={controllerModalState}
      onClose={closeModal}
      title={!destinoId ? strings.textModal.ModalTitle : strings.textModal.ModalTitleEdit}
      onSave={handleSubmit(onSubmit)}
      save
      scroll
      isLoad={isLoading}
      disableCloseClickOut
    >
      <>
        <Grid container spacing={3} className={classes.modalStyle}>
          <Grid item sm={12}>
            <Controller
              name="cultura_id"
              control={control}
              defaultValue={DefaultInputRegion.cultura_id}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel id="cultura_id">Cultura*</InputLabel>
                  <Select
                    {...field}
                    labelId="cultura_label"
                    id="cultura_id"
                    label="cultura_id"
                    autoWidth
                    required
                    defaultValue=""
                    error={!!errors?.cultura_id}
                  >
                    {cutureRegion.map((Option) => (
                      <MenuItem key={Option.cutureValue} value={Option.cutureValue}>
                        {Option.cultureName}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.cultura_id?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.cultura_id?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name="origem_id"
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <FormControl variant="outlined" className={classes.selectInput}>
                  <InputLabel
                    id="origem_id"
                    style={errors?.origem_id ? { color: '#f43539' } : { color: '#2c3242' }}
                  >
                    Origem Agricultor*
                  </InputLabel>

                  <Select
                    {...field}
                    labelId="origem_agricultor"
                    id="origem_id"
                    key={Math.random()}
                    label="Origem Agricultor"
                    autoWidth
                    required
                    disabled={!!destinoId}
                    placeholder="Origem Agricultor"
                    error={!!errors?.origem_id}
                    value={field.value || origemId}
                  >
                    {statesBrazil.map((Option: any) => (
                      <MenuItem key={Option.id} value={Option.id}>
                        {Option.descricao}
                      </MenuItem>
                    ))}
                  </Select>

                  {errors.origem_id?.message && (
                    <FormHelperText className={classes.helperText}>
                      {errors.origem_id?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name="destino_id"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="destino_id"
                  multiple
                  key={Math.random()}
                  onChange={(e, values) => setValue('destino_id', values)}
                  options={stateWithBrazilDescription}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionDisabled={(selectOption: any) =>
                    getValues('destino_id')?.some(
                      (destino: any) => destino.id === DESTINO_ID_BRASIL,
                    )
                      ? selectOption.id !== DESTINO_ID_BRASIL
                      : getValues('destino_id')?.some(
                          (destino: any) => destino.id !== DESTINO_ID_BRASIL,
                        )
                      ? selectOption.id === DESTINO_ID_BRASIL
                      : false
                  }
                  getOptionLabel={(option) => option.descricao}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Limite Entorno"
                      placeholder="Favorites"
                      variant="outlined"
                      required
                      error={!!errors?.destino_id}
                      helperText={errors.destino_id?.message ?? messageError?.destino_id}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </>
    </Modal>
  )
}
