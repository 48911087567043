const strings = {
  detalhes: 'Detalhes da Auditoria',
  filterMission: 'Selecione a missão',
  farmerDescription: {
    title: 'Dados do Agricultor',
    cpf: 'CPF',
    phone: 'Celular',
    mail: 'E-mail',
    status: 'Situação',
  },
}

export default strings
