import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  modalStyle: {
    padding: '8px',
    minWidth: 800,
  },
  headerInformation: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#000',
  },
  iconSize: {
    fontSize: 45,
  },
  alterationTime: {
    fontWeight: 500,
    fontSize: 18,
    color: '#000',
    margin: '10px 0',
  },
  arrowIcon: {
    marginTop: 85,
    paddingLeft: 50,
  },

  headerTable: {
    fontWeight: 'bold',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  cellTable: {
    fontSize: 16,
    fontWeight: 600,
    color: '#171a20',
  },
  cardHeaderInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItens: 'center',
    height: '56px',
    margin: '12px 8px 8px 8px',

    '&:is(:first-child)': {
      margin: '4px 8px 8px 8px',
    },
  },
}))
