import React, { useState, useEffect } from 'react'

import { Grid, Typography, TextField } from '@material-ui/core'
import { GridFilterModel, GridColumnHeaderParams } from '@material-ui/data-grid'
import dayjs from 'dayjs'

interface Props {
  params: GridColumnHeaderParams
  items: GridFilterModel['items']
  setFilter: (props: GridFilterModel) => void
  operator?: string
}

const SearchDataGrid: React.FC<Props> = (props: Props) => {
  const { params, items, setFilter, operator = 'contains' } = props
  const [operatorValue, setOperatorValue] = useState(operator)

  useEffect(() => {
    if (!operator && !!params.colDef.filterOperators?.length) {
      setOperatorValue(params.colDef.filterOperators[0].value)
    }
  }, [operator])

  const handleFilterChange = (value: any) => {
    const { type } = params.colDef

    if (dayjs(value).isValid() && type === 'date') {
      const newFilterDate = {
        columnField: params.field,
        operatorValue,
        id: Date.now(),
        value: dayjs(value).format('DD/MM/YYYY'),
      }

      const updatedItemsDate = [...items.filter((item) => item.columnField !== params.field)]

      setFilter({
        items: [...updatedItemsDate, newFilterDate],
      })
    }
    const newFilter = {
      columnField: params.field,
      operatorValue,
      id: Date.now(),
      value,
    }

    const updatedItems = [...items.filter((item) => item.columnField !== params.field)]

    setFilter({
      items: [...updatedItems, newFilter],
    })
  }

  const renderTextField = () => {
    const { field, headerName, type } = params.colDef

    if (type === 'date') {
      return (
        <TextField
          id={`search-${field}`}
          placeholder={`Pesquisar ${headerName}`}
          type="date"
          variant="outlined"
          size="small"
          value={
            dayjs(items.find((filtro) => filtro.columnField === field)?.value).format(
              'DD/MM/YYYY',
            ) || ''
          }
          onChange={(event) => handleFilterChange(event.target.value)}
        />
      )
    }

    return (
      <TextField
        id={`search-${field}`}
        placeholder={`Pesquisar ${headerName}`}
        type="search"
        variant="outlined"
        size="small"
        value={items.find((filtro) => filtro.columnField === field)?.value || ''}
        onChange={(event) => handleFilterChange(event.target.value)}
      />
    )
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Typography variant="body1">{params.colDef.headerName}</Typography>
      {renderTextField()}
    </Grid>
  )
}

export default SearchDataGrid
