import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import { StatesProps } from '~/types/states-types'

type AcessStateState = {
  messageError: string
  isLoading: boolean
}

type AcessStateActions = {
  listStates: () => Promise<StatesProps[] | any>
  getStatebyId: (stateId?: number) => Promise<StatesProps | any>
}

type State = AcessStateState & AcessStateActions

const initialState: AcessStateState = {
  isLoading: false,
  messageError: '',
}

export const useStateStore = create<State>((set) => ({
  ...initialState,
  listStates: async () => {
    set({ isLoading: true })
    try {
      const state = await api.get('/country/state/list')
      return state.data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
  getStatebyId: async (stateId) => {
    set({ isLoading: true })
    try {
      const state = await api.get<StatesProps>(`/country/state/${stateId}`)
      return state
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
}))
