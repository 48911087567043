import { useState, useCallback, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, List, Drawer, Divider, ListItem, ClickAwayListener } from '@material-ui/core'
import clsx from 'clsx'

import { ItemMenu, FooterMenu, HeaderMenu } from '~/components/navSection/components'
import linkItems from '~/components/navSection/items'
import { AppRoutePath } from '~/constants/paths'

import { useStyles } from './styles'

export function DrawerMenu(): ReactElement {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const history = useHistory()

  const handleOpenDrawer = useCallback(() => setOpen((previous) => !previous), [])

  return (
    <ClickAwayListener onClickAway={() => (open ? handleOpenDrawer() : null)}>
      <Box>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <List>
            <ListItem button onClick={handleOpenDrawer} className={classes.listImage}>
              <HeaderMenu isOpen={open} />
            </ListItem>
          </List>
          <Divider />
          <List className={classes.listRoot}>
            {linkItems.map(({ icon, route, title, focused }) => (
              <ItemMenu
                key={route}
                route={route}
                icon={icon}
                title={title}
                focused={focused}
                onClick={() => (open ? handleOpenDrawer() : null)}
              />
            ))}
          </List>
          <Divider />
          <FooterMenu handleOpenDrawer={() => history.push(AppRoutePath.PROFILE)} />
        </Drawer>
      </Box>
    </ClickAwayListener>
  )
}
