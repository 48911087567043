import * as yup from 'yup'

export const validationSchema = {
  nome: yup.string().required('Nome é obrigatório'),
  fantasia: yup.string().required('Nome Fantasia é obrigatório'),
  documento: yup.string().required('CPF é obrigatório'),
  email: yup.string().email('Formato de email, inválido.').required('E-mail é obrigatório'),
  celular: yup.string().required('Celular é obrigatório'),
  cep: yup.string().required('Cep é obrigatório'),
  estado_id: yup.string().required('Estado é obrigatório'),
  cidade_id: yup.string().required('Cidade é obrigatório'),
  rua: yup.string().required('rua é obrigatório'),
  bairro: yup.string().required('Bairro é obrigatório'),
  numero: yup.string().required('Número é obrigatório'),
  status: yup.string().required('Status é obrigatório'),
  situacao: yup.string().required('Situação é obrigatório'),
}
