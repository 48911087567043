import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import Swal from 'sweetalert2'

import Modal from '~/components/modal'
import { incompletes } from '~/constants/data-field'
import { submitImcompleteResult } from '~/mappers/validation-mapper'
import { useValidationStore } from '~/store/validation/validation-store'
import { IResultsModalActionsIncomplete, IValidationSumary } from '~/types/validation-types'

import { useStyles } from './styles'

const ResultActionsModal: React.ForwardRefRenderFunction<IResultsModalActionsIncomplete> = (
  props,
  ref,
) => {
  const [modalComponenteState, setModalComponenteState] = useState<boolean>(false)
  const { isLoadingButton, client, incompleteResults } = useValidationStore()
  const [submitIncomplete, setSubmitIncomplete] = useState<IValidationSumary>(
    {} as IValidationSumary,
  )
  useImperativeHandle(ref, () => {
    return {
      handleAction(validationDataParams: any) {
        setModalComponenteState(true)
        setSubmitIncomplete(validationDataParams)
      },
    }
  })
  const handleIncompleteResult: SubmitHandler<any> = async (data) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Classificar esse resultado como Incompleto?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      cancelButtonColor: '#ff7d89',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não, cancele',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setModalComponenteState(false)
        await incompleteResults(submitImcompleteResult(data, submitIncomplete, client?.cliente_id))
      }
    })
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<any>()
  const classes = useStyles()

  return (
    <Modal
      onClose={() => {
        setModalComponenteState(false)
      }}
      open={modalComponenteState}
      isLoad={isLoadingButton}
      title="Atualizando Resultado"
      save
      onSave={handleSubmit(handleIncompleteResult)}
    >
      <Grid container spacing={3} className={classes.modalStyle}>
        <Grid item sm={12}>
          <Controller
            name="incompleto"
            control={control}
            defaultValue="1"
            render={({ field }) => (
              <FormControl variant="outlined" className={classes.selectInput}>
                <InputLabel id="incompleto_id">Motivo</InputLabel>
                <Select
                  {...field}
                  labelId="incompleto_label"
                  id="incompleto_id"
                  label="incompleto_id"
                  autoWidth
                  required
                  error={!!errors?.incompleto}
                >
                  {incompletes.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.incompleto?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.incompleto?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

export default forwardRef(ResultActionsModal)
