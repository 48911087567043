import Swal from 'sweetalert2'
import create from 'zustand'

import { api } from '~/services/api'
import { CityProps } from '~/types/city-types.ts'

type AcessCityState = {
  messageError: string
  isLoading: boolean
}

type AcessCityActions = {
  listCitiesByStateId: (stateId?: number) => Promise<CityProps[] | any>
  getCityById: (cityId?: number) => Promise<CityProps | any>
}

type State = AcessCityState & AcessCityActions

const initialState: AcessCityState = {
  isLoading: false,
  messageError: '',
}

export const useCityStore = create<State>((set) => ({
  ...initialState,
  listCitiesByStateId: async (stateId) => {
    set({ isLoading: true })
    try {
      const cities = await api.get(`/country/state/${stateId}/cities`)
      return cities.data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }

    return []
  },
  getCityById: async (cityId) => {
    set({ isLoading: true })
    try {
      const citiesByCtvs = await api.get<CityProps>(`/country/state/${cityId}/cities/ctv`)
      return citiesByCtvs
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
}))
