import { ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Message } from '~/components/messages'
import Association from '~/pages/association'
import AssocationInfo from '~/pages/association/components/information-user'
import Audit from '~/pages/audit'
import AuditDetails from '~/pages/audit-details'
import AuditFarmer from '~/pages/audit-farmer'
import Clients from '~/pages/client'
import ClientDetails from '~/pages/client/client-details'
import Consultancy from '~/pages/consultancy'
import ConsultancyDetails from '~/pages/consultancy/consultancy-details'
import Ctv from '~/pages/ctv'
import { EnlargeRegion } from '~/pages/enlarge-region'
import GrowCrops from '~/pages/growcrops'
import { HarvestRegistration } from '~/pages/harvest-registration'
import Home from '~/pages/home'
import { Microregion } from '~/pages/microregion'
import Users from '~/pages/users'
import InformationUser from '~/pages/users/components/information-user'
import Validation from '~/pages/validation'
import ValidationDetail from '~/pages/validation/components/validation-detail'

import TitlePage from '../components/title-page'
import { Title } from '../constants/pages'
import { AppRoutePath } from '../constants/paths'

const routes = [
  {
    path: AppRoutePath.HOME,
    title: Title.HOME,
    Component: Home,
  },
  {
    path: AppRoutePath.AUDIT,
    title: Title.AUDIT,
    Component: Audit,
  },
  {
    path: AppRoutePath.AUDITDETAILS,
    title: Title.AUDIT,
    Component: AuditDetails,
  },
  {
    path: AppRoutePath.AUDITFARMER,
    title: Title.AUDIT,
    Component: AuditFarmer,
  },
  {
    path: AppRoutePath.USERS,
    title: Title.USERS,
    Component: Users,
  },
  {
    path: AppRoutePath.CLIENTS,
    title: Title.CLIENTS,
    Component: Clients,
  },
  {
    path: AppRoutePath.CLIENTDETAIL,
    title: Title.CLIENTS,
    Component: ClientDetails,
  },
  {
    path: AppRoutePath.CONSULTANCYDETAIL,
    title: Title.CONSULTANCY,
    Component: ConsultancyDetails,
  },
  {
    path: AppRoutePath.CTV,
    title: Title.ACESSOCTV,
    Component: Ctv,
  },
  {
    path: AppRoutePath.GROWCROPS,
    title: Title.GROWCROPS,
    Component: GrowCrops,
  },
  {
    path: AppRoutePath.ENLARGEREGION,
    title: Title.ENLARGEREGION,
    Component: EnlargeRegion,
  },
  {
    path: AppRoutePath.MICROREGION,
    title: Title.MICROREGION,
    Component: Microregion,
  },
  {
    path: AppRoutePath.HARVESTREGISTRATION,
    title: Title.HARVESTREGISTRATION,
    Component: HarvestRegistration,
  },
  {
    path: AppRoutePath.CONSULTANCY,
    title: Title.CONSULTANCY,
    Component: Consultancy,
  },
  {
    path: AppRoutePath.VALIDATION,
    title: Title.VALIDATION,
    Component: Validation,
  },
  {
    path: AppRoutePath.VALIDATIONDETAIL,
    title: Title.VALIDATION,
    Component: ValidationDetail,
  },
  {
    path: AppRoutePath.USERSDETAIL,
    title: Title.USERS,
    Component: InformationUser,
  },

  {
    path: AppRoutePath.ASSOCIATION,
    title: Title.ASSOCIATION,
    Component: Association,
  },
  {
    path: AppRoutePath.ASSOCIATIONDETAIL,
    title: Title.ASSOCIATION,
    Component: AssocationInfo,
  },
]

export default function AppRoutes(): ReactElement {
  return (
    <Switch>
      <Route exact path="/app" render={() => <Redirect to={AppRoutePath.HOME} />} />
      {routes.map(({ path, title, Component }) => (
        <Route key={path} exact path={path}>
          {(props) => (
            <TitlePage {...props} title={title}>
              <Component />
              <Message />
            </TitlePage>
          )}
        </Route>
      ))}
    </Switch>
  )
}
