import { VorietyTransterInputProps, VorietyTransterOutputProps } from '~/types/grow-crops-types'

export function growCropsMapper(requestType: VorietyTransterInputProps) {
  return {
    variedade: {
      nome: requestType.nome,
      cultura_id: requestType.cultura_id,
      tecnologia: requestType.tecnologia,
      status: requestType.status,
      genetica_id: requestType.genetica_id,
      grupo_maturacao: requestType.grupo_maturacao ?? '',
    },
    caracteristicas: {
      raca1: requestType.raca1
        ? {
            caracteristica_detalhe_id: 1,
            reacao: requestType.raca1,
          }
        : undefined,

      raca2: requestType.raca2
        ? {
            caracteristica_detalhe_id: 2,
            reacao: requestType.raca2,
          }
        : undefined,
      raca3: requestType.raca3
        ? {
            caracteristica_detalhe_id: 3,
            reacao: requestType.raca3,
          }
        : undefined,
      raca4: requestType.raca4
        ? {
            caracteristica_detalhe_id: 4,
            reacao: requestType.raca4,
          }
        : undefined,
      raca5: requestType.raca4mais
        ? {
            caracteristica_detalhe_id: 5,
            reacao: requestType.raca4mais,
          }
        : undefined,
      raca6: requestType.raca5
        ? {
            caracteristica_detalhe_id: 6,
            reacao: requestType.raca5,
          }
        : undefined,
      raca7: requestType.raca6
        ? {
            caracteristica_detalhe_id: 7,
            reacao: requestType.raca6,
          }
        : undefined,
      raca8: requestType.raca7
        ? {
            caracteristica_detalhe_id: 8,
            reacao: requestType.raca7,
          }
        : undefined,
      raca9: requestType.raca8
        ? {
            caracteristica_detalhe_id: 9,
            reacao: requestType.raca8,
          }
        : undefined,
      raca10: requestType.raca9
        ? {
            caracteristica_detalhe_id: 10,
            reacao: requestType.raca9,
          }
        : undefined,
      raca11: requestType.raca10
        ? {
            caracteristica_detalhe_id: 11,
            reacao: requestType.raca10,
          }
        : undefined,
      raca12: requestType.raca11
        ? {
            caracteristica_detalhe_id: 12,
            reacao: requestType.raca11,
          }
        : undefined,
      raca13: requestType.raca12
        ? {
            caracteristica_detalhe_id: 13,
            reacao: requestType.raca12,
          }
        : undefined,
      raca14: requestType.raca13
        ? {
            caracteristica_detalhe_id: 14,
            reacao: requestType.raca13,
          }
        : undefined,
      raca15: requestType.raca14
        ? {
            caracteristica_detalhe_id: 15,
            reacao: requestType.raca14,
          }
        : undefined,
      raca16: requestType.raca14mais
        ? {
            caracteristica_detalhe_id: 16,
            reacao: requestType.raca14mais,
          }
        : undefined,

      meloidogyneincognita: requestType.meloidogyneincognita
        ? {
            caracteristica_detalhe_id: 17,
            reacao: requestType.meloidogyneincognita,
          }
        : undefined,
      meloidogynejavanica: requestType.meloidogynejavanica
        ? {
            caracteristica_detalhe_id: 18,
            reacao: requestType.meloidogynejavanica,
          }
        : undefined,
      pratylenchusbrachyurus: requestType.pratylenchusbrachyurus
        ? {
            caracteristica_detalhe_id: 19,
            reacao: requestType.pratylenchusbrachyurus,
          }
        : undefined,
      crancrodahaste: requestType.crancrodahaste
        ? {
            caracteristica_detalhe_id: 20,
            reacao: requestType.crancrodahaste,
          }
        : undefined,
      pustulabacteriana: requestType.pustulabacteriana
        ? {
            caracteristica_detalhe_id: 21,
            reacao: requestType.pustulabacteriana,
          }
        : undefined,
      manchaolhodera: requestType.manchaolhodera
        ? {
            caracteristica_detalhe_id: 22,
            reacao: requestType.manchaolhodera,
          }
        : undefined,
      manchaalvo: requestType.manchaalvo
        ? {
            caracteristica_detalhe_id: 23,
            reacao: requestType.manchaalvo,
          }
        : undefined,
      ferrugemasiatica: requestType.ferrugemasiatica
        ? {
            caracteristica_detalhe_id: 24,
            reacao: requestType.ferrugemasiatica,
          }
        : undefined,
      podridaoradiculardefitoftora: requestType.podridaoradiculardefitoftora
        ? {
            caracteristica_detalhe_id: 25,
            reacao: requestType.podridaoradiculardefitoftora,
          }
        : undefined,
      mofobranco: requestType.mofobranco
        ? {
            caracteristica_detalhe_id: 26,
            reacao: requestType.mofobranco,
          }
        : undefined,
      oidio: requestType.oidio
        ? {
            caracteristica_detalhe_id: 27,
            reacao: requestType.oidio,
          }
        : undefined,
    },
  } as VorietyTransterOutputProps
}
