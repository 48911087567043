import React, { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { Grid, Paper, Typography, Button, Box, TextField } from '@material-ui/core'
import { DataGrid, GridColDef, GridColumnHeaderParams } from '@material-ui/data-grid'
import AddIcon from '@material-ui/icons/Add'
import Swal from 'sweetalert2'

import SearchDataGrid from '~/components/search-data-grid'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { interactionsEmuns, motivesEmuns, plantupEmuns, resultEmuns } from '~/enums'
import { useFilterValidationStateFarmer } from '~/store/validation/filter-validation-farmer'
import { useValidationStore } from '~/store/validation/validation-store'
import { IFarmerInteraction, IFarmerModalAction } from '~/types/validation-types'

import ModalFarmer from './components/modal-farmer'
import string from './strings'
import { useStyles } from './styles'

export const InteractionsFarmer: React.FC = () => {
  const { items } = useFilterValidationStateFarmer()
  const setFilter = useFilterValidationStateFarmer((state) => state.setFilter)
  const modalInteracationFarmeRef = useRef<IFarmerModalAction>(null)

  const classes = useStyles()
  const { farmerInteraction, SafraField, isLoading } = useValidationStore()

  const farmerInteractionCreateUniqueKey = farmerInteraction.map((item: IFarmerInteraction) => {
    return {
      ...item,
      id: Math.random(),
    }
  })

  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  const handleModalFieldController = () => {
    if (SafraField.some((item) => item.cultura_id === '0')) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Selecione uma cultura, safra e tipo safra!',
        confirmButtonColor: '#2b78c0',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: true,
        customClass: {
          container: 'alert-container',
        },
      })
    } else {
      modalInteracationFarmeRef.current?.handleAction()
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'usuario_criacao',
      headerName: 'Usuario',
      width: 200,
      minWidth: 120,
      valueGetter: (params) =>
        params.row?.usuario_criacao ? params.row.usuario_criacao : 'Usuario não informado',
      renderCell: (params: any) =>
        params.row?.usuario_criacao ? params.row.usuario_criacao : 'Usuario não informado',
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },

    {
      field: 'cultura_safra_tipo',
      headerName: 'Cultura-Safra-tipo',
      width: 200,
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'meio_interacao',
      headerName: 'Interação',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      valueGetter: (params) => interactionsEmuns[params.row?.meio_interacao],
      renderCell: (params: any) => {
        return interactionsEmuns[params.row?.meio_interacao]
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'data_interacao',
      headerName: 'Data',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const date = new Date(params.row?.data_interacao)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
      renderCell: (params) => {
        const date = new Date(params.row?.data_interacao)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'motivo',
      headerName: 'Motivo',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => motivesEmuns[params.row?.motivo],
      renderCell: (params: any) => {
        return motivesEmuns[params.row?.motivo]
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'conhece_plantup',
      headerName: 'Conhece PlantUP',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => plantupEmuns[params.row?.conhece_plantup],
      renderCell: (params: any) => {
        return plantupEmuns[params.row?.conhece_plantup]
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'autorizou',
      headerName: 'Autorizou',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => plantupEmuns[params.row?.autorizou],
      renderCell: (params: any) => {
        return plantupEmuns[params.row?.autorizou]
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'area_plantio',
      headerName: 'Área Plantio',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(Number(params.row.area_plantio)),
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'resultado',
      headerName: 'Resultado',
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => resultEmuns[params.row?.resultado],
      renderCell: (params: any) => {
        return resultEmuns[params.row?.resultado]
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
    {
      field: 'observacao',
      headerName: 'Observação',
      minWidth: 500,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <TextField
            value={cellValues.row.observacao}
            InputProps={{ disableUnderline: true }}
            multiline
            style={{ width: '100%', height: '100%' }}
          />
        )
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <SearchDataGrid params={params} setFilter={setFilter} items={items} />
      ),
    },
  ]
  return (
    <Paper variant="outlined" className={`${classes.paper}`}>
      {isLoading ? (
        <Skeleton height={300} borderRadius={10} />
      ) : (
        <>
          <Grid item container sm={12} xs={12} className={classes.headerContainer}>
            <Typography variant="h5" className={classes.typography}>
              {string.title}
            </Typography>
            <Box>
              <Button
                variant="contained"
                className={classes.buttonAdd}
                onClick={handleModalFieldController}
              >
                <AddIcon />
              </Button>
            </Box>
          </Grid>
          <DataGrid
            rows={farmerInteractionCreateUniqueKey}
            columns={columns}
            disableSelectionOnClick
            autoHeight
            filterModel={{ items }}
            rowHeight={100}
            onFilterModelChange={(model) => setFilter(model)}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </>
      )}

      <ModalFarmer ref={modalInteracationFarmeRef} />
    </Paper>
  )
}
