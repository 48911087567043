import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
    overflowX: 'auto',
  },
  typography: {
    fontSize: 30,
    fontWeight: 400,
    color: '#455a64',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    overflowX: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    marginLeft: '16px',
  },
  skeleton: {
    borderRadius: '10px',
  },

  header: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 14,
  },

  cell: {
    whiteSpace: 'nowrap',
    alignItems: 'center',
    textAlign: 'center',
  },
}))
