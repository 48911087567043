import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    padding: '20px 20px 20px 0',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 250px',
    marginBottom: '20px',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#282a2b',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  primary: {
    color: '#24D2B5',
    fontSize: '20px',
    fontWeight: 200,
  },
  secundary: {
    color: '#989b9a',
    fontSize: '20px',
    fontWeight: 200,
  },
  cardValidation: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'red',
  },
  cardValidationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 20px',
  },
  button: {
    backgroundColor: '#21ABDE',
    color: '#FFFFFF',
    '&:hover': {
      color: '#21ABDE',
    },
  },
  cardTypographyPosition: {
    padding: '8px 0px',
  },
}))
