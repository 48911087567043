import { GridFilterItem, GridFilterModel, GridLinkOperator } from '@material-ui/data-grid'
import create, { SetState } from 'zustand'

type MessageActions = {
  setFilter: (props: GridFilterModel) => void
  clearFilterVariety: () => void
}

type State = GridFilterModel & MessageActions

const initialState: GridFilterModel = { items: [] }

export const useVarietyFilterState = create<State>((set: SetState<State>) => ({
  ...initialState,
  setFilter: (props: GridFilterModel) => {
    set({ ...props, linkOperator: GridLinkOperator.And })
  },
  clearFilterVariety: () => {
    set({
      items: [],
    })
  },
}))

interface GridFilterState {
  items: GridFilterItem[]
  setFilter: (newFilters: GridFilterItem[]) => void
}

export const useFilterGrowcropsState = create<GridFilterState>((set) => ({
  items: [],
  setFilter: (newFilters) => set({ items: newFilters }),
}))
