import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  buttonTable: {
    background: '#24D2B5',
    color: '#ffffff',
    transition: '0.2s',
    '&:hover': {
      background: '#24D2B5',
      filter: `brightness('0.9')`,
    },
  },
  tableCellStye: {
    borderBottom: 'none',
  },
  titlePosition: {
    marginBottom: 10,
  },
  formStyle: {
    width: '100%',
  },
}))
