import axios from 'axios'
import Swal from 'sweetalert2'
import create from 'zustand'

type CepState = {
  messageError: string
  isLoading: boolean
}

type CepType = {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia?: string
  ddd: string
  siafi: string
  erro?: boolean
}

type CepActions = {
  find: (cep: string) => Promise<CepType | any>
}

type State = CepState & CepActions

const initialState: CepState = {
  isLoading: false,
  messageError: '',
}

export const useCepStore = create<State>((set) => ({
  ...initialState,
  find: async (cep) => {
    set({ isLoading: true })
    try {
      const { data } = await axios.get<CepType>(`https://viacep.com.br/ws/${cep}/json/`)

      return data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Tente novamente em instantes!',
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }

    return []
  },
}))
