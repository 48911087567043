import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, TextField, LinearProgress } from '@material-ui/core'
import * as yup from 'yup'

import Modal from '~/components/modal'
import { useGrowCropsStore } from '~/store/variety/growcrops'

import { useStyles } from './styles'

interface ModalProps {
  nameField: string
  label: string
}

const schema = yup.object({
  nome: yup.string().required('Campo é obrigatório'),
})

export const ModalPostTechnologyGenetic: React.FC<ModalProps> = ({ nameField, label }) => {
  const classes = useStyles()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({
    resolver: yupResolver(schema),
  })

  const {
    clearFormsCreateUser,
    modalPostController,
    isLoading,
    postModalController,
    createdGenetic,
    createdTechnology,
  } = useGrowCropsStore()

  const closeModalTabs = () => {
    modalPostController(false)
    clearFormsCreateUser()
  }

  const onSubmit = async (data: any) => {
    if (nameField === 'genetica') {
      createdGenetic(data)
    } else {
      createdTechnology(data)
    }
  }

  useEffect(() => {
    reset()
  }, [postModalController])
  return (
    <Modal
      open={postModalController}
      onClose={closeModalTabs}
      title={label}
      onSave={handleSubmit(onSubmit)}
      save
      scroll
      disableCloseClickOut
    >
      <Box p={4}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Controller
            name="nome"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id={nameField}
                required
                variant="outlined"
                label={label}
                defaultValue=""
                placeholder={label}
                error={!!errors?.nome}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.nome?.message}
                className={classes.selectInput}
              />
            )}
          />
        )}
      </Box>
    </Modal>
  )
}
