import { useEffect, useState } from 'react'

import { Grid, Typography, Tooltip } from '@material-ui/core'
import { AxiosError } from 'axios'

import { checkfalse, checkok, checkwarning, checkokaut, checkno } from '~/assets/img'
import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'

import strings from './strings'
import { useStyles } from './styles'

type PropsHistoryAudit = {
  rn: number
  safra: string
  cultura: string
  cliente: string
  inconsistencia: string
  status_auditoria: string
  tipo_auditoria?: string
}

type Props = {
  email: string
}

interface PropsDetailAudit {
  dados?: PropsHistoryAudit
  description: string
  typeAudit: string | undefined
}

const DetailsLastAudits = (props: PropsDetailAudit) => {
  const classes = useStyles()
  const { dados, description, typeAudit } = props
  return (
    <Grid xs={1} item>
      <Tooltip
        title={
          <Typography color="inherit" className={classes.textCenter}>
            {description} <br />
            {dados?.cliente} <br />
            {dados?.safra} <br />
            {dados?.cultura} <br />
          </Typography>
        }
      >
        <img src={typeAudit} alt={description} />
      </Tooltip>
    </Grid>
  )
}

const HistoryAuditAgpro = (props: Props) => {
  const classes = useStyles()
  const [historyAudit, setHistoryAudit] = useState<PropsHistoryAudit[]>([])
  const { email } = props
  const alert = useAlert()

  useEffect(() => {
    api
      .get(`/audit/agpros/audits`, {
        params: { email },
      })
      .then((res: any) => setHistoryAudit(res.data))
      .catch((error: AxiosError) => {
        alert.show({ message: error.response?.data.message, type: 'error' })
      })
  }, [])

  return (
    <Grid item container xs={6} className={classes.gridCenter}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.lastAudit}>
          {strings.lastAudits}
        </Typography>
      </Grid>
      {historyAudit.length > 0 ? (
        historyAudit.map((value) => {
          const uniqueKey = `${value.status_auditoria}-${value.inconsistencia}`
          let typeAudit = checkok
          let description = strings.approvedManual
          if (value.status_auditoria === '3' && value.inconsistencia === '1') {
            typeAudit = checkwarning
            description = strings.approvedInconsistency
          } else if (value.status_auditoria === '4') {
            typeAudit = checkfalse
            description = strings.reproved
          } else if (value.status_auditoria === '3' && value.tipo_auditoria === '1') {
            typeAudit = checkokaut
            description = strings.approvedAut
          }
          return (
            <>
              <DetailsLastAudits
                key={uniqueKey}
                description={description}
                dados={value}
                typeAudit={typeAudit}
              />
            </>
          )
        })
      ) : (
        <DetailsLastAudits
          description="Sem auditoria para a safra atual e passada!"
          typeAudit={checkno}
        />
      )}
    </Grid>
  )
}

export default HistoryAuditAgpro
