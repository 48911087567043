import { IConsultancy, inputConsultancy } from '~/types/consultancy-types'

export function consultancyCreateMapper(requestType: IConsultancy) {
  return {
    ...requestType,
    celular: requestType?.celular
      ?.replace(/\s/g, '')
      ?.replace('(', '')
      ?.replace(')', '')
      ?.replace('+', '')
      ?.replace('55', ''),
  } as any
}

export function consultancyEditMapper(requestType: inputConsultancy) {
  return {
    ...requestType,
    cidade_id: requestType.cidade_id.id ?? '',
    celular: requestType?.celular
      ?.replace(/\s/g, '')
      ?.replace('(', '')
      ?.replace(')', '')
      ?.replace('+', '')
      ?.replace('-', '')
      ?.replace('55', ''),
  } as any
}
