import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 284

export const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))',
    padding: `0 ${theme.spacing(1)}px`,
    ...theme.mixins.toolbar,
  },
  avatar: {
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(0.5)}px`,
    },
    [theme.breakpoints.up('xl')]: {
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  drawerPaper: {
    overflowX: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      minWidth: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: theme.spacing(10),
    },
    // filter: 'drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.16))',
    // boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.16)'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      width: theme.spacing(10),
    },
    // filter: 'drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.16))',
    // boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.16)',
  },
  drawerDiv: {
    margin: theme.spacing(6),
    visibility: 'visible',
    alignSelf: 'flex-start',
  },
  plantupIntelligence: {
    maxWidth: theme.spacing(15),
    minWidth: theme.spacing(12),
  },
  upLight: {
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(4),
    },
    [theme.breakpoints.up('xl')]: {
      width: theme.spacing(6),
    },
  },
  divLogo: {
    backgroundColor: '#f9cb3e',
    alignSelf: 'center',
    // borderRadius: theme.spacing(22.5),
    margin: theme.spacing(0.5),
    [theme.breakpoints.up('xs')]: {
      minHeight: theme.spacing(6),
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: theme.spacing(8),
    },
  },
  bgGrey: {
    backgroundColor: theme.palette.type === 'light' ? '#C7D2DA' : '#606164',
  },
  listRoot: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  listImage: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    justifyContent: 'space-between',
  },
  imgProfile: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(4),
      maxHeight: theme.spacing(4),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'unset',
      maxHeight: 'unset',
    },
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px 0 0`,
  },
  themeMode: {
    display: 'flex',
    justifyContent: 'center',
  },
  centerLogo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 5% 0',
  },
  userFullname: {
    marginLeft: theme.spacing(1),
    color: theme.palette.type === 'light' ? '#C7D2DA' : '#606164',
  },
  descriptionList: {
    '& > span': {
      fontFamily: 'DinTextProRegular',
      fontWeight: 400,
    },
  },
}))
