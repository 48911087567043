/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import Modal from '~/components/modal'
import { growCropsMapper } from '~/mappers/grow-crops-mapper'
import { resetFieldMapper } from '~/mappers/variedade-mapper'
import { useGrowCropsStore } from '~/store/variety/growcrops'
import { VarietyProps, VorietyTransterInputProps } from '~/types/grow-crops-types'

import { CystNematodeForms } from '../cyst-nematoide-forms'
import GrowCropsForms from '../growcrops-forms'
import { IllnessForms } from '../illness-forms'
import { LesionNematodeForms } from '../lesion-nematoide-forms'
import { TablePanel } from '../tab-panel'
import strings from './strings'

const schema = yup.object({
  cultura_id: yup.string().required('Campo é obrigatório'),
  nome: yup.string().required('Campo é obrigatório'),
  genetica_id: yup.string().required('Campo é obrigatório'),
  tecnologia: yup.string().required('Campo é obrigatório'),
  grupo_maturacao: yup.string().nullable(),
  status: yup.string().required('Campo é obrigatório'),
})

interface Props {
  variety?: VarietyProps
}

export const TabsGrowCrops: React.FC<Props> = ({ variety }) => {
  const {
    controllerModalTabs,
    clearFormsCreateUser,
    isLoading,
    modalTabsController,
    cultureState,
    updateVarietyById,
    setAlterChange,
  } = useGrowCropsStore()

  const [noInforamtionField, setNoInforamtionField] = useState<boolean>(false)

  const methods = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      cultura_id: '',
      nome: '',
      genetica_id: '',
      tecnologia: '',
      grupo_maturacao: '',
      status: '',
    },
  })

  useEffect(() => {
    setTabsControl(0)
    setNoInforamtionField(false)
    if (variety) {
      methods.reset(resetFieldMapper(variety))
    } else {
      methods.reset({})
    }
  }, [variety])

  const [tabsControl, setTabsControl] = useState<number>(0)

  const tabsHandleControl = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleTabsControl = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsControl(newValue)
  }
  const createVariety = useGrowCropsStore((state) => state.createVariety)

  const closeModalTabs = () => {
    modalTabsController(false)
    clearFormsCreateUser()
    setAlterChange(false)
  }

  const getFieldsNoValue = () => {
    const valuesFields = methods.getValues()

    return Object.entries(valuesFields).filter((element) => {
      const key = element[0]
      return (
        key !== 'cultura_id' &&
        key !== 'genetica_id' &&
        key !== 'tecnologia' &&
        key !== 'status' &&
        key !== 'nome' &&
        key !== 'grupo_maturacao' &&
        !element[1]
      )
    })
  }

  const handleChangeFieldValue = async (valueFieldParams: any, data: any, dataId: any) => {
    const valuesFields = getFieldsNoValue()
    if (valueFieldParams === 'Sem Informação' && valuesFields.length > 0 && tabsControl !== 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Deseja preencher como "Sem Informação" as características que não estão preenchidas?',
        confirmButtonColor: '#2b78c0',
        showCancelButton: true,
        cancelButtonColor: '#ff7d89',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: true,
        customClass: {
          container: 'alert-container',
        },
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          valuesFields.map((item) => methods.setValue(item[0], 'Sem Informação'))
          const valuesField = methods.getValues()
          const growCropsMappers = growCropsMapper(valuesField)
          dataId
            ? await updateVarietyById(growCropsMappers, dataId)
            : await createVariety(growCropsMappers)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          const valuesField = methods.getValues()
          const growCropsMappers = growCropsMapper(valuesField)
          dataId
            ? await updateVarietyById(growCropsMappers, dataId)
            : await createVariety(growCropsMappers)
        } else {
          Swal.close()
        }
      })
    }
  }

  const verifyUpdate = async (data: any, dataId: any) => {
    const growCropsMappers = growCropsMapper(data)
    tabsControl !== 0
      ? handleChangeFieldValue('Sem Informação', growCropsMappers, variety?.id)
      : await updateVarietyById(data, dataId)
  }

  const verifyCreate = async (data: any) => {
    const growCropsMappers = growCropsMapper(data)
    tabsControl === 0
      ? await createVariety(data)
      : handleChangeFieldValue('Sem Informação', growCropsMappers, variety?.id)
  }

  const onSubmit = async (data: VorietyTransterInputProps) => {
    if (!variety?.id) {
      const valuesFields = getFieldsNoValue()
      const growCropsMappers = growCropsMapper(data)
      valuesFields.length > 0
        ? verifyCreate(growCropsMappers)
        : await createVariety(growCropsMappers)
    } else {
      const valuesFields = getFieldsNoValue()
      const growCropsMappers = growCropsMapper(data)
      valuesFields.length > 0
        ? verifyUpdate(growCropsMappers, variety?.id)
        : await updateVarietyById(growCropsMappers, variety?.id)
      setAlterChange(false)
    }
  }

  return (
    <Modal
      open={controllerModalTabs}
      onClose={closeModalTabs}
      title={strings.textModal.ModalTitle}
      onSave={methods.handleSubmit(onSubmit)}
      save
      scroll
      isLoad={isLoading}
      disableCloseClickOut
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AppBar position="static">
            {parseInt(cultureState, 3) === 1 ? (
              <Tabs value={tabsControl} onChange={handleTabsControl} centered>
                <Tab label="Cultivar" {...tabsHandleControl(0)} id="tabs-modal-cultivar" />
                <Tab label="Nematoides Cisto" {...tabsHandleControl(1)} id="tabs-modal-cultivar" />
                <Tab
                  label="Nematoides Lesão/Galha"
                  {...tabsHandleControl(2)}
                  id="tabs-modal-cultivar"
                />
                <Tab label="Doenças" {...tabsHandleControl(3)} id="tabs-modal-cultivar" />
              </Tabs>
            ) : (
              <Tabs value={tabsControl} onChange={handleTabsControl}>
                <Tab label="Cultivar" {...tabsHandleControl(0)} />
              </Tabs>
            )}
          </AppBar>
          <TablePanel value={tabsControl} index={0}>
            <GrowCropsForms />
          </TablePanel>
          <TablePanel value={tabsControl} index={1}>
            <CystNematodeForms
              askQuestion={noInforamtionField}
              setAskQuestion={setNoInforamtionField}
            />
          </TablePanel>
          <TablePanel value={tabsControl} index={2}>
            <LesionNematodeForms
              askQuestion={noInforamtionField}
              setAskQuestion={setNoInforamtionField}
            />
          </TablePanel>
          <TablePanel value={tabsControl} index={3}>
            <IllnessForms askQuestion={noInforamtionField} setAskQuestion={setNoInforamtionField} />
          </TablePanel>
        </form>
      </FormProvider>
    </Modal>
  )
}
