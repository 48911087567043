import create from 'zustand'
import { persist } from 'zustand/middleware'

import { api } from '~/services/api'

export type PropsMissions = {
  id: number
  titulo: string
  cpf: string
  mission_detail: [
    {
      id: number
      missao_id: number
      cultura_id: number
      safra_id: number
    },
  ]
}

type MissionState = {
  isLoading: boolean
  expiresIn: Date
  missions: PropsMissions[]
}

type MissionAction = {
  listMissions: () => Promise<void>
}

type State = MissionState & MissionAction

const initialState: MissionState = {
  isLoading: false,
  expiresIn: new Date(Date.now() - 86400 * 1000),
  missions: [],
}

export const useStoreMissions = create<State>(
  persist(
    (set) => ({
      ...initialState,
      listMissions: async () => {
        set({ isLoading: true })
        try {
          const { data: dataMissions } = await api.get('/missions')

          set({
            isLoading: false,
            expiresIn: new Date(Date.now() + 86400 * 1000),
            missions: dataMissions,
          })
        } catch (error: any) {
          set({ isLoading: false })
        }
      },
    }),
    {
      name: 'missionsAgpro',
    },
  ),
)
