import { ReactElement } from 'react'

import { Divider, Grid, Typography } from '@material-ui/core'

import { Premium } from '~/enums/enum'
import { CpfCnpjMask, FormatCep, FormatPhoneWithCountry } from '~/utils'

import { StatusEnum } from './enum'
import strings from './strings'
import { useStyles } from './styles'

type Props = {
  dadosClient?: any
}

function DetailClient(props: Props): ReactElement {
  const classes = useStyles()
  const { dadosClient } = props
  const date = new Date(dadosClient?.created_at)
  const parseDate = new Intl.DateTimeFormat('pt-br').format(date)

  return (
    <Grid item xs={12}>
      <Grid item xs={12} className={classes.headerContainer}>
        <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {' '}
            {strings.document}{' '}
          </Typography>
          <Typography variant="body2"> {CpfCnpjMask(dadosClient?.documento)} </Typography>
        </Grid>
        <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {' '}
            {strings.mail}{' '}
          </Typography>
          <Typography variant="body2"> {dadosClient?.email} </Typography>
        </Grid>
        <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {' '}
            {strings.phone}{' '}
          </Typography>
          <Typography variant="body2">
            {' '}
            {FormatPhoneWithCountry(dadosClient?.pais_celular, dadosClient?.celular)}{' '}
          </Typography>
        </Grid>
        <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {' '}
            {strings.status}{' '}
          </Typography>
          <Typography variant="body2"> {StatusEnum[dadosClient.status]} </Typography>
        </Grid>
        <Grid container item direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {' '}
            {strings.premium}{' '}
          </Typography>
          <Typography variant="body2"> {Premium[dadosClient.premium]} </Typography>
        </Grid>
      </Grid>
      <Divider style={{ margin: '10px 0' }} />
      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="flex-start"
        style={{ textAlign: 'start' }}
      >
        <Grid xs={7} item direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {strings.address}
          </Typography>

          <Typography variant="body1">{`Logradouro: ${dadosClient?.rua} , Nº ${
            dadosClient?.numero
          }, Bairro ${dadosClient?.bairro}, CEP: ${FormatCep(dadosClient?.cep)}`}</Typography>
          <Typography variant="body1">{`Cidade: ${dadosClient?.city?.descricao} - ${dadosClient?.city?.state?.sigla} / ${dadosClient?.city?.state?.pais?.nome_pt}`}</Typography>
        </Grid>
        <Grid
          xs={2}
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ marginLeft: '16px' }}
        >
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {' '}
            {strings.source}{' '}
          </Typography>
          <Typography variant="body2"> {dadosClient.origem} </Typography>
        </Grid>
        <Grid
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ marginLeft: '40px' }}
        >
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {' '}
            {strings.registerIn}{' '}
          </Typography>
          <Typography variant="body2"> {parseDate} </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DetailClient
