import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 800,
    transform: 'translateZ(0)',
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  resumeAuditTable: {
    flex: 1,
    display: 'flex',
    height: '80vh',
  },
  containerFormsModal: {
    width: '100%',
  },
  textInput: {
    color: '#00446B',
    borderRadius: '30px',
  },
  selectInput: {
    width: '100%',
  },
  modalStyle: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  formStyle: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  iconButton: {
    padding: 10,
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))
