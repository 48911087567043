/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2'
import create from 'zustand'

import { StateCodes } from '~/enums/enum'
import { api } from '~/services/api'
import { RequestStateList, CityProps, ListRequestCtv, CityRequestProps } from '~/types/state-types'

type AcessCtvState = {
  statesList: RequestStateList[]
  messageError: string
  cityState: CityProps
  requestCtv: ListRequestCtv[]
  controllerModal: boolean
  isLoading: boolean
  stateId?: number
  listCtvByClient: ListRequestCtv[]
}

type AcessCtvActions = {
  listState: () => Promise<RequestStateList[]>
  getCityById: (stateId?: number) => Promise<void>
  getCtv: () => Promise<ListRequestCtv[]>
  copyCtv: (originId: number, destinyId: number) => Promise<void>
  getStateId: (stateId?: number) => Promise<void>
  modalController: (value: boolean) => void
  updateCtvByCity: (cityId: number, responsibleCtv?: number, stateId?: number) => Promise<void>
  setStatesList: (statesList: any[]) => void
  getCtvByClient: (clientId: number | string) => Promise<ListRequestCtv[]>
}

type State = AcessCtvState & AcessCtvActions

const initialState: AcessCtvState = {
  statesList: [],
  isLoading: false,
  messageError: '',
  cityState: {} as CityProps,
  requestCtv: [],
  controllerModal: false,
  stateId: undefined,
  listCtvByClient: [],
}

export const userAcessCtv = create<State>((set, get) => ({
  ...initialState,

  listState: async () => {
    try {
      const state = await api.get<RequestStateList[]>('/country/state/list')
      state.data = state.data.map((state) => {
        return {
          ...state,
          descricao_pais: StateCodes[state.pais_id],
          sigla_pais: state.pais_id === '1' ? 'BR' : 'PY',
        }
      })

      set({
        statesList: state.data,
      })
      return state.data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
      return []
    }
  },
  getCityById: async (stateId) => {
    set({ isLoading: true })
    try {
      const citiesByCtvs = await api.get<CityRequestProps>(`/country/state/${stateId}/cities/ctv`)

      set((state) => {
        const indexState = get().statesList.findIndex((state) => state.id === stateId)
        state.statesList[indexState].cities = citiesByCtvs.data.cidades
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
  },
  // eslint-disable-next-line consistent-return
  getCtv: async () => {
    try {
      const ctvs = await api.get(`/ctv/list`)
      return ctvs.data
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro Ao Remover vinculo Usuário!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    }
  },
  copyCtv: async (originId, destinyId) => {
    const { modalController } = get()
    set({ isLoading: true })
    await api
      .post(`/ctv/copy?ctv_origem_id=${originId}&ctv_destino_id=${destinyId}`)
      .then((resp) => {
        set({ isLoading: false })
        Swal.fire({
          icon: 'success',
          title: 'Cadastrado com sucesso!',
          text: resp?.data?.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalController(false)
        })
      })
      .catch((err) => {
        Swal.fire({
          icon: 'warning',
          title: 'Erro Ao Copiar Acessos',
          text: err.response?.data?.errors?.ctv_destino_id,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
        set({ isLoading: false })
      })
  },
  modalController: (value) => {
    set({
      controllerModal: value,
    })
  },
  getStateId: async (stateIdParams) => {
    set({ stateId: stateIdParams })
  },
  updateCtvByCity: async (cityId, responsibleCtv) => {
    const { stateId, getCityById } = get()
    set({ isLoading: true })
    await api
      .put(`/country/state/city/${cityId}?ctv_responsavel=${responsibleCtv}`)
      .then(() => {
        set({ isLoading: false })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'CTV atualizado com sucesso!',
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          getCityById(stateId)
        })
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Erro Ao Copiar Acessos',
          text: err.response?.data?.errors.ctv_responsavel,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
        set({ isLoading: false })
      })
  },
  setStatesList: (statesList) => set({ statesList }),
  getCtvByClient: async (clientId) => {
    set({ isLoading: true })
    try {
      const { data: listCtvByClient } = await api.get<ListRequestCtv[]>(`/ctv/client/${clientId}`)
      set({ listCtvByClient })
      return listCtvByClient
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    } finally {
      set({ isLoading: false })
    }
    return []
  },
}))
