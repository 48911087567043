import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  paper: {
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  cardPaper: {
    border: '1px solid rgba(224, 224, 224, 1)',
    margin: '8px 0px',
    display: 'flex',
    padding: '8px 8px',
    minHeight: '170px',
    alignItems: 'center',
    borderRadius: '30px',
    flexDirection: 'row',
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  formStyle: {
    width: '100%',
    margin: '8px 0px 0px 0px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  gridFields: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  textInput: {
    width: '100%',
    color: '#00446B',
    borderRadius: '30px',
    height: '62px',
  },
  buttonActions: {
    gap: '16px',
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonClear: {
    padding: '6px 16px',
  },
  buttonFilter: {
    padding: '6px 16px',
  },
}))
