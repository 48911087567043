import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  iconsFastAccess: {
    fontSize: 100,
    backgroundColor: '#303f9f',
    borderRadius: 90,
    padding: 20,
    color: 'white',
  },
  cardPaper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 300,
    borderRadius: 30,
  },
}))
