import { makeStyles } from '@material-ui/core/styles'

import { backgroundLogin } from '../../assets'

export const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    minHeight: '100%',
    margin: 0,
  },
}))
