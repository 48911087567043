import { ReactElement, useEffect, useState } from 'react'

import { Grid, Paper, Typography } from '@material-ui/core'
import { DataGrid, GridColDef, GridValueGetterParams } from '@material-ui/data-grid'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useFarmStore } from '~/store/farm-store'

import strings from './strings'
import { useStyles } from './styles'

type Props = {
  cliente_id: string
}

const columns: GridColDef[] = [
  {
    field: 'nome',
    headerName: 'Propriedade',
    width: 150,
    flex: 0.3,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params: GridValueGetterParams) => {
      return params.value === '1' ? 'Ativo' : 'Inativo'
    },
  },
]

function DetailFarm(props: Props): ReactElement {
  const classes = useStyles()
  const { cliente_id } = props
  const getFarmsByClientId = useFarmStore((state) => state.getFarmsByClientId)
  const { listFarm, isLoading } = useFarmStore()
  const [ativas, setAtivas] = useState(0)
  const [inativas, setInativas] = useState(0)

  useEffect(() => {
    getFarmsByClientId(cliente_id).then((dados) => {
      dados.forEach((value) => {
        if (value.status === '1') setAtivas((previous) => previous + 1)
        else setInativas((previous) => previous + 1)
      })
    })
  }, [])

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} className={classes.headerProperty}>
          <Grid item xs={3}>
            <Typography variant="h5" align="left">
              {strings.farm}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" style={{ color: 'green' }}>
              {`Ativas: ${ativas}`}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" style={{ color: 'red' }}>
              {`Inativas: ${inativas}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.resumeTable}>
          <DataGrid
            rows={listFarm}
            columns={columns}
            loading={isLoading}
            rowsPerPageOptions={[25, 50, 100]}
            autoPageSize
            autoHeight
            rowHeight={38}
            localeText={GRID_DEFAULT_LOCALE_TEXT}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DetailFarm
