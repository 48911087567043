import { VarietyProps, VorietyTransterInputProps } from '~/types/grow-crops-types'

export function resetFieldMapper(requestType?: VarietyProps) {
  const characteristics = requestType?.product?.characteristics ?? []

  return {
    cultura_id: requestType?.cultura_id,
    genetica_id: requestType?.genetica_id,
    tecnologia: requestType?.tecnologia,
    status: requestType?.status,
    nome: requestType?.nome,
    grupo_maturacao: requestType?.grupo_maturacao,
    raca1: characteristics?.find((item) => item?.caracteristica_detalhe_id === '1')?.reacao,
    raca2: characteristics?.find((item) => item?.caracteristica_detalhe_id === '2')?.reacao,
    raca3: characteristics?.find((item) => item?.caracteristica_detalhe_id === '3')?.reacao,
    raca4: characteristics?.find((item) => item?.caracteristica_detalhe_id === '4')?.reacao,
    raca4mais: characteristics?.find((item) => item?.caracteristica_detalhe_id === '5')?.reacao,
    raca5: characteristics?.find((item) => item?.caracteristica_detalhe_id === '6')?.reacao,
    raca6: characteristics?.find((item) => item?.caracteristica_detalhe_id === '7')?.reacao,
    raca7: characteristics?.find((item) => item?.caracteristica_detalhe_id === '8')?.reacao,
    raca8: characteristics?.find((item) => item?.caracteristica_detalhe_id === '9')?.reacao,
    raca9: characteristics?.find((item) => item?.caracteristica_detalhe_id === '10')?.reacao,
    raca10: characteristics?.find((item) => item?.caracteristica_detalhe_id === '11')?.reacao,
    raca11: characteristics?.find((item) => item?.caracteristica_detalhe_id === '12')?.reacao,
    raca12: characteristics?.find((item) => item?.caracteristica_detalhe_id === '13')?.reacao,
    raca13: characteristics?.find((item) => item?.caracteristica_detalhe_id === '14')?.reacao,
    raca14: characteristics?.find((item) => item?.caracteristica_detalhe_id === '15')?.reacao,
    raca14mais: characteristics?.find((item) => item?.caracteristica_detalhe_id === '16')?.reacao,
    meloidogyneincognita: characteristics?.find((item) => item?.caracteristica_detalhe_id === '17')
      ?.reacao,
    meloidogynejavanica: characteristics?.find((item) => item?.caracteristica_detalhe_id === '18')
      ?.reacao,
    pratylenchusbrachyurus: characteristics?.find(
      (item) => item?.caracteristica_detalhe_id === '19',
    )?.reacao,
    crancrodahaste: characteristics?.find((item) => item?.caracteristica_detalhe_id === '20')
      ?.reacao,
    pustulabacteriana: characteristics?.find((item) => item?.caracteristica_detalhe_id === '21')
      ?.reacao,
    manchaolhodera: characteristics?.find((item) => item?.caracteristica_detalhe_id === '22')
      ?.reacao,
    manchaalvo: characteristics?.find((item) => item?.caracteristica_detalhe_id === '23')?.reacao,
    ferrugemasiatica: characteristics?.find((item) => item?.caracteristica_detalhe_id === '24')
      ?.reacao,
    podridaoradiculardefitoftora: characteristics?.find(
      (item) => item?.caracteristica_detalhe_id === '25',
    )?.reacao,
    mofobranco: characteristics?.find((item) => item?.caracteristica_detalhe_id === '26')?.reacao,
    oidio: characteristics?.find((item) => item?.caracteristica_detalhe_id === '27')?.reacao,
  } as VorietyTransterInputProps
}
