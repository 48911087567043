import * as React from 'react'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Button, FormControl, Grid, MenuItem, Paper, TextField } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import MuiPhoneNumber from 'material-ui-phone-number-2'

import { Cargo, PerfilAdmin, Status, ValidacaoAutomaticaFilter } from '~/constants/data-field'
import { useUserStore } from '~/store/user/user-store'
import { UserFilters } from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

interface QuickSearchToolbar {
  onChangeFilter: ((filters: UserFilters) => void) | undefined
}

const defaultValues = {
  nome: '',
  cpf: '',
  perfil_admin: '',
  email: '',
  celular: '',
  cargo: '',
  status: '',
  validacao_auditoria_automatica: '',
}

const UserCardFilter: React.FC<QuickSearchToolbar> = ({ onChangeFilter }) => {
  const classes = useStyles()
  const { filters, setFilters } = useUserStore()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<UserFilters>({
    defaultValues,
  })

  useEffect(() => {
    if (filters) {
      Object.keys(filters).forEach((key) => {
        setValue(key as keyof UserFilters, filters[key as keyof UserFilters])
      })
    }
  }, [filters, setValue])

  const onSubmit: SubmitHandler<UserFilters> = async (formUserFilters) => {
    setFilters(formUserFilters)

    if (onChangeFilter) {
      onChangeFilter(formUserFilters)
    }
  }

  const resetForm = () => {
    reset(defaultValues)
    setFilters(null)
  }

  return (
    <Paper elevation={0} className={`${classes.paper} ${classes.cardPaper}`}>
      <FormControl variant="outlined" className={classes.formStyle}>
        <Grid item sm={12} className={classes.gridFields} container spacing={2}>
          <Grid item sm={3}>
            <Controller
              name="nome"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="nome"
                  variant="outlined"
                  label="Usuário"
                  placeholder="Nome do usuário"
                  className={classes.textInput}
                  error={!!errors?.nome}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.nome?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="email"
                  variant="outlined"
                  label="E-mail"
                  placeholder="E-mail"
                  error={!!errors?.email}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.email?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="cpf"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  id="cpf"
                  variant="outlined"
                  label="CPF"
                  placeholder="CPF"
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  value={
                    field.value
                      ? maskInterceptor(
                          field?.value?.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj',
                        )(field.value)
                      : ''
                  }
                />
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="celular"
              control={control}
              render={({ field }) => (
                <MuiPhoneNumber
                  {...field}
                  size="small"
                  id="celular"
                  defaultCountry="br"
                  label="Celular"
                  variant="outlined"
                  onlyCountries={['br', 'py']}
                  masks={{ py: '+... ... ......' }}
                  error={!!errors?.email}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.email?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                  InputProps={{
                    style: {
                      borderRadius: 16,
                      width: '100%',
                      color: '#00446B',
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} className={classes.gridFields} container spacing={2}>
          <Grid item sm={3}>
            <Controller
              name="perfil_admin"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="perfil_admin"
                  variant="outlined"
                  label="Perfil Admin"
                  defaultValue=""
                  placeholder="Pefil Admin"
                  error={!!errors?.perfil_admin}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.perfil_admin?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {PerfilAdmin.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.nameValue}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="cargo"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="cargo"
                  variant="outlined"
                  label="Cargo"
                  defaultValue=""
                  placeholder="Cargo"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {Cargo.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.nameValue}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="status"
                  variant="outlined"
                  label="Status"
                  defaultValue=""
                  placeholder="Status"
                  error={!!errors?.status}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.status?.message}
                  className={classes.textInput}
                  value={field.value || ''}
                >
                  {Status.map((Option) => (
                    <MenuItem key={Option.statusValue} value={Option.statusValue}>
                      {Option.statusName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={3}>
            <Controller
              name="validacao_auditoria_automatica"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  size="small"
                  id="validacao_auditoria_automatica"
                  variant="outlined"
                  label="Valida Resultados Automaticamente?"
                  placeholder="validacao_auditoria_automatica"
                  className={classes.textInput}
                  error={!!errors?.validacao_auditoria_automatica}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.validacao_auditoria_automatica?.message}
                >
                  {ValidacaoAutomaticaFilter.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>

        <Grid item sm={12} className={classes.buttonActions}>
          <Button
            id="button-limpar-form"
            variant="contained"
            color="secondary"
            className={classes.buttonClear}
            onClick={resetForm}
            endIcon={<ClearIcon />}
          >
            {strings.buttons.clear}
          </Button>
          <Button
            id="button-filtrar-form"
            type="submit"
            variant="contained"
            color="primary"
            className={classes.buttonFilter}
            endIcon={<SearchIcon />}
            onClick={handleSubmit(onSubmit)}
          >
            {strings.buttons.send}
          </Button>
        </Grid>
      </FormControl>
    </Paper>
  )
}

export default UserCardFilter
