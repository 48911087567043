import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  grid: {
    borderRadius: '50px 0',
    right: 10,
    margin: theme.spacing(3),
  },

  paper: {
    margin: theme.spacing(8, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(6),
  },

  logoItelligence: {
    width: '60%',
    marginBottom: '15px',
  },

  forgotPassword: {
    textAlign: 'right',
    alignSelf: 'center',
  },

  iconForgotPassword: {
    verticalAlign: 'top',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '70%',
    borderRadius: '17px',
    height: '45px',
  },

  submitLogin: {
    backgroundColor: 'rgb(23,58,88)',
    border: '1px solid rgb(23,58,88)',
  },

  alert: {
    width: '50%',
  },
}))
