import React, { useState, useCallback } from 'react'

import { Button, Collapse, LinearProgress, TableCell, TableRow } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { StateCodes } from '~/enums/enum'
import { userAcessCtv } from '~/store/acesso-ctv-store'
import { RequestStateList } from '~/types/state-types'

import { TableCtv } from '../table-ctv'
import strings from './strings'
import { useStyles } from './styles'

interface ItensRowProps {
  item: RequestStateList
  listCityForState(stateId: number): Promise<void>
}

export const ItensRow: React.FC<ItensRowProps> = ({ item, listCityForState }) => {
  const classes = useStyles()

  const { isLoading, getStateId } = userAcessCtv()
  const [selectStateId, setSelectStateId] = useState<number | undefined>()

  const handleOpenCollapse = useCallback(
    (stateId: number) => {
      getStateId(stateId)
      setSelectStateId((previous) => (previous === stateId ? undefined : stateId))
      if (!selectStateId) {
        listCityForState(stateId)
      }
    },
    [selectStateId],
  )

  return (
    <>
      <TableRow id={`state-tr-${item.id}`}>
        <TableCell align="center" id={`state-td-describe-${item.id}`}>
          {item.descricao}
        </TableCell>
        <TableCell align="center" id={`state-td-initials-${item.id}`}>
          {item.sigla}
        </TableCell>
        <TableCell align="center" id={`state-td-county-${item.id}`}>
          {StateCodes[item.pais_id]}
        </TableCell>
        <TableCell align="center" id={`state-td-country-initials-${item.id}`}>
          {item.pais_id === '1' ? 'BR' : 'PY'}
        </TableCell>
        <TableCell align="center" id={`state-td-${item.id}`}>
          <Button
            variant="contained"
            onClick={() => handleOpenCollapse(item.id)}
            className={classes.buttonTable}
            endIcon={
              selectStateId === item.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
          >
            {strings.tableHeader.counties}
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={5} className={classes.tableCellStye}>
          <Collapse in={selectStateId === item.id} style={{ display: 'block' }} unmountOnExit>
            {isLoading ? <LinearProgress /> : <TableCtv dataCities={item.cities} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
