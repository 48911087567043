import { ReactElement, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem, TextField, Divider, CircularProgress } from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import * as yup from 'yup'

import TransitionsModal from '~/components/modal'
import { useAlert } from '~/hooks/message'
import { clientUpdateMapper } from '~/mappers/client-mapper'
import { useCepStore } from '~/store/cep-store'
import { useCityStore } from '~/store/city-store'
import { useClientStore } from '~/store/client/client-store'
import { useStateStore } from '~/store/state-store'
import { ClientProps } from '~/types/client-types'
import { FormatCep } from '~/utils'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './styles'

const schema = yup.object().shape({
  documento: yup.string().required('Campo obrigatório'),
  nome: yup.string().required('Campo obrigatório'),
  email: yup.string().required('Campo obrigatório'),
  celular: yup.string().when('pais_id', {
    is: (value: any) => value === '1' ?? value,
    then: yup.string().required('CPF é obrigatório'),
    otherwise: yup.string().nullable(),
  }),
  status: yup.string().required('Campo obrigatório'),
})

type Props = {
  open: boolean
  onClose: () => void
  cliente_id: number
}

function EditClient(props: Props): ReactElement {
  const classes = useStyles()
  const alert = useAlert()
  const { open, onClose } = props
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])

  const updateClient = useClientStore((state) => state.update)
  const { messageError, isLoading, client } = useClientStore()
  const { listCitiesByStateId } = useCityStore()
  const { listStates } = useStateStore()
  const { find: findCepStore, isLoading: isLoadingCep } = useCepStore()

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<ClientProps>({
    resolver: yupResolver(schema),
    defaultValues: client,
  })

  useEffect(() => {
    setCities([])
    listStates().then((data) => setStates(data))

    if (client?.city?.state?.descricao)
      listCitiesByStateId(client?.city?.state?.id).then((data) => setCities(data.cidades))
    setValue('premium', 0)
    reset({ ...client, celular: `${client?.pais_celular}${client?.celular}` })
  }, [open])

  const onSubmit: SubmitHandler<ClientProps> = async (clientForm) => {
    const editFieldClient = clientUpdateMapper(clientForm)
    updateClient({ ...editFieldClient, cidade_id: clientForm.city?.id }, editFieldClient.id)
  }

  const findCep = async (cep: string) => {
    const viacep = await findCepStore(cep.replace('-', ''))

    if (viacep.erro) {
      alert.show({ message: 'CEP não encontrado!', type: 'error' })
      return
    }

    setValue('bairro', viacep.bairro, { shouldDirty: true })
    setValue('rua', viacep.logradouro, { shouldDirty: true })

    const state = states.find(({ sigla }) => sigla === viacep.uf)

    if (state) {
      setValue('city.state', state, { shouldDirty: true })

      listCitiesByStateId(getValues('city.state')?.id).then((data) => {
        setCities(data.cidades)
        setValue(
          'city',
          data?.cidades?.find(
            (value: any) =>
              value.descricao === viacep.localidade.normalize('NFD').replace(/\p{Mn}/gu, ''),
          ),
          { shouldDirty: true },
        )
      })
    }
  }

  return (
    <TransitionsModal
      open={open}
      onClose={onClose}
      onSave={handleSubmit(onSubmit)}
      title={strings.title}
      save
      disableCloseClickOut
      isLoad={isLoading}
    >
      <Grid container xs className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.forgotPassword} spacing={2}>
            <Controller
              name="nome"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="textfield-editar-cliente"
                  label="Nome"
                  variant="outlined"
                  required
                  helperText={errors.nome?.message || messageError?.nome}
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="documento"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="textfield-editar-cliente"
                  variant="outlined"
                  required
                  label={strings.document}
                  helperText={errors.documento?.message || messageError?.documento}
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  value={
                    field.value
                      ? maskInterceptor(
                          field?.value?.replace(/\D/g, '').length <= 11 ? 'cpf' : 'cnpj',
                        )(field.value)
                      : ''
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="textfield-editar-cliente"
                  variant="outlined"
                  label={strings.mail}
                  placeholder={strings.mail}
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.email?.message || messageError?.email}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="celular"
              control={control}
              render={({ field }) => (
                <MuiPhoneNumber
                  {...field}
                  required
                  id="textfield-editar-cliente"
                  label={strings.phone}
                  defaultCountry="br"
                  masks={{ py: '+... ... ......' }}
                  variant="outlined"
                  onlyCountries={['br', 'py']}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={
                    errors.celular?.message || messageError?.celular || messageError?.pais_celular
                  }
                  InputProps={{
                    style: {
                      borderRadius: 30,
                      width: '100%',
                    },
                  }}
                  className={classes.textInput}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  required
                  id="textfield-editar-cliente"
                  variant="outlined"
                  label={strings.status}
                  placeholder="Selecione uma opção"
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.status?.message || messageError?.status}
                >
                  <MenuItem key={0} value={0}>
                    Inativo
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Ativo
                  </MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="premium"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  required
                  id="textfield-editar-cliente"
                  variant="outlined"
                  label={strings.premium}
                  placeholder="Selecione uma opção"
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.premium?.message || messageError?.premium}
                >
                  <MenuItem key={0} value={0}>
                    Não
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Sim
                  </MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs>
            <Controller
              name="cep"
              control={control}
              render={({ field: { onChange, ...field } }) => (
                <TextField
                  {...field}
                  required
                  id="textfield-editar-cliente"
                  variant="outlined"
                  label={strings.cep}
                  placeholder={strings.cep}
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  InputProps={{
                    endAdornment: <>{isLoadingCep ? <CircularProgress size={15} /> : ''}</>,
                  }}
                  onChange={(value) => {
                    if (value.target.value.length === 9) findCep(value.target.value)
                    return onChange(value)
                  }}
                  helperText={errors.cep?.message || messageError?.cep}
                  value={field.value ? FormatCep(field.value) : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="rua"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="textfield-editar-cliente"
                  variant="outlined"
                  label={strings.street}
                  placeholder={strings.street}
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.status?.message || messageError?.rua}
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <Controller
              name="numero"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="textfield-editar-cliente"
                  variant="outlined"
                  label={strings.houseNumber}
                  placeholder={strings.houseNumber}
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.numero?.message || messageError?.numero}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="bairro"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  id="textfield-editar-cliente"
                  variant="outlined"
                  label={strings.district}
                  placeholder={strings.district}
                  className={classes.textInput}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  helperText={errors.bairro?.message || messageError?.bairro}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="city.state"
              control={control}
              render={({ field }) => (
                <Autocomplete<any>
                  {...field}
                  getOptionLabel={(option) => option?.descricao}
                  options={states}
                  id="textfield-editar-cliente"
                  onChange={(e, value) => {
                    setValue('city.state', value)
                    if (value?.id) {
                      setCities([])
                      setValue('city', { descricao: '' })
                      listCitiesByStateId(value.id).then((data) => setCities(data.cidades))
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      label="Selecione o Estado"
                      placeholder="Selecione o Estado"
                      className={classes.textInput}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Autocomplete<any>
                  {...field}
                  id="textfield-editar-cliente"
                  getOptionLabel={(option) => option?.descricao || ''} // Certifique-se de retornar uma string válida
                  options={cities}
                  isOptionEqualToValue={(option, value) => option.title === value.descricao}
                  onChange={(e, value) => setValue('city', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      defaultValue={5200605}
                      variant="outlined"
                      label="Selecione a Cidade"
                      placeholder="Selecione a Cidade"
                      className={classes.textInput}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      helperText={errors.city?.message || messageError?.cidade_id}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </TransitionsModal>
  )
}

export default EditClient
