import React, { createContext, useCallback, useState } from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ptBR } from '@material-ui/core/locale'
import { Theme, ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import darkTheme from './dark-theme'
import lightTheme from './light-theme'

interface ThemeContextData {
  theme: Theme
  toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData)

const initialTheme = lightTheme

export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(() => createTheme(initialTheme, ptBR))

  const toggleTheme = useCallback(() => {
    setTheme((oldTheme) =>
      createTheme(oldTheme?.palette.type === 'light' ? darkTheme : lightTheme, ptBR),
    )
  }, [])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <div style={{ height: '100vh' }}>
          <CssBaseline />
          <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
        </div>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = (): ThemeContextData => {
  return React.useContext(ThemeContext)
}
