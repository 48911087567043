import { Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import { useMessageStore } from '~/store/message-store'

export const Message = () => {
  const { message, timeDuration, type, open } = useMessageStore()
  const close = useMessageStore((state) => state.close)

  return (
    <>
      {message && (
        <Snackbar
          open={open}
          autoHideDuration={timeDuration}
          onClose={close}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ position: 'fixed', zIndex: 1300 }}
        >
          <Alert onClose={close} severity={type}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
