/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, MenuItem, InputLabel, Select, TextField, FormHelperText } from '@material-ui/core'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import TransitionsModal from '~/components/modal'
import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'
import { useStoreMissions } from '~/store/missions-store'

import { useStyles } from './styles'

type FormState = {
  missao_id: number
  tipo_fonte?: string
  nome?: string
  meio_interacao?: number
  observacao?: string
  tipo_safra: number
}

const schema = yup.object().shape({
  missao_id: yup.number().required('Campo obrigatório'),
  meio_interacao: yup.number().required('Campo obrigatório'),
  tipo_fonte: yup.string().required('Campo obrigatório'),
  nome: yup.string().required('Campo obrigatório'),
  observacao: yup.string().required('Campo obrigatório'),
  tipo_safra: yup.string().required('Campo obrigatório'),
})

type Props = {
  open: boolean
  onClose: () => void
  cliente_id: string
  setRealoadAuxiliary: (count: any) => void
}

function AddInteraction(props: Props): ReactElement {
  const { open, onClose, cliente_id, setRealoadAuxiliary } = props
  const { missions } = useStoreMissions()
  const alert = useAlert()
  const [selectedMissionId, setSelectedMissionId] = useState<number | undefined>(undefined)
  const [selectedTipoSafra, setSelectedTipoSafra] = useState<number | undefined>(undefined)
  const [selectedMeioInteracao, setSelectedMeioInteracao] = useState<number | undefined>(undefined)

  const { handleSubmit, control, formState, reset, watch } = useForm<FormState>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    watch((value) => {
      setSelectedMissionId(value.missao_id)
      setSelectedTipoSafra(value.tipo_safra)
      setSelectedMeioInteracao(value.meio_interacao)
    })
  }, [watch])

  const { errors } = formState
  const classes = useStyles()

  const onSubmit = (data: FormState) => {
    const { missao_id } = data

    const { cultura_id, safra_id } = missions.find((mission) => mission.id === missao_id)!
      .mission_detail[0]

    api
      .post('/interaction/auxiliary', {
        ...data,
        data_interacao: new Date(),
        cultura_id,
        safra_id,
        cliente_id,
      })
      .then(() => {
        alert.show({ message: 'Inserido com sucesso!', type: 'success' })
        setRealoadAuxiliary((previous: any) => previous + 1)
        reset()
        onClose()
      })
      .catch((error: AxiosError) =>
        alert.show({ message: error.response?.data.message, type: 'error' }),
      )
  }

  return (
    <TransitionsModal
      open={open}
      onClose={onClose}
      onSave={handleSubmit(onSubmit)}
      title="Adicionar dados"
      save
      disableCloseClickOut
    >
      <Grid container spacing={2} className={classes.paper}>
        <Grid item xs={6} className={classes.forgotPassword}>
          <Controller
            name="missao_id"
            control={control}
            render={({ field }) => (
              <Grid item xs={12}>
                <InputLabel id="missao_id">Missão</InputLabel>
                <Select
                  labelId="missao_id"
                  id="missao_id"
                  {...field}
                  value={selectedMissionId ?? ''}
                  onChange={(event) => {
                    setSelectedMissionId(event.target.value as number)
                    field.onChange(event)
                  }}
                  className={classes.selectInput}
                  required
                >
                  {missions.map(({ id, titulo }: any) => (
                    <MenuItem value={id} key={id}>
                      {titulo}
                    </MenuItem>
                  ))}
                </Select>
                {errors.missao_id?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.missao_id?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={6} className={classes.forgotPassword}>
          <Controller
            name="tipo_safra"
            control={control}
            defaultValue={undefined}
            render={({ field }) => (
              <Grid item>
                <InputLabel id="tipo_safra">Tipo de Safra</InputLabel>
                <Select
                  labelId="tipo_safra"
                  id="tipo_safra"
                  className={classes.selectInteration}
                  required
                  {...field}
                  value={selectedTipoSafra ?? ''}
                  onChange={(event) => {
                    const newValue = event.target.value as number
                    setSelectedTipoSafra(newValue)
                    field.onChange(newValue)
                  }}
                >
                  <MenuItem value={1}>1º Safra</MenuItem>
                  <MenuItem value={2}>2º Safra</MenuItem>
                </Select>

                {errors.tipo_safra?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.tipo_safra?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>

        <Grid item xs={3} className={classes.forgotPassword}>
          <Controller
            name="nome"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                id="standard-basic"
                label="Nome"
                {...field}
                helperText={errors.nome?.message}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} className={classes.forgotPassword}>
          <Controller
            name="tipo_fonte"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Grid>
                <InputLabel id="tipo_fonte">Tipo de Fonte</InputLabel>
                <Select
                  labelId="tipo_fonte"
                  id="tipo_fonte"
                  className={classes.selectInteration}
                  required
                  {...field}
                >
                  <MenuItem value={1}>AgPro</MenuItem>
                  <MenuItem value={2}>Consultor</MenuItem>
                  <MenuItem value={3}>CTV</MenuItem>
                  <MenuItem value={4}>Diretor</MenuItem>
                  <MenuItem value={5}>Gerente</MenuItem>
                  <MenuItem value={6}>RCA</MenuItem>
                </Select>
                {errors.tipo_fonte?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.tipo_fonte?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={3} className={classes.forgotPassword}>
          <Controller
            name="meio_interacao"
            control={control}
            defaultValue={undefined}
            render={({ field }) => (
              <Grid>
                <InputLabel id="meio_interacao">Meio de Interação</InputLabel>

                <Select
                  labelId="meio_interacao"
                  id="meio_interacao"
                  className={classes.selectInteration}
                  required
                  {...field}
                  value={selectedMeioInteracao ?? ''}
                  onChange={(event) => {
                    const newValue = event.target.value as number
                    setSelectedMeioInteracao(newValue)
                    field.onChange(newValue)
                  }}
                >
                  <MenuItem value={1}>WhatsApp</MenuItem>
                  <MenuItem value={2}>Telefone</MenuItem>
                  <MenuItem value={2}>Email</MenuItem>
                </Select>

                {errors.meio_interacao?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.meio_interacao?.message}
                  </FormHelperText>
                )}
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridObservation}>
          <Controller
            name="observacao"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Grid item xs={12}>
                <TextField
                  id="observacao"
                  label="Observação"
                  multiline
                  rows={4}
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                  helperText={errors.observacao?.message}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                  {...field}
                />
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    </TransitionsModal>
  )
}

export default AddInteraction
