import React from 'react'
import { Link } from 'react-router-dom'

import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Button,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { AppRoutePath } from '~/constants/paths'
import { useValidationStore } from '~/store/validation/validation-store'
import { maskInterceptor } from '~/utils/mask-interceptor'

import string from './strings'
import { useStyles } from './styles'

export const ClientDetails: React.FC = () => {
  const classes = useStyles()
  const { client } = useValidationStore()
  return (
    <Grid item xs={12} sm={12} container>
      <Paper variant="outlined" className={`${classes.paper}`}>
        <Grid item xs={12} sm={12} container spacing={2}>
          <Grid item xs container justifyContent="flex-start" alignContent="center">
            <Grid item sm={3}>
              <Typography variant="h5" className={classes.typography}>
                {string.title}
              </Typography>
            </Grid>

            <Link
              to={`${AppRoutePath.CLIENTDETAIL.replace(':cliente_id', client?.cliente_id ?? '')}`}
            >
              <Button variant="contained" className={classes.buttonVisably}>
                <VisibilityIcon />
              </Button>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} container justifyContent="space-between" alignItems="center">
            <Grid item sm={3}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={string.document}
                    secondary={
                      client?.cpf_cnpj_agricultor.length === 11
                        ? maskInterceptor('cpf')(client?.cpf_cnpj_agricultor || '')
                        : maskInterceptor('cnpj')(client?.cpf_cnpj_agricultor || '')
                    }
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item sm={3}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={string.email}
                    secondary={client?.email}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item sm={3}>
              <List>
                <ListItem>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secundary,
                    }}
                    primary={string.celular}
                    secondary={maskInterceptor('phone')(client?.celular_agricultor || '')}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item sm={3}>
              <List>
                <ListItem>
                  <Grid container direction="column" style={{ maxWidth: '120px' }}>
                    <ListItemText
                      classes={{
                        primary: classes.primary,
                        secondary: classes.secundary,
                      }}
                      primary={string.situacao}
                    />
                    <Chip
                      label={client?.maior_situacao === '1' || '0' ? 'Ativo' : 'Inativo'}
                      style={
                        '1' || '0'
                          ? { backgroundColor: '#24d2b5', color: '#fff' }
                          : { backgroundColor: '#ff5c6c', color: '#fff' }
                      }
                    />
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
