import { ThemeOptions } from '@material-ui/core/styles'

import commonTheme from './common-theme'

const lightTheme = {
  ...commonTheme,
  palette: {
    type: 'light',
    primary: {
      main: '#00446B',
    },
    secondary: {
      main: '#737373',
      lighter: '#ECEFF1',
    },
    text: {
      primary: '#00446B',
      secondary: '#2C3242',
      light: '#ffffff',
    },
  },
  shape: {
    borderRadius: 16,
  },
} as ThemeOptions

export default lightTheme
