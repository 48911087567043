const strings = {
  tableHeader: {
    state: 'Estado',
    initials: 'Sigla',
    country: 'País',
    initialsCountry: 'Sigla País',
    counties: 'Munícipios',
  },
}

export default strings
