import React, { useEffect } from 'react'

interface Props {
  title: string
}

const TitlePage: React.FC<Props> = ({ title, children }) => {
  useEffect(() => {
    document.title = title
  }, [title])

  return <>{children}</>
}

export default TitlePage
