/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
import Swal from 'sweetalert2'
import create, { GetState, SetState } from 'zustand'

import {
  fieldControllerDataMapper,
  submitValidation,
  transformHectareByResult,
  validationDataMapper,
  validationSummaryMapper,
} from '~/mappers/validation-mapper'
import { api } from '~/services/api'
import {
  IAssistantInteraction,
  IAssistantInteractionSubmitMapper,
  ICreateInteractionCodeSubmit,
  IFarmerInteraction,
  IFarmerInteractionSubmitMapper,
  IFieldController,
  IHectareByResult,
  IMapperSubmitCulture,
  IRefSubmitMapper,
  IRefs,
  IRefsInteraction,
  IRefsInteractionSubmitMapper,
  IResponseInteraction,
  IResultAproved,
  IResultDesapproved,
  IResultIncomplete,
  IResultSafras,
  IResultSubmit,
  ISubmitFieldControllerTransformer,
  ITalhao,
  IValidation,
  IValidationData,
  IValidationFilters,
  IValidationHistory,
  IValidationSumary,
} from '~/types/validation-types'

type ValidationState = {
  isLoading: boolean
  messageError: any
  refs: IRefs[]
  isLoadingRemoveResultState: boolean
  history: IValidationHistory[]
  SafraField: IResultSafras[]
  assistantInteraction: IAssistantInteraction[]
  refsInteraction: IRefsInteraction[]
  farmerInteraction: IFarmerInteraction[]
  validationList: IValidation[]
  modifiedCityList: IValidation[]
  validationData: IValidationData[]
  validationSumary: IValidationSumary[]
  aba: number
  data: any
  isLoadingActions: boolean
  controllerModal: boolean
  isLoadingInteraction: boolean
  client?: IValidation
  resultSafras: IResultSafras[]
  HectareByResult: []
  talhao: ITalhao[]
  showField: boolean
  isLoadingButton: boolean
  errorsUsers: boolean
  isLoadingHistory: boolean
  fieldControllers: IFieldController[]
  hectareInfo: IHectareByResult
  filters: IValidationFilters | null
}

type ValidationActions = {
  listValidation: () => Promise<void>
  clearForms: () => void
  setNavigation: (aba: number) => void
  setShowField: (state: boolean) => void
  setGettersData: (data: any) => void
  setHectareInfo: (hectareInfo: IHectareByResult) => void
  findClientById: (validationData?: IValidation[], clientId?: string) => void
  getResultsBySafra: (clientId?: string) => Promise<void>
  getValidationData: (payload: IResultSubmit, clientId?: string) => Promise<void>
  getHectareByResult: (payload: IResultSubmit, clientId?: string) => Promise<any[]>
  getFieldController: (payload: IMapperSubmitCulture, clientId?: string) => Promise<void>
  getValidationSumary: (payload: IResultSubmit, clientId?: string) => Promise<void>
  getFarmerInteraction: (payload: IMapperSubmitCulture, clientId?: string) => Promise<void>
  getAssistantInteraction: (payload: IMapperSubmitCulture, clientId?: string) => Promise<void>
  getRefsInteraction: (payload: IMapperSubmitCulture, clientId?: string) => Promise<void>
  getRefs: (clientId?: string) => Promise<void>
  setSafraField: (safras: any) => void
  createNewFieldController: (
    payload: ISubmitFieldControllerTransformer,
    clientId?: string,
  ) => Promise<void>
  deleteResults: (resultsId: any) => Promise<void>
  disapprovedResults: (payload: IResultDesapproved) => Promise<void>
  aprovedResults: (payload: IResultAproved) => Promise<void>
  incompleteResults: (payload: IResultIncomplete) => Promise<void>
  createInteractionCode: (payload: ICreateInteractionCodeSubmit) => Promise<IResponseInteraction>
  createInteractionFarmer: (payload: IFarmerInteractionSubmitMapper) => Promise<void>
  createRefs: (payload: IRefSubmitMapper) => Promise<void>
  createInteractionRefs: (payload: IRefsInteractionSubmitMapper) => Promise<void>
  createInteractionAssistence: (payload: IAssistantInteractionSubmitMapper) => Promise<void>
  getHistory: (payload: IValidationData) => Promise<IValidationHistory>
  refreshSummaryData: () => Promise<void>
  refreshHectareInfo: () => Promise<void>
  setFilters: (filters: IValidationFilters | null) => void
}

type State = ValidationState & ValidationActions

const initialState: ValidationState = {
  isLoading: false,
  isLoadingActions: false,
  isLoadingHistory: false,
  showField: false,
  data: undefined,
  isLoadingRemoveResultState: false,
  history: [],
  isLoadingInteraction: false,
  SafraField: [],
  isLoadingButton: false,
  errorsUsers: false,
  controllerModal: false,
  talhao: [],
  refs: [],
  refsInteraction: [],
  assistantInteraction: [],
  farmerInteraction: [],
  validationSumary: [],
  messageError: [],
  validationList: [],
  modifiedCityList: [],
  aba: 0,
  HectareByResult: [],
  fieldControllers: [],
  resultSafras: [],
  client: {
    celular_agricultor: '',
    cliente_nome: '',
    cpf_cnpj_agricultor: '',
    descricao_alterado_por: '',
    descricao_situacao: '',
    email: '',
    maior_situacao: '',
    qtde_total: '',
    ultima_data_envio_validacao: '',
    cliente_id: '',
  },
  hectareInfo: {
    aprovado: '0.00',
    em_validacao: '0.00',
    inserido: '0.00',
    reprovado: '0.00',
  },
  validationData: [],
  filters: null,
}

export const useValidationStore = create<State>((set: SetState<State>, get: GetState<State>) => ({
  ...initialState,
  setFilters: (filters) => set({ filters }),
  listValidation: async () => {
    try {
      const { data } = await api.get('validation')
      set({
        validationList: data,
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Opss, Algo erro acontenceu!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    }
  },
  clearForms: () => {
    set({
      messageError: '',
      errorsUsers: false,
    })
  },

  setShowField: (state) => {
    set({
      showField: state,
    })
  },
  setGettersData: (data) => {
    set({
      data,
    })
  },
  createInteractionAssistence: async (payload) => {
    const {
      getFarmerInteraction,
      getFieldController,
      getValidationData,
      getValidationSumary,
      getAssistantInteraction,
      getRefsInteraction,
      getRefs,
      data,
      client,
    } = get()
    await api
      .post(`validation/interaction/auxiliary`, payload)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(async () => {
          await Promise.all([
            getFieldController(fieldControllerDataMapper(data), client?.cliente_id),
            getValidationData(validationDataMapper(data), client?.cliente_id),
            getValidationSumary(validationSummaryMapper(data), client?.cliente_id),
            getAssistantInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getFarmerInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefsInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefs(client?.cliente_id),
          ])
        })
      })
      .catch((error: any) => {
        set({
          isLoading: false,
          errorsUsers: true,
          messageError: error.response?.data?.errors,
        })
      })
  },
  createInteractionRefs: async (payload) => {
    const {
      client,
      data,
      getRefsInteraction,
      getFarmerInteraction,
      getFieldController,
      getValidationData,
      getValidationSumary,
      getAssistantInteraction,
      getRefs,
    } = get()
    await api
      .post(`validation/interaction/reference`, payload)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(async () => {
          await Promise.all([
            getFieldController(fieldControllerDataMapper(data), client?.cliente_id),
            getValidationData(validationDataMapper(data), client?.cliente_id),
            getValidationSumary(validationSummaryMapper(data), client?.cliente_id),
            getAssistantInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getFarmerInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefsInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefs(client?.cliente_id),
          ])
        })
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errorsUsers: true,
        })
      })
  },
  createRefs: async (payload) => {
    const {
      getFarmerInteraction,
      getFieldController,
      getValidationData,
      getValidationSumary,
      getAssistantInteraction,
      getRefsInteraction,
      data,
      client,
      getRefs,
    } = get()
    await api
      .post(`validation/reference/`, payload)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(async () => {
          await Promise.all([
            getRefs(client?.cliente_id),
            getFieldController(fieldControllerDataMapper(data), client?.cliente_id),
            getValidationData(validationDataMapper(data), client?.cliente_id),
            getValidationSumary(validationSummaryMapper(data), client?.cliente_id),
            getAssistantInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getFarmerInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefsInteraction(fieldControllerDataMapper(data), client?.cliente_id),
          ])
        })
      })
      .catch((error: any) => {
        set({
          isLoading: false,
          errorsUsers: true,
          messageError: error.response?.data?.errors,
        })
      })
  },
  createInteractionFarmer: async (payload) => {
    const {
      getFarmerInteraction,
      getFieldController,
      getValidationData,
      getValidationSumary,
      getAssistantInteraction,
      getRefsInteraction,
      getRefs,
      data,
      client,
    } = get()
    await api
      .post(`validation/interaction/farmer`, payload)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(async () => {
          await Promise.all([
            getFieldController(fieldControllerDataMapper(data), client?.cliente_id),
            getValidationData(validationDataMapper(data), client?.cliente_id),
            getValidationSumary(validationSummaryMapper(data), client?.cliente_id),
            getAssistantInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getFarmerInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefsInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefs(client?.cliente_id),
          ])
        })
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errorsUsers: true,
        })
      })
  },
  // eslint-disable-next-line consistent-return
  createInteractionCode: async (payload) => {
    try {
      set({
        isLoadingInteraction: true,
      })
      const { data } = await api.post(`validation/interaction/`, {
        meio_interacao: payload.meio_interacao,
        data_interacao: payload.data_interacao,
        tipo_fonte: payload.tipo_fonte,
      })
      set({
        showField: true,
        isLoadingInteraction: false,
      })
      return data
    } catch (error: any) {
      set({
        isLoadingInteraction: false,
        messageError: error?.response?.data?.message,
      })
      Swal.fire({
        icon: 'error',
        title: 'Opps Ao aconteceu de errado!',
        text: error?.response?.data?.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    }
  },
  disapprovedResults: async (payload) => {
    const { refreshSummaryData } = get()
    set({
      isLoadingActions: true,
    })
    await api
      .put(`validation/summary/disapprove`, {
        cliente_id: payload.cliente_id,
        safra_id: payload.safra_id,
        cultura_id: payload.cultura_id,
        tipo_safra: payload.tipoSafra,
        motivo_reprovacao: payload.motivo_reprovacao,
        descricao_reprovacao: payload.descricao_reprovacao,
      })
      .then((resp) => {
        set({
          isLoadingActions: false,
          messageError: '',
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => refreshSummaryData())
      })
      .catch((error: any) => {
        set({
          isLoadingActions: false,
          errorsUsers: true,
        })
        Swal.fire({
          icon: 'error',
          title: 'Opps Ao aconteceu de errado!',
          text: error.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      })
  },
  getHistory: async (payload) => {
    set({
      isLoadingHistory: true,
    })
    try {
      const { data } = await api.get(`validation/change-history`, {
        params: {
          cultura_id: Number(payload.cultura_id),
          fazenda_id: Number(payload.fazenda_id),
          safra_id: Number(payload.safra_id),
          tipo_safra: Number(payload.tipo_safra),
        },
      })
      set({
        isLoadingHistory: false,
      })
      return data
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  aprovedResults: async (payload) => {
    const { refreshSummaryData } = get()
    set({
      isLoadingActions: true,
    })
    await api
      .put(`validation/summary/approve`, {
        cliente_id: payload.cliente_id,
        safra_id: payload.safra_id,
        cultura_id: payload.cultura_id,
        tipo_safra: payload.tipoSafra,
      })
      .then((resp) => {
        set({
          isLoadingActions: false,
          messageError: '',
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => refreshSummaryData())
      })
      .catch((error: any) => {
        set({
          isLoadingActions: false,
          errorsUsers: true,
        })
        Swal.fire({
          icon: 'error',
          title: 'Opps Ao aconteceu de errado!',
          text: error.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      })
  },
  incompleteResults: async (payload) => {
    const { refreshSummaryData } = get()

    set({
      isLoadingActions: true,
    })
    await api
      .put(`validation/summary/incomplete`, {
        cliente_id: payload.cliente_id,
        safra_id: payload.safra_id,
        cultura_id: payload.cultura_id,
        tipo_safra: payload.tipoSafra,
        descricao_incompleto: payload.descricao_incompleto,
      })
      .then((resp) => {
        set({
          isLoadingActions: false,
          messageError: '',
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => refreshSummaryData())
      })
      .catch((error: any) => {
        set({
          isLoadingActions: false,
          errorsUsers: true,
        })
        Swal.fire({
          icon: 'error',
          title: 'Opps Ao aconteceu de errado!',
          text: error.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      })
  },
  deleteResults: async (resultsId) => {
    set(() => ({
      isLoadingRemoveResultState: true,
      messageError: '',
      errorsUsers: false,
    }))
    const { getValidationData, data, client } = get()
    await api
      .delete(`validation`, {
        params: {
          resultados_id: resultsId,
        },
      })
      .then((resp) => {
        set(() => ({
          isLoading: false,
          messageError: '',
          errorsUsers: false,
          isLoadingRemoveResultState: false,
        }))
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(async () => {
          getValidationData(validationDataMapper(data), client?.cliente_id)
        })
      })
      .catch((error: any) => {
        set({
          isLoadingRemoveResultState: false,
          isLoading: false,
          errorsUsers: true,
        })
        Swal.fire({
          icon: 'error',
          title: 'Opps Ao aconteceu de errado!',
          text: error.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        })
      })
  },
  createNewFieldController: async (payload) => {
    const {
      getFieldController,
      getValidationData,
      getValidationSumary,
      getFarmerInteraction,
      getRefsInteraction,
      getRefs,
      data,
      client,
      getAssistantInteraction,
    } = get()
    set({
      isLoadingButton: true,
    })
    await api
      .post('validation/contact', payload)
      .then((resp) => {
        set(() => ({
          isLoadingButton: false,
          messageError: '',
          errorsUsers: false,
        }))
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(async () => {
          await Promise.all([
            getFieldController(fieldControllerDataMapper(data), client?.cliente_id),
            getValidationData(validationDataMapper(data), client?.cliente_id),
            getValidationSumary(validationSummaryMapper(data), client?.cliente_id),
            getAssistantInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getFarmerInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefsInteraction(fieldControllerDataMapper(data), client?.cliente_id),
            getRefs(client?.cliente_id),
          ])
        })
      })
      .catch((error: any) => {
        set({
          isLoadingButton: false,
          errorsUsers: true,
          messageError: error.response?.data?.errors,
        })
      })
  },
  getRefsInteraction: async (payload, clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/interaction/reference/${clientId}`, {
        params: {
          cultura: payload.cultura,
        },
      })
      set({
        refsInteraction: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  setSafraField: (safras) => {
    set({
      SafraField: safras,
    })
  },
  getRefs: async (clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/reference/${clientId}`)
      set({
        refs: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },

  getAssistantInteraction: async (payload, clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/interaction/auxiliary/${clientId}`, {
        params: {
          cultura: payload.cultura,
        },
      })
      set({
        assistantInteraction: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  getValidationData: async (payload, clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/details/${clientId}`, {
        params: {
          situacao: payload.situacao,
          cultura: payload.cultura,
        },
      })
      set({
        validationData: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  getFarmerInteraction: async (payload, clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/interaction/farmer/${clientId}`, {
        params: {
          cultura: payload.cultura,
        },
      })
      set({
        farmerInteraction: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  getValidationSumary: async (payload, clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/summary/${clientId}`, {
        params: {
          situacao: payload.situacao,
          cultura: payload.cultura,
        },
      })
      set({
        validationSumary: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  getFieldController: async (payload, clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/contact/${clientId}`, {
        params: {
          cultura: payload.cultura,
        },
      })
      set({
        fieldControllers: data,
        isLoading: false,
      })
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  setHectareInfo: (hectareInfo) => {
    set({
      hectareInfo,
    })
  },
  // eslint-disable-next-line consistent-return
  getHectareByResult: async (payload, clientId) => {
    set({
      isLoading: true,
    })
    try {
      const { data } = await api.get(`validation/hectar`, {
        params: {
          situacao: payload.situacao,
          cliente_id: clientId,
          cultura: payload.cultura,
        },
      })
      set({
        isLoading: false,
      })
      return data
    } catch (error: any) {
      set({
        isLoading: false,
        messageError: error?.response?.data?.message,
      })
    }
  },
  getResultsBySafra: async (clientId) => {
    const { data } = await api.get(`validation/harvest-filter/${clientId}`)
    set({
      resultSafras: data,
    })
  },
  setNavigation: (abas) => {
    set({
      aba: abas,
    })
  },
  findClientById: (data, clientId) => {
    const client = data ? data.find((client) => client.cliente_id === clientId) : undefined
    set({
      client,
    })
  },
  refreshSummaryData: async () => {
    const {
      getFieldController,
      refreshHectareInfo,
      getValidationData,
      getValidationSumary,
      getRefs,
      data,
      client,
    } = get()

    await Promise.all([
      refreshHectareInfo(),
      getFieldController(fieldControllerDataMapper(data), client?.cliente_id),
      getValidationData(validationDataMapper(data), client?.cliente_id),
      getValidationSumary(validationSummaryMapper(data), client?.cliente_id),
      getRefs(client?.cliente_id),
    ])
  },
  refreshHectareInfo: async () => {
    const { getHectareByResult, setHectareInfo, data, client } = get()
    const results: any[] = await getHectareByResult(submitValidation(data), client?.cliente_id)

    setHectareInfo(transformHectareByResult(results))
  },
}))
