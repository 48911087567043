import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Box, Grid, Paper, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'

import HeadersDetails from '~/shared/headers-details/headers-details'
import { useClientStore } from '~/store/client/client-store'

import AgproClient from './components/agpro-client'
import ContactsMade from './components/contacts-made'
import CtvClient from './components/ctv-client'
import DetailFarm from './components/details-farm'
import EquipeClient from './components/equipe-client'
import InfoFarmer from './components/info-client'
import strings from './strings'
import { useStyles } from './styles'

type Params = {
  cliente_id: string
}

const ClientDetails: React.FC = () => {
  const classes = useStyles()
  const { cliente_id } = useParams<Params>()
  const getClientById = useClientStore((state) => state.getClientById)
  const { client: clientData } = useClientStore()
  const [valueTabs, setValueTabs] = useState<string>('1')

  const history = useHistory()

  const onBack = () => {
    history.goBack()
  }

  useEffect(() => {
    getClientById(cliente_id)
  }, [])

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: string) => {
    setValueTabs(newValue)
  }

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <HeadersDetails nameTitle={clientData?.nome} onClickBack={onBack} />

        <Grid container item xs={12} spacing={0} className={classes.bodyInformation}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {clientData && <InfoFarmer dadosClient={clientData} />}
            </Grid>
            <Box sx={{ width: '100%', fontFamily: 'body1' }}>
              <TabContext value={valueTabs}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    style={{
                      marginLeft: '16px',
                      marginRight: '16px',
                      border: 'solid 1px rgba(0, 0, 0, 0.12)',
                      borderEndStartRadius: '0px',
                      borderEndEndRadius: '0px',
                      borderBottom: 'none',
                    }}
                  >
                    <Tab className={classes.tabs} label={strings.tabsLabel.tabA} value="1" />
                    <Tab className={classes.tabs} label={strings.tabsLabel.tabB} value="2" />
                    <Tab className={classes.tabs} label={strings.tabsLabel.tabC} value="3" />
                  </TabList>
                </Box>
                <TabPanel style={{ padding: '0px 16px' }} value="1">
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      <DetailFarm cliente_id={cliente_id} />
                    </Typography>
                  </Grid>
                </TabPanel>
                <TabPanel style={{ padding: '0px 16px' }} value="2">
                  <Grid item xs={12}>
                    <EquipeClient cliente_id={cliente_id} type={1} title={strings.team} />
                  </Grid>
                  <Grid item xs={12}>
                    <CtvClient cliente_id={cliente_id} type={2} title={strings.ctv} />
                  </Grid>
                  <Grid item xs={12}>
                    <AgproClient cliente_id={cliente_id} title={strings.agpro} />
                  </Grid>
                </TabPanel>
                <TabPanel style={{ padding: '0px 16px' }} value="3">
                  <Grid item xs={12}>
                    <ContactsMade cliente_id={cliente_id} />
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default ClientDetails
