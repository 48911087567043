import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    padding: '20px 20px 20px 0',
    maxHeight: 1080,
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  resumeAuditTable: {
    flex: 1,
    display: 'flex',
    height: '80vh',
  },
  containerFormsModal: {
    width: '100%',
  },
  buttonTable: {
    background: '#24D2B5',
    color: '#ffffff',
    transition: '0.2s',
    '&:hover': {
      background: '#24D2B5',
      filter: `brightness('0.9')`,
    },
  },
  textInput: {
    width: '100%',
    color: '#00446B',
    borderRadius: '30px',
  },
  tableCellStye: {
    borderBottom: 'none',
  },
  modalStyle: {
    maxWidth: 800,
    padding: 30,
  },
  helperText: {
    color: theme.palette.error.main,
  },
  arrowPosition: {
    marginTop: 50,
    fontSize: 25,
  },
  titlePosition: {
    marginBottom: 10,
  },
  formStyle: {
    width: '100%',
  },
}))
