/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'

import { Button, CircularProgress } from '@material-ui/core'
import { DataGrid, GridColDef, GridRowId } from '@material-ui/data-grid'
import DeleteIcon from '@material-ui/icons/Delete'
import Swal from 'sweetalert2'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useFilterAuditState } from '~/store/audit/filter-audit'
import { useValidationStore } from '~/store/validation/validation-store'

interface IValidationDataProps {
  talhoes: any
  alterChangeSingleDetailPainel: () => void
}
export const FarmerGrid: React.FC<IValidationDataProps> = ({
  talhoes,
  alterChangeSingleDetailPainel,
}) => {
  const { items } = useFilterAuditState()
  const { deleteResults, isLoadingRemoveResultState } = useValidationStore()
  const [resultIds, setResultIds] = useState<GridRowId[]>([])
  const columns: GridColDef[] = [
    {
      field: 'nome_talhao',
      headerName: 'Talhão',
      flex: 0.2,
      width: 150,
      minWidth: 120,
    },

    {
      field: 'area_plantada',
      headerName: 'Área Plantada',
      width: 200,
    },
    {
      field: 'produtividade',
      headerName: 'Produtividade',
      width: 120,
      flex: 0.2,
    },
    {
      field: 'created_at',
      headerName: 'Data Cadastro',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      renderCell: (params) => {
        const date = new Date(params.row?.created_at)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
    },
    {
      field: 'plantas_ha',
      headerName: 'Plantas/ha',
      width: 120,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'acoes',
      headerName: 'Ações',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => {
        return isLoadingRemoveResultState ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            disabled={resultIds.length === 0}
            style={
              resultIds.length === 0
                ? { backgroundColor: '#c1c1c1', fontSize: '10px' }
                : {
                    backgroundColor: '#FF5C6C',
                    color: '#fff',
                    fontSize: '10px',
                  }
            }
            onClick={async () => {
              Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Deseja realmente remover este(s) resultado(s)?',
                confirmButtonColor: '#2b78c0',
                showCancelButton: true,
                cancelButtonColor: '#ff7d89',
                confirmButtonText: 'Sim, remova',
                cancelButtonText: 'Não, cancele',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCloseButton: true,
                customClass: {
                  container: 'alert-container',
                },
              }).then(async (result: any) => {
                if (result.isConfirmed) {
                  deleteResults(resultIds)
                }
              })
            }}
          >
            <DeleteIcon />
          </Button>
        )
      },
    },
  ]

  return (
    <DataGrid
      style={{ margin: 5 }}
      rows={talhoes}
      columns={columns}
      disableSelectionOnClick
      autoHeight
      checkboxSelection
      onSelectionModelChange={(ids: GridRowId[]) => {
        setResultIds(ids)
        alterChangeSingleDetailPainel()
      }}
      filterModel={{ items }}
      getRowId={(row) => row.resultado_id}
      localeText={GRID_DEFAULT_LOCALE_TEXT}
    />
  )
}
