import { ReactElement } from 'react'

import { Box, Link, Typography } from '@material-ui/core'

export default function Copyright(): ReactElement {
  const currentYearText = ` ${new Date().getFullYear()}.`

  return (
    <Box
      style={{
        width: '100%',
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="http://attosementes.com.br/">
          ATTO Intelligence
        </Link>
        {currentYearText}
      </Typography>
    </Box>
  )
}
