import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  gridMain: {
    display: 'flex',
    padding: '20px 20px 20px 0',
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    height: '100%',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  primary: {
    color: '#455a64',
    fontSize: 20,
    fontWeight: 400,
  },
  secundary: {
    color: '#455a64',
    fontSize: 15,
  },

  typography: {
    fontSize: 28,
    fontWeight: 400,
    color: '#455a64',
    textAlign: 'left',
    marginLeft: '16px',
  },
  buttonVisably: {
    backgroundColor: '#48bbe6',
    color: '#fff',
    fontSize: '10px',
  },
}))
