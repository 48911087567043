import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, TextField, MenuItem, Fade, FormControl } from '@material-ui/core'
import _ from 'lodash'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import * as yup from 'yup'
import 'react-phone-input-2/lib/material.css'

import Modal from '~/components/modal'
import { CargoConsultoria, Status, DefaultInput, PerfilId } from '~/constants/data-field'
import { userCreateMapper } from '~/mappers/user-mapper'
import { useConsultacyStore } from '~/store/consultancy/consultancy-store'
import { useUserStore } from '~/store/user/user-store'
import {
  PropsFormCreateUser,
  FormsHandleEdit,
  RequestDataUser,
} from '~/types/user-types/user-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import strings from './strings'
import { useStyles } from './style'

const schema = yup.object({
  email: yup.string().email('Formato de email Inválido.').required('Formato de email Inválido.'),
  cpf: yup.string().nullable(),
  celular: yup.string().required('Celular é obrigatório'),
  nome: yup.string().required('Nome é obrigatório'),
  status: yup.string().required('Campo é obrigatório'),
  cargo_id: yup.string().required('Campo é obrigatório'),
  perfil_id: yup.string().required('Campo é obrigatório'),
})

const EditUser: React.ForwardRefRenderFunction<FormsHandleEdit> = (props, ref) => {
  const classes = useStyles()
  const {
    isLoading,
    messageError,
    clearFormsCreateUser,
    modalControllerChangeEdit,
    controllerModalStateEdit,
  } = useUserStore()

  const { UserConsultant, requestUpdateUserConsultancy } = useConsultacyStore()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PropsFormCreateUser>({
    resolver: yupResolver(schema),
  })
  const onSubmit: SubmitHandler<any> = async (usersData) => {
    const userMapper = userCreateMapper(usersData)
    const userData = _.omitBy(userMapper, _.isNil) as RequestDataUser
    await requestUpdateUserConsultancy(UserConsultant?.id, userData)
  }

  const closeModal = () => {
    modalControllerChangeEdit(false)
    clearFormsCreateUser()
  }

  useEffect(() => {
    reset({
      ...UserConsultant,
      celular:
        UserConsultant.celular && UserConsultant.celular.length >= 10
          ? `55${UserConsultant.celular}`
          : `595${UserConsultant.celular}`,
      perfil_id: UserConsultant.perfil_id ?? DefaultInput.associationAdminDefault,
    })
  }, [reset, UserConsultant])

  useImperativeHandle(ref, () => {
    return {
      openModalEditForms() {
        modalControllerChangeEdit(true)
        reset()
      },
    }
  })

  return (
    <Modal
      open={controllerModalStateEdit}
      onClose={closeModal}
      title={strings.textModal.editModalTitle}
      onSave={handleSubmit(onSubmit)}
      save
      isLoad={isLoading}
      disableCloseClickOut
      scroll
    >
      <Fade in={controllerModalStateEdit}>
        <FormControl
          onSubmit={handleSubmit(onSubmit)}
          variant="outlined"
          className={classes.formStyle}
        >
          <Grid container spacing={2} className={classes.modalStyle}>
            <Grid item sm={!_.isEmpty(UserConsultant.cpf) ? 7 : 12}>
              <Controller
                name="nome"
                control={control}
                defaultValue={UserConsultant.nome ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="nome"
                    variant="outlined"
                    label="Nome completo"
                    placeholder="Nome do usuário"
                    className={classes.textInput}
                    error={!!errors?.nome}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.nome?.message}
                  />
                )}
              />
            </Grid>

            {!_.isEmpty(UserConsultant.cpf) ? (
              <Grid item sm={5}>
                <Controller
                  name="cpf"
                  control={control}
                  defaultValue={UserConsultant.cpf ?? ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      id="cpf"
                      variant="outlined"
                      label="CPF"
                      placeholder="CPF"
                      className={classes.textInput}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                      error={(!!errors?.cpf || !_.isEmpty(messageError?.cpf)) && true}
                      helperText={errors.cpf?.message ?? messageError?.cpf}
                      value={maskInterceptor('cpf')(field.value)}
                    />
                  )}
                />
              </Grid>
            ) : null}

            <Grid item sm={7}>
              <Controller
                name="email"
                control={control}
                defaultValue={UserConsultant.email ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="email"
                    variant="outlined"
                    label="E-mail"
                    placeholder="E-mail"
                    className={classes.textInput}
                    error={(!!errors?.email || !_.isEmpty(messageError?.email)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.email?.message ?? messageError?.email}
                  />
                )}
              />
            </Grid>

            <Grid item sm={5}>
              <Controller
                name="celular"
                control={control}
                defaultValue={UserConsultant.celular ?? ''}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    required
                    id="celular"
                    defaultCountry="br"
                    label="Celular"
                    variant="outlined"
                    onlyCountries={['br', 'py']}
                    masks={{ py: '+... ... ......' }}
                    error={(!!errors?.celular || !_.isEmpty(messageError?.celular)) && true}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.celular?.message ?? messageError?.celular}
                    className={classes.textInput}
                    InputProps={{
                      style: {
                        borderRadius: 30,
                        width: '100%',
                        color: '#00446B',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Controller
                name="status"
                control={control}
                defaultValue={UserConsultant.status ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="status"
                    variant="outlined"
                    label="Status"
                    placeholder="Status"
                    className={classes.textInput}
                    key={UserConsultant.status}
                    defaultValue={UserConsultant.status}
                    error={!!errors?.status}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.status?.message}
                  >
                    {Status.map((Option) => (
                      <MenuItem key={Option.statusValue} value={Option.statusValue}>
                        {Option.statusName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="cargo_id"
                control={control}
                defaultValue={UserConsultant.cargo_id ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    id="cargo"
                    variant="outlined"
                    label="Cargo"
                    placeholder="Cargo"
                    className={classes.textInput}
                    key={UserConsultant.cargo_id}
                    defaultValue={UserConsultant.cargo_id ?? ''}
                    error={!!errors?.cargo_id}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    helperText={errors.cargo_id?.message}
                  >
                    {CargoConsultoria.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item sm={6}>
              <Controller
                name="perfil_id"
                control={control}
                defaultValue={UserConsultant.perfil_id ?? ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    id="outlined-required"
                    variant="outlined"
                    label="Perfil"
                    placeholder="Perfil"
                    className={classes.textInput}
                    key={UserConsultant.perfil_id}
                    defaultValue={UserConsultant.perfil_id ?? ''}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                    }}
                  >
                    {PerfilId.map((Option) => (
                      <MenuItem key={Option.value} value={Option.value}>
                        {Option.nameValue}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Fade>
    </Modal>
  )
}

export default forwardRef(EditUser)
