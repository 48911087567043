import React, { ReactElement, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, Paper, Typography, TextField } from '@material-ui/core'
import { DataGrid, GridColDef, GridCellParams, GridValueGetterParams } from '@material-ui/data-grid'
import { AxiosError } from 'axios'

import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useAlert } from '~/hooks/message'
import { api } from '~/services/api'
import { useStoreMissions, PropsMissions } from '~/store/missions-store'

import strings from './strings'
import { useStyles } from './styles'

const columnsFarmer = (missions: PropsMissions[]): GridColDef[] => [
  { field: 'id', hide: true },
  {
    field: 'safra',
    headerName: 'Missão',
    flex: 0.2,
    sortable: false,
    renderCell: (params: GridCellParams) => (
      <Typography component="span" variant="subtitle2">
        {missions &&
          missions.map((value) => {
            const { row } = params
            const { mission_detail } = value
            if (
              row?.safra_id === mission_detail[0]?.safra_id &&
              row?.cultura_id === mission_detail[0]?.cultura_id
            )
              return value.titulo
            return ''
          })}
      </Typography>
    ),
    valueGetter: (params: GridValueGetterParams) => {
      return (
        missions &&
        missions.map((value) => {
          const { row } = params
          const { mission_detail } = value
          if (
            row?.safra_id === mission_detail[0]?.safra_id &&
            row?.cultura_id === mission_detail[0]?.cultura_id
          )
            return value.titulo
          return ''
        })
      )
    },
  },
  {
    field: 'interaction.data_contato',
    headerName: 'Data',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
    type: 'date',
    valueGetter: (params) => new Date(params.row?.interaction?.data_contato),
    valueFormatter: (params) =>
      new Date(params.row?.interaction?.data_contato).toLocaleDateString(),
  },
  {
    field: 'autorizou',
    headerName: 'Autorizou?',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'conhece_plantup',
    headerName: 'Conhece o PlantUP?',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'situacao',
    headerName: 'Situação',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'interacao',
    headerName: 'Etapa Interação',
    flex: 0.2,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => params.row?.interaction?.etapa_interacao,
    valueFormatter: (params) =>
      params.row?.interaction?.etapa_interacao === '0' ? 'Validação' : 'Auditoria',
  },
  {
    field: 'observacao',
    headerName: 'Observações',
    flex: 0.3,
    headerAlign: 'center',
    align: 'center',
    renderCell: (cellValues) => {
      return (
        <TextField
          value={cellValues.row?.observacao}
          InputProps={{ disableUnderline: true }}
          multiline
          style={{ width: '100%', height: '100%' }}
        />
      )
    },
  },
]

type PropsRows = {
  id?: number
  interacao_id: string
  observacao: string
  autorizou: string
  conhece_plantup: number
  situacao: string
  cultura_id: number
  safra_id: number
  cliente_id: number
  interaction: {
    id: number
    data_contato: Date
    etapa_interacao: string
  }
  harvest?: {
    id: number
    safra: string
  }
}
type ParamsRoute = {
  cliente_id: string
}

type Props = {
  filtros: any
}

function GridInteractionFarmer(props: Props): ReactElement {
  const classes = useStyles()
  const { cliente_id } = useParams<ParamsRoute>()
  const alert = useAlert()

  const [rows, setRows] = useState<PropsRows[]>([])
  const { missions } = useStoreMissions()
  const { filtros } = props
  useEffect(() => {
    api
      .get(`/interaction/farmer/${cliente_id}`)
      .then((res: any) => {
        res.data.map((r: any, i: any) => {
          r.id = i
          return r
        })
        setRows(res.data)
      })
      .catch((error: AxiosError) =>
        alert.show({ message: error.response?.data.message, type: 'error' }),
      )
  }, [])

  return (
    <Grid xs={12} sm={12} item>
      <Paper variant="outlined" className={`${classes.paper} ${classes.paperSummaryAudit}`}>
        <Typography variant="h5" className={classes.textTitleCard}>
          {strings.interationFarmer.title}
        </Typography>
        <DataGrid
          rows={rows}
          columns={columnsFarmer(missions)}
          disableSelectionOnClick
          localeText={GRID_DEFAULT_LOCALE_TEXT}
          autoHeight
          rowHeight={65}
          {...filtros}
        />
      </Paper>
    </Grid>
  )
}

export default GridInteractionFarmer
