/* eslint-disable prettier/prettier */
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { api } from '~/services/api'
import { Page, SourcePage } from '~/types/pagination-types'
import { UserFilters, UserList, UsersQueryParams } from '~/types/user-types/user-types'
import { removeCountryOfPhone } from '~/utils'
import { convertToPageUsers } from '~/utils/functions/functions'
import { maskRemove } from '~/utils/mask-interceptor'

export const useUsersQuery = ({
  size,
  page,
  filters,
}: UsersQueryParams): UseQueryResult<Page<UserList>> => {
  const fetchUsers = async ({
    size,
    page,
    filters,
  }: {
    size: number
    page: number
    filters: UserFilters | null
  }): Promise<Page<UserList>> => {
    let formatCpf: string | undefined
    if (filters?.cpf) {
      formatCpf = maskRemove(filters.cpf)
    }

    let formatCelular: string | undefined
    if (filters?.celular) {
      const removeCountry = removeCountryOfPhone(filters?.celular)
      formatCelular = maskRemove(removeCountry)
    }

    const { data }: { data: SourcePage<UserList> } = await api.get('/users', {
      params: {
        page,
        size,
        nome: filters?.nome,
        cpf: formatCpf,
        perfil_admin: filters?.perfil_admin,
        email: filters?.email,
        celular: formatCelular,
        cargo: filters?.cargo,
        status: filters?.status,
        validacao_auditoria_automatica: filters?.validacao_auditoria_automatica,
      },
    })

    return convertToPageUsers(data)
  }

  return useQuery<Page<UserList>>({
    queryKey: ['users', size, page, filters],
    queryFn: () => fetchUsers({ page, size, filters }),
  })
}
