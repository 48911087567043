import { ClientProps } from '~/types/client-types'

export function clientUpdateMapper(dados: ClientProps) {
  return {
    ...dados,
    pais_celular:
      dados?.celular?.replace('595', '').replace('+', '').replace(/\s/g, '').length === 9
        ? '595'
        : '55',
    celular:
      dados?.celular?.length === 11
        ? dados.celular
            .replace(/\s/g, '')
            .replace('(', '')
            .replace(')', '')
            .replace('+', '')
            .replace('55', '')
        : dados?.celular
            ?.replace(/\s/g, '')
            .replace('(', '')
            .replace(')', '')
            .replace('+', '')
            .replace('55', '')
            .replace('595', ''),
  } as ClientProps
}
