import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%', // Fix IE 11 issue
  },
  buttonDelete: {
    color: theme.palette.error.main,
  },
  textInput: {
    minWidth: '300px',
    fontSize: '12px',
    color: '#00446B',
    borderRadius: '30px',
    paddingRight: '5px',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  divider: {
    height: 1.5,
    width: '100%',
    marginTop: '10px',
  },
  typography: {
    textAlign: 'left',
    marginLeft: '16px',
  },
}))
