export const TypeSafraEmuns: { [index: string]: any } = {
  '1': '1° Safra',
  '2': '2° Safra',
}

export const CultureCodesEnums: { [index: string]: any } = {
  '1': 'Soja',
  '2': 'Algodão',
  '3': 'Milho',
}

export const FonteEmuns: { [index: string]: any } = {
  '1': 'PlantUP	',
  '2': 'Protege',
  '3': 'Portal',
}

export const incompleteEmuns: { [index: string]: any } = {
  '1': 'Sapiens',
  '2': 'Protege',
  '3': 'Brain',
  '4': 'Equipe ATTO',
  '5': 'Referência do Cliente',
  '6': 'Cliente',
  '7': 'Outro',
}

export const interactionsEmuns: { [index: string]: any } = {
  '1': 'E-mail',
  '2': 'Ligação',
  '3': 'WhatsApp',
}

export const motivesEmuns: { [index: string]: any } = {
  '1': 'Aguardando Correção',
  '2': 'Aguardando Referências',
  '3': 'Área Incompleta',
  '4': 'Checando Referências',
  '5': 'Entar em Contato',
  '6': 'Lançamento Não Autorizado',
  '7': 'Não é Agricultor',
  '8': 'Não tem Interesse',
  '9': 'Tentativa De Contato',
  '10': 'Validação Ok',
}

export const plantupEmuns: { [index: string]: any } = {
  '1': 'Sim',
  '2': 'Não',
}

export const resultEmuns: { [index: string]: any } = {
  '1': 'Aprovado',
  '2': 'Em Andamento',
  '3': 'Incompleto',
  '4': 'Novo',
  '5': 'Pendente',
  '6': 'Reprovado',
}

export const TypeInteraction: { [index: string]: any } = {
  '1': 'Agpro',
  '2': 'Consultor',
  '3': 'CTV',
  '4': 'Diretor',
  '5': 'Gerente',
  '6': 'RCA',
}
