export const PerfilAdmin: { [index: string]: any } = {
  '0': '-- | --',
  '1': 'TI',
  '2': 'Admin',
  '3': 'Diretoria',
  '4': 'CTV',
}

export const StateCodes: { [index: string]: any } = {
  '1': 'Brasil',
  '168': 'Paraguai',
}

export const CultureCodes: { [index: string]: any } = {
  '1': 'Soja',
  '2': 'Algodão',
  '3': 'Milho',
}

export const ProfileCodes: { [index: string]: any } = {
  '1': 'Administrador',
  '2': 'Consultor',
}

export const TypeSafraEmuns: { [index: string]: any } = {
  '1': '1° Safra',
  '2': '2° Safra',
}

export const TypeAgpro: { [index: string]: any } = {
  '0': 'Não',
  '1': 'Sim',
}

export const Premium: { [index: string]: any } = {
  '0': 'Não',
  '1': 'Sim',
}

export const TypeCargos: { [index: string]: any } = {
  '10': 'Proprietário',
  '11': 'Supervisor',
  '12': 'Consultor',
  '13': 'Cargo Administrativo',
  '14': 'Gerente',
  '15': 'Coordenador',
  '1': 'Proprietário/Socio (a)',
  '2': 'Filho (a)',
  '6': 'Supervisor (a)',
  '7': 'Cargo administrativo (a)',
  '8': 'Agrônomo (a) da Propriedade',
  '9': 'Consultor Técnico (a) - Externo',
  '3': 'Diretor (a)',
  '4': 'Gerente',
  '5': 'Coordenador (a) (Agricultor)',
}

export const TypeValidacaoAutomaticaFilter: { [index: string]: any } = {
  '0': 'Não',
  '1': 'Sim',
  '2': 'Apenas validação',
}
