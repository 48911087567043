import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  },
  typography: {
    fontSize: 28,
    fontWeight: 400,
    color: '#455a64',
  },
  headerContainer: {
    marginBottom: 30,
    paddingLeft: 16,
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  buttonAdd: {
    backgroundColor: '#48bbe6',
    color: '#fff',
    fontSize: '10px',
  },
}))
