import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: '600px',
    width: '715px',
    border: '2px solid',
    boxShadow: theme.shadows[5],
    '&::-webkit-scrollbar': {
      width: '0.01em',
    },
  },
  gridHeader: {
    margin: theme.spacing(2),
  },
  gridMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    height: '100%',
    flexGrow: 1,
  },
  containerFormsModal: {
    height: '100%',
    margin: '0px',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  gridFooter: {
    flexDirection: 'row',
    padding: theme.spacing(1),
    width: '100%',
    minWidth: 600,
    flexGrow: 1,
  },
  saveButton: {
    textAlign: 'right',
  },
  textInput: {
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  form: {
    flexGrow: 1,
  },
  gridContainerForms: {
    height: 'auto',
  },
}))
