/* eslint-disable prettier/prettier */
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { api } from '~/services/api'
import { Page, SourcePage } from '~/types/pagination-types'
import { IValidation, IValidationFilters, ValidationQueryParams } from '~/types/validation-types'
import { removeCountryOfPhone } from '~/utils'
import { convertToPageValidation, formatNumberQtyTotal } from '~/utils/functions/functions'
import { maskRemove } from '~/utils/mask-interceptor'

export const useValidationQuery = ({
  size,
  page,
  filters,
}: ValidationQueryParams): UseQueryResult<Page<IValidation>> => {
  const fetchValidation = async ({
    size,
    page,
    filters,
  }: {
    size: number
    page: number
    filters: IValidationFilters | null
  }): Promise<Page<IValidation>> => {
    let formatCpfCNPJ: string | undefined
    if (filters?.cpf_cnpj_agricultor) {
      formatCpfCNPJ = maskRemove(filters.cpf_cnpj_agricultor)
    }

    let formatCelular: string | undefined
    if (filters?.celular_agricultor) {
      const removeCountry = removeCountryOfPhone(filters?.celular_agricultor)
      formatCelular = maskRemove(removeCountry)
    }

    let formatQtyTotal: string | undefined
    if (filters?.qtde_total) {
      formatQtyTotal = formatNumberQtyTotal(filters?.qtde_total)
    }

    const { data }: { data: SourcePage<IValidation> } = await api.get('validation', {
      params: {
        page,
        size,
        cliente_nome: filters?.cliente_nome,
        cpf_cnpj_agricultor: formatCpfCNPJ,
        celular_agricultor: formatCelular,
        email: filters?.email,
        descricao_alterado_por: filters?.descricao_alterado_por,
        descricao_situacao: filters?.descricao_situacao,
        qtde_total: formatQtyTotal,
        ultima_data_envio_validacao: filters?.ultima_data_envio_validacao,
      },
    })
    return convertToPageValidation(data)
  }

  return useQuery<Page<IValidation>>({
    queryKey: ['validation', size, page, filters],
    queryFn: () => fetchValidation({ page, size, filters }),
  })
}

export const useValidationListModifiedCityQuery = ({
  size,
  page,
  filters,
}: ValidationQueryParams): UseQueryResult<Page<IValidation>> => {
  const fetchValidation = async ({
    size,
    page,
    filters,
  }: {
    size: number
    page: number
    filters: IValidationFilters | null
  }): Promise<Page<IValidation>> => {
    let formatCpfCNPJ: string | undefined
    if (filters?.cpf_cnpj_agricultor) {
      formatCpfCNPJ = maskRemove(filters.cpf_cnpj_agricultor)
    }

    let formatCelular: string | undefined
    if (filters?.celular_agricultor) {
      const removeCountry = removeCountryOfPhone(filters?.celular_agricultor)
      formatCelular = maskRemove(removeCountry)
    }

    let formatQtyTotal: string | undefined
    if (filters?.qtde_total) {
      formatQtyTotal = formatNumberQtyTotal(filters?.qtde_total)
    }

    const { data }: { data: SourcePage<IValidation> } = await api.get('validation/list/changes', {
      params: {
        page,
        size,
        cliente_nome: filters?.cliente_nome,
        cpf_cnpj_agricultor: formatCpfCNPJ,
        celular_agricultor: formatCelular,
        email: filters?.email,
        descricao_alterado_por: filters?.descricao_alterado_por,
        descricao_situacao: filters?.descricao_situacao,
        qtde_total: formatQtyTotal,
        ultima_data_envio_validacao: filters?.ultima_data_envio_validacao,
      },
    })
    return convertToPageValidation(data)
  }

  return useQuery<Page<IValidation>>({
    queryKey: ['validation', size, page, filters],
    queryFn: () => fetchValidation({ page, size, filters }),
  })
}
