import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import _ from 'lodash'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import Modal from '~/components/modal'
import { farmer } from '~/constants/data-field'
import { safraFieldMapper, submitFieldController } from '~/mappers/validation-mapper'
import { useValidationStore } from '~/store/validation/validation-store'
import { IModalField } from '~/types/validation-types'

import { ValidationSchema } from './schemaValidation'
import strings from './strings'
import { useStyles } from './styles'

const schema = yup.object(ValidationSchema)

const ModalField: React.ForwardRefRenderFunction<IModalField> = (props, ref) => {
  const {
    SafraField,
    isLoadingButton,
    client,
    createNewFieldController,
    messageError,
    clearForms,
  } = useValidationStore()

  const [modalFieldController, setModalFieldController] = useState<boolean>(false)

  const handleCreateNewField: SubmitHandler<any> = async (data) => {
    const dataMapper = submitFieldController(data, client?.cliente_id)
    createNewFieldController(dataMapper)
  }
  const closeModal = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Deseja cancelar a inserção?',
      confirmButtonColor: '#2b78c0',
      showCancelButton: true,
      cancelButtonColor: '#ff7d89',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true,
      customClass: {
        container: 'alert-container',
      },
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setModalFieldController(false)
      }
    })
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm<any>({ resolver: yupResolver(schema) })
  useImperativeHandle(ref, () => {
    return {
      actionsController() {
        reset()
        clearForms()
        setModalFieldController(true)
        setValue('cultura', safraFieldMapper(SafraField))
      },
    }
  })
  const classes = useStyles()

  return (
    <Modal
      open={modalFieldController}
      onClose={closeModal}
      save
      title={strings.titleModal}
      disableCloseClickOut
      isLoad={isLoadingButton}
      onSave={handleSubmit(handleCreateNewField)}
    >
      <Grid container spacing={3} className={classes.modalStyle}>
        <Grid item sm={6}>
          <Controller
            name="agricultor"
            control={control}
            defaultValue="1"
            render={({ field }) => (
              <FormControl variant="outlined" className={classes.selectInput}>
                <InputLabel id="agricultor_id">Agricultor*</InputLabel>
                <Select
                  {...field}
                  labelId="agricultor_label"
                  id="agricultor_id"
                  label="agricultor_id"
                  autoWidth
                  required
                  error={!!errors?.agricultor}
                >
                  {farmer.map((Option) => (
                    <MenuItem key={Option.value} value={Option.value}>
                      {Option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.agricultor?.message && (
                  <FormHelperText className={classes.helperText}>
                    {errors.agricultor?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="area_consultada"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="area_consultada"
                variant="outlined"
                defaultValue=""
                label="Área Consultada"
                placeholder="Área Consultada"
                className={classes.selectInput}
                error={
                  (!!errors?.area_consultada || !_.isEmpty(messageError?.area_consultada)) && true
                }
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.area_consultada?.message ?? messageError?.area_consultada}
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="fonte"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                required
                id="fonte"
                variant="outlined"
                label="Fonte Consultada"
                defaultValue=""
                placeholder="Fonte Consultada"
                className={classes.selectInput}
                error={!!errors?.fonte}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                helperText={errors.fonte?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name="cultura"
            control={control}
            render={({ field }) => (
              <FormControl variant="outlined" className={classes.selectInput}>
                <Autocomplete
                  {...field}
                  multiple
                  key={Math.random()}
                  id="cultura"
                  options={SafraField}
                  getOptionLabel={(option) => option.descricao}
                  onChange={(e, values) => setValue('cultura', values)}
                  isOptionEqualToValue={(option, value) => option.descricao === value.descricao}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cultura"
                      required
                      variant="outlined"
                      error={!!errors?.cultura}
                      helperText={errors.cultura?.message ?? messageError?.cultura}
                    />
                  )}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}
export default forwardRef(ModalField)
