import create from 'zustand'
import { persist } from 'zustand/middleware'

import { api } from '~/services/api'

type AuditList = {
  cliente_id: number
  desc_status_auditoria: string
  agricultor: string
  safra: string
  data_ultima_validacao: Date
  id: number
}

type AuthState = {
  isLoading: boolean
  messageError: string
  expiresIn: Date
  auditList: AuditList[]
}

type AuthActions = {
  load: () => Promise<void>
  clearError: () => void
}

type State = AuthState & AuthActions

const initialState: AuthState = {
  isLoading: false,
  messageError: '',
  expiresIn: new Date(Date.now() - 86400 * 1000),
  auditList: [],
}

export const useAuditListStore = create<State>(
  persist(
    (set) => ({
      ...initialState,
      load: async () => {
        set({ isLoading: true, messageError: '' })
        try {
          const { data: auditList } = await api.get('/audit/list')

          set({
            isLoading: false,
            auditList,
          })
        } catch (error: any) {
          set({
            isLoading: false,
            messageError: error?.response?.data?.message,
          })
        }
      },
      clearError: () => {
        set({ messageError: '' })
      },
    }),
    {
      name: 'auditList',
    },
  ),
)
