import React from 'react'

import { Grid, Typography, TextField } from '@material-ui/core'

type FiltersProps = {
  columnField: string
  value: string
}

interface Props {
  params: {
    headerName?: string
    field: string
  }
  items: any[]
  filtros: FiltersProps[]
  addFiltros: (filtros: FiltersProps[]) => void
  setFilter: (statesList: any[]) => void
}

const SearchTable: React.FC<Props> = ({ params, items, setFilter, filtros, addFiltros }) => {
  const { headerName, field } = params

  const handleFilter = (
    field: string,
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newFilters = filtros.filter((filtro) => filtro.columnField !== field)
    newFilters.push({ columnField: field, value: value.target.value })

    addFiltros(newFilters)
    const filteredItems = newFilters.reduce(
      (acc, filtro) =>
        acc.filter((item) =>
          item[filtro.columnField].toLowerCase().includes(filtro.value.toLowerCase()),
        ),
      items,
    )

    setFilter(filteredItems)
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center">
      <Typography variant="body1"> {headerName} </Typography>
      <TextField
        id="outlined-search"
        placeholder={`Pesquisar ${headerName}`}
        type="search"
        variant="outlined"
        size="small"
        onChange={(value) => handleFilter(field, value)}
      />
    </Grid>
  )
}

export default SearchTable
