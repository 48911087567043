import React from 'react'

import { Box } from '@material-ui/core'

interface TabPanelProps {
  children: React.ReactNode
  index: number
  value: number
}

export const TablePanel: React.FC<TabPanelProps> = ({ children, index, value, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
