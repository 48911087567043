import Swal from 'sweetalert2'
import create, { GetState, SetState } from 'zustand'

import { api } from '~/services/api'
import {
  VorietyListProps,
  CultureProps,
  GeneticProps,
  TecnologyProps,
  VorietyTransterOutputProps,
  VarietyProps,
  FeaturesProps,
} from '~/types/grow-crops-types'

type GrowCropsState = {
  isLoading: boolean
  isLoadingModal: boolean
  postModalController: boolean
  messageError: any
  errorsUsers: boolean
  expiresIn: Date
  growCropsList: VorietyListProps[]
  controllerModalTabs: boolean
  progress: number
  cultureState: string
  variety: any
  geneticList: GeneticProps[]
  tecnologyList: TecnologyProps[]
  cultureList: CultureProps[]
  featuresList: FeaturesProps[]
  isAlertActive: boolean
  alterChange: boolean
  updateAction: boolean
}

type GrowCropsActions = {
  clearFormsCreateUser: () => void
  createVariety: (params: VorietyTransterOutputProps) => Promise<void>
  modalTabsController: (value: boolean) => void
  getCultureList: () => Promise<void>
  getTechnologyList: () => Promise<void>
  getGeneticList: () => Promise<void>
  setCultureValue: (cultureValue: string) => void
  getVarietyById: (varietyId?: number) => Promise<VarietyProps>
  updateVarietyById: (params: VorietyTransterOutputProps, varietyId?: number) => Promise<void>
  getFeaturesList: () => Promise<void>
  handleActionChangeFieldValues: (activeParams: boolean) => void
  modalPostController: (value: boolean) => void
  createdTechnology: (paramsTechnology: string) => Promise<void>
  createdGenetic: (paramsGenetic: string) => Promise<void>
  setAlterChange: (params: boolean) => void
  setUpdateAction: () => void
}

type State = GrowCropsState & GrowCropsActions

const initialState: GrowCropsState = {
  isLoading: false,
  isAlertActive: false,
  postModalController: false,
  geneticList: [],
  tecnologyList: [],
  cultureList: [],
  variety: null,
  cultureState: '',
  isLoadingModal: false,
  messageError: '',
  errorsUsers: false,
  progress: 0,
  expiresIn: new Date(Date.now() - 86400 * 1000),
  growCropsList: [],
  controllerModalTabs: false,
  featuresList: [],
  alterChange: false,
  updateAction: false,
}

export const useGrowCropsStore = create<State>((set: SetState<State>, get: GetState<State>) => ({
  ...initialState,
  clearFormsCreateUser: () => {
    set({ messageError: '', errorsUsers: false })
  },
  modalPostController: (value) => {
    set({
      postModalController: value,
    })
  },
  setUpdateAction: () => {
    set({
      updateAction: true,
    })
  },
  getGeneticList: async () => {
    try {
      const { data } = await api.get(`/genetic/list`)
      set({
        geneticList: data,
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Ops Algo aconteceu de errado!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    }
  },
  createdTechnology: async (paramsTechnology) => {
    const { clearFormsCreateUser, modalPostController, getTechnologyList } = get()
    set({ isLoading: true })
    await api
      .post('/technology/', paramsTechnology)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          expiresIn: new Date(),
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          getTechnologyList()
          modalPostController(false)
        })
        clearFormsCreateUser()
      })
      .catch(() => {
        set({
          isLoading: false,
          errorsUsers: true,
        })
      })
  },
  setAlterChange: (params) => {
    set({
      alterChange: params,
    })
  },
  createdGenetic: async (paramsGenetic) => {
    const { clearFormsCreateUser, modalPostController, getGeneticList } = get()
    set({ isLoading: true })
    await api
      .post('/genetic/', paramsGenetic)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          expiresIn: new Date(),
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          getGeneticList()
          modalPostController(false)
        })
        clearFormsCreateUser()
      })
      .catch(() => {
        set({
          isLoading: false,
          errorsUsers: true,
        })
      })
  },
  getTechnologyList: async () => {
    try {
      const { data } = await api.get(`/technology/list`)
      set({
        tecnologyList: data,
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Oops Algo aconteceu de errado!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    }
  },
  getVarietyById: async (varietyId) => {
    const { data } = await api.get(`/variety/${varietyId}`)
    set({ variety: data })
    return data
  },
  createVariety: async (params) => {
    const { clearFormsCreateUser, modalTabsController } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .post('/variety', params)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          expiresIn: new Date(),
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalTabsController(false)
        })
        clearFormsCreateUser()
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errorsUsers: true,
        })
      })
  },
  updateVarietyById: async (params, paramsId) => {
    const { clearFormsCreateUser, modalTabsController } = get()
    set({ isLoading: true, messageError: '' })
    await api
      .put(`/variety/${paramsId}`, params)
      .then((resp) => {
        set({
          isLoading: false,
          messageError: '',
          expiresIn: new Date(),
          errorsUsers: false,
        })
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: resp.data.message,
          confirmButtonColor: '#2b78c0',
          customClass: {
            container: 'alert-container',
          },
        }).then(() => {
          modalTabsController(false)
        })
        clearFormsCreateUser()
      })
      .catch((err) => {
        set({
          isLoading: false,
          messageError: err.response?.data?.errors,
          errorsUsers: true,
        })
      })
  },
  getFeaturesList: async () => {
    const { data } = await api.get(`/features`)
    set({ featuresList: data })
  },
  getCultureList: async () => {
    try {
      const { data } = await api.get(`/culture/list`)
      set({
        cultureList: data,
      })
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Oops Algo aconteceu de errado!',
        text: error.message,
        confirmButtonColor: '#2b78c0',
        customClass: {
          container: 'alert-container',
        },
      })
    }
  },
  modalTabsController: (value) => {
    set({
      controllerModalTabs: value,
    })
  },
  setCultureValue: (cultureValue) => {
    set({
      cultureState: cultureValue,
    })
  },
  handleActionChangeFieldValues: (activeParams) => {
    set({ isAlertActive: activeParams })
  },
}))
