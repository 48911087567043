const strings = {
  title: 'Fazer login',
  email: {
    label: 'E-mail',
  },
  password: {
    label: 'Senha',
  },
  remember: {
    label: 'Lembrar-me',
  },
  buttons: {
    submit: 'Entrar',
    submitAgpro: 'Continuar com AgPro',
    forgotPassword: 'Esqueceu sua senha?',
    createAccount: 'Não tem uma conta?',
    registerAccount: 'Cadastre-se',
  },
  language: {
    english: 'Inglês(US)',
    portuguese: 'Português(BR)',
  },
}

export default strings
