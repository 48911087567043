import React from 'react'
import { Link } from 'react-router-dom'

import { Grid, Paper, Typography, Button } from '@material-ui/core'

import { useStyles } from './styles'

interface Props {
  description: string
  route: string
  title: string
}

const CardFastAccess: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <Grid item xs id="menu-card-container">
      <Paper elevation={3} className={`${classes.paper} ${classes.cardPaper}`}>
        <Typography variant="body1" id="menu-card-text">
          {props.description}
        </Typography>
        <Link to={props.route}>{children}</Link>
        <Link to={props.route}>
          <Button variant="contained" color="primary" id="button-menu-card">
            {props.title}
          </Button>
        </Link>
      </Paper>
    </Grid>
  )
}

export default CardFastAccess
