import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 700,
    width: '100%',
    maxWidth: 1100, // Fix IE 11 issue
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  logoItelligence: {
    width: '60%',
    marginBottom: '15px',
  },

  forgotPassword: {
    textAlign: 'right',
    alignSelf: 'center',
  },

  iconForgotPassword: {
    verticalAlign: 'top',
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '70%',
    borderRadius: '17px',
    height: '45px',
  },

  submitLogin: {
    backgroundColor: 'rgb(23,58,88)',
    border: '1px solid rgb(23,58,88)',
  },

  alert: {
    width: '50%',
  },
  selectInteration: {
    float: 'left',
    minWidth: '100%',
  },
  gridObservation: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  helperText: {
    color: theme.palette.error.main,
  },
  textInput: {
    width: '100%',
  },
}))
