import * as yup from 'yup'

export const validationSchema = yup.object({
  motivo: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),

  area_plantio: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),

  local: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),

  resultado: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),
  referencia: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),

  observacao: yup.string().when('interacao', {
    is: (exists: any) => !!exists,
    then: yup.string().required('Campo é obrigatorio'),
    otherwise: yup.string(),
  }),
})
