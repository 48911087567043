import React, { ReactElement, useState } from 'react'

import { Grid, Paper, Typography, IconButton } from '@material-ui/core'
import { Edit } from '@material-ui/icons'

import { ClientProps } from '~/types/client-types'

import DetailClient from './components/detail-client'
import EditClient from './components/edit-client'
import strings from './strings'
import { useStyles } from './styles'

type Props = {
  dadosClient: ClientProps
}

function InfoFarmer(props: Props): ReactElement {
  const classes = useStyles()
  const { dadosClient } = props

  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <Grid xs={12} sm={12} item>
      <Paper variant="outlined" className={`${classes.paper}`}>
        <Grid xs={12} container justifyContent="space-between">
          <Typography variant="h5"> {strings.infoFarmer} </Typography>
          <IconButton
            component="span"
            color="primary"
            onClick={() => setOpenModal(true)}
            id="button-editar-cliente"
          >
            <Edit />
          </IconButton>
        </Grid>
        {!!dadosClient && <DetailClient dadosClient={dadosClient} />}
      </Paper>
      <EditClient
        open={openModal}
        onClose={() => setOpenModal(false)}
        cliente_id={dadosClient?.id}
      />
    </Grid>
  )
}

export default InfoFarmer
