interface InputExtensionAreaI {
  cultura_id: number
  destino_id: DestinoOrigemI[]
  origem_id: number
}

interface DestinoOrigemI {
  descricao: string
  id: number
  pais_id: string
  sigla: string
}

interface OutPutExtensionAreaI {
  cultura_id: number
  destino_id: number[]
  origem_id: number
  tipo_vinculo: number
  status: number
}

export function extensioAreaMapper(params: InputExtensionAreaI) {
  return {
    origem_id: params?.origem_id,
    destino_id: params?.destino_id?.map((item) => item.id),
    status: 1,
    cultura_id: params.cultura_id,
    tipo_vinculo: params?.destino_id?.find((item) => item.id === 1) ? 1 : 2,
  } as OutPutExtensionAreaI
}
